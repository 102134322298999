import { granularityDescSort } from '@/constants/dateTime';
import { MenuItemType } from '@aloudata/aloudata-design';
import { getGranularityDisplayName } from '../helper';
import _ from 'lodash';
import { customGranularityList } from '@/common/globalInfo/appConfig';
import { IDateTag } from '@/typings/periodLimit';
import { EDateGranularityType } from '@/typings';
import { MenuInfo } from '@aloudata/aloudata-design/dist/Menu';
import t from '@/locales';

interface IProps {
  supportCustomCalendar?: boolean;
  dateTags?: IDateTag[];
  renderOptions?: (granularity: string[]) => string[];
  onClick?: (granularityName: string, dateTag?: string) => void;
}
export default function getGranularityMenu({
  supportCustomCalendar,
  dateTags,
  renderOptions,
  onClick,
}: IProps): MenuItemType[] {
  let renderGranularities: string[] = [];

  granularityDescSort.forEach((granularity) => {
    renderGranularities.push(granularity);
    if (supportCustomCalendar) {
      const matchCustomCalendar = _.filter(customGranularityList, (item) => {
        return item.timeGranularity === granularity;
      });
      matchCustomCalendar.forEach((item) => {
        renderGranularities.push(item.name);
      });
    }
  });
  if (renderOptions) {
    renderGranularities = _.filter(renderGranularities, (name) => {
      return renderOptions(renderGranularities).includes(name);
    });
  }

  const menuItems: MenuItemType[] = [];
  _.forEach(renderGranularities, (item) => {
    if (dateTags?.length && item === EDateGranularityType.DAY) {
      const dateTagMenuItems = dateTags.map((dateTag) => {
        return {
          label: dateTag.name,
          key: encodeOptionKey(EDateGranularityType.DAY, dateTag.exprCode),
          onClick: (e: MenuInfo) => {
            e.domEvent.stopPropagation();
            const { key } = e;
            const { granularity: granularityNameVal, dateTag: dateTagVal } =
              decodeOptionKey(key);
            onClick?.(granularityNameVal, dateTagVal);
          },
        };
      });
      menuItems.push({
        key: encodeOptionKey(item),
        label: getGranularityDisplayName(item),
        children: [
          {
            label: t.components.filter.customTimePicker.calendarDay,
            key: encodeOptionKey(EDateGranularityType.DAY),
            onClick: (e: MenuInfo) => {
              e.domEvent.stopPropagation();
              const { key } = e;
              const { granularity: granularityNameVal, dateTag: dateTagVal } =
                decodeOptionKey(key);
              onClick?.(granularityNameVal, dateTagVal);
            },
          },
          ...dateTagMenuItems,
        ],
      });
    } else {
      menuItems.push({
        key: encodeOptionKey(item),
        label: getGranularityDisplayName(item),
        onClick: (e) => {
          e.domEvent.stopPropagation();
          onClick?.(item);
        },
      });
    }
  });
  return menuItems;
}

const SEPARATOR = '__';
function encodeOptionKey(granularity: string, dateTag: string = '') {
  return `${granularity}${SEPARATOR}${dateTag || ''}`;
}

function decodeOptionKey(key: string) {
  const [granularity, dateTag] = key.split(SEPARATOR);
  return {
    granularity,
    dateTag: dateTag || undefined,
  };
}
