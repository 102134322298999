import { EDataSourceType } from '.';
import { IAuthority } from './authority';
import { TRefreshSettingGranularity } from '@/services/datasource/type';
import { IDatasetSourceInfo } from './dataset';
import { IUser } from './user';

export enum EDatasourceEntityType {
  CATALOG = 'CATALOG',
  SCHEMA = 'SCHEMA',
  TABLE = 'TABLE',
  SPACE = 'SPACE',
  COLUMN = 'COLUMN',
  FILE_CATALOG = 'FILE_CATALOG', // 前端使用的文件目录，服务端没有这个枚举
  FILE = 'FILE',
}

export interface IDatasourceItem {
  name: string; // 从引擎同步过来的数据源名称
  entityType: EDatasourceEntityType;
  guid: string; //代表了资源的路径信息
  fileSourcePath?: string; // file上传文件才有这个属性
}
export interface IDatasource extends IDatasourceItem {
  displayName: string;
  owner?: IUser;
  authority?: IAuthority;
  id: string; // 数据源的唯一标识
  datasourceType: EDataSourceType | null;
  icon: string;
  schema: string;
  extension: string;
  configuration: string;
  createTime: number;
  isEngine: boolean;
}
export interface IDatasourceChildrenItem extends IDatasourceItem {
  description?: string;
  path: IDatasourcePath[];
}
export interface IFileTableData {
  fileId: string;
  name: string;
  guid: string;
  description: string;
  displayName: string;
  entityType: EDatasourceEntityType;
  owner: IUser;
  createTime: number;
  updateTime: number;
  authority: IAuthority;
}

// 数据源路径中的目录项
export interface IDatasourceSchemaItem extends IDatasourceItem {
  children: IDatasourceSchemaItem[];
  path: IDatasourcePath[];
}

export interface IDatasourcePath {
  guid: string;
  name: string;
  entityType: EDatasourceEntityType;
}

// 数据源加速设置定义
export enum ERefreshSettingDateFormat {
  NOT_DATE = 'NOT_DATE',
  YYYYMMDD = 'yyyymmdd',
  YYYY_MM_DD = 'yyyy-mm-dd',
}

export enum ESign {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

export enum ERefreshMethod {
  ALL = 'ALL',
  INCREMENT = 'INCREMENT',
}

export enum ERefreshPeriod {
  MANUAL = 'MANUAL',
  TIMING = 'TIMING',
}

export interface IRefreshConfig {
  partitionColumnName?: string; // 如果有分区字段的话则为分区字段的名称
  dateFormat?: ERefreshSettingDateFormat; // 如果列是字符串/数字类型的，需要有日期格式
  dateGranularity?: TRefreshSettingGranularity; // 分区字段的粒度
  refreshPeriod: ERefreshPeriod;
  cron?: string; // 定时更新的表达式
  refreshMethod: ERefreshMethod;
  incrementRefreshScope?: IIncrementRefreshScope; // 相对于分区字段的偏移
}

export interface IIncrementRefreshScope {
  interval: number;
  sign: ESign;
}

export interface IPartitionMapping extends IIncrementRefreshScope {
  columnName: string;
}

export interface IPhysicalTableDetail extends IDatasetSourceInfo {}
