import {
  useGqlMutation,
  useGqlLazyQuery,
  useGqlQuery,
} from '@/common/utils/gqlRequest';
import {
  ESourceType,
  IDataset,
  IDatasetRelation,
  IDatasetSourceInfo,
  IPartitionMappingConfig,
  ISimpleDataset,
  TDatasetRelationRequest,
} from '@/typings/dataset';
import { IListItems } from '@/typings';
import { get, post } from '@/common/utils/request';
import {
  ICheckRelationReq,
  IDatasetRelationResponse,
  IErrorPath,
  IRelationDatasets,
  IServerDataset,
} from './types';
import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { gql } from '@apollo/client';
import {
  addSrcDatasetName2Relation,
  addSrcDatasetName2Relations,
} from './helper';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import { datasetDetailTpl, simpleDatasetTpl, sourceTpl } from './gql';
import { tenantInfo } from '@/common/globalInfo/appConfig';
import { useRequest } from 'ahooks';

/**
 * 获取数据集来源信息
 */
export function useQueryDatasetSourceData(
  onCompleted?: (data: { querySourceData: IDatasetSourceInfo }) => void,
) {
  const schema = gql`
    query querySourceData(
      $content: String
      $type: String
      $engineName: String
      $datasetName: String
    ) {
      querySourceData(
        content: $content
        type: $type
        engineName: $engineName
        datasetName: $datasetName
      ) {
        ${sourceTpl}
      }
    }
  `;
  const { data, run, loading } = useGqlLazyQuery<
    { querySourceData: IDatasetSourceInfo },
    {
      type: ESourceType;
      content: string;
      engineName: string;
      datasetName?: string;
    }
  >(schema, {
    onCompleted,
  });

  return {
    data,
    queryDatasource: useCallback(
      ({
        type,
        content,
        engineName = tenantInfo.engineInfo.name,
        datasetName,
      }: {
        type: ESourceType;
        content: string;
        engineName?: string;
        datasetName?: string;
      }) => {
        return run({
          type,
          content,
          engineName,
          datasetName,
        });
      },
      [run],
    ),
    loading,
  };
}

interface ISqlDatasetRequest {
  name?: String;
  sql: String;
}
export function useCheckSqlConfig() {
  const schema = gql`
    query checkSqlConfig($sqlDatasetRequest: SqlDatasetRequest) {
      checkSqlConfig(sqlDatasetRequest: $sqlDatasetRequest)
    }
  `;
  return useGqlLazyQuery<
    { checkSqlConfig: boolean },
    { sqlDatasetRequest: ISqlDatasetRequest }
  >(schema);
}

export const QUERY_DATASET_INFO_SCHEMA = gql`
    query queryDatasetInfo($name: String) {
      queryDatasetInfo(name: $name) {
        ${datasetDetailTpl}
      }
    }
  `;

/**
 * 手动获取单个数据集的详情
 */
export function useGetDatasetInfo() {
  const { data, loading, run } = useGqlLazyQuery<
    { queryDatasetInfo: IDataset },
    { name: string }
  >(QUERY_DATASET_INFO_SCHEMA);

  const resData = useMemo(() => {
    const dataset = data?.queryDatasetInfo;
    return parseDatasetInfo(dataset);
  }, [data]);

  return {
    data: resData,
    loading,
    run: useCallback(
      async (params: { name: string }) => {
        const res = await run(params);
        return parseDatasetInfo(res.queryDatasetInfo)!;
      },
      [run],
    ),
  };
}

function parseDatasetInfo(dataset?: IDataset): IDataset | null {
  if (!dataset) {
    return null;
  }
  return parseColumnsWithDataType<IDataset>(dataset);
}

/**
 * 获取数据集列表
 */
export interface IDatasetListRequest {
  name?: string;
  authority: 'ALL' | 'OWNER' | 'USER' | 'AUTHORITY';
  ownerIds?: string[];
  categoryId?: string;
  pager: {
    pageSize: number;
    pageNumber: number;
  };
}
export const QUERY_DATASET_LIST_SCHEMA = gql`
query queryDatasetList($datasetListRequest: DatasetListRequest) {
  queryDatasetList(datasetListRequest: $datasetListRequest) {
    data {
      ${simpleDatasetTpl}
    }
    hasNext
    total
    pageNumber
    pageSize
  }
}
`;
export function useGetDatasetList(
  reqParams: IDatasetListRequest,
  opt?: {
    onCompleted?: (data: {
      queryDatasetList: IListItems<ISimpleDataset>;
    }) => void;
    skip?: boolean;
  },
) {
  const schema = QUERY_DATASET_LIST_SCHEMA;
  const { data, loading, refetch } = useGqlQuery<
    {
      queryDatasetList: IListItems<ISimpleDataset>;
    },
    {
      datasetListRequest: IDatasetListRequest;
    }
  >(
    schema,
    {
      datasetListRequest: reqParams,
    },
    {
      onCompleted: opt?.onCompleted,
      skip: opt?.skip,
    },
  );

  const listWithDataType = useMemo(() => {
    const list = data?.queryDatasetList?.data;
    if (!list) {
      return null;
    }
    return _.map(list, (item) => {
      return parseColumnsWithDataType<ISimpleDataset>(item);
    });
  }, [data]);

  return {
    datasetList: listWithDataType,
    data: data?.queryDatasetList,
    loading,
    refetch,
  };
}
export function useGetDatasetListLazy() {
  const schema = QUERY_DATASET_LIST_SCHEMA;
  const { data, run, loading } = useGqlLazyQuery<
    {
      queryDatasetList: IListItems<ISimpleDataset>;
    },
    {
      datasetListRequest: IDatasetListRequest;
    }
  >(schema);

  const listWithDataType = useMemo(() => {
    const list = data?.queryDatasetList?.data;
    if (!list) {
      return null;
    }
    return _.map(list, (item) => {
      return parseColumnsWithDataType<ISimpleDataset>(item);
    });
  }, [data]);

  return {
    datasetList: listWithDataType,
    data: data?.queryDatasetList,
    loading,
    run,
  };
}
/**
 * 新增单个关联
 */
export function useCreateRelation() {
  const schema = gql`
    mutation createRelation($relation: RelationRequest) {
      createRelation(relation: $relation)
    }
  `;
  const { run, loading } = useGqlMutation<
    { createRelation: boolean },
    { relation: TDatasetRelationRequest }
  >(schema);

  return {
    run: useCallback(
      (params: {
        relation: IDatasetRelation;
        srcDataset: IDataset;
        dstDataset: IDataset;
      }) => {
        const { relation, srcDataset } = params;
        const serverRelation = addSrcDatasetName2Relation(
          _.omit(relation, ['srcDataset', 'dstDataset']),
          srcDataset.name,
        );
        return run({
          relation: _.omit(serverRelation, ['id']),
        });
      },
      [run],
    ),
    loading,
  };
}

/**
 * 修改单个关联
 */
export function useUpdateRelation() {
  const schema = gql`
    mutation updateRelation($relation: UpdateRelationRequest) {
      updateRelation(relation: $relation)
    }
  `;
  const { run, loading } = useGqlMutation<
    { updateRelation: boolean },
    { relation: TDatasetRelationRequest }
  >(schema);

  return {
    run: useCallback(
      (params: {
        relation: IDatasetRelation;
        srcDataset: IDataset;
        dstDataset: IDataset;
      }) => {
        const { relation, srcDataset } = params;
        const serverRelation = addSrcDatasetName2Relation(
          _.omit(relation, ['srcDataset', 'dstDataset']),
          srcDataset.name,
        );
        return run({
          relation: serverRelation,
        });
      },
      [run],
    ),
    loading,
  };
}

/**
 * 删除单个关联
 */
export function useDeleteRelation(options?: { onCompleted: () => void }) {
  const schema = gql`
    mutation deleteRelation($id: String) {
      deleteRelation(id: $id)
    }
  `;
  return useGqlMutation<{ deleteRelation: boolean }, { id: string }>(
    schema,
    options,
  );
}

/**
 * 校验关联关系是否有环路等不合法的情况
 */
export function checkRelations(reqParams: ICheckRelationReq) {
  return post<{
    result: boolean; // 是否验证通过
    errorMsg?: string; // 验证没有通过的错误信息
  }>('/dataset/checkRelations', reqParams);
}

/**
 * 校验数据集的名称和展示名是否唯一
 */
export function checkDatasetName(reqParams: {
  name: string; // 输入的名称
  displayName: string; // 输入的展示名
  datasetName?: string; // 数据集名称
}) {
  return post<{
    result: boolean;
    errorMsg?: string;
    errorPath?: string;
  }>('/dataset/checkDataset', reqParams);
}

/**
 * 检查数据集中是否含有异常
 */

export function checkDataset(request: IDataset) {
  return post<IErrorPath[]>('/dataset/check', request);
}

export const QUERY_DOWN_DATASETS_SCHEMA = gql`
    query queryDownDatasets($name: [String]) {
      queryDownDatasets(name: $name){
        ${datasetDetailTpl} 
      }
  }
  `;

/**
 * 获得下游的数据集列表
 * @param reqParams
 * @returns
 */
export function useQueryDownDatasets() {
  const { run, loading } = useGqlLazyQuery<
    {
      queryDownDatasets: IDataset[];
    },
    {
      name: string[];
    }
  >(QUERY_DOWN_DATASETS_SCHEMA);

  return {
    getQueryDownDatasets: useCallback(
      (names: string[]) =>
        run({
          name: names,
        }).then((res) => {
          return res.queryDownDatasets.map((item) => ({
            ...item,
            defColumns: item.defColumns.map((col) => ({
              ...col,
              dataType: originDataType2DataTypeMap[col.originDataType],
            })),
            columns: item.columns.map((col) => ({
              ...col,
              dataType: originDataType2DataTypeMap[col.originDataType],
            })),
          }));
        }),
      [run],
    ),
    loading,
  };
}

/**
 * 新建数据集接口
 */

export function useCreateDataset() {
  const schema = gql`
    mutation createDataset($datasetDetailsRequest: DatasetDetailsRequest) {
      createDataset(datasetDetailsRequest: $datasetDetailsRequest)
    }
  `;

  const { run, loading } = useGqlMutation<
    { createDataset: boolean },
    { datasetDetailsRequest: IServerDataset }
  >(schema);

  return {
    run: useCallback(
      (params: { dataset: IServerDataset }) => {
        const { dataset } = params;
        return run({
          datasetDetailsRequest: addSrcDatasetName2Relations(dataset),
        });
      },
      [run],
    ),
    loading,
  };
}

export function useSaveDataset() {
  const schema = gql`
    mutation updateDataset($datasetDetailsRequest: DatasetDetailsRequest) {
      updateDataset(datasetDetailsRequest: $datasetDetailsRequest)
    }
  `;

  const { run, loading } = useGqlMutation<
    { updateDataset: boolean },
    { datasetDetailsRequest: IServerDataset }
  >(schema);

  return {
    saveDataset: run,
    loading,
  };
}

/**
 * 修改数据集基础信息
 */
export function useUpdateDatasetBaseInfo() {
  const schema = gql`
    mutation updateDatasetBase(
      $name: String
      $displayName: String
      $categoryId: String
      $description: String
    ) {
      updateDatasetBase(
        name: $name
        displayName: $displayName
        description: $description
        categoryId: $categoryId
      )
    }
  `;
  const { run, loading } = useGqlMutation<
    {
      updateDatasetBase: boolean;
    },
    {
      name: string;
      displayName: string;
      description: string;
      categoryId: string;
    }
  >(schema);

  return {
    run,
    loading,
  };
}

/**
 * 删除数据集
 */
export function useDeleteDataset() {
  const schema = gql`
    mutation deleteDataset($name: String) {
      deleteDataset(name: $name)
    }
  `;
  const { run, loading } = useGqlMutation<
    {
      deleteDataset: boolean;
    },
    { name: string }
  >(schema);

  return { run, loading };
}

/**
 * 获取1端数据集和N端数据集
 */

export const useQueryRelationDatasets = () => {
  const schema = gql`
    query queryRelationDatasets($name: String) {
      queryRelationDatasets(name: $name) {
        dataset {
          ${datasetDetailTpl}
        }
        downDatasets {
          ${datasetDetailTpl}
        }
        upDatasets {
          ${datasetDetailTpl}
        }
      }
    }
  `;

  return useGqlLazyQuery<
    {
      queryRelationDatasets: IRelationDatasets;
    },
    {
      name: string;
    }
  >(schema);
};

/**
 * 为字段列表的每个项添加 dataType 属性
 */
export function parseColumnsWithDataType<T>(datasetInfo: ISimpleDataset) {
  return {
    ...datasetInfo,
    columns: _.map(datasetInfo.columns, (column) => {
      return {
        ...column,
        dataType: originDataType2DataTypeMap[column.originDataType],
      };
    }),
    defColumns: _.map(datasetInfo.defColumns, (defColumn) => {
      return {
        ...defColumn,
        dataType: originDataType2DataTypeMap[defColumn.originDataType],
      };
    }),
  } as T;
}
// queryRelation(request: QueryRelationRequest): DatasetRelationResponse

export const useQueryRelationER = () => {
  const schema = gql`
    query queryRelation($request: QueryRelationRequest) {
      queryRelation(request: $request) {
        datasets {
          name
          displayName
          description
          columns {
            name
            originDataType
            displayName
          }
          relations {
            relationConditions {
              srcAst
              dstAst
            }
          }
          relationFroms {
            relationConditions {
              srcAst
              dstAst
            }
          }
        }
        relations {
          srcDatasetName
          dstDatasetName
          type
          relationConditions {
            srcAst
            dstAst
          }
        }
      }
    }
  `;

  const { data, run, loading } = useGqlLazyQuery<
    {
      queryRelation: IDatasetRelationResponse;
    },
    {
      request: {
        datasetName: string;
        inputStep: number;
        outputStep: number;
      };
    }
  >(schema);
  return {
    run: useCallback(
      (datasetName: string) => {
        return run({
          request: {
            datasetName,
            inputStep: 10000,
            outputStep: 10000,
          },
        });
      },
      [run],
    ),
    data,
    loading,
  };
};

// 更新数据集的partitionMapping
export const useSetupPartitionMapping = () => {
  const schema = gql`
    mutation setupPartitionMapping(
      $setupPartitionMappingRequest: SetupPartitionMappingRequest
    ) {
      setupPartitionMapping(
        setupPartitionMappingRequest: $setupPartitionMappingRequest
      )
    }
  `;

  const { run: setupPartitionMapping, loading } = useGqlMutation<
    { setupPartitionMapping: boolean },
    {
      setupPartitionMappingRequest: {
        datasetName: string;
        partitionMapping?: IPartitionMappingConfig;
      };
    }
  >(schema);

  return {
    setupPartitionMapping,
    loading,
  };
};

// 根据一段SQL获取Sql出现的物理表详情 getSourceTable

export const useGetSourceTableFromSql = () => {
  const schema = gql`
    query getSourceTable($sql: String) {
      getSourceTable(sql: $sql) {
        ${sourceTpl}
      }
    }
  `;

  return useGqlLazyQuery<
    {
      getSourceTable: IDatasetSourceInfo[];
    },
    { sql: string }
  >(schema);
};

// 判断当前数据集能否被删除
export function useCanDeleteDataset() {
  const request = ({ name }: { name: string }) =>
    get<{
      canOperate: boolean;
      message?: string;
    }>('/dataset/canDelete', { name });

  return useRequest<
    {
      canOperate: boolean;
      message?: string;
    },
    [{ name: string }]
  >(request, {
    manual: true,
  });
}
