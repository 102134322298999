import { IPrompt } from '@/common/domain/Formula/functions/autoComplete';
import t from '@/locales';
import { FxLine } from '@aloudata/icons-react';
import { ISelPos } from '../AutoCompleteList';
import styles from './index.less';
import classNames from 'classnames';
import { calculatePopupPosition } from '../helper';
import { useRef } from 'react';
import { useClickAway } from 'ahooks';

export const KEEP_FOCUS_CLASS_COlUMN = 'keepFocusClassColumn';

interface IPromptTriggerProps {
  show: boolean;
  promptInfo: IPrompt;
  selPos: ISelPos;
  onClose: () => void;
}
const BOX_WIDTH = 480;
export const functionHelperDescHref =
  'https://alidocs.dingtalk.com/i/p/a0gX1qd74KrVqXeJ/docs/dQPGYqjpJYaO9K2oixLZv7djJakx1Z5N?dontjump=true';
const PromptTrigger = ({
  show,
  promptInfo,
  selPos,
  onClose,
}: IPromptTriggerProps) => {
  const boxRef = useRef<HTMLDivElement>(null);

  const { functionDesc, functionGrammar, functionParamsDesc } = promptInfo;
  const { x, y } = calculatePopupPosition(selPos.x, selPos.y, BOX_WIDTH);

  useClickAway(() => {
    onClose();
  }, boxRef);

  return (
    <div
      ref={boxRef}
      className={styles.wrapper}
      style={{
        display: show || true ? 'block' : 'none',
        top: y,
        left: x,
      }}
    >
      <div className={styles.nameBox}>
        <div className={styles.nameBoxLeft}>
          <FxLine size={16} />
        </div>
        <div className={styles.nameBoxRight}>
          <div className={styles.grammar}>{functionGrammar}</div>
          <div className={styles.descItem}>{functionDesc}</div>
        </div>
      </div>
      <div className={styles.paramList}>
        {functionParamsDesc.map((paramDesc, index) => (
          <div className={styles.paramItem} key={index}>
            <div className={styles.paramName}>{paramDesc.name}</div>
            <div className={styles.paramDesc}>{paramDesc.desc}</div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <a
          className={classNames(styles.footerRight, KEEP_FOCUS_CLASS_COlUMN)}
          href={functionHelperDescHref}
          target="_blank"
        >
          {t.codeEditor.explain.helper}
        </a>
      </div>
    </div>
  );
};
export default PromptTrigger;
