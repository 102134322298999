export const metric = {
  list: {
    batch: {
      adjustCategory: (value: string) => {
        return `Metric ${value} has no category adjustment permission`;
      },
      transfer: (value: string) => {
        return `Metric ${value} has no transfer permission`;
      },
    },
    create: 'Create Metric',
    metric: 'Metric',
    displayName: 'Display Name',
    dataOwner: 'Owner',
    category: {
      select: {
        title: 'All Metrics',
      },
    },
    type: 'Type',
    status: 'Status',
    dataset: 'Dataset',
    menu: {
      view: 'View',
      share: 'Share',
      copy: 'Copy to Create Metric',
      collect: {
        default: 'Favorite',
        success: 'Added to Favorites',
      },
      cancelCollect: {
        default: 'Remove Favorite',
        success: 'Removed from Favorites',
      },
      delete: 'Delete',
      editCategory: {
        default: 'Modify Category',
        success: 'Category Modify Successfully',
      },
      applyAdmin: 'Apply for Admin Permission',
      applySuccess: 'Application Successfully',
      category: 'Category',
      edit: 'Edit',
      transfer: 'Transfer',
    },
    number: ({ total }: { total: string | number }) => `${total} Metrics`,
    operateSuccess: 'Operation Successfully',
    deleteSuccess: 'Deleted Successfully',
  },
  detail: {
    overview: {
      preview: 'Data Preview',
      default: 'Overview',
      tableChart: 'Table',
      saveConfig: 'Set as Default',
      saveConfigSuccess: 'Saved Successfully',
      noPermission: 'No permission to view, please apply to administrator',
      notSupportPreview: 'This metric does not support preview',
    },
    attribution: {
      dimensionAttribution: 'Dimensions Attr',
      treeAttribution: 'Metrics Attr',
      save: 'Set Default',
      saveSuccess: 'Saved',
      mainMetric: 'Main Metric:',
      subMetrics: 'Breakdown Metrics:',
      editRelation: 'Edit Metric Relationship',
      filters: 'Filters:',
      default: 'Attribution',
      analysisDate: 'Date:',
      comparisonType: 'Comp Type',
      comparisonDate: 'Comp Date:',
      analysisDimension: 'Attr Dimension:',
      seeResult: 'View Attr Results',
      dayOverDay: 'DoD',
      weekOverWeek: 'WoW',
      monthOverMonth: 'MoM',
      quarterOverQuarter: 'QoQ',
      yearOverYear: 'YoY',
      summaryComparisonType: {
        dayOverDay: 'Yesterday Same Period',
        weekOverWeek: 'Last Week Same Period',
        monthOverMonth: 'Last Month Same Period',
        quarterOverQuarter: 'Last Quarter Same Period',
        yearOverYear: 'Last Year Same Period',
      },
      customized: 'Custom',
      analysisReport: 'Analysis Report',
      overallContributionRate: 'Contribution Rate',
      growthRate: 'Growth Rate',
      topInfluenceFactors: (limit: number) => `Top ${limit} Influence Factors:`,
      be: 'is',
      guideTip:
        'Select analysis dimensions to view their impact on metric results.',
      pleaseSelectDimension: 'Select Attr dimension',
      currentValue: 'Current Value',
      comparisonValue: 'Comp Value',
      comparisonRate: 'Comp Rate',
      growth: 'Growth Value',
      dimensionValueLimitTip: 'Only showing top 200 dimension values',
      compare: 'Compare',
      cancel: 'Cancel',
      dataLoading: 'Loading data...',
      dimensionValue: 'Dimension Value',
    },
    preview: {
      config: {
        label: {
          timeRange: 'Date:',
          metric: 'Metric:',
          dimension: 'Dimension:',
          filter: 'Filter:',
        },
        add: 'Add',
      },
      title: 'Data Preview',
      sort: 'Sort',
      downloadFileName: (metricName: string) => `${metricName}_Preview_Data`,
    },
    definition: 'Definition',
    authority: {
      default: 'Permissions',
      use: 'Usage Permission',
      rowFilter: {
        emptyDesc: 'No row permission rules configured',
        title: 'Row Permission Rules',
        mode: 'Mode',
        rule: 'Rule',
        edit: 'Configure Rules',
        conditionalRule: 'Condition Combination',
        tableRule: 'Permission Table Match',
        default: 'Row-level Permission',
        dimensionRelated: 'Related Dimension',
        manageRules: 'Configure Rules',
        addRules: 'Add Rule',
        manageRulesSuccess: 'Rules configured successfully',

        table: {
          ruleName: 'Rule Name',
          dimension: 'Related Dimension',
          restrictedUsers: 'Restricted Users',
          rule: {
            default: 'Visible Content',
            desc: 'Meet all following conditions',
          },
          operate: {
            title: 'Actions',
            edit: 'Edit',
            delete: 'Delete',
          },
        },
        createModal: {
          rule: 'Rule',
          ruleName: 'Rule Name',
          users: 'Restricted Users',
          rules: 'Visible Content',
          specifyDimension: 'Specify Dimension',
          whitelists: 'Whitelist',
          necessary: ({ name }: { name: string | number }) =>
            `${name} cannot be empty`,
          name: 'Rule Name',
          authorityTable: 'Permission Table',
          userColumn: 'Username Identifier Field',
          tableColumn: 'Permission Table Field',
          setFilter: 'Set Filter Condition',
          filterDimensionDesc:
            'Filter conditions take effect when query includes specified dimension',
          type: 'Relationship',
          and: 'AND',
          or: 'OR',
          dimension: 'Metric Dimension',
          authorityTableColumn: 'Permission Table Field',
          authorityTableColumnSelect: 'Select Field',
          delete: 'Delete',
          addFilter: 'Add Filter Association',
          dimensionFilter: {
            flagDesc:
              'Filter conditions take effect when query includes specified dimension',
            tooltip: {
              includes:
                'Row permission filter takes effect when query includes selected dimension',
              excludes:
                'Row permission filter takes effect when query uses dimensions other than excluded ones',
            },
            flagDescSmall: 'Specify Dimension',
            includes: 'Include',
            excludes: 'Exclude',
          },
        },
      },
      username: 'User',
      role: 'Permission',
      addAuth: 'Grant Permission',
      authUserAndUserGroup: 'Grant Permission to User/User Group',
    },
    dimension: {
      default: 'Dimensions',
      count: ({ count }: { count: string | number }) => `${count} Dimensions`,
      displayName: 'Display Name',
      code: 'Dimension Code',
      name: 'Name',
      description: 'Description',
      datatype: 'Type',
      category: 'Category',
      owner: 'Owner',
    },
    lineage: 'Lineage',
    version: {
      default: 'Version',
      title: 'Version',
      owner: 'Creator',
      createTime: 'Create Time',
      operate: {
        title: 'Actions',
        view: 'View',
        delete: 'Delete',
      },
      status: {
        draft: 'Draft',
        onLine: 'Online Version',
        current: 'Current Version',
        unPublish: 'Unpublished',
      },
      delete: {
        title: 'Delete Draft',
        content:
          'Delete draft from system. This draft cannot be recovered after deletion',
        ok: 'Delete',
        cancel: 'Cancel',
      },
      infoView: {
        close: 'Close',
        edit: 'Edit',
        publishDraft: 'Publish Draft to Online',
      },
    },
    monitor: {
      title: 'Monitor',
      count: (number: number) => `${number} Monitors`,
      add: 'Create Monitor',
      table: {
        name: 'Name',
        status: 'Status',
        time: 'Detection Time',
        owner: 'Owner',
        operate: {
          title: 'Actions',
          view: 'View',
          edit: 'Edit',
          delete: 'Delete',
        },
        deleteModal: {
          title: 'Delete Monitor',
          deleteDesc:
            'Monitor cannot be recovered after deletion. Do you want to delete?',
          deleteSuccess: 'Deleted Successfully',
        },
      },
    },
    edit: 'Edit',
    editDraft: 'Edit Draft',
    share: 'Share',
    favCount: 'Favorite',
    queryCount: 'Query',
    viewCount: 'View',
    countDesc: 'Statistics for the last 7 days (including today)',
    publish: 'Publish',
    setOnLine: 'Set Online',
  },
  define: {
    ownerAuthorityAlert: {
      start: 'You are using metric owner "',
      end: '" permission to edit metric',
    },
    label: {
      metricName: 'Metric Display Name',
      metricCode: 'Metric Name',
      bizOwner: 'Business Owner',
      description: 'Business Caliber',
      category: 'Category',
      analysisDimension: 'Analysis Dimension',
      unit: {
        default: 'Unit',
        currency: {
          default: 'Currency Unit',
          cnyFen: 'Cent (CNY)',
          cnyYuan: 'Yuan (CNY)',
          cnyWan: '10K Yuan (CNY)',
          cnyBaiWan: 'Million Yuan (CNY)',
          cnyYiYuan: '100M Yuan (CNY)',
          usdCent: 'Cent (USD)',
          usdDollar: 'Dollar (USD)',
          eurEuro: 'Euro (EUR)',
          hkdDollar: 'Dollar (HKD)',
          newTaiwanDollar: 'Dollar (TWD)',
        },
        time: {
          default: 'Time Unit',
          day: 'Day',
          month: 'Month',
          week: 'Week',
          year: 'Year',
          hour: 'Hour',
          minute: 'Minute',
          second: 'Second',
          quarter: 'Quarter',
          millisecond: 'Millisecond',
        },
        ratio: {
          default: 'Ratio Unit',
          decimal: 'Decimal',
          percentage: 'Percentage',
          permille: 'Permille',
        },
        noun: {
          default: 'Rank',
          rank: 'Ranking',
        },
        objectQuantity: {
          default: 'Object Quantity',
          household: 'Household',
          transaction: 'Transaction',
          item: 'Item',
          individual: 'Individual',
          occurrence: 'Occurrence',
          personDay: 'Person-Day',
          family: 'Family',
          hand: 'Hand',
          sheet: 'Sheet',
          package: 'Package',
        },
        weight: {
          default: 'Weight Unit',
          ton: 'Ton',
          kilogram: 'Kilogram',
        },
        other: 'Other',
      },
      dataset: 'Dataset',
      statisticalMethod: 'Stat Method',
      halfAdditiveDimensions: 'Semi-additive Metric',
      metricTime: 'Metric Time',
      nonAdditiveDimensions: 'Non-additive Dimensions',
      atomicAndComposition: 'Metric',
      windowGrouping: 'Window Grouping',
      windowChoice: 'Window Selection',
      atomic: 'Basic Metric',
      extType: 'Derivative Type',
      statisticalPeriod: 'Time Constraint',
      dimensionFilter: 'Business Constraint',
      extMode: 'Derivative Method',
      filter: 'Filter',
      addDimension: 'Add Field',
      expr: 'Composite Expression',
    },
    help: {
      metricCode:
        'Unique name for metric, cannot be modified after creation, maximum 50 characters, supports letters, numbers and underscores.',
      windowGrouping:
        'When calculating metric aggregation, data will be pre-grouped by window grouping fields, and data will be filtered within grouped data according to window selection.',
    },
    message: {
      categoryRequired: 'Select category',
      metricNameRequired: 'Enter metric display name',
      metricCodeRequired: 'Enter metric name',
      bizOwnerIdRequired: 'Select business owner',
      metricCodePattern:
        'Metric name can only contain letters, numbers, and underscores',
      datasetRequired: 'Select dataset',
      aggExprRequired: 'Select aggregation field',
      metricTimeRequired: 'Select metric date identifier',
      datasetMissed: 'Dataset unavailable',
      columnMissed: 'Field does not exist',
      metricTimeMissed: 'Metric date identifier field has been deleted',
      unExitColumn: 'Field has been deleted or is no longer a dimension field',
      exprError: 'Expression error',
      noDateColumn: 'Unavailable reason: Dataset has no date type field',
    },
    title: {
      basicInfo: 'Basic Information',
      calculateCaliber: 'Calculation Logic',
      sql: 'Metric SQL',
      dimension: 'Analysis Dimension',
      disabledDesc:
        'Following dimensions are disabled for this metric (Blacklist mode)',
      availableDesc:
        'Following dimensions are available for this metric (Whitelist mode)',
      partDimensionDesc: 'Following dimensions apply to partial metrics',
      disabledEmpty: 'No disabled dimensions',
      availableEmpty: 'No available dimensions',
    },
    metricPicker: {
      disableReason:
        'Can only use basic metrics and composite metrics generated from basic metrics',
      cannotRelySelf: 'Cannot depend on metric itself',
    },
    sql: {
      desc: 'Show SQL code for metric results with metric date as today',
      loading: 'Getting SQL code...',
      loadFailed: 'Failed to get SQL code',
      copySuccess: 'Copied successfully',
    },
    agg: {
      sum: 'Sum',
      count: 'Count',
      distinctCount: 'Distinct Count',
      max: 'Max',
      min: 'Min',
      avg: 'Avg',
    },
    radio: {
      normal: 'Normal',
      customExpr: 'Custom Expression',
    },
    businessLimit: {
      des: 'Meet all following conditions',
    },
    placeholder: {
      metricTimeWithoutTime: 'This dataset has no date type field',
    },
    windowChoice: {
      max: 'Maximum',
      min: 'Minimum',
    },
    extType: {
      samePreviewsPeriod: 'Period-over-Period',
      ratio: 'Ratio',
      rank: 'Ranking',
      acc: 'Accumulative',
    },
    dimension: {
      disableReason: 'Already in analysis dimensions',
      allCanUse: 'Available for all metrics',
      onlyCanUse: (text: string) => `Only available for ${text}`,
      newDimensionDefaultEnableTip:
        'When data model changes, new available dimensions may be added and will be automatically enabled',
      foldUp: 'Collapse',
      unfold: 'More',
      add: 'Add',
      newDimensionDefaultEnable: 'New dimensions are enabled by default',
      placeholder: {
        atomic: 'View dimensions after selecting dataset',
        derived: 'View dimensions after selecting basic/composite metric',
        composition: 'View dimensions after creating composite expression',
      },
    },
    dynamic: {
      currentTime: 'Current Time',
      de: 'of',
      plus: 'Add',
      minus: 'Subtract',
    },
    timeGranularity: {
      day: 'Day',
      week: 'Week',
      month: 'Month',
      quarter: 'Quarter',
      year: 'Year',
      minute: 'Minute',
      hour: 'Hour',
      second: 'Second',
    },
    granularitySelect: {
      currentDay: 'Current Day',
      granularityStart: ({ granularity }: { granularity: string | number }) =>
        `Start of ${granularity}`,
      granularityEnd: ({ granularity }: { granularity: string | number }) =>
        `End of ${granularity}`,
    },
    modal: {
      title: {
        create: 'Create Metric',
        edit: 'Edit Metric',
        draft: 'Edit Metric Draft',
      },
      ok: {
        isEdit: 'Save Draft',
        notEdit: 'Save Draft',
      },
      sameMetric: {
        title: 'Duplicate Metric',
        tip: 'There is an existing metric with the same caliber, continue to publish?',
        ok: 'Continue Publishing',
        cancel: 'Cancel Publishing',
      },
      publish: 'Save and Publish',
      selectMetricType: 'Metric Type',
    },
    metric: {
      text: {
        atomic: 'Basic Metric',
        derived: 'Derived Metric',
        composition: 'Composite Metric',
      },
    },
    indirection: {
      mode: {
        default: 'Derivative Method',
        samePreviewsPeriod: {
          selectPlaceholder: 'Select',
          default: 'Period-over-Period',
          value: 'Value',
          growthValue: 'Growth Value',
          growth: 'Growth Rate',
          seq: 'Sequential',
          pop: 'over',
          lastWeekEnd: 'Last Week End',
          lastMonthEnd: 'Last Month End',
          lastQuarterEnd: 'Last Quarter End',
          lastYearEnd: 'Last Year End',
          calendarDay: 'Calendar Day',
          customPeriodModal: {
            title: 'Custom Period-over-Period',
            type: 'Calculation Type',
            relativeTime: 'Relative Time',
          },
          lastEnd: ({
            granularityDisplayName,
          }: {
            granularityDisplayName: string;
          }) => `End of ${granularityDisplayName}`,
          before: 'Past',
          after: 'Future',
          displayName: (
            relativeTime: string,
            typeName: string,
            overName: string,
            dateTagName: string,
          ) =>
            `${relativeTime}${overName}${typeName}${
              dateTagName ? ` (${dateTagName})` : ''
            }`,
        },
        proportion: 'Proportion',
        rank: 'Ranking',
        customAgg: 'Custom Aggregation',
      },
      rank: {
        dimension: 'Ranking Dimension',
        range: 'Ranking Range',
        method: {
          default: 'Ranking Method',
          dense: 'RankDense (1,1,2,3)',
          sparse: 'Rank (1,1,3,4)',
          rowNumber: 'RowNumber (1,2,3,4)',
          des: 'Ascending (Low to High)',
          asc: 'Descending (High to Low)',
        },
      },
      proportion: {
        dimension: 'Proportion Dimension',
        range: 'Proportion Range',
      },
    },

    period: {
      today: 'Today',
      yesterday: 'Yesterday',
      nearly1days: 'Last 1 Day',
      nearly7days: 'Last 7 Days',
      nearly30days: 'Last 30 Days',
      nearly180days: 'Last 180 Days',
      nearly365days: 'Last 365 Days',
      nearly3years: 'Last 3 Years',
      currentWeek: 'This Week',
      lastWeek: 'Last Week',
      week: {
        last: ({ number }: { number: string | number }) =>
          `Last ${number} Weeks`,
      },
      currentMonth: 'This Month',
      lastMonth: 'Last Month',
      month: {
        last: ({ number }: { number: string | number }) =>
          `Last ${number} Months`,
      },
      currentQuarter: 'This Quarter',
      lastQuarter: 'Last Quarter',
      quarter: {
        last: ({ number }: { number: string | number }) =>
          `Last ${number} Quarters`,
      },
      currentYear: 'This Year',
      currentWeekToDate: 'Week to Date',
      currentMonthToDate: 'Month to Date',
      currentQuarterToDate: 'Quarter to Date',
      currentYearToDate: 'Year to Date',
      currentWeekStart: 'Start of Week',
      lastWeekEnd: 'End of Last Week',
      currentMonthStart: 'Start of Month',
      lastMonthEnd: 'End of Last Month',
      currentYearStart: 'Start of Year',
      lastYearEnd: 'End of Last Year',
      historyToDate: 'History to Date',
      customOption: 'Custom',
      inValidTimeValue: 'Time granularity must match end time granularity',
      type: {
        recently: 'Last N Days/Last N Weeks...',
        toDate: 'Week to Date/Month to Date...',
        special: 'Yesterday/This Week/Start of Month...',
      },
      calcType: {
        all: 'Overall',
      },
      customLimit: {
        title: 'Custom Time Limitation Combination',
        accordingTo: 'By',
        calc: 'Calculate',
        per: 'Per',
        result: 'metric result,',
      },
      custom: 'Custom',
    },
    extMode: {
      avg: 'Avg',
      sum: 'Sum',
      max: 'Max',
      min: 'min',
      customDimensionGroups: {
        add: 'Add Group Aggregation',
        max: 'Max',
        min: 'Min',
        sum: 'Sum',
      },
      validate: {
        noValue: 'Select derivative method',
        customAgg: {
          noDimension: 'Please add custom aggregation method',
          incomplete: 'Please complete custom aggregation method',
        },
        samePeriod: {
          noDimension: 'Select period-over-period',
        },
        granularityChange:
          'Time granularity has changed, please reselect derivative method period-over-period',
      },
    },
    derivativeMode: {
      customDimensionGroups: {
        base: 'Using',
        result: 'result of',
        firstRowGroup:
          'as dimension, calculate metric results under this dimension.',
        otherRowGroup:
          'as dimension, within analysis dimension combination, calculate',
        lastRowGroup: 'within analysis dimension combination, calculate',
      },
    },
    rank: {
      rank: 'Sparse Ranking',
      rankDense: 'Dense Ranking',
      rankNumber: 'Continuous Ranking',
    },
    expr: {
      required: 'Enter expression',
      error: 'Expression validation error',
      useExpression: 'Enter calculation expression, e.g.: [Field1] + [Field2]',
      filter: 'Enter filter expression',
    },
    validate: {
      atomic: 'Select basic metric',
      atomicNotExist: 'Basic metric has been deleted',
      mustChooseOne:
        'One of "Time Limitation", "Business Limitation" or "Derivative Method" must be filled',
      filterConfig: 'Please complete filter content',
    },
    edit: {
      placeholder:
        'Enter expression to calculate dimension values, e.g.:\nIF( SUM([Order Amount]) > 1000,"High","Low" )',
      placeholder1:
        'Enter filter expression, e.g.: IN([Field],"Value1,Value2") OR [Field2]>Value3',
    },
    metricTimeHelper: {
      partitionText: 'Partition',
      desc1: 'With partition field',
      desc2: 'Partition mapping not enabled, enable it to improve query speed.',
      setBtnText: 'Set Partition Mapping',
    },
  },
  definition: {
    label: {
      dataset: 'Dataset',
      aggExpr: 'Stat Method',
      metricTime: 'Metric Time Identifier:',
      halfAdditiveDimensions: 'Dimension',
      agg: 'Aggregation',
      filter: 'Filter',
      businessFilter: 'Business Constraint',
      windowGrouping: 'Window Grouping',
      windowChoice: 'Window Selection',
      nonAdditiveDimensions: 'Non-additive Dimensions:',
      atomic: 'Metric',
      statistics: 'Time Constraint',
      extMode: 'Derivative Method',
      expr: 'Composite Expression',
    },
    filter: {
      endWith: ({ text }: { text: string | number }) => `Ends with ${text}`,
      notEndWith: ({ text }: { text: string | number }) =>
        `Does not end with ${text}`,
      startWith: ({ text }: { text: string | number }) => `Starts with ${text}`,
      notStartWith: ({ text }: { text: string | number }) =>
        `Does not start with ${text}`,
      contain: 'Contains',
      notContain: 'Does not contain',
      equal: 'Equals',
      infinitesimal: 'Infinitesimal',
      infinity: 'Infinity',
      notEqual: 'Not equals',
      date: {
        range: {
          all: 'Any time period',
          before: 'Earlier than',
          after: 'Later than',
          specify: 'Specify date',
        },
      },
    },
  },
  create: {
    title: 'Create Metric',
    cancel: 'Cancel',
    helpDocument: 'Help Manual',
    desc: {
      atomic: 'Aggregate based on business measure fields',
      derived: 'Adding Constraints Based on Basic Metrics',
      composition: 'Arithmetic Operations on Multiple Metrics',
    },
  },
  edit: {
    title: 'Modify Basic Information',
    restoreOnlineConfiguration: 'Restore to Online Configuration',
  },
  save: {
    edit: {
      success: 'Saved Successfully',
    },
    create: {
      success: 'Created Successfully',
    },
  },
  common: {
    input: {
      placeholder: 'Enter',
    },
    select: {
      placeholder: 'Select',
    },
    aggColumn: {
      placeholder: 'Select',
    },
  },
  aggType: {
    placeholder: 'Aggregate',
    mode: {
      custom: 'Use Expression',
      column: 'Use Field',
    },
  },
  reset: {
    error: 'Failed to get data',
    button: {
      reset: 'Refresh',
    },
  },
  autoBusinessCaliberAtomic: (
    datasetName: string,
    columnDisplayName: string,
    aggType: string,
  ) => {
    return `${aggType} of ${columnDisplayName} in ${datasetName}`;
  },
  autoBusinessCaliberDerived: (
    periodName: string,
    atomicMetricName: string,
    dimensionNames: string[],
  ) => {
    return `Calculate ${atomicMetricName} for ${periodName}, filtered by ${dimensionNames.join(
      ', ',
    )}`;
  },
  upload: {
    button: 'Batch Upload Metrics',
    modal: {
      title: 'Batch Upload Metrics',
      downloadTemplateDesc: 'Download batch upload metric template:',
      downloadTemplate: 'Download Template',
      uploadFile: 'Click to Upload',
      uploadDesc: 'or Drag and Drop Files Here',
      uploadingText: 'Uploading file data...',
      cancelUploading: 'Cancel Upload',
      cancel: 'Cancel',
      create: 'Create',
      success: 'Created Successfully',
      fileName: 'Create Metric Draft Template',
      errorMsg: 'Failed to upload file',
      retry: 'Retry Upload',
    },
  },
  metricCategoryRouter: {
    more: 'More',
    refresh: 'Refresh',
    rename: 'Rename',
    delete: 'Delete',
    moveCategory: 'Move Category',
    authManage: 'Permission Management',
    all: (type: string) => `All ${type}`,
    search: 'Metric Code, Display Name, Name',
    category: 'Category',
    metric: 'Metric',
    createMore: 'Create',
    createSubCategory: 'Create Category',
    createMetric: 'Create Metric',
  },
};

export const preview = {
  metricPicker: {
    derivate: 'Quick Derive',
    format: 'Display Format',
    noDerivate: 'None',
    samePeriod: 'Period-over-Period',
    self: 'Current Metric',
  },
  previewData: 'Preview Data',
  filterDataTypeChanged:
    'Data type of dimension corresponding to filter condition has changed, please reconfigure',
};

export const metic = {
  detail: {
    setOffLine: 'Take Offline',
  },
};
