import { ITextDataInput } from './property';

export interface ISoftIntegrationBaseInfo {
  code: string;
  type: ESoftIntegrationType;
}

export enum ESoftIntegrationType {
  ENTERPRISE_WECHAT = 'ENTERPRISE_WECHAT',
}

export enum ESwitchStatus {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

export interface ISoftIntegrationDefConfig extends ITextDataInput {
  placeholder: string;
  required: true;
  description: string;
  name: string;
  displayName: string;
  defaultValue: string;
}

// 办公软件集成定义
export interface ISoftIntegration {
  type: ESoftIntegrationType;
  defConfig: ISoftIntegrationDefConfig[];
  description: string; // 描述信息
  status: ESwitchStatus;
  records: ISoftIntegrationRecord[];
}

export interface ISoftIntegrationDetail {
  code: string;
  displayName: string;
  softIntegration: ISoftIntegration;
}

export interface ISoftIntegrationRecord {
  code?: string;
  type: ESoftIntegrationType;
  displayName: string;
  config: {
    [key: string]: string;
  };
  status: ESwitchStatus;
}

//  ============登录方式相关 ============

export enum ELoginAuthenticationType {
  ACCOUNT_PASSWORD = 'ACCOUNT_PASSWORD', // 账号密码
  HTTP = 'HTTP', // HTTP
  ENTERPRISE_WECHAT = 'ENTERPRISE_WECHAT', // 企业微信
  AUTH2_SSO_LOGIN = 'AUTH2_SSO_LOGIN',
}

export enum EAuthenticationStatus {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  FORCE_OPEN = 'FORCE_OPEN',
}

export interface ILoginAuthenticationBaseInfo {
  type: ELoginAuthenticationType;
  status: EAuthenticationStatus;
  name: string;
}

export interface IAccountLoginAuthentication
  extends ILoginAuthenticationBaseInfo {
  type: ELoginAuthenticationType.ACCOUNT_PASSWORD;
  config: {
    // 认证方式
    userAuthentication: IUserAuthentication & {
      // 超管
      accountOwner: EAuthenticationStatus;
    };
  }[];
}

export enum ERequestType {
  POST = 'POST',
  GET = 'GET',
}

export enum EHttpLoginConfigInputType {
  TEXT = 'TEXT',
  PASSWORD = 'PASSWORD',
}

export interface IHttpLoginAuthenticationConfig {
  code?: string; // 唯一id
  displayName: string; // 展示名
  certificationAddress: string; // 认证地址
  requestType: ERequestType;
  config: {
    params: {
      name: string; // 字段名称
      displayName: string; // 字段展示名
      userFillIn: boolean; // 是否由用户填入
      inputType: EHttpLoginConfigInputType;
      defaultValue: string;
    }[];
    result: {
      codePath: string;
      accountPath: string;
      codeSuccessValue: string;
    };
  };
  userAuthentication: IUserAuthentication;
}
export interface IHttpLoginAuthentication extends ILoginAuthenticationBaseInfo {
  type: ELoginAuthenticationType.HTTP;
  config: IHttpLoginAuthenticationConfig[];
}

export interface IUserAuthentication {
  // 手动添加的用户
  manualAccount: ESwitchStatus;
  // 同步的用户
  syncAccount: ESwitchStatus;
}

export interface IEnterpriseWechatLoginAuthenticationConfig {
  softIntegrationDetail: ISoftIntegrationRecord;
  userAuthentication: IUserAuthentication;
}

export interface IEnterpriseWechatLoginAuthentication
  extends ILoginAuthenticationBaseInfo {
  type: ELoginAuthenticationType.ENTERPRISE_WECHAT;
  config: IEnterpriseWechatLoginAuthenticationConfig[];
}

export interface ISsoLoginAuthenticationConfig {
  ssoUrl: string;
  code: string;
  displayName: string;
}

export interface ISsoLoginAuthenticationAuthentication
  extends ILoginAuthenticationBaseInfo {
  type: ELoginAuthenticationType.AUTH2_SSO_LOGIN;
  config: ISsoLoginAuthenticationConfig[];
}

export type TLoginAuthentication =
  | IAccountLoginAuthentication
  | IHttpLoginAuthentication
  | IEnterpriseWechatLoginAuthentication
  | ISsoLoginAuthenticationAuthentication;

// ====================== 消息推送相关 ==========
export enum EPushMessageType {
  CUSTOM = 'CUSTOM',
  ENTERPRISE_WECHAT = 'ENTERPRISE_WECHAT',
}

export interface IPushMessage {
  code?: string;
  enDisplayName: string;
  displayName: string;
  config: {
    pushUrl: string;
    type: EPushMessageType;
  };
  remark: string;
}
