import { EDimensionType, TDimension } from '../../src/typings/dimension';
import { EFormulaType, EOriginDataType } from '../../src/typings/formula';
import { getMockDatasetInfo } from '../utils';
import getUserInfo from '../utils/getUserInfo';
import _ from 'lodash';
import { mockCategoryList } from '../category/mockData';
import { IUser } from '@aloudata/aloudata-design';
import { EOnlineStatus } from '../../src/typings/metric';
import { EPropertyType } from '../../src/typings/property';
import { EColumnDataType } from '../../src/typings';
import { EUserRole } from '../../src/typings/authority';

export const getDimension = (
  name: string,
  type?: EDimensionType,
  originDataType?: EOriginDataType,
): TDimension => {
  const innerType =
    type || _.sample([EDimensionType.BIND, EDimensionType.CUSTOM])!;
  const innerOriginDataType = originDataType || EOriginDataType.INT;
  const properties = {
    [EPropertyType.BASE]: {
      dimName: {
        value: 'dimName11',
      },
      dimCode: {
        value: 'dimCode11',
      },
      dimDisplayName: {
        value: '展示名',
      },
    },
    [EPropertyType.BUSINESS]: {
      dimDescription: {
        value: 'dimDescription',
      },
      dimCategoryId: {
        value: [_.sample(mockCategoryList)!],
      },
    },
    [EPropertyType.MANAGE]: {},
    [EPropertyType.TECHNOLOGY]: {},
  };
  if (innerType === EDimensionType.BIND) {
    return {
      name,
      dimName: `${name}-dimName`,
      dimCode: `${name}-dimCode`,
      displayName: `${name}-displayName`,
      type: innerType,
      description:
        'descriptiondescriptiondescriptiondescriptiondescriptiondescription',
      category: [_.sample(mockCategoryList)!],
      originDataType: innerOriginDataType,
      dimensionDataType: EColumnDataType.DECIMAL,
      datasetName: 'datasetName',
      config: {
        columnName: `${name}-columnName`,
      },
      isPublished: true,
      status: EOnlineStatus.ONLINE,
      authority: {
        authRoleList: [EUserRole.ADMIN],
        canEdit: true,
        canDelete: !!_.sample([true, false]),
        canUsage: !!_.sample([true, false]),
        canAuth: true,
        canTransfer: !!_.sample([true, false]),
      },
      owner: getUserInfo(),
      hasPermission: true,
      properties,
      hasDraft: true,
      isEnable: true,
    };
  }

  return {
    name,
    dimName: `${name}-dimName`,
    dimCode: `${name}-dimCode`,
    displayName: `${name}-displayName`,
    type: EDimensionType.CUSTOM,
    description:
      'descriptiondescriptiondescriptiondescriptiondescriptiondescription',
    category: [_.sample(mockCategoryList)!],
    originDataType: _.sample([EOriginDataType.INT, EOriginDataType.DATE])!,
    dimensionDataType: EColumnDataType.DECIMAL,
    isPublished: true,
    status: EOnlineStatus.ONLINE,
    config: {
      formula: {
        type: EFormulaType.CONSTANT,
        val: {
          type: EOriginDataType.DOUBLE,
          val: '1',
        },
      },
    },
    layout: {
      expr: '1',
    },
    datasetName: 'datasetName',
    authority: {
      canEdit: !!_.sample([true, false]),
      canDelete: !!_.sample([true, false]),
      canUsage: !!_.sample([true, false]),
      canAuth: !!_.sample([true, false]),
      canTransfer: !!_.sample([true, false]),
    },
    owner: getUserInfo(),
    hasPermission: true,
    properties,
    hasDraft: true,
    isEnable: true,
  };
};

export const getDimensionList = () => {
  return _.map(_.range(50), (item, index) => getDimension(index.toString()));
};
