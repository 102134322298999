export const userBaseInfo = `#graphql
          userId
          nickname
          account
          userSource
  
          ban
          creationMode
          disableProperties
`;

export const userDetail = `#graphql
  ${userBaseInfo}
            phone
          email
          resetPasswordFirstLogin
                  authorityResource {
            authorityRole
            authoritySource
            authority {
              canEdit
              canDelete
              canAuth
              authRoleList
            }
          }
`;
