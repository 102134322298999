import zhCN from './zh';
import enSource from './en';
import { ELanguage } from '@/typings';

// TODO 如果需要支持多语言，支持自动检测 UserLanguage 等，可以结合 BrowserLanguageDetector 等实现
/**
 * 获取翻译器
 * @param locale 支持的语言
 * @returns translator
 */
export const getTranslator = (locale: ELanguage = ELanguage.ZH) => {
  if (locale === ELanguage.ZH) return zhCN;
  if (locale === ELanguage.EN) return enSource;
  return zhCN;
};

export default getTranslator(window.language);
