import { ReactComponent as ArrowNewWindowSvg } from '@/assets/icon/Arrow-New-window-light-line.svg';
import { TDimension } from '@/typings/dimension';
import styles from './index.less';
import ColumnDataTypeIcon from '@/common/ui/ColumnDataTypeIcon';
import { originDataType2DataTypeMap } from '../../Formula/constant';
import { windowOpen } from '@/common/utils/windowOpen';
import DimensionInfo from './DimensionInfo';
import { DATATYPE_COLOR } from '../Picker/constants';
import { memo } from 'react';

function DimensionCard(props: IProps) {
  const { dimension } = props;
  const { originDataType, displayName } = dimension;

  const dataType = originDataType2DataTypeMap[originDataType];

  return (
    <div className={styles.dimensionCard}>
      <div className={styles.header}>
        <div className={styles.nameArea}>
          <div className={styles.dataType}>
            <ColumnDataTypeIcon dataType={dataType} color={DATATYPE_COLOR} />
          </div>
          <div className={styles.displayName} title={displayName}>
            {displayName}
          </div>
        </div>
        <div
          className={styles.icon}
          onClick={() => {
            windowOpen(`/dimension/detail/${dimension.name}`);
          }}
        >
          <ArrowNewWindowSvg size={20} color="#6B7280" />
        </div>
      </div>
      <div className={styles.body}>
        <DimensionInfo dimension={dimension} />
      </div>
    </div>
  );
}

interface IProps {
  dimension: TDimension;
}

export default memo(DimensionCard);
