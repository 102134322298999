export const define = {
  label: {
    displayName: 'Display Name',
    name: 'Name',
    category: 'Category',
    description: 'Description',
    owner: 'Owner',
    dataset: 'Dataset',
    content: 'Content',
  },
  version: {
    publish: 'Publish Online',
    publishDraft: 'Publish Draft',
    editDraft: 'Edit Draft',
    online: 'Online',
  },
  module: {
    calcLogic: {
      title: 'Calculation Logic',
      description:
        'This dimension maps the following data and will automatically switch to appropriate data based on the queried metrics.',
    },
    valueManage: {
      title: 'Value Management',
      description: 'Set dimension value information',
      queryValue: 'Query and Fill',
      clear: 'Clear Data',
      table: {
        value: 'Dimension Value',
        name: 'Dimension Value Name',
        description: 'Description',
        delete: 'Delete',
        placeholder: {
          value: 'Enter dimension value',
          name: 'Enter dimension value name',
          description: 'Enter description',
        },
      },
      errorText: {
        valueEmpty: 'Dimension value cannot be empty',
        nameEmpty: 'Dimension value name cannot be empty',
        valueRepeat: 'Duplicate dimension value',
        nameRepeat: 'Duplicate dimension value name',
        allEmpty: 'Must contain at least one dimension value',
      },
    },
    preview: {
      title: 'Value Preview',
      desc: 'Only showing first 100 records',
      close: 'Close',
      button: 'Preview Data',
    },
  },
  create: {
    success: 'Dimension created successfully',
    default: 'Create Dimension',
  },
  edit: {
    success: 'Dimension saved successfully',
    default: 'Edit Dimension',
    btnTitle: 'Edit',
  },
  dimensionType: {
    bind: 'Field Creation',
    custom: 'Custom',
  },
  save: 'Save Draft',
  onlySave: 'Save',
  saveAndPublish: 'Save and Publish',
  resetToOnline: 'Reset to Online Configuration',
  close: 'Close',
  validate: {
    empty: ({ field }: { field: string | number }) =>
      `${field} cannot be empty`,
    expr: 'Invalid expression',
  },
  changeCategory: 'Modify Category',
  offLine: 'Offline',
  message: {
    namePattern:
      'Dimension name can only contain letters, numbers, underscores and cannot use double underscores',
  },
  input: {
    placeholder: 'Enter',
  },
  list: {
    memberPicker: {
      title: 'Owner:',
    },
  },
  content: {
    field: 'Fields',
    expr: 'Expression',
  },
};

export const list = {
  title: 'Dimension',
  category: {
    select: {
      title: 'All Dimensions',
    },
  },
  batch: {
    adjustCategory: (value: string) => {
      return `Dimension ${value} has no permission to adjust category`;
    },
    transfer: (value: string) => {
      return `Dimension ${value} has no permission to transfer`;
    },
  },
  column: {
    status: 'Status',
    version: 'Version',
    handle: 'Actions',
  },
  total: ({ total }: { total: string | number }) => `${total} Dimensions`,
  btn: {
    createDimension: 'Create Dimension',
    transfer: 'Transfer Owner',
  },
  dropdown: {
    transfer: 'Transfer',
    view: 'View',
    edit: 'Edit',
    delete: 'Delete',
    offLine: 'Offline',
    onLine: 'Online',
    publish: 'Publish Online',
  },
};

export const detail = {
  header: {
    share: 'Share',
  },
  tabTitle: {
    overview: 'Overview',
    define: 'Definition',
    version: 'Version',
    authority: 'Permission',
  },
  modal: {
    title: 'Dimension Details',
    close: 'Close',
    edit: 'Edit',
    publishDraft: 'Publish Draft to Online',
  },
  title: {
    calcLogic: 'Calculation Logic',
  },
  define: {
    bind: 'Fields',
    custom: 'Expression',
  },
  subTitle: {
    dataset: 'Dataset',
    content: 'Content',
  },
  preview: {
    desc: 'Preview first 100 dimension values',
    empty: 'JSON type dimension cannot be previewed',
  },
};

export const category = {
  all: 'All',
};
