import { METRIC_TIME } from '@/constants';
import {
  EDateDynamicOperator,
  EDateValueType,
  EDependencyType,
  EFilterType,
} from '../NewFilter/types';
import {
  EDateFilterType,
  EFilterConfigType,
  ESelectFilterType,
  TFilterConfig,
} from './type';
import { getUniqId } from '@/common/utils';
import t from '@/locales';
import { EColumnDataType, EDateGranularityType } from '@/typings';
import dayjs from 'dayjs';

export const getDefaultFilterConfig = (): TFilterConfig => {
  return {
    type: EFilterConfigType.DATE,
    dependency: {
      type: EDependencyType.DIMENSION,
      name: METRIC_TIME,
    },
    id: getUniqId(),
    displayName: t.components.globalFilter.right.date.metricTimeDisplayName,
    value: {
      granularityName: EDateGranularityType.DAY,
      type: EDateFilterType.SPECIFY,
      defaultValueEnable: true,
      defaultValue: {
        type: EFilterType.DATE_SPECIFY,
        granularity: EDateGranularityType.DAY,
        values: {
          type: EDateValueType.DYNAMIC,
          unit: EDateGranularityType.DAY,
          operator: EDateDynamicOperator.DECREASE,
          value: 0,
        },
      },
    },
  };
};

export function getDefaultFilterConfigByDimensionColumnType(
  dimensionColumnType: EColumnDataType,
  dimensionName: string,
): TFilterConfig {
  switch (dimensionColumnType) {
    case EColumnDataType.DATE:
    case EColumnDataType.DATE_TIME:
      return {
        type: EFilterConfigType.DATE,
        dependency: {
          type: EDependencyType.DIMENSION,
          name: dimensionName,
        },
        id: getUniqId(),
        displayName: t.components.globalFilter.emptyFilterDisplayName,
        value: {
          granularityName: EDateGranularityType.DAY,
          type: EDateFilterType.SPECIFY,
          defaultValueEnable: false,
          defaultValue: {
            type: EFilterType.DATE_SPECIFY,
            granularity: EDateGranularityType.DAY,
            values: {
              type: EDateValueType.FIXED,
              value: dayjs(dayjs().format('YYYY-MM-DD')).valueOf(),
            },
          },
        },
      };
    case EColumnDataType.INT:
    case EColumnDataType.DOUBLE:
    case EColumnDataType.DECIMAL:
    case EColumnDataType.BOOLEAN:
    case EColumnDataType.TEXT:
      return {
        type: EFilterConfigType.SELECT,
        dependency: {
          type: EDependencyType.DIMENSION,
          name: dimensionName,
        },
        id: getUniqId(),
        displayName: t.components.globalFilter.emptyFilterDisplayName,
        value: {
          type: ESelectFilterType.MULTIPLE,
          defaultValueEnable: false,
          defaultValue: {
            type: EFilterType.EQUAL,
            values: [],
          },
        },
      };
    default:
      throw new Error('Unsupported dimension column type');
  }
}

export function getEmptyFilterConfigByType(
  type: EFilterConfigType,
): TFilterConfig {
  if (type === EFilterConfigType.DATE) {
    return {
      type: EFilterConfigType.DATE,
      dependency: {
        type: EDependencyType.DIMENSION,
        name: '',
      },
      id: getUniqId(),
      displayName: t.components.globalFilter.emptyFilterDisplayName,
      value: {
        granularityName: EDateGranularityType.DAY,
        type: EDateFilterType.SPECIFY,
        defaultValueEnable: true,
        defaultValue: {
          type: EFilterType.DATE_SPECIFY,
          granularity: EDateGranularityType.DAY,
          values: {
            type: EDateValueType.FIXED,
            value: dayjs(dayjs().format('YYYY-MM-DD')).valueOf(),
          },
        },
      },
    };
  }

  return {
    type: EFilterConfigType.SELECT,
    dependency: {
      type: EDependencyType.DIMENSION,
      name: '',
    },
    id: getUniqId(),
    displayName: t.components.globalFilter.emptyFilterDisplayName,
    value: {
      type: ESelectFilterType.MULTIPLE,
      defaultValueEnable: false,
      defaultValue: {
        type: EFilterType.EQUAL,
        values: [],
      },
    },
  };
}
