import t from '@/locales';
import styles from './index.less';
import { AldSelect } from '@aloudata/aloudata-design';
import { type IProps as IDimensionTreePickerProps } from '../DimensionTreePicker';
import DimensionPickerDropdown from '../Dropdown';
import { useCallback, useMemo } from 'react';
import ColumnDataTypeIcon from '@/common/ui/ColumnDataTypeIcon';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import {
  EColumnDataType,
  EDateGranularityType,
  EOriginDataType,
} from '@/typings';
import { DATATYPE_COLOR } from '../constants';
import { IDimensionPickerValue } from '../types';
import { TDimension } from '@/typings/dimension';
import { isDateLikeDataType } from '@/common/utils';
import classNames from 'classnames';
import { ISelectProps } from '@aloudata/aloudata-design/dist/AldSelect/interface';
import { DimensionCardDropdown } from '../../DimensionCard/Dropdown';
import GranularitySelect from '@/common/domain/granularity/GranularitySelect';
import { isTimeGranularity } from '@/common/domain/filter/NewFilter/common/CustomTimePicker/GranularityPicker/helper';
import { getRealGranularity } from '@/common/domain/granularity/helper';

/**
 * 指标单选组件
 */
export default function DimensionSelect(props: IProps) {
  const {
    value,
    onChange,
    selectProps = {},
    size,
    disabled,
    showGranularity = true,
    supportTimeGranularity,
    className,
    ...otherProps
  } = props;

  const selectValue = useMemo(() => {
    if (value?.data) {
      return {
        key: value.data.name,
        label: value.data.displayName,
      };
    }
    return undefined;
  }, [value]);

  const SMALL_ICON_SIZE = 16;
  const MIDDLE_ICON_SIZE = 20;
  const iconSize =
    selectProps.size === 'small' ? SMALL_ICON_SIZE : MIDDLE_ICON_SIZE;

  const onChangeDimension = useCallback(
    (newDim: TDimension) => {
      if (newDim === value?.data) {
        return;
      }
      const newValue: IDimensionPickerValue = {
        dimensionName: newDim.name,
        data: newDim,
      };
      if (
        isDateLikeDataType(originDataType2DataTypeMap[newDim.originDataType])
      ) {
        newValue.granularity = EDateGranularityType.DAY;
      }
      onChange?.(newValue);
    },
    [onChange, value?.data],
  );

  let selectNode = (
    <AldSelect
      {...selectProps}
      open={false}
      value={selectValue}
      prefix={
        value && value.dimensionName ? (
          <ColumnDataTypeIcon
            size={iconSize}
            dataType={
              originDataType2DataTypeMap[
                value?.data?.originDataType || EOriginDataType.VARCHAR
              ]
            }
            color={DATATYPE_COLOR}
          />
        ) : undefined
      }
      labelInValue
      disabled={disabled}
      placeholder={
        selectProps.placeholder || t.components.dimensionPicker.placeholder
      }
    />
  );

  const selectWidth = selectProps.width !== 'fill' ? selectProps.width : '100%';
  const dropdownChildrenDivProps = {
    style: {
      width: selectWidth,
    },
  };

  if (value && value.data) {
    selectNode = (
      <DimensionCardDropdown
        dimension={value.data}
        dropdown={{
          placement: 'top',
        }}
      >
        {/* // Dropdown 的子元素必须能响应 onClick 事件，因此这里套一层 div */}
        <div {...dropdownChildrenDivProps}>{selectNode}</div>
      </DimensionCardDropdown>
    );
  }

  return (
    <div
      className={classNames(
        styles.wrap,
        {
          [styles.small]: size === 'small',
        },
        className,
      )}
    >
      <DimensionPickerDropdown
        {...otherProps}
        value={value?.data}
        size={size}
        disabled={disabled}
        dropdown={{ disabled }}
        onChange={onChangeDimension}
      >
        {/* // Dropdown 的子元素必须能响应 onClick 事件，因此这里套一层 div */}
        <div {...dropdownChildrenDivProps}>{selectNode}</div>
      </DimensionPickerDropdown>
      {showGranularity && value?.data && value?.granularity && (
        <GranularitySelect
          supportCustomCalendar
          className={styles.granularity}
          value={{ granularity: value?.granularity }}
          renderOptions={(granularities) => {
            const valueDataType =
              originDataType2DataTypeMap[
                value?.data?.originDataType || EOriginDataType.VARCHAR
              ];
            if (
              valueDataType !== EColumnDataType.DATE_TIME ||
              !supportTimeGranularity
            ) {
              return granularities.filter((item) => {
                return !isTimeGranularity(getRealGranularity(item));
              });
            }
            return granularities;
          }}
          onChange={(granularity) => {
            if (!value) {
              return;
            }
            onChange?.({
              dimensionName: value.dimensionName,
              data: value.data,
              granularity,
            });
          }}
        />
      )}
    </div>
  );
}

interface IProps
  extends Omit<
    IDimensionTreePickerProps,
    'picked' | 'onPick' | 'renderNode' | 'value' | 'onChange'
  > {
  selectProps?: ISelectProps;
  disabled?: boolean;
  value?: IDimensionPickerValue;
  onChange?: (value: IDimensionPickerValue) => void;
  showGranularity?: boolean; // 是否展示粒度选择器
  supportTimeGranularity?: boolean; // 是否支持比日更小的时间粒度
  className?: string;
}
