export const colorArray = [
  '#3769AD',
  '#F28E2C',
  '#6A4389',
  '#0A9953',
  '#B82828',
  '#AD5C0B',
  '#4F3267',
  '#0D7240',
  '#832525',
];
