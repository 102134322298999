import t from '@/locales';
import { EColumnDataType } from '@/typings';
import { IDefColumn } from '@/typings/dataset';
import { EFormulaType, EOriginDataType, TFormula } from '@/typings/formula';
import { originDataType2DataTypeMap } from '../../constant';
import functionConfig from '../../functionConfig/functionConfig';
import { EFindEntityResultType, findEntityAdapter } from '../findEntity';
import iterate from '../iterate';
import checkFunction, { ECheckResultType } from '../validate/function';
import { TExternalInfo } from '@/common/domain/codeEditor/CodeEditor/type';
import { getEngineType } from '../tool';
import _ from 'lodash';
import { IFilterFunctionsList } from '../../type';
export enum EDerivateOriginDataType {
  ORIGIN_DATA_TYPE = 'ORIGIN_DATA_TYPE',
  ERROR = 'ERROR',
}
export type TDerivateOriginDataTypeResult =
  | {
      type: EDerivateOriginDataType.ORIGIN_DATA_TYPE;
      originDataType: EOriginDataType;
      ast: TFormula;
      dataType: EColumnDataType;
    }
  | {
      type: EDerivateOriginDataType.ERROR;
      errorMsg: string;
      ast: TFormula;
      originDataType: EOriginDataType;
      dataType: EColumnDataType;
    };
class FormulaError {
  error:
    | {
        type: EDerivateOriginDataType.ERROR;
        errorMsg: string;
      }
    | undefined;

  public setError(errorMsg: string) {
    if (this.error) {
      return;
    } else {
      this.error = {
        type: EDerivateOriginDataType.ERROR,
        errorMsg,
      };
    }
  }

  public getError() {
    return this.error;
  }
}
export function derivateOriginDataType(
  ast: TFormula,
  externalInfo: TExternalInfo,
  filterFunctionList: IFilterFunctionsList,
): TDerivateOriginDataTypeResult {
  const tempAst = _.cloneDeep(ast);
  const { type } = tempAst;
  // 对于空公式和部分公式，直接返回
  if (type === EFormulaType.PARTIAL || type === EFormulaType.EMPTY) {
    return {
      type: EDerivateOriginDataType.ORIGIN_DATA_TYPE,
      originDataType: EOriginDataType.VARCHAR,
      dataType: EColumnDataType.TEXT,
      ast: tempAst,
    };
  }
  const initialOriginDataType = EOriginDataType.VARCHAR;
  const formulaError = new FormulaError();
  const queryEngineType = getEngineType();
  const originDataTypeRes = iterate(
    tempAst,
    initialOriginDataType,
    (astItem, previous) => {
      const { type: astItemType } = astItem;
      if (astItemType === EFormulaType.COLUMN) {
        const entity = findEntityAdapter(astItem.path, externalInfo);
        if (
          entity.type === EFindEntityResultType.COLUMN &&
          !(entity.result as IDefColumn)?.exception
        ) {
          return entity.result.originDataType;
        } else {
          formulaError.setError(t.formula.derivate.error.referenceError);
          return EOriginDataType.VARCHAR;
        }
      }
      if (astItemType === EFormulaType.FUNCTION) {
        const { op } = astItem;
        const checkResult = checkFunction({
          type: astItemType,
          op,
          args: previous,
          ast: astItem,
          filterFunctionList,
        });
        if (checkResult.type === ECheckResultType.ORIGIN_DATA_TYPE) {
          return checkResult.originDataType;
        } else {
          astItem.errorMsg = checkResult.errorMsg;
          formulaError.setError(checkResult.errorMsg);
          return EOriginDataType.VARCHAR;
        }
      }
      if (astItemType === EFormulaType.CONSTANT) {
        const { val } = astItem;
        return val.type;
      }
      if (astItemType === EFormulaType.PARENTHESIS) {
        const [originDataType] = previous;
        return originDataType;
      }
      if (astItemType === EFormulaType.UNARY_OPERATOR) {
        const [argOriginDataType] = previous;
        const { unOperators } = functionConfig[queryEngineType];
        const { op } = astItem;
        const unOperator = unOperators[op.toUpperCase()];
        const originDataType = unOperator.signatures.find(
          (item) => item.args[0] === argOriginDataType,
        )?.returnType;
        if (originDataType) {
          return originDataType;
        }
        astItem.errorMsg = t.formula.derivate.error.constantError;
        formulaError.setError(t.formula.derivate.error.constantError);
        return EOriginDataType.VARCHAR;
      }
      if (astItemType === EFormulaType.BINARY_OPERATOR) {
        const [leftOriginDataType, rightOriginDataType] = previous;
        const { binOperators } = functionConfig[queryEngineType];
        const { op } = astItem;
        const binOperator = binOperators[op];
        const originDataType = binOperator.signatures.find(
          (item) =>
            item.args[0] === leftOriginDataType &&
            item.args[1] === rightOriginDataType,
        )?.returnType;
        if (originDataType) {
          return originDataType;
        }
        astItem.errorMsg = t.formula.derivate.error.binaryOperatorError;
        formulaError.setError(t.formula.derivate.error.binaryOperatorError);
        return EOriginDataType.VARCHAR;
      }
      formulaError.setError(t.formula.derivate.error.unknownError);
      return EOriginDataType.VARCHAR;
    },
  );
  const dataType = originDataType2DataTypeMap[originDataTypeRes];
  const error = formulaError.getError();
  if (error) {
    return {
      type: EDerivateOriginDataType.ERROR,
      errorMsg: error.errorMsg,
      originDataType: originDataTypeRes,
      dataType,
      ast: tempAst,
    };
  } else {
    return {
      type: EDerivateOriginDataType.ORIGIN_DATA_TYPE,
      originDataType: originDataTypeRes,
      dataType,
      ast: tempAst,
    };
  }
}
