import _ from 'lodash';
import { graphql, rest } from 'msw';
import { getRestfulResponse } from '../utils';
import {
  getSimpleMetricList,
  getMockSimpleMetricInfo,
  dimensionList,
} from '../utils/getSimpleMetricList';
import { EColumnDataType, EOriginDataType } from '../../src/typings';
import { filterByKeyword, getUniqId } from '../../src/common/utils';
import { EAccountType, EResourceType } from '../../src/typings/authority';
import { ERowFilterType } from '../../src/services/metric/types';
import {
  DIMENSION_NAME_PREFIX_UUID,
  METRIC_CODE_PREFIX_UUID,
} from '../utils/constants';

const mockMetrics = getSimpleMetricList();

export const metric = [
  graphql.query('queryCommonOneEndpointDatasetsWithFilter', (req, res, ctx) => {
    return res(
      ctx.data({
        queryCommonOneEndpointDatasetsWithFilter: _.map(
          _.range(20),
          (index) => ({
            columnName: `column_${index}`,
            columnDisplayName: `columnDisplayName_${index}`,
            datasetName: `datasetName_${index}`,
            description: `description_${index}`,
            originDataType: _.sample([
              EOriginDataType.INTEGER,
              EOriginDataType.FLOAT,
              EOriginDataType.DOUBLE,
            ])!,
          }),
        ),
      }),
      ctx.delay(500),
    );
  }),
  graphql.query('queryMetricList', (req, res, ctx) => {
    const codes = req.variables.metricConditionRequest.codes;
    const keyword = req.variables.metricConditionRequest.keyword;
    const categoryId = req.variables.metricConditionRequest.categoryId;
    const list =
      _.isArray(codes) && codes.length > 0
        ? mockMetrics.splice(0, codes.length)
        : mockMetrics;
    let filteredList = keyword
      ? filterByKeyword(list, (item) => item.name, keyword)
      : list;
    filteredList = categoryId
      ? _.filter(filteredList, (item) => {
          return !!_.find(
            item.category,
            (category) => category.id === categoryId,
          );
        })
      : filteredList;
    return res(
      ctx.data({
        queryMetricList: {
          data: filteredList,
          pageSize: 20,
          pageNumber: 1,
          total: 50,
          hasNext: true,
        },
      }),
      ctx.delay(_.sample([100, 500, 1000, 2000])),
    );
  }),
  graphql.query('queryDimensionsByDatasetName', (req, res, ctx) => {
    return res(
      ctx.data({
        queryDimensionsByDatasetName: dimensionList,
        pageSize: 20,
        pageNumber: 1,
        total: 50,
        hasNext: true,
      }),
      ctx.delay(1000),
    );
  }),
  graphql.mutation('deleteMetric', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteMetric: true,
      }),
    );
  }),
  graphql.mutation('collectMetric', (req, res, ctx) => {
    return res(
      ctx.data({
        collectMetric: true,
      }),
    );
  }),
  graphql.mutation('cancelCollectMetric', (req, res, ctx) => {
    return res(
      ctx.data({
        cancelCollectMetric: true,
      }),
    );
  }),
  graphql.mutation('saveMetric', (req, res, ctx) => {
    return res(
      ctx.data({
        saveMetric: true,
      }),
    );
  }),
  graphql.mutation('createMetric', (req, res, ctx) => {
    return res(
      ctx.data({
        createMetric: true,
      }),
    );
  }),
  graphql.query('definitionDuplicatedCheck', (req, res, ctx) => {
    return res(
      ctx.data({
        definitionDuplicatedCheck: getSimpleMetricList(),
      }),
    );
  }),
  graphql.mutation('editMetric', (req, res, ctx) => {
    return res(
      ctx.data({
        editMetric: true,
      }),
    );
  }),

  graphql.mutation('updateMetric', (req, res, ctx) => {
    return res(
      ctx.data({
        updateMetric: true,
      }),
    );
  }),

  graphql.query('queryMetricDetail', (req, res, ctx) => {
    return res(
      ctx.data({
        queryMetricDetail: getMockSimpleMetricInfo(req.variables.code, 0, true),
      }),
      ctx.delay(500),
    );
  }),

  graphql.mutation('saveMetricPreviewConfig', (req, res, ctx) => {
    return res(
      ctx.data({
        saveMetricPreviewConfig: true,
      }),
    );
  }),

  graphql.mutation('saveMetricAttributionTree', (req, res, ctx) => {
    return res(
      ctx.data({
        saveMetricAttributionTree: true,
      }),
    );
  }),

  graphql.mutation('saveMetricAttributionReport', (req, res, ctx) => {
    return res(
      ctx.data({
        saveMetricAttributionReport: true,
      }),
    );
  }),

  graphql.mutation('publishResource', (req, res, ctx) => {
    return res(
      ctx.data({
        publishResource: true,
      }),
    );
  }),

  graphql.mutation('updateResourceStatus', (req, res, ctx) => {
    return res(
      ctx.data({
        updateResourceStatus: true,
      }),
    );
  }),

  graphql.mutation('deleteResourceVersion', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteResourceVersion: true,
      }),
    );
  }),

  graphql.query('queryResourceVersionList', (req, res, ctx) => {
    const resourceType = req.variables.resourceType;

    return res(
      ctx.data({
        queryResourceVersionList: Array.from({ length: 10 }).map(
          (value, index) => ({
            resourceId: getUniqId(),
            resourceType,
            version: 'v' + index,
            owner: {
              userId: getUniqId(),
              nickname: 'nickname' + index,
              account: 'account' + index,
              photo: '',
            },
            gmtCreate: Date.now(),
            status: _.sample([
              'UNPUBLISHED',
              'PUBLISHED',
              'CURRENT_VERSION',
              'DRAFT',
            ]),
          }),
        ),
      }),
    );
  }),

  graphql.query('queryRowFilterRuleList', (req, res, ctx) => {
    const ruleType = _.sample([
      ERowFilterType.CONDITION_RULE,
      ERowFilterType.CONDITION_RULE,
    ]);

    const content =
      ruleType === ERowFilterType.CONDITION_RULE
        ? {
            users: Array.from({ length: 5 })
              .fill(0)
              .map((v, i) => {
                const type = _.sample([
                  EAccountType.USER,
                  EResourceType.USER_GROUP,
                ]);
                const displayName =
                  type === EAccountType.USER ? '这是一个人' : '这是一个组';

                return {
                  authorityAccountType: type,
                  account: 'account' + i,
                  resourceId: 'id' + i,
                  displayName: displayName,
                  photo: '',
                };
              }),
            rules: [],
            whitelists: Array.from({ length: 5 })
              .fill(0)
              .map((v, i) => {
                const type = _.sample([
                  EAccountType.USER,
                  EResourceType.USER_GROUP,
                ]);
                const displayName =
                  type === EAccountType.USER ? '这是一个人' : '这是一个组';

                return {
                  authorityAccountType: type,
                  account: 'account' + i,
                  resourceId: 'id' + i,
                  displayName: displayName,
                  photo: '',
                };
              }),

            owner: {
              account: 'ownerAccount',
              userId: getUniqId(),
              nickname: 'nickname',
              photo: '',
            },

            dimensionFilter: {
              dimensionFlag: true,
              includes: ['1', '2'],
              excludes: ['3', '4'],
            },
          }
        : {
            table: 'testTable', // 选择的物理表guid
            userIdentifier: 'column', // 用户标识
            dimensionFlag: _.sample([true, false]), // 是否打开维度筛选
            tableRuleContents: new Array(10).fill(0).map((text, index) => ({
              dimension: `${DIMENSION_NAME_PREFIX_UUID}_` + index,
              column: 'column',
              dimensionFilter: {
                dimensionFlag: true,
                includes: ['1', '2'],
                excludes: ['3', '4'],
              },
            })),
          };

    return res(
      ctx.data({
        queryRowFilterRuleList: {
          resourceId: getUniqId(),
          resourceType: EResourceType.METRIC,
          ruleType,
          rules: Array.from({ length: 10 })
            .fill(0)
            .map((value, index) => ({
              id: getUniqId(),
              name: '这是规则i' + index,
              ruleType: ruleType,
              layout: getMockSimpleMetricInfo(
                `${METRIC_CODE_PREFIX_UUID}_derived`,
                1,
              ).layout.filters,
              rule: content,
            })),
        },
      }),
    );
  }),

  rest.get('/metric/name_duplicated_check', async (req, res, ctx) =>
    res(
      ctx.json(
        getRestfulResponse({
          result: true,
          errorMsg: 'error message',
        }),
      ),
    ),
  ),
  rest.get('/metric/code_duplicated_check', async (req, res, ctx) =>
    res(
      ctx.json(
        getRestfulResponse({
          result: true,
          errorMsg: 'error message',
        }),
      ),
    ),
  ),
  rest.get('/metric/display_name_duplicated_check', async (req, res, ctx) =>
    res(ctx.json(getRestfulResponse(false))),
  ),
  graphql.query('queryMetricMatchesCandidate', async (req, res, ctx) =>
    res(
      ctx.data(
        getRestfulResponse({
          queryMetricMatchesCandidate: {
            disabledMetrics: [
              { code: 'asdjadasdasdj_atomic_1', message: '不能使用' },
            ],
            metrics: getSimpleMetricList(),
          },
        }),
      ),
      ctx.delay(2000),
    ),
  ),

  rest.post('/metric/expr_validate', async (req, res, ctx) =>
    res(ctx.json(getRestfulResponse({ success: true }))),
  ),

  rest.post('/metric/template/upload', async (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse({
          uploadId: '123456789',
          successMetricsId: '32324324',
          totalCount: 10,
          successCount: 8,
          errorCount: 2,
          info: '识别指标：3个\n<success>可成功创建指标：1个</success><error>异常指标：2个</error>    指标名称重复：1个 (第3行) \n* 指标展示名重复：2个 (第3行,第4行)\n* safdsaf',
        }),
      ),
      ctx.delay(500),
    );
  }),
  graphql.mutation('updateRowFilterRules', (req, res, ctx) => {
    return res(
      ctx.data({
        updateRowFilterRule: true,
      }),
    );
  }),
  graphql.mutation('batchCreateDraft', (req, res, ctx) => {
    return res(
      ctx.data({
        batchCreateDraft: true,
      }),
    );
  }),
];
