import {
  EColumnDataType,
  EDataSourceType,
  EDateGranularityType,
  EOriginDataType,
} from '@/typings';
import AirIconSrc from '@/assets/img/AIR.png';
import starrocksSrc from '@/assets/img/starrocks.png';
import sqlserverSrc from '@/assets/img/icon-data-platform-sql-server.png';
import postgreSrc from '@/assets/img/icon-data-platform-postgre-sql.png';
import mongoDbSrc from '@/assets/img/icon-data-platform-mongo-db.png';
import mysqlSrc from '@/assets/img/icon-data-platform-my-sql.png';
import defaultSrc from '@/assets/img/Data-source-light-line.png';
import maxComputeSrc from '@/assets/img/icon-data-platform-max-compute.png';
import hiveSrc from '@/assets/img/icon-data-platform-hive.png';
import dorisSrc from '@/assets/img/Doris.png';
import t from '@/locales';
import { EDimensionType, IDimension } from '@/typings/dimension';
import { EUserRole, EResourceRole } from '@/typings/authority';
import { EOnlineStatus } from '@/typings/metric';
import { EPropertyType } from '@/typings/property';
import { EQueryEngineType } from '@/typings/formula';
export * from './dom';
export * from './reg';
export const DEFAULT_TENANT_LOGO =
  'https://as-public.oss-cn-hangzhou.aliyuncs.com/tenant-default-logo.png';
export const QUERY_ERROR_CODE = {
  UNAUTHORIZED_OR_DELETED: 'AS_12_0006', // 发布的时候 表没有权限或已删除
};
export const TRACE_PARAMS_LENGTH_LIMIT = 300;
// 指标日期的列名常量
export const METRIC_TIME = 'metric_time';
export const METRIC_TIME_ID = `${METRIC_TIME}_day`;

export const NO_CATEGORY = [];
// 指标日期的列定义常量
export const metricTimeColumn: IDimension = {
  name: METRIC_TIME,
  dimName: METRIC_TIME,
  displayName: t.common.column.metricTime,
  originDataType: EOriginDataType.DATE,
  description: '',
  isEnable: true,
  type: EDimensionType.BIND,
  dimensionDataType: EColumnDataType.DATE,
  authority: {
    canEdit: true,
    canDelete: true,
    canUsage: true,
    canAuth: true,
    canTransfer: false,
  },
  category: NO_CATEGORY,
  isPublished: true,
  hasPermission: true,
  datasetName: '',
  status: EOnlineStatus.ONLINE,
  hasDraft: false,
  properties: {
    [EPropertyType.BASE]: {
      dimName: {
        value: METRIC_TIME,
      },
      dimCode: {
        value: METRIC_TIME,
      },
      dimDisplayName: {
        value: t.common.column.metricTime,
      },
    },
    [EPropertyType.BUSINESS]: {
      dimDescription: {
        value: '',
      },
      dimCategoryId: {
        value: [],
      },
    },
    [EPropertyType.MANAGE]: {},
    [EPropertyType.TECHNOLOGY]: {},
  },
};
// 数据源的icon
export const datasourceIconMap: {
  [key in EQueryEngineType | EDataSourceType]: string;
} = {
  [EQueryEngineType.AIR]: AirIconSrc,
  [EQueryEngineType.AIR2]: AirIconSrc,
  [EQueryEngineType.STARROCKS]: starrocksSrc,
  [EQueryEngineType.DATABRICKS]: AirIconSrc,
  [EQueryEngineType.PRESTO]: AirIconSrc,
  [EDataSourceType.HIVE1]: hiveSrc,
  [EDataSourceType.HIVE2]: hiveSrc,
  [EDataSourceType.HIVE3]: hiveSrc,
  [EDataSourceType.MAXCOMPUTE]: maxComputeSrc,
  [EDataSourceType.MONGODB]: mongoDbSrc,
  [EDataSourceType.MYSQL]: mysqlSrc,
  [EDataSourceType.POSTGRESQL]: postgreSrc,
  [EDataSourceType.SQLSERVER]: sqlserverSrc,
  [EDataSourceType.DORIS]: dorisSrc,
};

export const defaultDataSoruceIcon = defaultSrc;

// 假设这个长度可以拿到所有的数据集
export const ALL_DATASET_LENGTH = 100000;
// 假设这个长度可以拿到所有的维度
export const ALL_DIMENSION_LENGTH = 100000;
// 假设这个长度可以拿到所有的维度
export const ALL_METRIC_LENGTH = 100000;
// 假设这个长度可以拿到所有的分析视图
export const ALL_ANALYSIS_VIEW_LENGTH = 100000;
// 用户的角色文案
export const userRoleMap = {
  [EUserRole.OWNER]: t.manage.role.owner,
  [EUserRole.ADMIN]: t.manage.role.admin,
  [EUserRole.ANALYST]: t.manage.role.analyst,
  [EUserRole.USAGER]: t.manage.role.usage,
  [EUserRole.VIEWER]: t.manage.role.viewer,
};
// 数据源角色文案
export const datasourceRoleMap = {
  [EResourceRole.OWNER]: t.datasource.role.owner,
  [EResourceRole.ADMIN]: t.datasource.role.admin,
  [EResourceRole.USAGER]: t.datasource.role.usager,
};
export const DEFAULT_SEPARATOR = '/';
export const dateGranularityOptions = [
  {
    label: t.filter.filter.date.dynamic.day,
    value: EDateGranularityType.DAY,
  },
  {
    label: t.filter.filter.date.dynamic.week,
    value: EDateGranularityType.WEEK,
  },
  {
    label: t.filter.filter.date.dynamic.month,
    value: EDateGranularityType.MONTH,
  },
  {
    label: t.filter.filter.date.dynamic.quarter,
    value: EDateGranularityType.QUARTER,
  },
  {
    label: t.filter.filter.date.dynamic.year,
    value: EDateGranularityType.YEAR,
  },
];
export const dateTimeGranularityOptions = [
  {
    label: t.filter.filter.date.dynamic.second,
    value: EDateGranularityType.SECOND,
  },
  {
    label: t.filter.filter.date.dynamic.minute,
    value: EDateGranularityType.MINUTE,
  },
  {
    label: t.filter.filter.date.dynamic.hour,
    value: EDateGranularityType.HOUR,
  },
  {
    label: t.filter.filter.date.dynamic.day,
    value: EDateGranularityType.DAY,
  },
  {
    label: t.filter.filter.date.dynamic.week,
    value: EDateGranularityType.WEEK,
  },
  {
    label: t.filter.filter.date.dynamic.month,
    value: EDateGranularityType.MONTH,
  },
  {
    label: t.filter.filter.date.dynamic.quarter,
    value: EDateGranularityType.QUARTER,
  },
  {
    label: t.filter.filter.date.dynamic.year,
    value: EDateGranularityType.YEAR,
  },
];

export const UN_CATEGORIZED_CATEGORY_ID = '-1';
