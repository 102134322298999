import AIR from './AIR';
import STARROCKS from './STARROCKS';
import DORIS from './DORIS';
import AIR2 from './AIR2';

import DATABRICKS from './DATABRICKS';
const functionConfig = {
  AIR,
  STARROCKS,
  DORIS,
  AIR2,
  DATABRICKS,
};

export default functionConfig;
