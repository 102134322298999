import { getUniqId } from '../../src/common/utils';
import _ from 'lodash';
import {
  EImpactResourceType,
  EResourceImpactType,
  TResourceImpactReport,
} from '../../src/services/resource/type';
import { EResourceType } from '../../src/typings/authority';

export const getMockResourceImpactReport = (
  index: number,
): TResourceImpactReport => {
  const resourceType:
    | EImpactResourceType.METRIC
    | EImpactResourceType.DIMENSION
    | EImpactResourceType.DATASET = _.sample([
    EImpactResourceType.METRIC,
    EImpactResourceType.DIMENSION,
    EImpactResourceType.DATASET,
  ]);

  return {
    curResourceId: getUniqId(),
    curResourceDisplayName: `测试资源${index}`,
    curResourceType: resourceType,
    curResourceDetail: {
      id: getUniqId(),
      name: `测试资源详情${index}`,
      displayName: `测试资源详情${index}`,
    },
    childResources: Array.from({ length: _.random(3, 10) }).map(() => ({
      resourceId: getUniqId(),
      displayName: `下游资源${getUniqId()}`,
      message: `测试消息${index}`,
      owner: {
        userId: getUniqId(),
        account: `test_account${index}`,
        nickname: `测试用户${index}`,
      },
      resourceType: _.sample([
        EResourceType.METRIC,
        EResourceType.DIMENSION,
        EResourceType.RP_TASK,
        EResourceType.METRIC_MONITOR,
      ]) as EResourceType,
      impactType: _.sample([
        EResourceImpactType.ERROR,
        EResourceImpactType.WARN,
      ]) as EResourceImpactType,
    })),
  };
};
