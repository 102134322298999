export const defaultCategory = {
  name: 'Uncategorized',
};

export const adjustCategory = {
  default: 'Modify Category',
  batch: 'Batch Modify Category',
};

export const categoryModifyModal = {
  defaultName: 'New Category',
  title: {
    rename: 'Rename',
    create: 'New Category',
  },
  cancel: 'Cancel',
  okText: {
    rename: 'Save',
    create: 'Create',
  },
  label: 'Name',
};
