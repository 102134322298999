import { IProps as IDimensionTreePickerProps } from '../DimensionTreePicker';
import { Dropdown } from '@aloudata/aloudata-design';
import { IDropdownProps } from '@aloudata/aloudata-design/dist/Dropdown';
import { useEffect, useState } from 'react';
import {
  EDropdownCloseReason,
  ESelectMode,
} from '@/common/domain/metric/Picker/types';
import DropdownContent from './DropdownContent';

export default function DimensionPickerDropdown(props: IProps) {
  const { dropdown, children, disabled, ...otherProps } = props;
  const [open, setOpen] = useState(!!dropdown?.open);
  const { onOpenChange: dropdownOnOpenChange, ...otherDropdownProps } =
    dropdown || {};
  const onClose = (reason: EDropdownCloseReason) =>
    dropdownOnOpenChange?.(false, reason);

  useEffect(() => {
    setOpen(!!dropdown?.open);
  }, [dropdown?.open]);

  return (
    <Dropdown
      open={open}
      onOpenChange={(isOpen) => {
        if (disabled) {
          return;
        }
        setOpen(isOpen);
        dropdownOnOpenChange?.(isOpen, EDropdownCloseReason.DROPDOWN_CLOSE);
      }}
      trigger={'click'}
      {...otherDropdownProps}
      disabled={disabled}
      dropdownRender={() => (
        <DropdownContent
          {...otherProps}
          onClose={(reason) => {
            setOpen(false);
            onClose?.(reason);
          }}
        />
      )}
    >
      {children}
    </Dropdown>
  );
}

interface IProps extends IDimensionTreePickerProps {
  children: React.ReactNode;
  title?: string;
  mode?: ESelectMode; // 为空时，为单选模式
  dropdown?: Omit<IDropdownProps, 'children' | 'onOpenChange'> & {
    onOpenChange?: (isOpen: boolean, reason: EDropdownCloseReason) => void;
  };
  disabled?: boolean;
}
