export const physicTable = 'Source Table';

export const file = 'File';

export const dataset = 'Dataset';

export const analysisView = 'View';

export const node = {
  start: 'Current Data',
  columns: 'Columns',
};

export const detail = {
  title: 'View Details',
  dataset: {
    displayName: 'Display Name',
    name: 'Name',
    source: 'Data Source',
    owner: 'Owner',
    desc: 'Description',
  },
  metric: {
    type: 'Metric Type',
    bizOwner: 'Data Owner',
    unit: 'Unit',
    category: 'Category',
    desc: 'Business Caliber',
  },
  file: {
    displayName: 'Display Name',
    name: 'Name',
    path: 'Path',
    owner: 'Owner',
    desc: 'Description',
  },
  physicalTable: {
    name: 'Table Name',
    datasourceDisplayName: 'Data Source',
    path: 'Path',
  },
  createTime: 'Created',
  updateTime: 'Last Updated',
  analysisView: {
    name: 'Name',
    desc: 'Description',
    owner: 'Owner',
  },
};

export const box = {
  title: {
    baseInfo: 'Basic Info',
  },
};
