import { TMetric } from '@/typings/metric';
import { Dropdown } from '@aloudata/aloudata-design';
import MetricCard from '.';
import { IDropdownProps } from '@aloudata/aloudata-design/dist/Dropdown';

export function MetricCardDropdown(props: IProps) {
  const { children, metric, dropdown } = props;
  if (!metric) return children;
  return (
    <Dropdown
      trigger="hover"
      destroyPopupOnHide
      initialFocus={-1}
      {...dropdown}
      dropdownRender={() => {
        return <MetricCard metric={metric} />;
      }}
    >
      {children}
    </Dropdown>
  );
}

interface IProps {
  children: React.ReactElement;
  metric?: TMetric;
  dropdown?: Partial<IDropdownProps>;
}
