import { getUniqId } from '../../src/common/utils';
import {
  EMonitorResultDataType,
  EMonitorResultDimensionSplitHeaderType,
  EMonitorResultDimensionSplitItemStatus,
  EMonitorResultStatus,
  IMonitorResult,
  IMonitorResultDimensionSplit,
  IMonitorResultValue,
  EMonitorRuleGroupOp,
  EOperator,
  EReceiverType,
  IMonitorConfig,
} from '../../src/typings/monitor';
import { EOriginDataType } from '../../src/typings';

import _ from 'lodash';
import {
  EDateDynamicOperator,
  EDateFilterType,
  EDateFilterValueType,
  EDateRangeType,
  EDateUnitKey,
} from '../../src/common/domain/filter/Filter/filter';
import { EGranularitySelect, TMetric } from '../../src/typings/metric';
import {
  EDateFilterUnit,
  EQuickCalcType,
} from '../../src/typings/analysisView';
import { EDateGranularityType } from '../../src/typings';
import { EAccountType, EUserRole } from '../../src/typings/authority';
import { getSimpleMetricList } from '../utils/getSimpleMetricList';

/**
 * mock 监控配置
 */
export function getMonitorConfigData(id: string, displayName: string) {
  const metric = getSimpleMetricList()[0];
  const metricCode = metric.code;
  const mockMonitorConfig: IMonitorConfig = {
    id,
    displayName: displayName,
    metric,
    dimensionSplit: [
      {
        dimension: metric.availableDimensions[0].name,
        values: _.map(_.range(100), (i) => `value${i}`),
      },
    ],
    execCron: '0 0 0 * * ?', // 每天执行一次
    ruleConfig: {
      id: 'bndgQfeBhuOZWbGW',
      op: EMonitorRuleGroupOp.OR,
      children: [
        {
          id: 'cZQPzLiFZgkoNjUV',
          left: {
            code: metricCode,
          },
          op: EOperator.GT,
          right: 0,
        },
        {
          id: 'tDPrUpmfMruARkri',
          left: {
            code: metricCode,
            type: EQuickCalcType.GROWTH_VALUE,
            granularity: EDateGranularityType.WEEK,
          },
          op: EOperator.LT,
          right: -3000,
        },
        {
          id: 'tyTJoBqcjEcDwltF',
          op: EMonitorRuleGroupOp.OR,
          children: [
            {
              id: 'XruWRTDZouVHPGKy',
              left: {
                code: metricCode,
                type: EQuickCalcType.GROWTH,
                granularity: EDateGranularityType.YEAR,
              },
              op: EOperator.GT,
              right: 0.22,
            },
            {
              id: 'QSZzJESUSeBXGmzB',
              left: {
                code: metricCode,
                type: EQuickCalcType.VALUE,
                granularity: EDateGranularityType.YEAR,
              },
              op: EOperator.BETWEEN,
              right: [0, 10000],
            },
          ],
        },
      ],
    },
    pushConfig: [
      {
        channels: [
          {
            channelId: 'EMAIL',
            type: 'EMAIL',
            receivers: [
              {
                receiverType: EReceiverType.USER_USER_GROUP,
                receiverInfo: {
                  accountType: EAccountType.USER,
                  id: 'user1',
                },
              },
            ],
          },
        ],
        title: 'Daily Sales Alert',
        content:
          '<p><span style="white-space: pre-wrap;">截止&nbsp;</span>${detect_time}<span style="white-space: pre-wrap;">&nbsp;，您关注的指标&nbsp;</span>${metric_name}<span style="white-space: pre-wrap;">&nbsp;的指标结果为&nbsp;</span>${metric_result}<span style="white-space: pre-wrap;">&nbsp;，命中告警条件：&nbsp;</span>${monitor_rule}<span style="white-space: pre-wrap;"></span></p><p><span style="white-space: pre-wrap;">请及时关注，指标负责人为&nbsp;</span>${metric_owner}<span style="white-space: pre-wrap;">&nbsp;&nbsp;</span>${split_dimension_value}<span style="white-space: pre-wrap;">&nbsp;</span></p>',
      },
    ],
    authority: {
      authRoleList: [EUserRole.OWNER, EUserRole.ANALYST],
      canCreate: true,
      canEdit: true,
      canDelete: true,
      canUsage: true,
      canAuth: true,
      canMove: false,
    },
    layout: {
      metricTimeFilter: {
        select: EDateFilterUnit.LAST30,
        value: {
          values: {
            type: EDateRangeType.RANGE,
            includeToday: true,
            values: {
              min: {
                operator: EDateDynamicOperator.DECREASE,
                type: EDateFilterValueType.DYNAMIC,
                granularitySelect: EGranularitySelect.CURRENT_DAY,
                unit: EDateUnitKey.DAY,
                value: 179,
              },
              max: {
                operator: EDateDynamicOperator.DECREASE,
                type: EDateFilterValueType.DYNAMIC,
                granularitySelect: EGranularitySelect.CURRENT_DAY,
                unit: EDateUnitKey.DAY,
                value: 0,
              },
            },
          },
          includeNull: false,
          type: EDateFilterType.RANGE,
          granularity: EDateGranularityType.DAY,
        },
      },
    },
  };

  return mockMonitorConfig;
}

export const getMonitorResult = (): IMonitorResult => {
  const id = getUniqId();
  const resultStatus = _.sample([
    {
      status: EMonitorResultStatus.NORMAL,
      msgs: [],
    },
    {
      status: EMonitorResultStatus.ABNORMAL,
      msgs: ['数据异常'],
    },
    {
      status: EMonitorResultStatus.METRIC_ABNORMAL,
      msgs: ['指标存在异常'],
    },
  ]);

  return {
    id,
    monitorTime: [],
    metricMonitorConfig: getMonitorConfigData(id, `name_${id}`),
    execTime: _.sample([new Date().getTime(), null]),
    resultData: _.sample([
      getMonitorResultValue(),
      getMonitorResultDimensionSplit(),
    ]) as IMonitorResultValue | IMonitorResultDimensionSplit,
    resultStatus: resultStatus.status,
    resultMsgs: resultStatus.msgs,
    owner: {
      userId: getUniqId(),
      account: 'testUser',
      nickname: 'testUser',
      photo: '',
    },
  };
};

export const getMonitorResultValue = (): IMonitorResultValue => ({
  resultDataType: EMonitorResultDataType.VALUE,
  value: _.sample([10000.123, '错误信息']) as number | string,
});

export const getMonitorResultDimensionSplit =
  (): IMonitorResultDimensionSplit => ({
    resultDataType: EMonitorResultDataType.DIMENSION_SPLIT,
    headers: [
      {
        type: EMonitorResultDimensionSplitHeaderType.DIMENSION,
        id: 'dimensionId',
        displayName: '维度1',
        originDataType: EOriginDataType.VARCHAR,
      },
      {
        type: EMonitorResultDimensionSplitHeaderType.METRIC,
        id: 'metric1',
        displayName: '指标1',
        originDataType: EOriginDataType.INT,
      },
      {
        type: EMonitorResultDimensionSplitHeaderType.METRIC,
        id: 'metric2',
        displayName: '指标2',
        originDataType: EOriginDataType.INT,
      },
      {
        type: EMonitorResultDimensionSplitHeaderType.METRIC,
        id: 'metric3',
        displayName: '指标3',
        originDataType: EOriginDataType.INT,
      },
      {
        type: EMonitorResultDimensionSplitHeaderType.STATUS,
        id: 'status',
        displayName: '状态',
        originDataType: EOriginDataType.INT,
      },
    ],
    data: Array.from({ length: 30 }, (v, index) => {
      return {
        dimensionId: `dimension${index}`,
        metric1: _.random(10000, 100000),
        metric2: _.random(10000, 100000),
        metric3: _.random(10000, 100000),
        status: _.sample([
          {
            status: EMonitorResultDimensionSplitItemStatus.NORMAL,
          },
          {
            status: EMonitorResultDimensionSplitItemStatus.ABNORMAL,
            msg: '异常信息',
          },
        ]),
      };
    }),
  });
