import styles from './index.less';
import { EFilterType, TFilter } from '../../types';
import {
  IOperatorProps,
  operatorLooseDataTypeMap,
} from '../Operator/CommonOperator';
import { isEmptyFilter } from '../../helper';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import { EOriginDataType } from '@/typings';
import { memo, useCallback } from 'react';
import { looseDataTypeMap } from '../../constants';
import _ from 'lodash';
import { getDefaultValues, getDefaultValuesByOriginDataType } from './helper';
import ValueNode, { IValuesPickerProps } from './ValueNode';
import { IDisabledMap } from '@/common/domain/dimension/DimensionPicker/type';

export function Condition(props: IProps) {
  const {
    renderLeft,
    renderValuesPicker,
    value,
    leftData,
    onChange,
    renderOperator,
    currentCode,
  } = props;
  const { dependency, granularity } = value;

  // 修改筛选类型
  const onChangeType = useCallback(
    (newFilterType: EFilterType) => {
      const newValue = {
        ...value,
        type: newFilterType,
        // 修改筛选类型后，改变默认的值
        values: getDefaultValues(newFilterType, granularity).values,
      } as TFilter;
      if (
        newFilterType === EFilterType.IS_NULL ||
        newFilterType === EFilterType.NOT_NULL
      ) {
        delete newValue.values;
      }
      onChange(newValue);
    },
    [onChange, value, granularity],
  );

  if (!leftData || isEmptyFilter(value)) {
    return (
      <div className={styles.wrap}>
        <div className={styles.left}>
          {renderLeft({
            value: { dependency, granularity },
            onChange: (newValue, originDataType) => {
              if (!originDataType) {
                // 清空了左值才会出现，目前不存在这种情况
                return;
              }
              const newColumnDataType =
                originDataType2DataTypeMap[originDataType];
              const newLooseDataType = looseDataTypeMap[newColumnDataType];
              const newFilterType =
                operatorLooseDataTypeMap[newLooseDataType][0].value;

              const newFilter = {
                ...value,
                dependency: newValue.dependency,
                granularity: newValue.granularity,
                type: newFilterType,
                values: getDefaultValuesByOriginDataType(
                  originDataType,
                  granularity,
                )?.values,
              } as TFilter;
              onChange(newFilter);
            },
          })}
        </div>
      </div>
    );
  }

  const leftColumnDataType =
    originDataType2DataTypeMap[leftData.originDataType];

  return (
    <div className={styles.wrap}>
      <div className={styles.left}>
        {renderLeft({
          value: { dependency, granularity },
          onChange: (newValue, originDataType) => {
            if (
              _.isEqual(value.dependency, newValue.dependency) &&
              value.granularity === newValue.granularity &&
              originDataType === leftData.originDataType
            ) {
              // 没有修改任何值
              return;
            }
            if (!originDataType) {
              // 清空了左侧的值
              return;
            }
            // 修改了左侧的值，需要重置右侧的值
            const newColumnDataType =
              originDataType2DataTypeMap[originDataType];
            const newLooseDataType = looseDataTypeMap[newColumnDataType];
            const newFilterType =
              operatorLooseDataTypeMap[newLooseDataType][0].value;
            const newValues = getDefaultValues(
              newFilterType,
              newValue.granularity,
            ).values;
            const newFilter = {
              ...value,
              type: newFilterType,
              dependency: newValue.dependency,
              granularity: newValue.granularity,
              values: newValues,
            } as TFilter;

            onChange(newFilter);
          },
        })}
      </div>
      <div>
        {renderOperator({
          columnDataType: leftColumnDataType,
          value: value.type,
          onChange: onChangeType,
        })}
      </div>
      {/* // 防止修改左侧实体后，右侧组件相同时，不会重新渲染 */}
      <div key={`${leftData.id}_${value.type}`}>
        <ValueNode
          value={value}
          currentCode={currentCode}
          originDataType={leftData.originDataType}
          onChange={onChange}
          renderValuesPicker={renderValuesPicker}
        />
      </div>
    </div>
  );
}

interface IProps {
  value: TFilter;
  onChange: (value: TFilter) => void;
  /**
   * 左侧基础数据
   */
  leftData?: ILeftData;
  /**
   * 渲染左侧的组件
   */
  renderLeft: (leftProps: ILeftProps) => React.ReactNode;
  renderValuesPicker: (
    valuesPickerProps: IValuesPickerProps,
  ) => React.ReactNode;
  disabled?: boolean;
  renderOperator: (props: IOperatorProps) => React.ReactNode;
  disabledMap?: IDisabledMap;
  currentCode?: string;
}

interface ILeftData {
  id: string;
  originDataType: EOriginDataType;
  displayName: string;
}

interface ILeftProps {
  value: {
    dependency: TFilter['dependency'];
    granularity?: string;
  };
  onChange: (
    value: ILeftProps['value'],
    originDataType?: EOriginDataType,
  ) => void;
}

export default memo(Condition);
