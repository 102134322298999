import { EFilterType, IMetricFilter, TMetric } from '@/typings/metric';
import { EDateGranularityType, EOriginDataType, IUser } from '.';
import { IDataset } from './dataset';
import { TDimension } from './dimension';
import {
  IAnalysisViewDimension,
  IAnalysisViewMetric,
  IAnalysisViewResponse,
} from '@/services/analysisView';
import { IIncrementRefreshScope } from './dataSource';
import { TFilter } from '@/common/domain/filter/NewFilter/types';
import { TNode } from '@aloudata/aloudata-design/dist/LogicTree';
import { TFilterInAnalysisView, TMetricTime } from './analysisView';
import { TFormula } from './formula';
import { IAuthority } from './authority';

/**
 * 基础的加速方案配置信息
 */
export interface IAcceleratePlanBase {
  id: string;
  name: string;
  displayName: string;
  planType: EAcceleratePlanType;
  datasetName: string;
  columns: IAcceleratePlanColumn[];
  metrics: string[];
  dimensions: IAcceleratePlanDimension[];
  // 方案粒度，值是维度的 name 列表
  planGranularity?: string[];
  authority: IAuthority;
}

/**
 * 加速方案详情，包含物化表、数据集等信息
 */
export interface IAcceleratePlanDetail extends IAcceleratePlanBase {
  owner?: IUser;
  dependencies: IAcceleratePlanDependencies;
  // 物化表数量
  createTime: number;
  updateTime: number;
  mtCount: number;
}

export interface IAcceleratePlanDependencies {
  metrics: TMetric[];
  dimensions: TDimension[];
  datasets: IDataset[];
}

/**
 * DAG 图
 */
export interface ITableDag {
  nodes: Array<IPdsNode | IMtNode>;
  relations: IRelation[];
}

export interface IBaseDagNode {
  id: string;
  tableName: string;
  // 下面这些属性都是前端计算出来的
  hasMoreInput?: boolean;
  hasMoreOutput?: boolean;
  direction?: ENodeDirection; // 相对于起始节点的方向
  isHidden?: boolean; // 是否被隐藏
}

export enum ENodeDirection {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export interface IRelation {
  srcId: string;
  dstId: string;
}

// 物化表
export interface IMtNode extends IBaseDagNode {
  nodeType: EAccelerateTableType.MT;
  acceleratePlan: IAcceleratePlanBase;
  latestInstance?: ITaskInstance | null;
  columns: IMtColumnMeta[];
  planType: EAcceleratePlanType;
  isPartition: boolean;
  isFullUpdate: boolean;
  owner?: IUser;
  size: string;
  latestPartition: string; // 最新分区
}

// 物理表
export interface IPdsNode extends IBaseDagNode {
  nodeType: EAccelerateTableType.PDS;
  latestInstance?: ITaskInstance | null;
  isPartition: boolean;
  isFullUpdate: boolean;
  refreshTime: string; // 刷新的 cron 表达式
  latestPartition: string; // 最新分区
}

export enum EAccelerateTableType {
  MT = 'MT',
  PDS = 'PDS',
}

export enum EAccelerateRecommendType {
  NEW_PLAN = 'NEW_PLAN', // 新增方案
  ADD_METRIC = 'ADD_METRIC', // 增加指标
}

/**
 * 物化表
 */
export interface IMaterializedTable {
  id: string;
  name: string;
  columns: IMtColumnMeta[] | null;
  acceleratePlan?: IAcceleratePlanDetail;
  createTime: number;
  updateTime: number;
  size: string;
  owner?: IUser;
  // 最近一次的任务实例，包含调度任务或手工任务
  latestInstance: ITaskInstance | null;
  // 最新数据，分区情况下是最新分区值，非分区情况下是最新数据更新的时间
  latestData?: string;
  // 最近成功的调度任务实例
  latestSuccessScheduledInstance: ITaskInstance | null;
  // 最近一次的调度任务实例
  latestScheduledInstance: ITaskInstance | null;
  isPartition: boolean;
  dataEndTime: number | null;
  dataStartTime: number | null;
  authority: IAuthority;
}

export interface IMtColumnMeta {
  name: string;
  originDataType: EOriginDataType;
  isPartition: boolean;
  // 物化表字段对应的来源的类型
  columnType: EMtColumnType;
  // 对应的指标 code/维度 name
  aggMapping: string;
  // 对应的数据集字段
  rawMapping: IAcceleratePlanColumn;
  timeRange?: Array<{
    startTime: number;
    endTime: number;
  }>;
}

// 物化表字段对应的来源的类型
export enum EMtColumnType {
  RAW = 'RAW', // 数据集字段
  METRIC = 'METRIC', // 指标
  DIMENSION = 'DIMENSION', // 维度
}

/**
 * 补数方案
 */

export interface IManualRefreshTaskInfo {
  id: string;
  name: string;
  plan: IAcceleratePlanDetail;
  status: EManualRefreshTaskStatus;
  startPartition: string;
  endPartition: string;
  metrics: TMetric[];
}

export interface IManualRefreshTask {
  id: string;
  status: ETaskInstanceStatus;
  table: IMaterializedTable;
  startPartition: string;
  endPartition: string;
  startTime: number;
  endTime: number;
}

export interface IManualRefreshTaskWithInstance extends IManualRefreshTask {
  table: IMaterializedTable;
}

export enum EManualRefreshTaskStatus {
  INIT = 'INIT',
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

/**
 * 任务实例
 */
export interface ITaskInstance {
  id: string;
  status: ETaskInstanceStatus;
  startPartition?: string;
  endPartition?: string;
  startTime: number;
  endTime: number;
  plan: IAcceleratePlanDetail;
  sql?: string;
  log?: string;
  type: ERefreshType;
  materializedTable?: IMaterializedTable;
  waitTime: number;
  authority: IAuthority;
}

export enum ETaskInstanceStatus {
  INIT = 'INIT',
  RUNNING = 'RUNNING',
  WAITING = 'WAITING',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export enum ERefreshType {
  MANUAL = 'MANUAL', // 补数任务
  SCHEDULED = 'SCHEDULED', // 周期任务
}

export interface IAcceleratePlanDimension {
  name: string;
  granularity?: string;
}

export interface IAcceleratePlanColumn {
  datasetName: string;
  columnName: string;
}

export enum EAcceleratePlanType {
  RAW = 'RAW', // 明细字段
  AGG = 'AGG', // 指标维度
}

export enum EAccelerateResultType {
  NORMAL = 'NORMAL',
  CODE = 'CODE',
  VIEW = 'VIEW',
}

export interface IAccelerateResultRefreshConfig {
  lowerIncrementRefreshScope: IIncrementRefreshScope;
  upperIncrementRefreshScope: IIncrementRefreshScope;
  granularity: EDateGranularityType; // 调度周期的粒度，服务端需要，其实可以通过 cron 表达式推导出来的
  isFullRefresh: boolean; // 是否全量更新
  isScheduleUpdate: boolean;
  isCustomTime: boolean; // 是否是自定义cron表达式
  cron?: string;
}

export interface IRetryStrategy {
  retryCount: number;
  interval: number;
}

export interface ILayout {
  filter?: TNode<TFilter>;
  metricTimeFilter: TMetricTime;
  // 指标衍生的额外信息
  metricDerived: {
    [id: string]: {
      // 业务限定
      filters: TNode<TFilterInAnalysisView>;
    };
  };
}

export interface IUpdateNotifyConfig {
  updateSuccessNotifyCodes?: string[];
  updateFailedNotifyCodes?: string[];
  updateDelayNotifyConfig?: {
    finishTime: string; // HH:mm:ss
    codes: string[];
  };
}

export interface IAccelerateResultPlanBaseInfo {
  id?: string;
  type: EAccelerateResultType;
  displayName: string;
  name: string;
  layout?: ILayout;
  owner?: IUser;
  authority: IAuthority;
}

export interface INormalAccelerateResultPlanContent {
  type: EAccelerateResultType.NORMAL;
  metrics: IAnalysisViewMetric[];
  dimensions: IAnalysisViewDimension[];
  filter?: IMetricFilter[];
  // 指标日期筛选
  metricTimeFilter: {
    type: EFilterType;
    formula: TFormula;
  } | null;
  isDisplayCode: boolean; // 是否展示指标/维度编码
  isFilterOpen: boolean; // 是否开启维度筛选
}

export interface ICodeAccelerateResultPlanContent {
  type: EAccelerateResultType.CODE;
  codeContent: {
    dimensions: string[];
    metrics: string[];
    [key: string]: unknown;
  };
}

export interface IViewAccelerateResultPlanContent {
  type: EAccelerateResultType.VIEW;
  viewDetail: IAnalysisViewResponse;
  isDisplayCode: boolean; // 是否展示指标/维度编码
}

export type TAccelerateResultPlanContent =
  | INormalAccelerateResultPlanContent
  | ICodeAccelerateResultPlanContent
  | IViewAccelerateResultPlanContent;

export interface IAccelerateResultPlanConfig {
  retryStrategy?: IRetryStrategy; // null代表终止
  updateNotifyConfig: IUpdateNotifyConfig;
  refreshConfig: IAccelerateResultRefreshConfig;
}

// 应用加速方案
export type TAccelerateResultPlan = IAccelerateResultPlanBaseInfo &
  TAccelerateResultPlanContent &
  IAccelerateResultPlanConfig;

export type TAccelerateResultPlanListItem = IAccelerateResultPlanBaseInfo &
  TAccelerateResultPlanContent & {
    mtCount: number;
    createTime?: number;
    updateTime?: number;
    dependencies: IAcceleratePlanDependencies;
    refreshConfig: IAccelerateResultRefreshConfig;
  };
