export const header = {
  title: 'Metric Catalog',
};

export const sidebar = {
  title: 'Filter',
  clear: 'Clear',
  category: 'Metric Category',
  metricType: 'Metric Type',
  owner: {
    default: 'Metric Owner',
    placeholder: 'Add Owner',
  },
};

export const mainContent = {
  count: ({ count }: { count: string | number }) => `${count} Metrics`,
  empty: {
    desc: 'No metrics match the criteria, please try again',
  },
};
