import {
  ELooseDataType,
  looseDataTypeMap,
} from '@/common/domain/Formula/helper';
import { METRIC_TIME } from '@/constants';
import t from '@/locales';
import { EColumnDataType } from '@/typings';
import { TSimpleCategory } from '@/typings/category';
import dayjs from 'dayjs';
import _ from 'lodash';
import { customAlphabet } from 'nanoid';

/**
 * 获取唯一 ID
 * @returns 唯一的 ID
 */
export function getUniqId() {
  // 16位的长度，每秒生成1000个ID的话，240年时间才有1%的可能性产生一次 ID 冲突
  // 参考 https://zelark.github.io/nano-id-cc/
  const CHARACTER_LEN = 16;
  const alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  return customAlphabet(alphabet, CHARACTER_LEN)();
}

export type TCommonData = object | string | number | boolean | null;

/**
 * 去除值为undefined的key，深度遍历
 * @param data object数据
 * @returns object数据
 */
export function removeUndefinedDeeplyForObject(
  obj: TCommonData,
): object | string | number | boolean | null {
  if (obj === null || typeof obj !== 'object' || Array.isArray(obj)) {
    return obj;
  }
  const res: {
    [key: string]: unknown;
  } = {};
  _.forOwn(obj, (val, key) => {
    if (val === undefined) {
      return;
    }
    if (typeof val === 'object' && val !== null) {
      res[key] = removeUndefinedDeeplyForObject(val);
    } else {
      res[key] = val;
    }
  });

  return res;
}

/**
 * 获取字段的数据类型对应的名称
 * @param dataType 字段的数据类型
 */
export function getColumnDataTypeName(dataType: EColumnDataType) {
  const nameMap: {
    [key in EColumnDataType]: string;
  } = {
    [EColumnDataType.INT]: t.common.dataTypeName.int,
    [EColumnDataType.DOUBLE]: t.common.dataTypeName.double,
    [EColumnDataType.DECIMAL]: t.common.dataTypeName.decimal,
    [EColumnDataType.DATE_TIME]: t.common.dataTypeName.dateTime,
    [EColumnDataType.DATE]: t.common.dataTypeName.date,
    [EColumnDataType.TEXT]: t.common.dataTypeName.text,
    [EColumnDataType.BOOLEAN]: t.common.dataTypeName.boolean,
    [EColumnDataType.JSON]: t.common.dataTypeName.json,
  };

  return nameMap[dataType];
}

/**
 * 判断是否是指标日期字段
 * @param IDimension
 */
export function isMetricTimeDimension({ name }: { name: string }) {
  return name === METRIC_TIME;
}

/**
 * 将列表转换为 object 结构
 * @param list 集合
 * @param idKey 主键名称
 */
export function list2Map<T extends object>(
  list: T[],
  idKey: keyof T,
): Record<string, T> {
  return _.reduce<T, Record<string, T>>(
    list,
    (res, item) => {
      res[String(item[idKey])] = item;

      return res;
    },
    {},
  );
}

/**
 * 根据关键词过滤列表
 * @param list 列表
 * @param getValue 获取待过滤的值。支持传入多个函数，只要有一个函数返回的值包含关键词，就会被过滤
 * @param keyword 关键词
 * @returns 过滤后的列表
 */
export function filterByKeyword<T extends object | string>(
  list: T[],
  getValue: ((item: T) => string) | Array<(item: T) => string>,
  keyword: string,
) {
  return list.filter((item) => {
    const getValueFnList =
      typeof getValue === 'function' ? [getValue] : getValue;

    return getValueFnList.some((cb) => {
      return (cb(item) || '')
        .toLowerCase()
        .includes(_.trim(keyword).toLowerCase());
    });
  });
}

/**
 * 获取实体数据所属的类目 ID
 * @param data 单项数据实体，如指标、维度等
 * @returns 类目 ID
 */
export function getCategoryId(data: { category: TSimpleCategory[] }) {
  return _.last(data.category)?.id || '';
}

/**
 * 是否是日期类型
 * @param dataType 数据类型
 */
export function isDateLikeDataType(
  dataType: EColumnDataType,
): dataType is EColumnDataType.DATE | EColumnDataType.DATE_TIME {
  return [EColumnDataType.DATE, EColumnDataType.DATE_TIME].includes(dataType);
}

/**
 * 是否是数字类型
 */
export function isNumberLikeDataType(dataType: EColumnDataType) {
  const looseDataType = looseDataTypeMap[dataType];

  return looseDataType === ELooseDataType.NUMBER;
}

/**
 * 返回带 K 单位的数字
 * @param num 数字
 */
export function getNumberWithUnitK(num?: number): string {
  if (typeof num !== 'number') {
    return '-';
  }
  const THOUSAND = 1000;
  if (num < THOUSAND) {
    return num.toString();
  }
  const numWithK = num / THOUSAND;
  const n = numWithK % 1 === 0 ? numWithK : numWithK.toFixed(1);

  return `${n}K`;
}

// 将毫秒值转为 yyyy-mm-dd hh:mm:ss
export function millisecondsToDateTime(milliseconds: number): string {
  const THOUSAND = 1000;
  const dateTime = dayjs.unix(milliseconds / THOUSAND);
  const formattedDateTime = dateTime.format('YYYY-MM-DD HH:mm:ss');

  return formattedDateTime;
}

/**
 * 打平树结构
 * @param nodes 树结构，每个节点包含 children 属性
 */
export function flattenChildren<T>(nodes: T[]): T[] {
  return _.flatMapDeep(nodes, (node: T): T[] => {
    const children: T[] = _.get(node, 'children') as T[];
    if (children && children.length > 0) {
      return [node, ...flattenChildren(children)];
    }

    return [node];
  });
}

// 获取时间差
export function getTimeDiff(
  beginTime: number | string,
  endTime: number | string,
) {
  if (!endTime || !beginTime) return '-';

  // 先转换成 YYYY-MM-DD HH:mm:ss 格式，在做时间差计算
  const formatStartTime = dayjs(beginTime).format('YYYY-MM-DD HH:mm:ss');
  const formatEndTime = dayjs(endTime).format('YYYY-MM-DD HH:mm:ss');
  const timeDiff = dayjs(formatEndTime).diff(dayjs(formatStartTime), 'second');
  // 根据时间长短 转成 xx 时 xx 分 xx秒
  const SIXTY = 60;
  const ONE_HOUR = 3600;
  if (timeDiff < SIXTY) return `${timeDiff}${t.common.granularity.second}`;
  if (timeDiff < ONE_HOUR) {
    const minute = Math.floor(timeDiff / SIXTY);
    const second = timeDiff % SIXTY;

    return `${minute}${t.common.granularity.minute}${second}${t.common.granularity.second}`;
  }
  const hour = Math.floor(timeDiff / ONE_HOUR);
  const minute = Math.floor((timeDiff % ONE_HOUR) / SIXTY);
  const second = (timeDiff % ONE_HOUR) % SIXTY;

  return `${hour}${t.common.granularity.hour}${minute}${t.common.granularity.minute}${second}${t.common.granularity.second}`;
}

export function formatTime(
  time: number,
  format: string = 'YYYY-MM-DD HH:mm:ss',
) {
  if (!time) return '';

  return dayjs(time).format(format);
}
