export const analysis = {
  list: {
    title: {
      page: 'All Metrics Views',
    },
    displayName: 'Display Name',
    name: 'Name',
    accStatus: 'Acceleration Status',
    description: 'Description',
    updateTime: 'Last Modified',
    handler: 'Actions',
    owner: 'Owner',
    create: 'Create Metrics View',
    config: 'Configure',
    startTime: 'Start Time',
    endTime: 'End Time',
    menu: {
      edit: 'Edit',
      transfer: 'Transfer',
      copy: 'Copy',
    },
    batch: {
      transfer: (value: string) =>
        `No transfer permission for metrics view ${value}`,
      adjustCategory: (value: string) =>
        `No category adjustment permission for metrics view ${value}`,
    },
  },
  config: {
    metric: {
      noquick: 'None',
      periodicComparison: {
        default: 'Period Comparison',
      },
      ranking: {
        default: 'Ranking',
        globalAsc: 'Global Ascending',
        globalDesc: 'Global Descending',
        groupAsc: 'Group Ascending',
        groupDesc: 'Group Descending',
        groupTitle: 'Select Ranking Scope',
      },
      proportion: {
        default: 'Proportion',
        global: 'Global Proportion',
        group: 'Group Proportion',
        groupTitle: 'Select Proportion Scope',
      },
      dateLimit: {
        none: 'None',
        custom: 'Custom',
        modalTile: 'Custom Time Constraint',
      },
    },
  },
  acc: {
    createModal: {
      title: 'Created Successfully',
      desc1:
        'The acceleration plan has been created successfully. Do you want to backfill the data for this acceleration plan?',
      desc2:
        'If you choose not to backfill now, you can do it later in the "Metric Acceleration / Materialization Plan" module.',
    },
    createAcc: 'Create Plan',
    emptyDesc:
      'Create an acceleration plan to help optimize query performance.',
    updateViewModal: {
      title: 'Metrics View Changed',
      desc1:
        'The metrics/dimensions in the current metrics view have changed. The acceleration plan will be regenerated. Do you want to backfill the data?',
      desc2:
        'If you choose not to backfill now, you can do it later in the "Metric Acceleration / Materialization Plan" module',
    },
    cancelText: 'Skip Backfill',
    confirmText: 'Backfill Data',
  },
};

export const apiIntegrated = {
  title: 'API Integration',
  desc: {
    desc1:
      'The metrics platform provides comprehensive API services for developers, including access token management, metric queries, metric multi-dimensional attribution, dimension management, metric management, metric/dimension category management, metrics view management, etc.',
    desc2:
      'When accessing the API, you need the API environment address, tenant ID (tenant-id), and user ID (user-id) information.',
  },
  env: 'Environment Info',
  tip: {
    tip1: 'You can visit ',
    detail: 'Metrics Platform User Manual',
    tip2: ' to view detailed API usage instructions',
  },
  copy: 'Copy',
  copySuccess: 'Copy successful',
};

export const analysisView = {
  sideBar: {
    title: 'View',
    documentation: 'BI Tool Integration Guide',
  },
  overview: {
    title: 'Data Preview',
    noPermission:
      'No permission to view, please request access from administrator',
  },
  list: {
    menu: {
      transfer: 'Transfer',
      editBaseInfo: 'Edit Basic Info',
      delete: 'Delete',
    },
    total: (total: number) => {
      return `${total} Metrics Views`;
    },
    empty: 'No content found, create an metrics view now',
    create: 'New Metrics View',
    deleteSuccess: 'Delete successful',
  },
  deleteSuccess: 'Delete successful',
  detail: {
    acc: 'Acc',
    share: 'Share',
    authority: {
      default: 'Permissions',
    },
    edit: 'Edit',
    editBaseInfo: 'Edit Basic Info',
    lineage: 'Lineage',
    overview: 'Overview',
    column: {
      default: 'Fields',
      displayName: 'Display Name',
      name: 'Name',
      datatype: 'Data Type',
      description: 'Description',
      emptyDesc:
        'No fields match the search criteria, please try searching again',
      columnCount: ({ count }: { count: string | number }) => `${count} Fields`,
    },
    basicInfo: {
      title: 'Basic Info',
      name: 'Name',
      category: 'Category',
      description: 'Description',
      owner: 'Owner',
      createTime: 'Created',
      updateTime: 'Last Modified',
    },
    editBaeInfoSuccess: 'Basic info updated successfully',
  },
  title: {
    unnamed: 'NewView',
    unDisplayNamed: 'Untitled Metrics View',
  },
  picker: {
    metric: {
      default: 'Metrics',
      onlyViewPermission: 'Show Accessible Only',
      unusable: ({ list }: { list: string | number }) =>
        `This metric cannot use dimensions ${list} for filtering`,
    },
    dimension: {
      default: 'Dimensions',
      unusable: ({ list }: { list: string | number }) =>
        `This dimension cannot analyze metrics ${list}`,
    },
  },
  result: {
    error: 'Error getting metrics view info',
  },
  basicInfo: {
    title: 'Publish',
    name: 'Name',
    displayName: 'Display Name',
    category: 'Category',
    description: 'Description',
    confirm: 'Save',
    cancel: 'Cancel',
    error: {
      name: 'Only letters, numbers and underscores allowed, cannot be empty',
    },
    empty: {
      category: 'Select category',
      displayName: 'Enter display name',
    },
  },
  header: {
    btn: {
      cancel: 'Cancel',
      edit: 'Edit',
      share: 'Share',
      save: 'Save',
      saveSuccess: 'Save successful',
    },
  },
  area: {
    header: {
      data: 'Data',
      config: 'Config',
    },
  },
  chart: {
    config: {
      column: {
        title: 'Dimension',
      },
      value: {
        title: 'Metric',
      },
    },
    select: {
      placeholder: 'Drag or select dimensions',
    },
    value: {
      placeholder: 'Drag or select metrics',
    },
  },
  config: {
    metricDataRange: 'Metric Time Range',
    column: {
      overflow: ({ maxLength }: { maxLength: string | number }) =>
        `Exceeds maximum limit of ${maxLength}`,
    },
  },
  menu: {
    title: {
      filter: 'Business Constraint',
      rename: 'Rename',
      delete: 'Delete',
      view: 'View',
      share: 'Share',
      granularity: 'Date Granularity',
      derivativeCalculation: 'Quick Derivation',
      dateLimit: 'Time Constraint',
    },
    filter: {
      desc: 'Meet the following conditions',
      clean: 'Clear',
      add: 'Add Condition',
      error: 'Please complete condition selector',
    },
  },
  filter: {
    empty: 'Drag or select dimensions',
    title: 'Filter',
    tooltip: {
      displayName: 'Display Name: ',
      description: 'Description: ',
    },
  },
  sort: {
    title: 'Sort',
    description: 'Sort by',
    btn: {
      add: 'Add Dimension/Metric',
    },
    input: {
      placeholder: 'Search',
    },
    asc: 'Ascending',
    desc: 'Descending',
  },
  sidebar: {
    queryButton: 'Query',
  },
  entity: {
    exception: {
      referenceNotFount: ({ name }: { name: string | number }) =>
        `${name} is not available`,
      dataTypeNotMatch: ({ name }: { name: string | number }) =>
        `${name} data type changed`,
      noPermission: 'No metric access permission',
    },
  },
  table: {
    column: {
      empty: 'Select metrics and dimensions to view analysis results',
    },
  },
  cantQuery: {
    reason: 'Select at least one metric',
  },
  download: {
    fileName: (fileName: string) => `${fileName}_download_data`,
  },
};
