export const workbook = {
  copy: {
    success: '复制成功',
    error: '复制失败',
  },
  list: {
    batch: {
      transfer: (value: string) => {
        return `指标看板 ${value} 无转交权限`;
      },
    },
    menu: {
      transfer: '转交',
      view: '查看',
      share: '分享',
      editBaseInfo: '修改基础信息',
      edit: '编辑',
      copy: '复制',
      delete: '删除',
      deleteConfirm: '确定',
      deleteConfirmContent: ({ name }: { name: string | number }) =>
        `是否删除看板[${name}]`,
      deleteConfirmOk: '确定',
      deleteConfirmCancel: '取消',
    },
    deleteSuccess: '删除成功',
    name: '名称',
    unPublished: '未发布',
    published: '已发布',
    creator: '负责人',
    description: '描述',
    status: '状态',
    handle: '操作',
    lastModified: '最近修改时间',
    createTime: '创建时间',
    title: '指标看板',
    create: '新建看板',
    total: ({ total }: { total: string | number }) => `${total}个看板`,
  },
  detail: {
    title: {
      unnamed: '未命名指标看板',
    },
  },
  result: {
    error: '获取指标看板信息异常',
  },
  basicInfo: {
    title: '修改基础信息',
    name: '名称',
    description: '描述',
    saveSuccess: '保存成功',
    confirm: '保存',
    cancel: '取消',
    error: {
      name: '请输入名称',
    },
  },
  header: {
    btn: {
      quit: '退出',
      save: '保存草稿',
      reset: '重置草稿为线上版本',
      edit: '编辑',
      share: '分享',
      publish: '发布',
      publishSuccess: '发布成功',
    },
  },
  shareModal: {
    title: '分享',
    success: '分享成功',
  },
  area: {
    header: {
      data: '数据',
      config: '分析配置',
    },
  },
  canvas: {
    addChart: '添加图表',
    addFilter: '筛选组件',
  },
  chart: {
    download: {
      noData: '暂无数据',
      failed: '查询数据失败',
    },
    csv: {
      name: '未命名文件',
    },
    title: {
      unnamed: '未命名图表',
    },
    type: {
      pivotTable: '透视表',
      metricTree: '指标树',
      lineChart: '折线图',
      columnChart: '分组条形图',
      barChart: '分组柱状图',
      pieChart: '饼图',
      scatter: '散点图',
      radar: '雷达图',
      area: '面积图',
      combo: '组合图',
    },
    config: {
      line: {
        title: '行',
      },
      column: {
        title: '列',
      },
      value: {
        title: '值',
      },
      tab: {
        data: '数据',
        insight: '洞察',
      },
    },
    select: {
      placeholder: '拖入或选择分析的维度',
    },
    value: {
      placeholder: '拖入或选择分析的指标',
    },
  },
  element: {
    filter: {
      title: {
        unnamed: '新筛选器',
      },
      dimension: {
        title: '筛选维度',
      },
      type: {
        default: '组件样式',
        number: '数值范围',
        date: '日期选择',
        list: '下拉列表',
      },
      name: '组件名称',
      noDimension: '请选择筛选维度',
      dimensionException: '筛选维度异常',
    },
    defaultTitle: '未命名图表',
    handle: {
      copy: '复制',
      delete: '删除',
      download: '下载',
      refresh: '刷新',
      downloadInfo: '下载当前图表展示的数据',
      editDescription: '编辑描述',
    },
    description: '描述',
    empty: {
      title: '选择要分析的指标与维度',
    },
  },
  pivotTable: {
    total: '总计',
    subTotal: '小计',
    maxError:
      '当前透视表数据不完整，行数超过或列数超过上限。请尝试调整透视表配置',
  },
  viz: {
    queryFailed: '查询失败',
  },
  config: {
    metricDataRange: '指标日期范围',
    metric: {
      noquick: '无',
      periodicComparison: {
        default: '同环比',
        value: {
          day: '环比值',
          other: '同比值',
        },
        growthValue: {
          day: '环比增长值',
          other: '同比增长值',
        },
        growth: {
          day: '环比增长率',
          other: '同比增长率',
        },
      },
      ranking: {
        default: '排名',
        globalAsc: '全局升序',
        globalDesc: '全局降序',
        rows: {
          asc: '行内升序',
          desc: '行内降序',
        },
        columns: {
          asc: '列内升序',
          desc: '列内降序',
        },
        groupAsc: '组内升序',
        groupDesc: '组内降序',
      },
      proportion: {
        default: '占比',
        global: '全局占比',
        rows: '行内占比',
        columns: '列内占比',
        group: '组内占比',
      },
    },
    column: {
      overflow: ({ maxLength }: { maxLength: string | number }) =>
        `超出最大数量限制，最大数量为${maxLength}`,
    },
  },
  picker: {
    metric: {
      default: '指标',
      onlyViewPermission: '只看有权限',
      displayName: '指标名称：',
      noPermission: '没有指标使用权限',
      desc: '业务口径：',
      unusable: ({ list }: { list: string | number }) =>
        `该指标无法使用维度${list}筛选`,
      fav: '收藏指标',
      analysis: '分析指标',
      search: '搜索',
      collapseAll: '折叠全部',
    },
    dimension: {
      default: '维度',
      displayName: '维度名称：',
      desc: '描述：',
      unusable: ({ list }: { list: string | number }) =>
        `该维度无法分析指标 ${list}`,
      analysis: '分析维度',
    },
    search: {
      placeholder: '搜索',
    },
    notFound: '未找到内容',
  },
  groupChart: {
    x: {
      title: '横轴',
    },
    y: {
      title: '纵轴',
    },
    legend: {
      title: '图例',
    },
    category: {
      title: '分类',
    },
  },
  metricTree: {
    mainMetric: '主指标',
    subMetrics: '拆解指标',
    editRelation: '编辑指标关系',
    derivateCalc: {
      title: '辅助指标',
      placeholder: '选择',
    },
    relation: {
      title: '编辑指标关系',
      node: {
        subTitle: '该指标由以下子指标关系组成',
        error: '表达式中存在无法识别和展示的字符',
        desc: '输入 [ 可快捷添加已选择指标',
        subDesc1: '支持键盘输入+、-、*、/、()。',
        subDesc2: '示例：[客单价]*[购买人数]',
      },
      save: '保存并更新图表',
    },
    chart: {
      mainMetricTip: '选择主指标',
      relationTip: '点击指标编辑拆解关系',
      contributionRate: (mainRateStr: string) => `主贡献率: ${mainRateStr} `,
      parentRate: (parentRateStr: string) => `(父: ${parentRateStr})`,
    },
    summary: {
      mainReason: '。主要影响原因为：',
      contributionRate: '贡献率为',
      is: '为',
      detail: '详细报告',
      close: '关闭',
    },
    attributionModal: {
      title: '归因分析',
    },
    insight: {
      title: '归因分析',
      comparison: '对比对象',
      showSummary: '显示归因摘要',
      preview: '预览',
      error: {
        title: '指标配置发生错误：',
        mainMetric: '主指标异常',
        subMetric: '拆解指标存在异常',
        mainMetricExpr: '请添加拆解指标并编辑指标关系',
        metricTimeActive: '请开启指标日期范围',
        treeNode: (displayName: string) =>
          `指标 [${displayName}] 的公式不是合法的四则运算`,
      },
    },
  },
  menu: {
    title: {
      rename: '重命名',
      delete: '删除',
      granularity: '日期粒度',
      sort: {
        default: '排序',
        asc: '升序',
        desc: '降序',
      },
      axisOrient: '坐标轴位置',
      vizType: '图形样式',
    },
  },
  filter: {
    empty: '拖入或选择过滤的维度',
    title: '筛选',
    filterConfig: {
      title: '筛选',
    },
    tooltip: {
      displayName: '展示名：',
      description: '描述：',
    },
  },
  sidebar: {
    elementOverview: '页面概览',
    create: {
      pivotTableChart: '透视表',
      viz: '可视化',
      GroupedBarHorizontalChartDuotone: '柱状图',
      lineChart: '折线图',
      pieChart: '饼图',
    },
    replaceDataSource: {
      inUse: {
        emptyTips: '暂无数据',
      },
    },
  },
  entity: {
    exception: {
      referenceNotFount: ({ name }: { name: string | number }) =>
        `${name}无法使用`,
      dataTypeNotMatch: ({ name }: { name: string | number }) =>
        `${name}数据类型变更`,
      noPermission: '没有指标使用权限',
    },
  },
};

export const sidebar = {
  vizStyle: {
    other: '其他',
  },
  dataConfig: '数据',
  styleConfig: '样式',
};

export const chart = {
  error: {
    msg: '系统开小差了',
  },
  styles: {
    categoryAxis: '类别轴',
    horizontalAxis: '横轴',
    verticalAxis: '纵轴',
    verticalAxis2: '纵轴2',
    valueAxis: '值轴',
    valueAxis2: '值轴2',
    showAxisName: ({ title }: { title: string }) => `显示${title}名称`,
    showAxisLabel: ({ title }: { title: string }) => `显示${title}标签`,
    axisRange: ({ title }: { title: string }) => `${title}范围`,
    showAxis: '显示轴',
    showTick: '展示刻度',
    showDataLabel: '显示标签',
    showGrid: '展示网格线',
    restoreDefault: '恢复至默认',
    directions: '标签方向',
    position: '位置',
    axisType: {
      title: '刻度类型',
      time: '时间',
      value: '线性',
      category: '离散',
    },
    categoryRange: {
      auto: '自动',
      custom: '自定义',
      placeholder: {
        max: '最大值',
        min: '最小值',
      },
    },
    categoryNum: {
      type: '类别',
      title: '显示类别数量',
      all: '全部',
      custom: '自定义',
      customTip: '输入展示数量，其余合并为其他',
    },
    legend: {
      title: '图例',
      position: {
        title: '位置',
        left: '左侧',
        right: '右侧',
        bottom: '底部',
        top: '顶部',
      },
      label: '标签文本',
      text: '文本',
    },
    value: '值',
    radarChartStyle: {
      polygon: '多边形',
      circle: '圆形',
    },
    dataLabel: {
      title: '数据标签',
      position: '位置',
      showRange: '显示范围',
      top: '顶部',
      center: '中间',
      bottom: '底部',
    },
    direction: {
      title: '方向',
      level: '水平',
      column: '垂直',
    },
    range: {
      title: '展示范围',
      auto: '自动',
      all: '全量',
    },
    labelContent: {
      value: '值',
      ratio: '占比',
      valueOrRatio: '值/占比',
    },
    border: {
      title: '描边',
      auto: '自动',
      open: '打开',
      close: '关闭',
    },
    gapWidth: {
      bar: '柱体',
      title: '宽度',
    },
    chartArea: {
      title: '绘图区域',
      isGradient: '启用渐变效果',
    },
    showRange: {
      maxValue: '仅最大值',
      minValue: '仅最小值',
      maxAndMinValue: '最大/最小值',
      endPoint: '端点',
      all: '全部',
    },
    lineLabelPosition: {
      auto: '自动',
      top: '线上方',
      bottom: '线下方',
    },
    scatterLabelPosition: {
      auto: '自动',
      top: '气泡上方',
      bottom: '气泡下方',
    },
    fillColor: '节点之间填充面积',
    showPoint: '显示数据点',
    lineStyle: {
      title: '可视化样式',
      lineStyle: '线段样式',
      mode: '设置模式',
      unify: '整体',
      part: '独立',
      type: '类型',
      none: '直线',
      step: '阶梯',
      stepStart: '阶梯（前）',
      stepEnd: '阶梯（后）',
    },
    nullValue: {
      title: 'null值处理',
      none: '隐藏',
      zero: '使用0代替',
    },
    showPointStyle: '显示数据点',
    pointStyle: '数据点样式',
    pointSize: '数据点大小',
    pieLabelContent: {
      category: '分类',
      value: '值',
      percentage: '占比',
      categoryValue: '分类/值',
      categoryPercentage: '分类/占比',
      valuePercentage: '值/占比',
      categoryValuePercentage: '分类/值/占比',
    },
  },
};
