import {
  Input,
  InputRef,
  Popover,
  ScrollArea,
  Tree,
} from '@aloudata/aloudata-design';
import { ReactComponent as SearchIcon } from '@/assets/icon/Search-light-line.svg';
import styles from './index.less';
import t from '@/locales';
import React, { useRef, useState } from 'react';
import functionConfig from '@/common/domain/Formula/functionConfig/functionConfig';
import { getEngineType } from '@/common/domain/Formula/functions/tool';
import { fuzzyMatch } from '@/pages/Dataset/helpers';
import {
  IFilterFunctionsList,
  IFunctionDesc,
} from '@/common/domain/Formula/type';
import _ from 'lodash';
import classNames from 'classnames';
import { ReactComponent as FolderIcon } from '@/assets/icon/Folder-light-line.svg';
import { ReactComponent as FxIcon } from '@/assets/icon/fx-light-line.svg';
import { KEEP_FOCUS_CLASS_COlUMN } from '@/common/domain/codeEditor/CodeEditor/PromptTrigger';

interface IProps {
  onClick: (functionName: string) => void;
  filterFunctionList: IFilterFunctionsList;
}

const getFunctionList = (
  filterFunctionList?: IFilterFunctionsList,
  key?: string,
) => {
  const functions = functionConfig[getEngineType()].functions;

  const functionArr = Object.keys(functions)
    .filter((name) => fuzzyMatch(name, key || ''))
    .filter((name) => !!name)
    .map((name) => functions[name])
    .filter((functionInfo) => {
      return filterFunctionList ? filterFunctionList(functionInfo) : true;
    })
    .filter((functionInfo) => !!functionInfo.descZh);

  return _.groupBy(functionArr, (functionInfo) => functionInfo.descZh?.type);
};

interface ITreeNode {
  title: string;
  key: string;
  desc?: IFunctionDesc;
  CustomIcon: React.ReactNode;
  children?: ITreeNode[];
}

const FunctionList = ({ onClick, filterFunctionList }: IProps) => {
  const inputRef = useRef<InputRef>(null);
  const [functionSearchKey, setFunctionSearchKey] = useState('');
  const functionArr = getFunctionList(filterFunctionList, functionSearchKey);

  const treeData: ITreeNode[] = Object.keys(functionArr).map((key) => {
    return {
      title: key,
      key,
      CustomIcon: <FolderIcon size={16} />,
      children: functionArr[key].map((functionInfo) => {
        return {
          title: functionInfo.descZh?.function || '',
          key: functionInfo.descZh?.function + '_func' || '',
          desc: functionInfo.descZh,
          CustomIcon: <FxIcon size={16} />,
        };
      }),
    };
  });

  const titleRender = (node: ITreeNode) => {
    const funcName = node.title;

    const treeItem = (
      <div
        className={classNames(KEEP_FOCUS_CLASS_COlUMN, styles.treeNode)}
        onClick={() => {
          if (!node.children) onClick(node.title);
        }}
      >
        <div className={styles.icon}> {node.CustomIcon}</div>
        {node.title}
      </div>
    );

    if (!node.desc) return treeItem;

    const { desc, paramArr, formula } = node.desc;

    const popoverContent = (
      <div className={styles.popoverContent}>
        <ScrollArea>
          <div className={styles.funcName}>{funcName}</div>
          <div className={styles.grammar}>{formula}</div>
          <div className={styles.desc}>{desc}</div>
          <div className={styles.param}>
            {paramArr?.map((paramDesc, index) => (
              <div className={styles.paramItem} key={index}>
                <div className={styles.paramName}>{paramDesc.name}</div>
                <div className={styles.paramDesc}>{paramDesc.desc}</div>
              </div>
            ))}
          </div>
        </ScrollArea>
      </div>
    );
    return (
      <Popover
        placement="left"
        content={popoverContent}
        className={styles.popover}
      >
        {treeItem}
      </Popover>
    );
  };

  return (
    <div className={styles.functionListBox}>
      <Input
        bordered
        ref={inputRef}
        value={functionSearchKey}
        className={styles.input}
        placeholder={t.filter.sort.search}
        size="small"
        prefix={<SearchIcon />}
        onChange={(e) => {
          setFunctionSearchKey(e.target.value.trim());
        }}
      />
      <div className={styles.functionList}>
        <Tree.DirectoryTree
          treeData={treeData}
          size="small"
          filterTreeNode={
            functionSearchKey
              ? (node) =>
                  fuzzyMatch(node.title?.toString() || '', functionSearchKey)
              : undefined
          }
          selectedKeys={!!functionSearchKey ? undefined : []}
          titleRender={titleRender}
        ></Tree.DirectoryTree>
      </div>
    </div>
  );
};
export default FunctionList;
