import { TSimpleDatasetInfo } from '@/pages/Dataset/components/RelationModal/Form/helper';
import { EColumnDataType, EOriginDataType, IDatasourceItem, IUser } from '.';
import { IAuthorityResource } from './authority';
import { EException, TFormula } from './formula';
import {
  IDatasource,
  IDatasourcePath,
  IIncrementRefreshScope,
  IPartitionMapping,
} from './dataSource';
import { TSimpleCategoryNode } from '@/common/domain/category/CategoryTree';
import { TRefreshSettingGranularity } from '@/services/datasource/type';

export interface IPartitionMappingConfig {
  partitionColumnName?: string;
  sourceTableGuid?: String; // 来源表
  sourceColumnName?: String; // 绑定来源表的字段
  dateGranularity: TRefreshSettingGranularity;
  columnPartitionMappings: IPartitionMapping[];
}

export interface ISimpleDataset {
  name: string;
  description: string; // 描述
  displayName: string; // 展示名
  owner?: IUser; // 所有者
  metricCount: number;
  updateTime: number; // 更新时间
  createTime: number; // 创建时间
  columns: IColumn[]; // 数据集的对外发布的字段信息
  defColumns: IDefColumn[]; // 数据集的定义字段信息，包含隐藏字段
  authorityResource: IAuthorityResource; // 权限相关
  category: TSimpleCategoryNode[];
  partitionMapping?: IPartitionMappingConfig;
  sourceInfo: {
    type: ESourceType;
  };
}

// 新建以及更新时传递给服务端的结构

export interface IDataset extends ISimpleDataset {
  sourceInfo: IDatasetSourceInfo; // 来源信息，如物理表、上传的文件、自定义 SQL 的 meta 信息
  relations: IDatasetRelation[]; // 被关联
  relationFroms: IDatasetRelation[]; // 主动关联
}

// 数据集的来源信息
export interface IDatasetSourceInfo {
  name: string; // 来源信息的唯一标识
  description: string; // 来源信息的描述
  displayName: string; // 来源信息的展示名
  type: ESourceType; // 来源类型
  content: string; // 来源的内容，可能是 ID 也可能是 SQL
  datasourceItems: IDatasourceItem[]; // 引擎信息
  columns: ITableColumn[]; // 表的列信息
  // 物理表在数据源中的位置
  path?: IDatasourcePath[];
  // 数据源
  datasource?: Pick<IDatasource, 'name' | 'displayName' | 'datasourceType'>;
}
export interface IBaseColumn {
  name: string; // 字段名称
  originDataType: EOriginDataType; // 字段数据类型
}

export interface ITableColumn extends IBaseColumn {
  displayName: string; // 展示名
  description: string; // 字段描述
  isPartition: boolean; // 是否是分区字段
  isFirstPartition: boolean;
}

export enum ESourceType {
  FILE = 'FILE', // 文件
  SQL = 'SQL', // SQL
  TABLE = 'TABLE', // 物理表
}

export interface IDatasetRelation {
  id?: string; // 关联ID
  type: ERelationType; // 关联类型
  srcDatasetName: string;
  srcDatasetDisplayName: string;
  dstDatasetName: string;
  dstDatasetDisplayName: string;
  srcDataset: TSimpleDatasetInfo; // 来源数据集信息,不包含关联信息
  dstDataset: TSimpleDatasetInfo; // 关联数据集信息
  relationConditions: IRelationCondition[]; // 关联条件
  owner?: IUser; // 负责人
  filters: TFormula[]; // 关联过滤条件，由分区配置生成
}

export type TDatasetRelationRequest = Omit<
  IDatasetRelation,
  'srcDataset' | 'dstDataset' | 'owner'
>;

export enum EPartitionOperationType {
  PLUS = 'PLUS', // 加
  MINUS = 'MINUS', // 减
}

export enum ERelationPartitionType {
  SAME_PERIOD = 'SAME_PERIOD', // 同周期
  LATEST = 'LATEST', // 关联数据集最新分区
  CUSTOM = 'CUSTOM', // 自定义
}

export interface IRelationCondition {
  srcAst: TFormula; // 来源字段
  dstAst: TFormula; // 关联字段
}

export enum ERelationType {
  HAS_ONE = 'ONE_TO_ONE', // 一对一
  HAS_MANY = 'MANY_TO_ONE', // 多对一
}

export interface IColumn extends IBaseColumn {
  displayName: string; // 字段的展示名
  dataType: EColumnDataType;
  description: string; // 描述
  type: EColumnType;
  isPartition: boolean; // 是否是分区字段
  isFirstPartition: boolean; // 是否是一级分区
  dataset?: IDataset; // 数据集信息
  dimension?: {
    name: string;
    displayName: string;
    originDataType: EOriginDataType;
    owner: {
      userId: string;
    };
  };
}

export interface IDefColumn extends IColumn {
  sourceColumn?: string; // 是否是从来源里面新建字段
  ast: TFormula;
  exception?: {
    type: EException;
    message?: string;
  };
  partitionMapping?: IIncrementRefreshScope; // 如果是日期字段且数据集有日期分区的情况下，可以配置对该字段的便宜值
}
export interface IServerDefColumn
  extends Omit<
    IDefColumn,
    'dataType' | 'dataset' | 'dimension' | 'isFirstPartition' | 'isPartition'
  > {}

export enum EColumnType {
  DIMENSION = 'DIMENSION', // 维度
  MEASURE = 'MEASURE', // 度量
}

export enum EColumnException {
  // 推导异常
  DERIVATE = 'DERIVATE',
  // 无法解析
  INVALID_FORMULA = 'INVALID_FORMULA',
  // 循环引用
  CYCLE_REFERENCE = 'CYCLE_REFERENCE',
  // 依赖字段不存在
  REFERENCE_NOT_EXIST = 'REFERENCE_NOT_EXIST',
}

// 数据集关联的数据集
export type TAssociatedDataset = IDataset[];
