import { rest } from 'msw';
import { getRestfulResponse } from '../utils';
import {
  getAttributionDrillDownTable,
  getSingleAttributionTableRow,
} from './mockData';
import {
  IQueryMetricAttributionReq,
  IQueryMetricDrillDownReq,
  IQueryMetricTreeAttributionResultReq,
} from '../../src/services/queryAttribution/types';
import _ from 'lodash';

export const queryAttribution = [
  // 查询指标多维归因报告
  rest.post('/metric/attribution/report/query', async (req, res, ctx) => {
    const { dimensions, metric, limit } =
      (await req.json()) as IQueryMetricAttributionReq;

    return res(
      ctx.json(
        getRestfulResponse({
          metric,
          table: {
            all: getSingleAttributionTableRow(),
            dimensions: _.reduce(
              dimensions,
              (acc, dimension) => {
                acc[dimension] = getAttributionDrillDownTable(limit);
                return acc;
              },
              {},
            ),
          },
        }),
      ),
    );
  }),
  // 取消查询指标多维归因报告
  rest.post('/metric/attribution/report/cancel', async (req, res, ctx) => {
    return res(ctx.json(getRestfulResponse(true)));
  }),
  // 查询指标多维归因下钻数据
  rest.post('/metric/attribution/drill/report/query', async (req, res, ctx) => {
    const { limit, metric, dimension } =
      (await req.json()) as IQueryMetricDrillDownReq;
    return res(
      ctx.json(
        getRestfulResponse({
          metric,
          table: {
            [dimension]: getAttributionDrillDownTable(limit),
          },
        }),
      ),
    );
  }),
  // 取消查询指标多维归因下钻数据
  rest.post(
    '/metric/attribution/drill/report/cancel',
    async (req, res, ctx) => {
      return res(ctx.json(getRestfulResponse(true)));
    },
  ),
  // 查询指标树归因结果
  rest.post('/metric/tree/attribution/report/query', async (req, res, ctx) => {
    const { metricTree } =
      (await req.json()) as IQueryMetricTreeAttributionResultReq;

    const keys = Object.keys(metricTree);
    return res(
      ctx.json(
        getRestfulResponse(
          _.reduce(
            keys,
            (acc, key) => {
              acc[key] = getSingleAttributionTableRow();
              return acc;
            },
            {},
          ),
        ),
      ),
      ctx.delay(2000),
    );
  }),
  // 取消查询指标树归因结果
  rest.post('/metric/tree/attribution/report/cancel', async (req, res, ctx) => {
    return res(ctx.json(getRestfulResponse(true)));
  }),
  // 检查指标是否支持多维分析
  rest.post('/metric/attribution/report/check', async (req, res, ctx) => {
    return res(
      ctx.json(
        getRestfulResponse({
          result: _.random(100) > 8,
          errorMsg: '指标定义中包含了聚合方式 MAX、MIN，不支持归因',
        }),
      ),
    );
  }),
];
