// 其他实体依赖项中的极简指标定义
export const metricSimpleTpl = `#graphql
  name
  code
  metricName
  metricCode
  description
  type
  category {
    id
    name
  }
  authority {
    canCreate
    canEdit
    canDelete
    canUsage
    canAuth
    canMove
    canTransfer
  }
  isPublished
  originDataType
`;

// 指标列表等处使用的，相比于详情内容简洁一些的指标定义
export const metricBaseTpl = `#graphql
  ${metricSimpleTpl}
  owner {
    userId
    nickname
    account
    photo
  }
  updater {
    userId
    nickname
    account
    photo
  }
  caliber
  gmtCreate
  gmtUpdate
  collect
  collectCount
  atomicComposite
  unit
  version
  status
  hasDraft
  properties
  approvalMutationStatus
  approvalAuthorityStatus
`;

export const metricDetailTpl = `#graphql
  ${metricBaseTpl}
  isNewDimensionDefaultEnable
  publicDimension {
    availableDimensions {
      name
      displayName
      originDataType
    }
    disableDimensions {
      name
      displayName
      originDataType
    }
  }
  availableFactorDimensions {
      name
      displayName
      originDataType
    }
  owners {
    type
    userModel {
      userId
      nickname
      account
      photo
    }
  }
  previewConfig {
    layout
    dependencies {
      dimensions {
        name
        displayName
        description
        hasPermission
        originDataType
      }
      metrics {
        ${metricSimpleTpl}
      }
    }
  }
  attributionTree {
    layout
    dependencies {
      dimensions {
        name
        displayName
        description
        hasPermission
        originDataType
      }
      metrics {
        ${metricSimpleTpl}
      }
    }
  }
  attributionReport {
    layout
    dependencies {
      dimensions {
        name
        displayName
        description
        hasPermission
        originDataType
      }
      metrics {
        ${metricSimpleTpl}
      }
    }
  }
  caliber
  layout
  viewDetailCount
  queryCount
  properties
`;

export const availableDimensionsTpl = `#graphql
  name
  displayName
  description
  dimName
  dimCode
  isPublished
  hasPermission
  originDataType
  dimName
  isEnable
  category {
    id
    name
  }
`;
