import {
  EAuthoritySource,
  EResourceType,
  EUserRole,
  IAuthorityResource,
} from './authority';
import { EQueryEngineType } from './formula';

export enum ELanguage {
  ZH = 'zh',
  EN = 'en',
}

export interface IStaticConfig {
  disabledDownloadData: boolean;
  disabledCreateSqlDataset: boolean;
}
// 服务端标准的列表结构
export interface IListItems<T> {
  data: T[];
  pageSize: number;
  pageNumber: number;
  total: number;
  hasNext: boolean;
}

export interface IPagerInput {
  pageSize: number;
  pageNumber: number;
}

// 列的数据类型
export enum EColumnDataType {
  // 整数
  INT = 'INT',
  // 小数
  DOUBLE = 'DOUBLE',
  // 高精度小数
  DECIMAL = 'DECIMAL',
  // 日期时间
  DATE_TIME = 'DATE_TIME',
  // 日期
  DATE = 'DATE',
  // 文本
  TEXT = 'TEXT',
  // 布尔
  BOOLEAN = 'BOOL',
  // 结构化数据
  JSON = 'JSON',
}

// 租户信息
export interface ITenant {
  tenantId: string;
  tenantName: string;
  displayName: string;
  engineInfo: {
    queryEngineType: EQueryEngineType;
    displayName: string;
    name: string;
  };

  logo: string; // 资源地址
}

export enum EUserSource {
  ALOUDATA = 'Aloudata',
}

// 用户信息
export interface IUser {
  userId: string; // 用户 ID
  account: string; // 账号
  nickname: string; // 昵称
  photo: string; // 头像地址
  userSource?: EUserSource; // 用户来源
  ban: boolean; // 用户是否被冻结
  creationMode: EUserCreationMode; // 用户的创建方式
}

export interface IUserAuthorityResource {
  resourceType: EResourceType;
  authorityRole: EUserRole;
  authoritySource: EAuthoritySource;
  authRoleList?: EUserRole[];
  canAuth: boolean;
  canCreateResources: EResourceType[];
  canCreate: boolean;
  canDelete: boolean;
  canEdit: boolean;
  canMove: boolean;
  canUsage: boolean;
  resourceDTO: {
    resourceId: string;
    resourceType: EResourceType;
  };
}

export interface IGlobalUserInfo extends IUser {
  allAuthorityResource: IUserAuthorityResource[];
}

export interface IUserWithAuthority extends IUser {
  email?: string;
  phone?: string;
  authorityResource: IAuthorityResource; // 该用户的权限信息
  allAuthorityResource?: IAuthorityResource[]; // 该用户的权限信息
  disableProperties: string[] | null; // 不可被编辑属性
}

export enum EUserCreationMode {
  MANUAL = 'MANUAL',
  SYNC = 'SYNC',
}

export interface IUserDetail extends IUserWithAuthority {
  groups: string[];
  resetPasswordFirstLogin: boolean;
}

// 统一的接口返回格式
export interface IRestfulResponse<T = unknown> extends IErrorResponse {
  data: T;
}

export interface IErrorResponse {
  detailErrorMsg: string;
  errorCode: string;
  success: boolean; // true 表示服务是正常的，出现了业务上的错误，false 表示服务异常
  errorMsg: string;
}

export interface IGraphqlResponse<T = unknown> {
  data: T | null; // null 表示业务上的错误，如表单提交内容出错等
  // data 为 null 时才读取 extensions 字段
  extensions?: IErrorResponse;
  errors?: IErrorResponse[];
}

/**
 * 服务端认的字段类型
 */
export enum EOriginDataType {
  TINYINT = 'TINYINT',
  SMALLINT = 'SMALLINT',
  INTEGER = 'INTEGER',
  INT = 'INT',
  BIGINT = 'BIGINT',

  BOOLEAN = 'BOOLEAN',

  FLOAT = 'FLOAT',
  REAL = 'REAL',
  DOUBLE = 'DOUBLE',

  DECIMAL = 'DECIMAL',

  VARCHAR = 'VARCHAR',
  VARBINARY = 'VARBINARY',
  CHAR = 'CHAR',
  TABLE = 'TABLE',
  LIST = 'LIST',
  STRING = 'STRING',

  DATE = 'DATE',

  DATETIME = 'DATETIME',
  TIME = 'TIME',
  TIMESTAMP = 'TIMESTAMP',
  TIME_WITH_TIME_ZONE = 'TIME WITH TIME ZONE',
  TIMESTAMP_WITH_TIME_ZONE = 'TIMESTAMP WITH TIME ZONE',
  INTERVAL_YEAR_TO_MONTH = 'INTERVAL YEAR TO MONTH',
  INTERVAL_DAY_TO_SECOND = 'INTERVAL DAY TO SECOND',
  TIMESTAMP_NTZ = 'TIMESTAMP_NTZ',

  JSON = 'JSON',
  VARIANT = 'VARIANT',
  STRUCT = 'STRUCT',
  MAP = 'MAP',
  ARRAY = 'ARRAY',
}

export interface IDatasourceItem {
  id: string;
  name: string; // 数据源展示名
  queryEngineType: EQueryEngineType;
}

export enum EDataSourceType {
  HIVE1 = 'HIVE1',
  HIVE2 = 'HIVE2',
  HIVE3 = 'HIVE3',
  MYSQL = 'MYSQL',
  POSTGRESQL = 'POSTGRESQL',
  SQLSERVER = 'SQLSERVER',
  MONGODB = 'MONGODB',
  MAXCOMPUTE = 'MAXCOMPUTE',
  DORIS = 'DORIS',
}
export interface IModuleAuthority {
  disableAcceleration?: boolean;
}

// 控制功能展示
export interface IFunctionShowControl {
  softIntegrationOff: boolean;
  rowFilterOff: boolean;
}

/**
 * 时间类型的粒度枚举
 */
export enum EDateGranularityType {
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  SECOND = 'SECOND',
}

export enum EEntityType {
  TABLE = 'TABLE',
  FILE = 'FILE',
  WORKBOOK = 'WORKBOOK',
  DATA_MODEL = 'DATA_MODEL',
  // 团队空间不属于实体，但是授权、请求等操作等同于实体，所以放到这里
  GROUP_SPACE = 'GROUP_SPACE',
  METRIC = 'METRIC',
  DATASET = 'DATASET',
}

// 创建单位类型的枚举
export enum EUnitType {
  CURRENCY = 'CURRENCY',
  TIME = 'TIME',
  RATIO = 'RATIO',
  NOUN = 'NOUN',
  OBJECT_QUANTITY = 'OBJECT_QUANTITY',
  WEIGHT = 'WEIGHT',
  OTHER = 'OTHER',
}

// 创建单位的枚举
export enum EUnit {
  // 货币单位
  CNY_FEN = 'CNY_FEN',
  CNY_YUAN = 'CNY_YUAN',
  CNY_WAN = 'CNY_WAN',
  CNY_BAI_WAN = 'CNY_BAI_WAN',
  CNY_YI_YUAN = 'CNY_YI_YUAN',
  USD_CENT = 'USD_CENT',
  USD_DOLLAR = 'USD_DOLLAR',
  EUR_EURO = 'EUR_EURO',
  HKD_DOLLAR = 'HKD_DOLLAR',
  NEW_TAIWAN_DOLLAR = 'NEW_TAIWAN_DOLLAR',

  // 时间单位
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  SECOND = 'SECOND',
  QUARTER = 'QUARTER',
  MILLISECOND = 'MILLISECOND',

  // 比例单位
  DECIMAL = 'DECIMAL',
  PERCENTAGE = 'PERCENTAGE',
  PERMILLE = 'PERMILLE',

  // 名词
  RANK = 'RANK',

  // 对象量次
  HOUSEHOLD = 'HOUSEHOLD',
  TRANSACTION = 'TRANSACTION',
  ITEM = 'ITEM',
  INDIVIDUAL = 'INDIVIDUAL',
  OCCURRENCE = 'OCCURRENCE',
  PERSON_DAY = 'PERSON_DAY',
  FAMILY = 'FAMILY',
  HAND = 'HAND',
  SHEET = 'SHEET',
  PACKAGE = 'PACKAGE',

  // 重量单位
  TON = 'TON',
  KILOGRAM = 'KILOGRAM',

  // 其他
  OTHER = 'OTHER',
}
