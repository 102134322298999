import { EColumnDataType, EOriginDataType } from '@/typings';
import { IDataset, TAssociatedDataset } from '@/typings/dataset';
import { TFormula } from '@/typings/formula';

export enum ETokenType {
  STRING = 'string',
  PARTIAL_STRING = 'partialString',
  NUMBER = 'number',
  TRUE = 'true',
  FALSE = 'false',
  ADDITION = '+',
  SUBTRACTION = '-',
  MULTIPLICATION = '*',
  DIVISION = '/',
  GREATER_THAN = '>',
  LESS_THAN = '<',
  AND = 'and',
  WITH = '&&',
  OR = '||',
  OR_KEYWORD = 'or',
  FUNCTION_ID = 'functionId',
  COLUMN = 'column',
  PARTIAL_COLUMN = 'partialColumnName',
  LEFT_BRACKETS = '(',
  RIGHT_BRACKETS = ')',
  COMMA = ',',
  EQUAL = '=',
  DEFAULT = 'default',
  WS = 'ws',
}

export interface IBaseToken {
  col: number;
  line: number;
  linBreaks: number;
  offset: number;
  text: string;
  type: ETokenType;
  value: string[] | string;
  toString: () => string;
}

export interface IToken extends IBaseToken {
  end: number;
  // 该token是否是通过自动提示填充的
  isComplete?: boolean;
  error?: {
    errorMsg: string;
  };
  // 如果是列id则要保存id的路径
  idPath?: string[];
  // 如果type是column 的时候，会添加该信息
  columnInfo?: {
    columnId: string;
    sheetId: string;
  };
}

export interface ICodeEditBasicProps {
  ast: TFormula | null;
  currentSheetId: string;
  currentColumnId: string;
  // 是否是只读
  readonly?: boolean;
  isEditorExpand: boolean;
  canExpand?: boolean;
  setIsEditorExpand: React.Dispatch<React.SetStateAction<boolean>>;
  // 确认完成方法（失去焦点也算完成）
  onSubmit: (
    doc: string,
    sheetId: string,
    columnId: string,
    ast?: TFormula,
  ) => void;
}

export enum ECodeEditorPropsType {
  DATASET = 'DATASET',
  LIST = 'LIST',
  COMPOSE_METRIC = 'COMPOSE_METRIC',
}

// 抽取公共的逻辑，将外部传入的列表的转换为改结构
export interface IListItem {
  id: string; // 唯一标识
  displayName: string; // 公式展示出的名称
  dataType: EColumnDataType;
  originDataType: EOriginDataType;
}

export type IDatasetExternalInfo = {
  type: ECodeEditorPropsType.DATASET;
  // 除数据集内部使用外，其他的地方这里应该都是为null。
  dataset: IDataset | null;
  one2manyDatasets: TAssociatedDataset;
  many2oneDatasets?: TAssociatedDataset;
};

export type IListExternalInfo = {
  type: ECodeEditorPropsType.LIST;
  list: IListItem[];
};

export type IComposeMetricExternalInfo = {
  type: ECodeEditorPropsType.COMPOSE_METRIC;
  list: IListItem[];
  dimensionList: IListItem[];
};

export type TExternalInfo =
  | IDatasetExternalInfo
  | IListExternalInfo
  | IComposeMetricExternalInfo;
export interface IEditorEvent {
  tokenList: IToken[];
  cursorPosition: number;
  hasFocus: boolean;
  autoCompleteTokenList: IToken[];
}

export interface IRender {
  offset: number;
  end: number;
  color: string;
}

export interface IHighlight {
  sheetId: string;
  columnId: string;
  colorIndex: number;
  isActive: boolean;
}

export interface IEditorError {
  from: number;
  to: number;
  errorMsg: string;
}

export interface IHighlightingRender {
  offset: number;
  end: number;
  color: string;
}
