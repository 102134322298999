import { Tooltip } from '@aloudata/aloudata-design';
import { useEffect, useState } from 'react';
import { TMetric } from '@/typings/metric';
import { useDragLayer } from 'react-dnd';
import { MetricCardDropdown } from '../../../MetricCard/Dropdown';

export function PickerTooltip(props: IProps) {
  const { metric, children, reason } = props;
  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
  }));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isDragging) {
      setOpen(false);
    }
  }, [isDragging]);

  let childrenNode = children;
  if (reason) {
    // 可分析约束报错
    childrenNode = <Tooltip title={reason}>{childrenNode}</Tooltip>;
  }

  return (
    <MetricCardDropdown
      metric={metric}
      dropdown={{
        placement: 'right',
        onOpenChange: (isOpen) => {
          setOpen(isOpen);
        },
        trigger: 'hover',
        open: !isDragging && open,
        delay: { open: 500, close: 0 },
      }}
    >
      {childrenNode}
    </MetricCardDropdown>
  );
}
interface IProps {
  children: React.ReactElement;
  metric: TMetric;
  reason: string;
}
