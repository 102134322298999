export const ownerFilter = {
  owner: '负责人',
  myOwner: '只看我负责的',
  placeholder: '请选择',
};

export const metricPicker = {
  favFolder: '我的收藏',
  allFolder: '全部指标',
  add: '添加',
  metric: '指标',
  notFoundException: '该指标已不可用',
};

export const processedDimensionCard = {
  referenceDimension: '来源维度:',
  code: '维度编码:',
  dimName: '维度名称:',
  description: '维度描述:',
  showAll: '全部',
  hideAll: '收起',
};

export const processedMetricCard = {
  referenceMetric: '来源指标:',
  code: '指标编码:',
  metricName: '指标名称:',
  description: '指标业务口径:',
  dateLimit: '时间限定:',
  samePeriod: '快速衍生:',
  filter: '业务限定:',
  showAll: '全部',
  hideAll: '收起',
};

export const selectWithSearch = {
  selectColumn: '选择字段',
  selectMetric: '选择指标',
  selectDate: '选择日期标识',
};

export const dimensionPicker = {
  dimension: '维度',
  allFolder: '全部维度',
  add: '添加',
  placeholder: '请选择维度',
};

export const datasetPicker = {
  dataset: '数据集',
  allFolder: '全部数据集',
  placeholder: '请选择数据集',
  datasetName: '数据集名称:',
  des: '描述:',
};
export const analysisViewPicker = {
  allFolder: '全部指标视图',
  analysisViewName: '指标视图名称:',
  des: '描述:',
};
export const sourceTablePicker = {
  placeholder: '请选择',
};

export const codeEditorWrapper = {
  column: '字段',
  metric: '指标',
  dimension: '维度',
  function: '函数',
};

export const deleteResourceModal = {
  title: ({ type }: { type: string }) => `删除${type}`,
  delete: '删除',
  cancel: '取消',
  type: {
    metric: '指标',
    dataset: '数据集',
    dimension: '维度',
    analysisView: '指标视图',
    physicTable: '物理表',
    file: '文件',
  },
  desc: ({ type }: { type: string | number }) =>
    `该${type}被以下资产引用，删除后会影响这些资产的使用，确认删除？`,
  desc1: ({ type }: { type: string | number }) =>
    `删除${type}后将不可恢复，确认删除吗？`,
  showMore: '展开全部',
  putAway: '收起',
};

export const traceInfo = {
  copy: '复制错误信息',
  copySuccess: '已复制错误信息',
};

export const metricTreeEditor = {
  placeholder: '输入 [ 插入指标',
};

export const formatMenuItem = {
  auto: '自动',
  quickOperation: {
    int: '整数',
    oneDecimalPlace: '1位小数',
    twoDecimalPlace: '2位小数',
    percentage: {
      default: '百分比',
      oneDecimalPlace: '百分比1位小数',
      twoDecimalPlace: '百分比2位小数',
      custom: '自定义',
    },
  },
};

export const customFormatModal = {
  title: '自定义格式设置',
  type: {
    default: '类型',
    auto: '自动',
    number: '数值',
    percentage: '百分比',
    currency: '货币',
  },
  decimal: {
    default: '小数位数',
    none: '无（0位）',
    decimal: ({ number }: { number: string | number }) => `${number}位`,
  },
  unit: {
    none: '无',
    default: '数据量级',
  },
  negative: '负数',
  thousand: {
    default: '千分位分隔符',
    open: '启用',
  },
  currency: {
    default: '货币符',
    necessary: '货币符不能为空',
    rmb: '人民币',
    dollar: '美元',
    pound: '英镑',
    custom: '自定义',
  },
  format: {
    default: '展示格式',
    auto: {
      default: '适配方式',
      ch: '自动适配（中文）',
      en: '自动适配（英文）',
    },
  },
};

export const versionTag = {
  unPublished: '未发布',
  published: '已发布',
  draft: '已保存未发布',
  offLine: '已下线',
  publishing: '发布中',
  offLining: '下线中',
  toBeDeleted: '待删除',
};

export const listTable = {
  checkedColumns: '显示字段',
  unCheckedColumns: '未显示字段',
  inputPlaceholder: '搜索',
};

export const cronEditor = {
  custom: '自定义表达式',
  minute: '分钟',
  hour: '时',
  select: '请选择',
  week: '星期',
  day: '号',
  hourly: '每小时',
  minute_1: '每分钟',
  minute_5: '每5分钟',
  minute_10: '每10分钟',
  minute_15: '每15分钟',
  minute_30: '每30分钟',
  daily: '每日',
  weekly: '每周',
  monthly: '每月',
  minuteDisplay: '每分钟更新',
  minute_5Display: '每5分钟更新',
  minute_10Display: '每10分钟更新',
  minute_15Display: '每15分钟更新',
  minute_30Display: '每30分钟更新',
  getHourDisplay: (hour: string) => `每小时的第 ${hour} 分钟更新`,
  getDailyDisplay: (hour: string, minute: string) =>
    `每天 ${hour} 时 ${minute} 分更新`,
  getWeeklyDisplay: (dayOfWeek: string, hour: string, minute: string) =>
    `每周 ${dayOfWeek} 的 ${hour} 时 ${minute} 分更新`,
  getMonthlyDisplay: (day: string, hour: string, minute: string) =>
    `每月 ${day} 号的 ${hour} 时 ${minute} 分更新`,
  Monday: '一',
  Tuesday: '二',
  Wednesday: '三',
  Thursday: '四',
  Friday: '五',
  Saturday: '六',
  Sunday: '日',
};
export const logic = {
  btn: {
    addCondition: '添加条件',
    addRelation: '添加关系',
  },
  select: {
    and: '且',
    or: '或',
  },
};

export const filter = {
  operator: {
    common: {
      isNull: '为空',
      notNull: '不为空',
      rank: {
        parent: '排名值',
        rank: '松散排名',
        denseRank: '密集排名',
        rankRowNumber: '连续排名',
      },
    },
    date: {
      specific: '指定时间',
      range: '时间范围',
      outOfRange: '超出范围',
      earlier: '早于',
      later: '晚于',
      matchMetric: '指标值满足筛选',
    },
    text: {
      equal: '等于',
      notEqual: '不等于',
      include: '包含',
      exclude: '不包含',
      startWith: '以...开头',
      notStartWith: '不以...开头',
      endWith: '以...结尾',
      notEndWith: '不以...结尾',
      matchMetric: '指标值满足筛选',
    },
    number: {
      equal: '等于',
      notEqual: '不等于',
      greater: '大于',
      greaterEqual: '大于等于',
      less: '小于',
      lessEqual: '小于等于',
      between: '介于',
      outOfRange: '超出范围',
      matchMetric: '指标值满足筛选',
    },
    boolean: {
      equal: '等于',
      exclude: '排除',
      notEqual: '不等于',
    },
    json: {
      include: '包含',
      notInclude: '不包含',
    },
  },
  condition: {
    textPlaceholder: '请输入',
    pickerPlaceholder: '请选择',
  },
  customTimePicker: {
    title: '自定义',
    calendarDay: '自然日',
    recent: '最近...',
    td: '自...至今',
    beginEnd: '期初期末',
    singleDate: '单日期',
    range: '日期范围',
    beforeAfter: '早于晚于',
    granularity: '时间粒度',
    fromTo: (from: string, to: string) => `${from} 至 ${to}`,
    singleDatePicker: {
      date: '日期',
      relative: '相对时间',
      fixed: '精确时间',
      current: (dateTime: string, granularity: string) =>
        `${dateTime}当${granularity}`,
    },
    rangeConfig: {
      start: '开始于',
      end: '结束于',
    },
    recentConfig: {
      before: '过去',
      after: '未来',
      toCurrent: (granularity: string) => `至当${granularity}`,
    },
    beginEndConfig: {
      current: '当前',
      decrease: '减少',
      firstDate: (granularity: string) => `第一${granularity}`,
      lastDate: (granularity: string) => `最后一${granularity}`,
      first: '第一个',
      last: '最后一个',
      offsetValue: (unit: string, direction: string, dateTag: string) =>
        // 月内第一个工作日、季内最后一天
        `${unit}内${direction}${dateTag}`,
    },
    mtd: {
      current: '当前',
      decrease: '减少',
      postfix: (unit: string, granularity: string) =>
        `${unit}初至当${granularity}`,
    },
    validate: {
      maxLessThanMin: '结束时间不能小于开始时间',
      dateTagNotFound: '已选的日期标识不存在，请重新选择',
    },
  },
  reservationPicker: {
    text: '仅保留',
  },
  metricTimePicker: {
    options: {
      today: '今天',
      yesterday: '昨天',
      last30Days: '近30天',
      last180Days: '近180天',
      last365Days: '近365天',
      mtd: '本月至今',
      qtd: '本季至今',
      ytd: '本年至今',
      thisWeek: '本周',
      thisMonth: '本月',
      thisQuarter: '本季',
      thisYear: '本年',
      last3Years: '近3年',
      custom: '自定义',
    },
  },
  baseTimePicker: {
    custom: '自定义',
    title: '基准日期',
    checkbox: '基准日期为',
    previous: '上',
    previousDay: '昨',
    empty: '无',
  },
  modalCondition: {
    title: '筛选',
    selectedDimension: '选中维度',
    granularity: '时间粒度',
    filterCondition: '过滤条件',
  },
};

export const downloadData = {
  download: '下载数据',
  tip: '下载前10万行数据结果',
};

export const nav = {
  home: '首页',
  metricCatalog: '指标目录',
  metricApplication: {
    title: '指标应用',
    nav: {
      metricBoard: '指标看板',
      metricView: '指标视图',
      metricMonitor: '指标监控',
      APIIntegrated: 'API集成',
      metricTree: '指标树',
    },
  },
  metricDefine: {
    title: '指标定义',
    nav: {
      dataset: '数据集',
      dimension: '维度',
      metric: '指标',
      datasource: '数据源',
      timeLimit: '时间限定',
    },
  },
  accelerate: {
    title: '指标加速',
    nav: {
      plan: '物化方案',
      mtTable: '物化表',
      manualPlan: '补数方案',
    },
  },

  managementSetUp: {
    title: '管理设置',
    nav: {
      userRole: {
        title: '用户角色',
        nav: {
          user: '用户',
          userGroup: '用户组',
          userAttribute: '用户属性',
        },
      },
      metricStandardize: {
        title: '指标规范',
        nav: {
          metricAttribute: '指标属性',
          dimensionAttribute: '维度属性',
          metricCategory: '指标类目',
          dimensionCategory: '维度类目',
          datasetCategory: '数据集类目',
          analysisViewCategory: '指标视图类目',
          customCalendar: '自定义日历',
        },
      },
      enterpriseSecurity: {
        title: '企业安全',
        nav: {
          centralizedAuthority: '集中授权',
          dataRowPermission: '全局行列权限',
          authRule: '权限规则',
          approvalManagement: '审批管理',
        },
      },
      platformSettings: '平台设置',
    },
  },
  approval: '审批中心',
};
export const resourceType = {
  metric: '指标',
  dataset: '数据集',
  dimension: '维度',
  analysisView: '指标视图',
  datasource: '数据源',
  workbook: '指标看板',
  tenant: '租户',
  view: '视图',
  file: '文件',
  userGroup: '用户组',
  userProperty: '用户属性',
  category: '类目',
  rpTask: '指标/明细加速',
  categoryMetric: '类目指标',
  categoryDimension: '类目维度',
  dimensionProperty: '维度属性',
  categoryDataset: '类目数据集',
  categoryAnalysisView: '类目指标视图',
  metricProperty: '指标属性',
  metricMonitor: '指标监控',
  metricAccelerate: '指标加速',
  resultPlan: '结果加速',
  metricTree: '指标树',
};
export const transferModal = {
  success: '转交成功',
  title: (resource: string, isMultiple = false) => {
    return `${isMultiple ? '批量' : ''}转交${resource}`;
  },
  label: {
    transferResource: (resource: string) => `转交${resource}`,
    currentOwner: '当前负责人',
    transferTo: '转交对象',
  },
  okText: '转交',
};

export const categoryHandlerBtn = {
  create: (resource: string) => `创建${resource}`,
  rename: '重命名',
  adjustCategory: '调整类目',
  authManage: '权限管理',
  delete: '删除',
};

export const grantRoleModal = {
  visibleRange: {
    resource: {
      dataset: '数据集',
      metric: '指标',
    },
    title: '可见范围',
    visibleToAll: '全员可见',
    visibleToSpecifiedUsers: '指定用户可见',
    selectVisibleRange: '可见范围',
    visibleToAllDescription: (type: string, isCategory: boolean) => {
      return isCategory
        ? `全部用户可见该类目及类目内的${type}信息`
        : `全部用户可见该${type}的信息`;
    },
    selectUsers: '选择用户',
    totalNum: ({ num }: { num: number }) => `共${num}个`,
    visibleToSpecifiedUsersDescription:
      '只有被指定用户可见该类目及类目内的数据',
    memberPickerPlaceholder: '选择用户/用户组',
  },
  grantRoleButton: {
    title: '选择成员',
    text: '授权',
    auth: '权限：',
    cancel: '取消',
    ok: '确定',
  },
};

export const moveCategoryModal = {
  title: '移动类目',
  tip: '移动类目后，可能会导致与此类目相关的权限发生变化，请知晓。',
};

export const authorityList = {
  owner: '负责人',
  authority: '权限',
};

export const categoryTree = {
  noAuthority: '无权限',
};

export const dataRowPermission = {
  refresh: '刷新',
  title: '数据行权限',
  columnTitle: '数据列权限',
  addRule: '添加规则',
  ruleNum: ({ num }: { num: number }) => `共${num}条规则`,
  ruleName: '规则名称',
  ruleDescription: '规则描述',
  creator: '创建人',
  operation: '操作',
  noData: '当前无权限规则，请添加新的规则。',
  edit: '编辑',
  delete: '删除',
  deleteSuccess: '删除成功',
  modal: {
    saveSuccess: '保存成功',
    editTitle: '编辑行规则权限',
    title: '新建行规则权限',
    columnTitle: '新建列规则权限',
    columnEditTitle: '编辑列规则权限',
    cancel: '取消',
    confirm: '保存',
    form: {
      baseInfo: '基础信息',
      ruleName: '规则名称',
      ruleDescription: '规则描述',
      tip: '仅适用成员可以使用指定维度进行分析，其他用户无法使用该维度。',
      availableDimensions: '可用维度',
      availableRange: '可用范围',
      effectiveMetric: '生效指标',
      specifyMetric: '指定指标',
      allMetric: '全部指标',
      showMetricCode: '显示指标编码',
      effectiveScope: '生效范围',
      applicableMember: '适用成员',
      applicableScope: '适用范围',
      specifyMember: '指定用户/用户组',
      allMember: '全部用户',
      conditionRule: '条件规则',
      matchRule: '匹配规则',
      fixedValue: '固定值/用户属性',
      permissionTable: '权限表',
      userIdentifier: '用户标识 (账号名称)',
      necessary: (name: string) => `${name}不能为空`,
    },
  },
};

export const customCalendar = {
  total: '个日历',
  columns: {
    displayName: '日历展示名',
    name: '日历名称',
    granularity: '时间粒度',
  },
  title: '自定义日历',
  modal: {
    title: '日历',
    baseInfo: '基础信息',
    rule: '日历规则',
    format: '日历格式',
    subTitle: {
      weekMonthCycle: '周月关系：',
      displayName: '日历展示名：',
      name: '日历名称：',
      template: '模板：',
      startDayOfWeek: '起始周：',
      startMonthOfYear: '起始月：',
      monthCycle: '月份周期：',
      baseDate: '基准日期：',
      extraWeek: '溢出周：',
      week: (name: string) => `${name}周：`,
      month: (name: string) => `${name}月：`,
      quarter: (name: string) => `${name}季：`,
      year: (name: string) => `${name}年：`,
    },
    monthCycleValue: (month: string, dayOrder: string, dayIndex: string) =>
      `以${month}${dayOrder}第${dayIndex}天作为月份开始`,
    baseDateValue: (month: string, lastDay: string) =>
      `以${month}的${lastDay}作为结束日期`,
    startMonthOfYearValue: (month: string) => `以${month}作为起始月`,
    extraWeekBelongMonth: (month: string) => `将溢出周划分到${month}`,
    map: {
      oneDayOfWeek: {
        lastSunday: '最后一个周日',
        endMonthRecentSunday: '月末最近的周日',
      },
      monthCycle: {
        last: '上个月',
        current: '当前月',
      },
      order: {
        asc: '正序',
        desc: '倒序',
      },
      templateType: {
        wmqy: '周-月-季-年',
        mqy: '月-季-年',
      },
      week: {
        one: '周一',
        two: '周二',
        three: '周三',
        four: '周四',
        five: '周五',
        six: '周六',
        seven: '周日',
      },
      month: {
        january: '一月',
        february: '二月',
        march: '三月',
        april: '四月',
        may: '五月',
        june: '六月',
        july: '七月',
        august: '八月',
        september: '九月',
        october: '十月',
        november: '十一月',
        december: '十二月',
      },
      number: {
        one: '一',
        two: '二',
        three: '三',
        four: '四',
        five: '五',
        six: '六',
        seven: '七',
        eight: '八',
        nine: '九',
        ten: '十',
        eleven: '十一',
        twelve: '十二',
      },
    },
  },
};
export const resourceImpactModal = {
  title: '资源下游影响',
  deleteDesc:
    '删除以下资产会对下游资产造成影响，请评估后谨慎操作，您可以下载报告以获取详细信息。',
  editDesc:
    '变更以下资产会对下游资产造成影响，请评估后谨慎操作，您可以下载报告以获取详细信息。',
  okBtnText: '明确影响，继续变更',
  deleteBtnText: '明确影响，继续删除',
  filter: {
    error: (sum: number) => `异常资产(${sum})`,
    change: (sum: number) => `逻辑变更资产(${sum})`,
    all: `全部`,
    metric: (sum: number) => `指标(${sum})`,
    dimension: (sum: number) => `维度(${sum})`,
    monitor: (sum: number) => `监控(${sum})`,
    accelerate: (sum: number) => `加速(${sum})`,
    view: (sum: number) => `指标视图(${sum})`,
  },
  download: {
    btn: '下载报告',
    downloadMsg: '下载成功',
    table: {
      type: {
        title: '影响类型',
        error: '资产异常',
        change: '资产逻辑变更',
      },
      resourceType: '资产类型',
      displayName: '资产展示名',
      resourceId: '资产名称',
      owner: '负责人',
      errorMsg: '异常原因',
    },
  },
  deleteModal: {
    title: '删除',
    content: '删除后将不可恢复，确认删除吗？',
    okText: '删除',
  },
  tableColumn: {
    type: '类型',
    name: '资产',
    owner: '负责人',
    errorMsg: '异常原因',
  },
};

export const globalFilter = {
  error: {
    configUncompleted: (displayName: string) =>
      `筛选器${displayName}未配置完成`,
  },
  title: '筛选器设置',
  left: {
    title: '筛选器',
    dropdown: {
      delete: '删除',
      rename: '重命名',
      addDate: '日期筛选',
      addSelect: '下拉筛选',
    },
  },
  right: {
    date: {
      label: {
        granularity: '时间粒度',
        method: '筛选方式',
        defaultValue: '默认值',
        dimension: '筛选维度',
      },
      method: {
        range: '区间',
        specify: '单',
      },
      metricTimeDisplayName: '指标日期',
    },
    select: {
      label: {
        dimension: '筛选维度：',
      },
      method: {
        single: '单选',
        multiple: '多选',
      },
    },
  },
  emptyFilterDisplayName: '未命名筛选器',
  footer: {
    ok: '保存',
  },
};
