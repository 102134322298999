export const defaultCategory = {
  name: '未分类',
};

export const adjustCategory = {
  default: '调整类目',
  batch: '批量调整类目',
};

export const categoryModifyModal = {
  defaultName: '新建类目',
  title: {
    rename: '重命名',
    create: '新建类目',
  },
  cancel: '取消',
  okText: {
    rename: '保存',
    create: '创建',
  },
  label: '类目名称',
};
