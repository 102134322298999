export const header = {
  input: {
    btnText: 'Search',
  },
  metric: {
    all: 'All Metrics',
    atomic: 'Basic Metrics',
    derived: 'Derived Metrics',
    composite: 'Composite Metrics',
    category: 'Category',
    allCategory: 'All Categories',
  },
  more: 'More Categories',
  helper: {
    title: 'Help Center',
    content: 'Get introduction, quick start, best practices and more',
    link: 'Learn More',
  },
  vetting: {
    waitingForMyApproval: 'My Approvals',
    myApply: 'My Requests',
    all: 'All',
    empty: 'All Approvals completed',
  },
};

export const body = {
  title: 'My Favorites',
  loadMore: 'Show More',
  empty: 'No favorites yet',
  serverError: 'Failed to get favorites list',
};

export const metricCard = {
  error: {
    queryError: 'Sorry, request failed',
  },
};
