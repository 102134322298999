import { IFilter } from '@/common/domain/filter/Filter/filter';
import { EColumnType } from './dataset';
import { EOriginDataType } from './formula';
import { IDerivateCalc, TMetricTime } from './analysisView';
import { EColumnDataType, EUnit } from '.';
import { IFilterItem } from '@/pages/Metric/Detail/Content/Authority/RowFilterTable/EditConditionalRuleModal/DimensionFilter/filter';
import {
  EAxisOrient,
  IComboConfig,
} from '@/common/domain/viz/Echarts/common/vizStyles/common';
import { EVizType } from '@/common/domain/viz/Echarts/typing';
import { IFormatConfig } from '@/services/metric/types';
import {
  IAreaChartStyle,
  IBarChartStyle,
  IComboChartStyle,
  ILineChartStyle,
  IPieChartStyle,
  IRadarChartStyle,
  IScatterChartStyle,
  TChartStyle,
} from './styles';
import { TComparisonDateValue } from '@/pages/Metric/Detail/Content/Attribution/AttributionReport/types';
import { TFilter } from '@/common/domain/filter/NewFilter/types';
import { TCustomTimeValue } from '@/common/domain/filter/NewFilter/common/CustomTimePicker/types';
import { IPreAgg } from './metric';
import { TNode } from '@aloudata/aloudata-design/dist/LogicTree';

export interface IDocument {
  pages: string[];
  pagesMap: Record<string, IPage>;
  elementMap: Record<string, TElement>;
  dependencies: Record<string, IDependencyItem>;
  view: IDocumentView;
  filterRelationship?: IFilterRelationShip;
}

export type TFilterInWorkbook = TFilter & {
  id: string;
};

export interface IFilterRelationShip {
  // 哪些组件可以筛选其他组件
  filter: {
    [key: string]: string[];
  };
  // 哪些组件被其他组件筛选
  filteredBy: {
    [key: string]: string[];
  };
}

export interface IDependencyItem {
  id: string;
  metrics: string[];
  dimensions: string[];
}

export interface IDateLimit {
  disabled: boolean;
  config?: TCustomTimeValue;
  preAggs: IPreAgg[];
  isPreAggCustom: boolean;
}

export type TElement = TChartElement | IFilterElement | IMetricTreeElement;

export type TChartElement = IPivotTableElement | IVizElement;

// 指标树组件
export interface IMetricTreeElement {
  type: EWorkBookElementType.METRIC_TREE;
  id: string;
  pageId: string;
  title: string;
  description?: string;
  layout: IPositionConfig;
  // 图表指标，包含主指标和拆解指标
  metricsMap: Record<string, IMetric>;
  // 主指标
  mainMetric?: string;
  // 拆解指标，每一项对应图表指标的 ID
  subMetrics: string[];
  // 指标关系
  treeNodes: TMetricTreeNode[];
  // 辅助指标方式
  derivativeCalcList: IDerivateCalc[];
  // 筛选器
  filterDependency: Record<string, IFilterDependency>;
  filters?: TFilterInWorkbook[]; // 绑定维度配置，和图表组件里的筛选器配置结构一致
  // 指标日期
  metricTimeFilter: TMetricTime;
  // 归因分析
  attribution?: IMetricTreeAttributionConfig;
}

// 指标树归因分析配置
export interface IMetricTreeAttributionConfig {
  comparisonData: TComparisonDateValue;
  showSummary: boolean;
}

export type TMetricTreeNode = IMetricTreeMetric | IMetricTreeOperator;

export interface IMetricTreeMetric {
  id: string; // uuid
  metricId: string; // 图表指标的 ID
  type: EMetricTreeNodeType.METRIC;
  children?: TMetricTreeNode[]; // 子关系
  collapsed?: boolean; // 是否折叠
}

export interface IMetricTreeOperator {
  id: string; // uuid
  type: EMetricTreeNodeType.OPERATOR;
  content: string; // +,-,*,/,(,)
}

export enum EMetricTreeNodeType {
  METRIC = 'METRIC',
  OPERATOR = 'OPERATOR',
}

export interface IFilterElement {
  type: EWorkBookElementType.FILTER;
  id: string;
  pageId: string;
  title: string;
  layout: IPositionConfig;
  showTitle: boolean; // 是否显示标题
  filterDependency: Record<string, IFilterDependency>;
  filters?: IFilter<IFilterItem>; // 绑定维度配置，和图表组件里的筛选器配置结构一致
  // 当筛选维度是指标日期时，筛选值用下面的结构保存
  metricTimeFilter?: TMetricTime;
  componentType: EFilterComponentType; // 筛选组件类型
}

// 筛选器组件类型
export enum EFilterComponentType {
  LIST = 'LIST',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
}

export interface IBasicChartElement {
  id: string;
  pageId: string;
  title: string;
  description?: string;
  // element 布局信息
  layout: IPositionConfig;
  dimensionsMap: Record<string, IDimension>;
  metricsMap: Record<string, IMetric>;
  filters?: TFilterInWorkbook[];
  filterDependency: Record<string, IFilterDependency>;
  metricTimeFilter: TMetricTime;
}

export interface IPivotTableElement extends IBasicChartElement {
  type: EWorkBookElementType.PIVOT_TABLE;
  rows: string[];
  columns: string[];
  values: string[];
}

export interface IVizElement extends IBasicChartElement {
  type: EVizType;
  xAxis?: string; // 在散点图的情况下，该字段指向分类字段
  yAxis: string[];
  legend?: string;
  y2Axis?: string[]; // 散点图的x轴
  axisOrient?: {
    [yId: string]: EAxisOrient;
  };
  comboConfig?: IComboConfig; // 组合图中值轴每个列对应的信息
  styles?: TChartStyle;
}

export enum EWorkBookElementType {
  PIVOT_TABLE = 'PIVOT_TABLE',
  METRIC_TREE = 'METRIC_TREE',
  FILTER = 'FILTER',
}

export type TElementType = EVizType | EWorkBookElementType;

export interface IColumnElement extends IVizElement {
  type: EVizType.COLUMN_CHART;
  styles?: IBarChartStyle;
  axisOrient: {
    [yId: string]: EAxisOrient;
  };
}
export interface IScatterElement extends IVizElement {
  type: EVizType.SCATTER_CHART;
  styles?: IScatterChartStyle;
  axisOrient: {
    [yId: string]: EAxisOrient;
  };
}
export interface IRadarElement extends IVizElement {
  type: EVizType.RADAR_CHART;
  styles?: IRadarChartStyle;
}
export interface IAreaElement extends IVizElement {
  type: EVizType.AREA_CHART;
  styles?: IAreaChartStyle;
  axisOrient: {
    [yId: string]: EAxisOrient;
  };
}

export interface IComboElement extends IVizElement {
  type: EVizType.COMBO_CHART;
  styles?: IComboChartStyle;
  axisOrient: {
    [yId: string]: EAxisOrient;
  };
  comboConfig: IComboConfig; // 组合图中值轴每个列对应的信息
}
export interface ILineChartElement extends IVizElement {
  type: EVizType.LINE_CHART;
  axisOrient: {
    [yId: string]: EAxisOrient;
  };
  styles?: ILineChartStyle;
}

export interface IBarChartElement extends IVizElement {
  type: EVizType.BAR_CHART;
  styles?: IBarChartStyle;
  axisOrient: {
    [yId: string]: EAxisOrient;
  };
}
export interface IPieChartElement extends IVizElement {
  type: EVizType.PIE_CHART;
  styles?: IPieChartStyle;
}

// 不继承指标
export interface IMetric {
  def: {
    code: string;
  };
  id: string;
  // 指标的名称
  name: string;
  displayName: string;
  // 自定义名称
  title?: string;
  derivativeCalc?: IDerivateCalc;
  description?: string;
  originDataType: EOriginDataType;
  dataType: EColumnDataType;
  exception?: IException;
  unit: EUnit;
  formatConfig?: IFormatConfig;
  // 时间限定
  dateLimit: IDateLimit;
  // 业务限定
  filters: TNode<TFilterInWorkbook>;
  // 业务限定依赖
  filterDependency: Record<string, IFilterDependency>;
}

export interface IDimension {
  def: {
    dimensionName: string;
  };
  id: string;
  // 在分析簿中展示名称
  displayName: string;
  // 维度的展示名
  dimensionDisplayName?: string;
  // 自定义名称
  title?: string;
  sort: ISort;
  // 名称
  name: string;
  // 粒度
  granularity?: string;
  dataType: EColumnDataType;
  originDataType: EOriginDataType;
  description: string; // 描述
  exception?: IException;
}

export interface IFilterDependency {
  def: {
    dimensionName: string;
  };
  id: string;
  title?: string;
  granularity?: string;
  dataType: EColumnDataType;
  originDataType: EOriginDataType;
  description: string;
  columnType: EColumnType;
  // 在分析簿中展示名称
  displayName: string;
  // 维度的展示名
  dimensionDisplayName?: string;
  // 维度名
  name: string;
  exception?: IException;
  isFillConfig: boolean;
}

export enum EExceptionType {
  // 外部引用不存在
  REFERENCE_NOT_FOUND = 'REFERENCE_NOT_FOUND',
  // 外部引用发生变化
  REFERENCE_CHANGED = 'REFERENCE_CHANGED',
  // 外部引用没有权限
  REFERENCE_NO_PERMISSION = 'REFERENCE_NO_PERMISSION',
}

export interface IException {
  type: EExceptionType;
  message: string;
}

export enum ESortType {
  DIMENSION = 'DIMENSION',
  METRIC = 'METRIC',
}

export interface ISort {
  // 指标或者维度的id
  id: string;
  type: ESortType;
  // 升降序
  isAsc: boolean;
}

export interface IPositionConfig {
  width: number;
  height: number;
  left: number;
  top: number;
}

export interface IPage {
  // page id
  pageId: string;
  // page title
  title: string;
}

export interface IDocumentView {
  // 当前选择的 page Id
  activePage: string;
  // 当前选中的 element Id
  activeElement?: string;
  // 当前选中的列
  activeColumns: string[];
} // 组件状态
export enum EElementStatus {
  // 初始状态  未触发请求 无配置
  INIT = 'init',
  // 加载中 查询中 显示顶部loading条，内容区域展示上一次显示内容
  LOADING = 'loading',
  // 正常显示
  COMPLETE = 'complete',
}

// 维度和指标的可用关系
export interface IAvailableMap {
  dimension: Record<string, Set<string>>;
  metric: Record<string, Set<string>>;
}
