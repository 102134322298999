import { ICalendarData } from '@/typings/customCalendar';
import { createContext, useCallback, useState } from 'react';
import { useGetCustomCalendarData } from '../../../services/customCalendar/index';
import { customCalendarList, tenantInfo } from '@/common/globalInfo/appConfig';
import { useLocalStorageState, useMount } from 'ahooks';

const CALENDAR_DATA_IN_LOCALSTORAGE_KEY = `${tenantInfo.tenantId}-customCalendarData`;

export const customCalendarListContext = createContext<{
  customCalendarData: Record<string, ICalendarData[]>;
  setCustomCalendarData: (
    customCalendarData: Record<string, ICalendarData[]>,
  ) => void;
  refreshCustomCalendarData: (id: string) => void;
}>({
  customCalendarData: {},
  setCustomCalendarData: () => {},
  refreshCustomCalendarData: () => {},
});

// eslint-disable-next-line no-magic-numbers
const DEFAULT_EXPIRE_TIME = 30 * 60 * 60 * 24 * 1000;

export const CustomCalendarListContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [customCalendarData, setCustomCalendarData] = useState<
    Record<string, ICalendarData[]>
  >({});
  const { run: queryCalendarData } = useGetCustomCalendarData();
  const [
    customCalendarDataInLocalStorage,
    setCustomCalendarDataInLocalStorage,
  ] = useLocalStorageState<{
    data: Record<string, ICalendarData[]>;
    expire: number;
  }>(CALENDAR_DATA_IN_LOCALSTORAGE_KEY, {
    defaultValue: {
      data: {},
      expire: 0,
    },
  });

  useMount(() => {
    customCalendarList.forEach((item) => {
      refreshCustomCalendarData(item.id);
    });
  });

  const refreshCustomCalendarData = useCallback(
    (id: string) => {
      if (
        customCalendarDataInLocalStorage &&
        customCalendarDataInLocalStorage.data[id] &&
        customCalendarDataInLocalStorage.expire > Date.now()
      ) {
        setCustomCalendarData({
          ...customCalendarData,
          [id]: customCalendarDataInLocalStorage.data[id],
        });
        return;
      } else {
        queryCalendarData({ id }).then((res) => {
          setCustomCalendarData({
            ...customCalendarData,
            [id]: res.queryCalendarData || [],
          });
          setCustomCalendarDataInLocalStorage({
            data: {
              ...customCalendarDataInLocalStorage?.data,
              [id]: res.queryCalendarData || [],
            },
            expire: Date.now() + DEFAULT_EXPIRE_TIME,
          });
        });
      }
    },
    [
      customCalendarData,
      customCalendarDataInLocalStorage,
      queryCalendarData,
      setCustomCalendarDataInLocalStorage,
    ],
  );

  return (
    <customCalendarListContext.Provider
      value={{
        customCalendarData,
        setCustomCalendarData,
        refreshCustomCalendarData,
      }}
    >
      {children}
    </customCalendarListContext.Provider>
  );
};
