import t from '@/locales';
import { getEnableCode } from '@/common/globalInfo/appConfig';
import styles from './index.less';
import { TMetric } from '@/typings/metric';

export default function MetricInfo(props: IProps) {
  const { metric } = props;

  return (
    <>
      {getEnableCode() && (
        <div className={styles.item}>
          <span className={styles.label}>
            {t.components.processedMetricCard.code}
          </span>
          <span className={styles.value}>{metric.metricCode || '-'}</span>
        </div>
      )}
      <div className={styles.item}>
        <span className={styles.label}>
          {t.components.processedMetricCard.metricName}
        </span>
        <span className={styles.value}>{metric.metricName || '-'}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.label}>
          {t.components.processedMetricCard.description}
        </span>
        <span className={styles.value}>{metric.description || '-'}</span>
      </div>
    </>
  );
}

interface IProps {
  metric: Pick<
    TMetric,
    | 'name'
    | 'code'
    | 'metricCode'
    | 'metricName'
    | 'description'
    | 'originDataType'
  >;
}
