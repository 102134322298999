import { graphql } from 'msw';
import { getUniqId } from '../../src/common/utils';
import _ from 'lodash';
import { getMockResourceImpactReport } from './helper';

export const resource = [
  // 获取删除资源的下游影响
  graphql.query('evaluateDeleteImpactForResource', (req, res, ctx) => {
    return res(
      ctx.data({
        evaluateDeleteImpactForResource: _.times(
          10,
          getMockResourceImpactReport,
        ),
      }),
      ctx.delay(1000),
    );
  }),

  // 获取资源变更的下游影响
  graphql.query('evaluateEditImpactForResource', (req, res, ctx) => {
    return res(
      ctx.data({
        evaluateDeleteImpactForResource: _.times(
          10,
          getMockResourceImpactReport,
        ),
      }),
      ctx.delay(1000),
    );
  }),

  // 获取资源发布草稿的下游影响
  graphql.query('evaluatePublishDraftImpactForResource', (req, res, ctx) => {
    return res(
      ctx.data({
        evaluatePublishDraftImpactForResource: _.times(
          10,
          getMockResourceImpactReport,
        ),
      }),
      ctx.delay(1000),
    );
  }),
];
