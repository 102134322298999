import { ReactComponent as InformationSvg } from '@/assets/icon/Information-Circle-light-line.svg';
import t from '@/locales';
import styles from './index.less';
import {
  EDisplayType,
  ESelectMode,
  ICategoryLikeItem,
  IDataItem,
  IDisabledMap,
  ITreeNode,
} from '../types';
import { EResourceType } from '@/typings/authority';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { InputSearch, Spin, Switch, Tree } from '@aloudata/aloudata-design';
import _ from 'lodash';
import { usePrevious, useSize } from 'ahooks';
import { buildCategoryTree } from '@/pages/Manage/CategoryManage/categoryHelper';
import { ICategory } from '@/typings/category';
import {
  excludeEmptyCategory,
  fillDataItemsIntoListData,
  fillDataItemsIntoTreeData,
  getAllCategoryKeys,
  getAllLeafKeys,
} from './helper';
import classNames from 'classnames';

function TreePicker<TDataItem extends ICategoryLikeItem>(
  props: IProps<TDataItem>,
  ref: React.Ref<ITreePickerRef>,
) {
  const {
    mode = ESelectMode.SINGLE,
    value,
    onChange,
    picked,
    onPick,
    staticDataItems,
    dataItems: propsDataItems,
    size,
    getDisabledMap,
    showSearch = true,
    showUnAvailableSwitch,
    filterCandidate,
    renderItem,
    getKey,
    queryDataList,
    iconSize,
    categories = [],
    loading,
    hideLocalStorageKey,
    hideEmptyCategory = !!propsDataItems,
    noPadding = false,
    displayType = EDisplayType.TREE,
  } = props;

  // 过滤关键词
  const [keyword, setKeyword] = useState('');

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  useImperativeHandle(ref, () => ({
    collapseAll: () => {
      setExpandedKeys([]);
    },
  }));

  // 已获取到的候选数据
  const {
    dataItems,
    onSearch,
    onLoadDataItemsInCategory,
    loadedKeys,
    isSearchLoading,
  } = useGetDataItems({
    dataItems: propsDataItems,
    queryDataList,
    getKey,
    staticDataItems,
  });

  // 是否开启不可分析过滤
  const { isHideUnAvailable, toggleUnAvailable } =
    useToggleUnAvailable(hideLocalStorageKey);

  const isSearchMode = useMemo(() => !!_.trim(keyword), [keyword]);

  const treeAreaRef = useRef<HTMLDivElement>(null);

  const treeSize = useSize(treeAreaRef);

  const disabledMap = useMemo(() => getDisabledMap?.() || {}, [getDisabledMap]);

  const filteredList = useMemo(() => {
    let list = dataItems;
    // 根据关键词过滤
    if (typeof filterCandidate === 'function') {
      list = _.filter(list, (item) => filterCandidate(item, keyword));
    }
    let listWithDisabled = _.map(list, (item) => {
      const disabledReason = disabledMap[getKey(item)] || '';
      return {
        data: item,
        disabled: !!disabledReason,
        disabledReason,
      };
    });
    if (isHideUnAvailable) {
      // 根据可用性排序，可用的在前面
      listWithDisabled = _.filter(listWithDisabled, (item) => !item.disabled);
    } else {
      listWithDisabled = _.sortBy(listWithDisabled, (item) => item.disabled);
    }
    return listWithDisabled;
  }, [
    dataItems,
    getKey,
    filterCandidate,
    disabledMap,
    keyword,
    isHideUnAvailable,
  ]);

  const treeData = useMemo(() => {
    const categoryTree = buildCategoryTree(categories);
    if (!_.size(categoryTree)) {
      return [];
    }
    let tree: ITreeNode<TDataItem>[] | undefined;
    if (displayType === EDisplayType.LIST) {
      if (!dataItems) {
        console.error('dataItems is required when displayType is LIST');
        return [];
      }
      tree = fillDataItemsIntoListData<TDataItem>(
        filteredList,
        getKey,
        _.keys(disabledMap),
      );
    } else {
      tree = fillDataItemsIntoTreeData<TDataItem>(
        !!propsDataItems, // 是否异步加载
        filteredList,
        categoryTree,
        iconSize,
        getKey,
        _.keys(disabledMap),
      );
      if (hideEmptyCategory || _.trim(keyword)) {
        // 在搜索模式下，排除空的类目
        tree = excludeEmptyCategory(tree);
      }
    }
    return tree;
  }, [
    filteredList,
    categories,
    iconSize,
    disabledMap,
    keyword,
    propsDataItems,
    getKey,
    hideEmptyCategory,
    displayType,
    dataItems,
  ]);

  const renderTreeNode = useCallback(
    (treeNode: ITreeNode<TDataItem>) => {
      if (treeNode.isCategory) {
        return treeNode.title;
      }
      return renderItem(treeNode.data as IDataItem<TDataItem>);
    },
    [renderItem],
  );

  const onCheckAsyncLeafNode = useCallback(
    (
      ids: string[],
      info: {
        checked: boolean;
      },
    ) => {
      // 在非全量情况下，ids 不是所有选中的指标的 id，而是已获取了数据的部分类目下的指标 id 列表
      const dataItemsByIds = ids
        .map((id) => dataItems.find((item) => getKey(item) === id))
        .filter((item): item is TDataItem => !!item);
      let newPicked: TDataItem[] = [];
      if (info.checked) {
        // 选中叶子节点
        newPicked = _.uniqBy(
          [...(picked || []), ...dataItemsByIds],
          (item: TDataItem) => getKey(item),
        );
      } else {
        // 反选，即取消选中叶子节点，从现有的选中列表中移除当前叶子节点
        newPicked = _.filter(picked, (item) => {
          const key = getKey(item);
          return !ids.includes(key);
        });
      }
      onPick?.(newPicked);
    },
    [dataItems, getKey, onPick, picked],
  );

  const previousLoadingRef = useRef(loading);

  useEffect(() => {
    if (
      mode === ESelectMode.MULTIPLE_CHECK &&
      !!propsDataItems &&
      !!previousLoadingRef.current &&
      !loading
    ) {
      // 全量数据情况下，展开所有类目
      // 这里的判断耦合了逻辑，loading 是在加载类目信息
      const allCategoryKeys = getAllCategoryKeys(treeData);
      setExpandedKeys(allCategoryKeys);
      previousLoadingRef.current = loading;
    }
  }, [mode, loading, treeData, previousLoadingRef, propsDataItems]);

  // 搜索模式下，展开所有类目
  const previousIsSearchMode = usePrevious(isSearchMode);
  useEffect(() => {
    if (!previousIsSearchMode && isSearchMode) {
      const allCategoryKeys = getAllCategoryKeys(treeData);
      setExpandedKeys(allCategoryKeys);
    }
  }, [isSearchMode, treeData, previousIsSearchMode]);

  const onExpandCategory = useCallback((keys: Array<React.Key>) => {
    setExpandedKeys(keys as string[]);
  }, []);

  const treeNode = useMemo(() => {
    if (loading || isSearchLoading) {
      return (
        <div className={styles.loading}>
          <Spin />
        </div>
      );
    }
    if (treeData.length === 0) {
      return (
        <div className={styles.empty}>
          <div className={styles.title}>
            {t.common.metricPicker.empty.title}
          </div>
          <div className={styles.desc}>{t.common.metricPicker.empty.desc}</div>
        </div>
      );
    }
    return (
      <Tree.DirectoryTree<ITreeNode<TDataItem>>
        checkable={mode !== ESelectMode.SINGLE}
        checkedKeys={picked?.map((item) => getKey(item))}
        onCheck={(newCheckedKeys, e) => {
          const { checked, node: currNode } = e;
          // 受影响的叶子节点
          let leafKeys: string[] = [];
          if (currNode.isCategory) {
            leafKeys = getAllLeafKeys(currNode);
          } else {
            leafKeys = [currNode.key];
          }
          onCheckAsyncLeafNode(leafKeys as string[], {
            checked,
          });
        }}
        size={size}
        treeData={treeData}
        selectable={mode === ESelectMode.SINGLE}
        selectedKeys={value ? [getKey(value)] : []}
        loadedKeys={loadedKeys}
        loadData={({ key }) => {
          return onLoadDataItemsInCategory(key as string);
        }}
        onClick={(e, data) => {
          if (mode !== ESelectMode.SINGLE) {
            return;
          }
          const treeItemData = data as unknown as ITreeNode<TDataItem>;
          if (treeItemData.isCategory === true) {
            return;
          }
          const dataItem = treeItemData.data as IDataItem<TDataItem>;
          if (dataItem.disabled) {
            return;
          }
          onChange?.(dataItem.data);
        }}
        expandedKeys={expandedKeys}
        onExpand={onExpandCategory}
        expandAction={mode !== ESelectMode.SINGLE ? false : 'click'}
        height={treeSize?.height}
        showIcon
        titleRender={renderTreeNode}
      />
    );
  }, [
    treeData,
    expandedKeys,
    mode,
    onChange,
    onCheckAsyncLeafNode,
    onExpandCategory,
    picked,
    renderTreeNode,
    size,
    treeSize?.height,
    value,
    loadedKeys,
    onLoadDataItemsInCategory,
    loading,
    isSearchLoading,
    getKey,
  ]);

  return (
    <div
      className={classNames(styles.metricTreePicker, {
        [styles.small]: size === 'small',
        [styles.noPadding]: noPadding === true,
      })}
    >
      {showSearch && (
        <div className={styles.search}>
          <InputSearch
            size={size || 'middle'}
            onSearch={(val) => {
              setKeyword(val);
              if (_.trim(val)) {
                onSearch(val);
              } else {
                setExpandedKeys([]);
              }
            }}
            inputMode
          />
        </div>
      )}
      {showUnAvailableSwitch && (
        <div className={styles.hideUnAvailable}>
          <div className={styles.tip}>
            <InformationSvg size={16} color="#9CA3AF" />
            {t.common.metricPicker.unAvailableTip}
          </div>
          <div className={styles.switch}>
            <Switch
              size="small"
              checked={isHideUnAvailable}
              onChange={toggleUnAvailable}
            />
          </div>
        </div>
      )}
      <div className={styles.treeWrapper}>
        <div className={styles.tree} ref={treeAreaRef}>
          {treeNode}
        </div>
      </div>
    </div>
  );
}

export interface IProps<TDataItem extends ICategoryLikeItem> {
  staticDataItems?: TDataItem[]; // 静态数据项，如“指标日期”，不需要通过 queryDataList 加载
  dataItems?: TDataItem[];
  value?: TDataItem; // 单选选中的指标
  onChange?: (dataItem: TDataItem) => void; // 单选选中的回调
  picked?: TDataItem[]; // 选中的指标列表
  onPick?: (dataItems: TDataItem[]) => void; // 批量勾选
  size?: 'small';
  showDataType?: boolean; // 是否显示数据类型，否则显示指标类型，在集中授权需要渲染成指标类型
  getDisabledMap?: () => IDisabledMap; // 禁用的 map
  renderItem: (dataItem: IDataItem<TDataItem>) => React.ReactNode; // 用于自定义支持拖拽的节点
  showSearch?: boolean; // 显示搜索框
  showUnAvailableSwitch?: boolean; // 显示不可用开关
  // 查询候选项时的代持资源信息，存在代持的情况下，不要根据当前用户的 canUsage 权限过滤候选列表
  queryListMeta?: {
    resourceType: EResourceType;
    resourceId: string;
  };
  mode?: ESelectMode; // 选择模式
  displayType?: EDisplayType; // 展示类型
  filterCandidate: (item: TDataItem, keyword: string) => boolean; // 过滤候选列表，如：指标详情页排除当前指标自身
  getKey: (item: TDataItem) => string; // 获取数据项的唯一标识
  queryDataList: (params: IReqParams) => Promise<TDataItem[]>;
  iconSize: number;
  categories: ICategory[];
  loading: boolean;
  hideLocalStorageKey: string;
  hideEmptyCategory?: boolean; // 是否隐藏空类目
  noPadding?: boolean; // 是否不显示左右内边距
}

function useGetDataItems<TDataItem>(opt: {
  dataItems?: TDataItem[];
  queryDataList: (params: IReqParams) => Promise<TDataItem[]>;
  getKey: (item: TDataItem) => string;
  staticDataItems?: TDataItem[];
}) {
  const {
    dataItems: propsDataItems,
    queryDataList,
    getKey,
    staticDataItems,
  } = opt;
  const [loadedKeys, setLoadedKeys] = useState<string[]>([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  // 已加载到的列表
  const [dataItems, setDataItems] = useState<TDataItem[]>(propsDataItems || []);

  const onLoadDataItemsInCategory = useCallback(
    (categoryId: string) => {
      return new Promise<void>((resolve, reject) => {
        if (propsDataItems) {
          resolve();
          return;
        }
        return queryDataList({
          categoryId,
        })
          .then((res) => {
            // 追加到已有的指标列表中，并去重
            setDataItems((prev) =>
              _.uniqBy([...prev, ...(res || [])], (item) => {
                return getKey(item);
              }),
            );
            setLoadedKeys((prev) => _.uniq([...prev, categoryId]));
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    [propsDataItems, queryDataList, getKey],
  );

  const queryId = useRef<number>(0);
  const onSearch = useCallback(
    (keyword: string) => {
      if (propsDataItems) {
        return;
      }
      // 搜索时，查询匹配该关键词的全量指标，以便在树形结构中回填
      queryId.current += 1;
      const currentQueryId = queryId.current;
      setIsSearchLoading(true);
      queryDataList({
        keyword,
      })
        .then((res) => {
          if (queryId.current !== currentQueryId) {
            // 如果当前查询的 id 和上次不一样，说明有新的搜索请求进来了，则不更新指标列表
            return;
          }
          // 由于是全量搜索到符合条件的指标列表，所以直接覆盖
          setDataItems(res);
        })
        .finally(() => {
          // 理论上可能会有时序问题，提前结束 loading 状态。实际测试没有遇到明显问题，先这样简单处理
          setIsSearchLoading(false);
        });
    },
    [propsDataItems, queryDataList],
  );

  const allDataItems = useMemo(() => {
    return [...(staticDataItems || []), ...(propsDataItems || dataItems || [])];
  }, [dataItems, staticDataItems, propsDataItems]);

  return {
    onLoadDataItemsInCategory,
    onSearch,
    loadedKeys,
    dataItems: allDataItems,
    isSearchLoading,
  };
}

function useToggleUnAvailable(hideLocalStorageKey: string) {
  const [isHideUnAvailable, setIsHideUnAvailable] = useState(
    localStorage.getItem(hideLocalStorageKey) === 'true',
  );

  const toggleUnAvailable = useCallback(() => {
    setIsHideUnAvailable((prev) => !prev);
    localStorage.setItem(hideLocalStorageKey, String(!isHideUnAvailable));
  }, [isHideUnAvailable, hideLocalStorageKey]);

  return {
    isHideUnAvailable,
    toggleUnAvailable,
  };
}

export default forwardRef(TreePicker) as <TDataItem extends ICategoryLikeItem>(
  props: IProps<TDataItem> & React.RefAttributes<ITreePickerRef>,
) => ReturnType<typeof TreePicker>;

export interface ITreePickerRef {
  collapseAll: () => void;
}

export interface IReqParams {
  categoryId?: string;
  keyword?: string;
}
