import {
  DocumentNode,
  useQuery,
  useLazyQuery,
  useMutation as ApolloUseMutation,
  type OperationVariables,
  DefaultContext,
  ApolloError,
} from '@apollo/client';
import { removeUndefinedDeeplyForObject } from '.';
import { TCommonData } from './index';
import { useCallback } from 'react';

export function useGqlQuery<TResData, TReqParams extends OperationVariables>(
  schema: DocumentNode,
  reqParams?: TReqParams,
  options: IUseGqlQueryOptions<TResData> = {},
) {
  const { data, loading, refetch } = useQuery<TResData, TReqParams>(schema, {
    ...options,
    variables: reqParams,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  return {
    data: data || null,
    loading,
    refetch: useCallback(
      (variables?: Partial<TReqParams> | undefined) => {
        return refetch(variables).catch((res: ApolloError) => {
          throw res.networkError as Error;
        });
      },
      [refetch],
    ),
  };
}

export interface IUseGqlQueryOptions<TData> {
  skip?: boolean; // 是否跳过请求，即不请求
  onCompleted?: (data: TData) => void;
}

export function useGqlLazyQuery<
  TResData,
  TReqParams extends OperationVariables,
>(schema: DocumentNode, options: IUseGqlLazyQueryOptions<TResData> = {}) {
  const [run, { loading, data }] = useLazyQuery<TResData, TReqParams>(schema, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: options.onCompleted,
  });

  const runQuery = useCallback(
    (reqParams: TReqParams, context?: DefaultContext) => {
      return run({
        variables: reqParams,
        context,
      }).then((res) => {
        if (res.error) {
          throw res.error.networkError as Error;
        }
        if (res.data === null) {
          //reject 返回 null 的请求
          console.error(`${res.observable.queryName} return null`);
          return Promise.reject(res.data);
        }
        return res.data!;
      });
    },
    [run],
  );

  return {
    data,
    loading,
    run: runQuery,
  };
}

export interface IUseGqlLazyQueryOptions<TData> {
  onCompleted?: (data: TData) => void;
}

export function useGqlMutation<TResData, TReqParams = unknown>(
  schema: DocumentNode,
  options?: IUseGqlMutationOptions<TResData>,
) {
  const [run, { loading }] = ApolloUseMutation<TResData, TReqParams>(schema, {
    fetchPolicy: 'no-cache',
    onCompleted: options?.onCompleted,
    notifyOnNetworkStatusChange: true,
  });

  return {
    run: useCallback(
      (reqParams: TReqParams) => {
        const reqWithoutUndefined = removeUndefinedDeeplyForObject(
          reqParams as TCommonData,
        );

        return run({
          variables: reqWithoutUndefined as TReqParams,
        }).catch((res: ApolloError) => {
          throw res.networkError as Error;
        });
      },
      [run],
    ),
    loading,
  };
}

interface IUseGqlMutationOptions<TData> {
  onCompleted?: (data: TData) => void;
}
