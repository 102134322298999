import { AldSelect } from '@aloudata/aloudata-design';
import getGranularityMenu from './getGranularityMenu';
import { getGranularityDisplayName } from '../helper';
import { IDateTag } from '@/typings/periodLimit';
import { EDateGranularityType } from '@/typings';
import _ from 'lodash';

interface IProps {
  supportCustomCalendar?: boolean;
  renderOptions?: (granularity: string[]) => string[];
  value?: {
    granularity: string;
    dateTag?: string;
  };
  onChange?: (value: string, dateTag?: string) => void;
  className?: string;
  disabled?: boolean;
  dateTags?: IDateTag[];
  width?: number;
}

export default function GranularityPicker({
  supportCustomCalendar,
  renderOptions,
  value = { granularity: '' },
  onChange: propsOnChange,
  className,
  disabled,
  dateTags,
  width,
}: IProps) {
  const { granularity: granularityName, dateTag } = value;

  return (
    <AldSelect
      labelInValue
      value={{
        label: getSelectDisplayName(granularityName, dateTag, dateTags),
        value: null,
      }}
      className={className}
      disabled={disabled}
      width={width}
      menu={{
        selectedKeys: [granularityName],
        items: getGranularityMenu({
          supportCustomCalendar,
          renderOptions,
          dateTags,
          onClick: propsOnChange,
        }),
      }}
    />
  );
}

export function getSelectDisplayName(
  granularity: string,
  dateTag?: string,
  dateTags?: IDateTag[],
) {
  if (granularity === EDateGranularityType.DAY && dateTag) {
    return _.find(dateTags, { exprCode: dateTag })?.name || dateTag;
  }
  return getGranularityDisplayName(granularity);
}
