import {
  ECommonFilterType,
  ECommonFilterValueType,
  EDateFilterType,
  EDateFilterValueType,
  EDateRangeType,
  EJSONFilterType,
  ENumberFilterType,
  ENumberFilterValueType,
  ENumberRangeOperator,
  TBooleanFilter,
  TDateFilter,
  TDateFixedValue,
  TDateRangeValues,
  TFilterSelectValues,
  TJSONFilter,
  TNumberFilter,
  TTextFilter,
} from '@/common/domain/filter/Filter/filter';
import { EColumnDataType } from '@/typings';
import {
  ESpecialColumnValue,
  specialColumnValueMap,
} from '@/common/domain/filter/FilterDependencyValuesSelect/helper';

const initValue: TDateFixedValue = {
  value: null,
  type: EDateFilterValueType.FIXED,
};

export const initValues: TDateRangeValues = {
  type: EDateRangeType.RANGE,
  includeToday: true,
  values: {
    min: initValue,
    max: initValue,
  },
};

function getDateDefaultFilter(): TDateFilter {
  return {
    type: EDateFilterType.RANGE,
    includeNull: false,
    values: initValues,
  };
}

function getTextDefaultFilter(): TTextFilter {
  return {
    type: ECommonFilterType.EQUAL,
    values: [],
  };
}

function getJsonDefaultFilter(): TJSONFilter {
  return {
    type: EJSONFilterType.CONTAIN,
    values: '',
  };
}

function getNumberDefaultFilter(): TNumberFilter {
  return {
    type: ENumberFilterType.RANGE,
    values: {
      low: {
        type: ENumberFilterValueType.NUMBER,
        operator: ENumberRangeOperator.LESSOREQUAL,
        value: undefined,
      },
      high: {
        type: ENumberFilterValueType.NUMBER,
        operator: ENumberRangeOperator.LESSOREQUAL,
        value: undefined,
      },
    },
  };
}

function getBooleanDefaultFilter(): TBooleanFilter {
  return {
    type: ECommonFilterType.EQUAL,
    values: [],
  };
}

export function getDefaultFilter(type: EColumnDataType) {
  switch (type) {
    case EColumnDataType.INT:
    case EColumnDataType.DECIMAL:
    case EColumnDataType.DOUBLE:
      return getNumberDefaultFilter();
    case EColumnDataType.BOOLEAN:
      return getBooleanDefaultFilter();
    case EColumnDataType.DATE_TIME:
    case EColumnDataType.DATE:
      return getDateDefaultFilter();
    case EColumnDataType.TEXT:
      return getTextDefaultFilter();
    case EColumnDataType.JSON:
      return getJsonDefaultFilter();
    default:
      return getTextDefaultFilter();
  }
}

export function transformValues(values: TFilterSelectValues) {
  const result: string[] = [];
  if (!values) return result;
  values.map((item) => {
    if (item.type === ECommonFilterValueType.NULL) {
      result.push(specialColumnValueMap[ESpecialColumnValue.NULL].value);
    } else if (item.type === ECommonFilterValueType.EMPTY) {
      result.push(specialColumnValueMap[ESpecialColumnValue.EMPTY].value);
    } else {
      result.push(String(item.value));
    }
  });
  return result;
}

export function getFilterSelectKey(key: string, type: ECommonFilterType) {
  return key + type;
}
