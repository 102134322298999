export const role = {
  admin: 'Can Manage',
  owner: 'Owner',
  viewer: 'Viewer',
  analyst: 'Data Administrator',
  usager: 'Can Use',
  revoke: 'Revoke Permission',
};

export const auth = {
  noAuth: 'No Permission',
  applyAuth: 'Request Permission',
  add: 'Add Permission',
  authority: {
    search: 'Search users, user groups',
    default: 'Authorize',
    success: 'Authorization successful',
  },
  notExisted: {
    default: 'Sorry, this resource does not exist',
    des: 'The resource may have been deleted.',
  },
  noPermission: {
    default: 'Sorry, you do not have permission to access this page',
    des: 'Please contact the organization administrator for access.',
  },
  noDatasetPermission: ({ name }: { name: string | number }) =>
    `No Permission: Data owner ${name} does not have access to this dataset, this dimension is not available`,
  list: {
    name: 'Authorized Users/Groups',
    extendTenant: 'Inherited from parent category',
    revoke: 'Revoke',
    columnTitle: {
      owner: 'Owner',
      auth: 'Permission',
    },
    owner: 'Owner',
  },
  goBack: 'Back',
};

export const centralizedAuth = {
  title: 'Centralized Authorization',
  authByUser: 'Authorize by User',
  authByResource: 'Authorize by Resource',
  useAuth: 'Usage Permission',
  rowFilterAuth: 'Row-level Permission',
  resource: 'Resource',
  user: 'User',
  userGroup: 'User Group',
  addUser: 'Add User',
  resourceType: {
    metric: 'Metric',
    dimension: 'Dimension',
    dataset: 'Dataset',
    analysisView: 'Metrics View',
    datasource: 'Data Source',
  },
  accountType: {
    user: 'User',
    userGroup: 'User Group',
  },
  empty: (typeName: string) =>
    `No content on this page, Select a ${typeName} to view`,
  exception: 'An error occurred, please refresh the page and try again',
  operation: 'Actions',
  remove: 'Remove',
  ownerWarning: "Cannot modify owner's permissions",
  selfWarning: 'Cannot modify your own permissions',
  addUserSuccess: 'Added successfully',
  removeUserSuccess: 'Removed successfully',
};

export const roleName = {
  tenant: {
    owner: {
      name: 'Super Administrator',
      desc: '',
    },
    admin: {
      name: 'Platform Administrator',
      desc: '',
    },
    analyst: {
      name: 'Metric Definer',
      desc: '',
    },
    usager: {
      name: 'Metric User',
      desc: '',
    },
  },
  category: {
    owner: {
      name: 'Owner',
      auth: 'Can Manage',
      desc: '',
    },
    admin: {
      name: 'Administrator',
      auth: 'Can Manage',
      desc: 'Can authorize/manage/create/use assets under category',
    },
    creator: {
      name: 'Creator',
      auth: 'Can Create',
      desc: 'Can create/use assets under category',
    },
    usager: {
      name: 'User',
      auth: 'Can Use',
      desc: 'Can use assets under category',
    },
    viewer: {
      name: 'Viewer',
      auth: 'Can View',
      desc: 'Can view assets under category',
    },
  },
  resource: {
    owner: {
      name: 'Owner',
      auth: 'Can Manage',
      desc: '',
    },
    admin: {
      name: 'Administrator',
      auth: 'Can Manage',
      desc: '',
    },
    usager: {
      name: 'User',
      auth: 'Can Use',
      desc: '',
    },
    viewer: {
      name: 'Viewer',
      auth: 'Can View',
      desc: '',
    },
  },
};
