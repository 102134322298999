import { getDefaultMetricTime } from '../../src/pages/AnalysisView/Define/common/constants';
import { getUniqId } from '../../src/common/utils';
import dayjs from 'dayjs';
import { EDateGranularityType } from '../../src/typings';
import { ROOT_NODE_ID } from '../../src/pages/MetricTree/Detail/constants';
import {
  EMetricTreeDirection,
  IAttributionExprNode,
  IMetricTreeDocument,
  ITreeNode,
  ITreeNodeConfig,
  TAttributionExprConfig,
} from '../../src/typings/metricTree';
import {
  dimensionList,
  getSimpleMetricList,
} from '../utils/getSimpleMetricList';
import { IDependencies } from '../../src/typings/analysisView';
import _ from 'lodash';
import { getInitialLogicNode } from '@aloudata/aloudata-design/dist/LogicTree/helper';
import { EMetricTreeNodeType } from '../../src/typings/workbook';
import { getDefaultFilterConfig } from '../../src/common/domain/filter/GlobalFilter/helper';
const metricList = getSimpleMetricList().slice(0, 10);
const relationData = generateRelation(5);

export function getMockDocument() {
  const relation = relationData.relation;
  const exprMap: TAttributionExprConfig = getMockExprMap(relation);

  const mockMetricTreeDocument: IMetricTreeDocument = {
    nodeConfig: relationData.nodeConfig,
    relation: relationData.relation,
    attribution: {
      active: true,
      exprMap: exprMap,
    },
    style: {
      direction: EMetricTreeDirection.HORIZONTAL,
      expandLevel: 2,
      isRedIncrease: true,
    },
    filtersConfig: {
      metricTimeFilter: getDefaultMetricTime(),
      filterConfig: [getDefaultFilterConfig()],
      filters: [],
    },
    comparisonConfig: {
      isShowBtn: true,
      default: {
        comparisonGranularityList: [
          EDateGranularityType.DAY,
          EDateGranularityType.MONTH,
        ],
        customConfig: {
          isSingle: false,
          startDateTime: dayjs().subtract(1, 'day').valueOf(),
          endDateTime: dayjs().valueOf(),
        },
      },
    },
  };
  return mockMetricTreeDocument;
}

function generateRelation(level: number): {
  relation: ITreeNode[];
  nodeConfig: Record<string, ITreeNodeConfig>;
} {
  const nodeConfig: Record<string, ITreeNodeConfig> = {};

  function generateChildren(
    parentId: string | null,
    currentLevel: number,
  ): ITreeNode[] {
    if (currentLevel >= level) {
      return [];
    }

    // 随机生成 0-4 个子节点
    // 第一层一定有2个节点，用于归因公式
    const childCount = currentLevel === 1 ? 2 : _.random(0, 4);
    const children: ITreeNode[] = [];

    for (let i = 0; i < childCount; i++) {
      const nodeId = getUniqId();

      // 创建节点配置
      const metric = metricList[_.random(0, metricList.length - 1)];
      nodeConfig[nodeId] = {
        id: nodeId,
        metric: {
          id: getUniqId(),
          def: {
            code: metric.code,
          },
          name: metric.name,
          displayName: metric.name,
          originDataType: metric.originDataType,
          dateLimit: {
            disabled: false,
            preAggs: [],
            isPreAggCustom: false,
          },
          filters: getInitialLogicNode([]),
          filterDependency: {},
        },
        dimensions: [],
        dimensionMap: {},
        targetManagement: {
          active: false,
        },
      };

      const node: ITreeNode = {
        id: nodeId,
        nodeConfigId: nodeId,
        parentId,
        children: generateChildren(nodeId, currentLevel + 1),
        collapsed: false,
      };

      children.push(node);
    }

    return children;
  }

  // 生成根节点
  const rootId = ROOT_NODE_ID;
  const metric = metricList[_.random(0, metricList.length - 1)];
  nodeConfig[rootId] = {
    id: rootId,
    metric: {
      id: getUniqId(),
      def: {
        code: metric.code,
      },
      name: metric.name,
      displayName: metric.name,
      originDataType: metric.originDataType,
      dateLimit: {
        disabled: false,
        preAggs: [],
        isPreAggCustom: false,
      },
      filters: getInitialLogicNode([]),
      filterDependency: {},
    },
    dimensions: [],
    dimensionMap: {},
    targetManagement: {
      active: false,
    },
  };

  const relation: ITreeNode[] = [
    {
      id: rootId,
      nodeConfigId: rootId,
      parentId: null,
      children: generateChildren(rootId, 1),
      collapsed: false,
    },
  ];

  return {
    relation,
    nodeConfig,
  };
}

export function getExternalDependencies(
  document: IMetricTreeDocument,
): IDependencies {
  const nodeConfig = document.nodeConfig;
  const depMetricCodes: string[] = [];
  const depDimensionNames: string[] = [];
  _.forIn(nodeConfig, (node) => {
    if (node.metric) {
      depMetricCodes.push(node.metric.def.code);
    }
    if (node.metric?.filterDependency) {
      _.forIn(node.metric.filterDependency, (filterDependency) => {
        depDimensionNames.push(filterDependency.def.dimensionName);
      });
    }
    _.forIn(node.dimensionMap, (dimension) => {
      depDimensionNames.push(dimension.def.dimensionName);
    });
  });

  const depMetricList = metricList.filter((metric) =>
    depMetricCodes.includes(metric.code),
  );
  const depDimensionList = dimensionList.filter((dimension) =>
    depDimensionNames.includes(dimension.name),
  );

  return {
    metrics: depMetricList,
    dimensions: depDimensionList,
  };
}

function getMockExprMap(relation: ITreeNode[]): TAttributionExprConfig {
  const exprMap: TAttributionExprConfig = {};

  function traverseTree(node: ITreeNode) {
    if (node.children.length >= 2) {
      const exprNodes: IAttributionExprNode[] = [];

      for (const child of node.children) {
        if (child.children.length >= 2) {
          traverseTree(child);
        }

        exprNodes.push({
          type: EMetricTreeNodeType.METRIC,
          content: child.id,
        });

        exprNodes.push({
          type: EMetricTreeNodeType.OPERATOR,
          content: '+',
        });
      }

      exprNodes.pop(); // 移除最后一个多余的 '+'

      exprMap[node.id] = exprNodes;
    }
  }

  for (const node of relation) {
    traverseTree(node);
  }

  return exprMap;
}
