import t from '@/locales';
import { EDateGranularityType } from '@/typings';
import _ from 'lodash';
const dateTimeMap = {
  [EDateGranularityType.YEAR]: {
    name: t.common.granularity.year,
  },
  [EDateGranularityType.QUARTER]: {
    name: t.common.granularity.quarter,
  },
  [EDateGranularityType.MONTH]: {
    name: t.common.granularity.month,
  },
  [EDateGranularityType.WEEK]: {
    name: t.common.granularity.week,
  },
  [EDateGranularityType.DAY]: {
    name: t.common.granularity.day,
  },
  [EDateGranularityType.HOUR]: {
    name: t.common.granularity.hour,
  },
  [EDateGranularityType.MINUTE]: {
    name: t.common.granularity.minute,
  },
  [EDateGranularityType.SECOND]: {
    name: t.common.granularity.second,
  },
};
export default dateTimeMap;
export const dateFormatMap = {
  [EDateGranularityType.YEAR]: 'YYYY',
  [EDateGranularityType.QUARTER]: 'YYYY-MM',
  [EDateGranularityType.MONTH]: 'YYYY-MM',
  [EDateGranularityType.WEEK]: 'YYYY-MM-DD',
  [EDateGranularityType.DAY]: 'YYYY-MM-DD',
  [EDateGranularityType.HOUR]: 'YYYY-MM-DD HH',
  [EDateGranularityType.MINUTE]: 'YYYY-MM-DD HH:mm',
  [EDateGranularityType.SECOND]: 'YYYY-MM-DD HH:mm:ss',
};

export const granularityAscSort = [
  EDateGranularityType.SECOND,
  EDateGranularityType.MINUTE,
  EDateGranularityType.HOUR,
  EDateGranularityType.DAY,
  EDateGranularityType.WEEK,
  EDateGranularityType.MONTH,
  EDateGranularityType.QUARTER,
  EDateGranularityType.YEAR,
];

export const granularityDescSort = _.reverse([...granularityAscSort]);
