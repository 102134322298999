import * as analysisView from './resources/analysisView/zh';
import * as authority from './resources/authority/zh';
import * as codeEditor from './resources/codeEditor/zh';
import * as common from './resources/common/zh';
import * as components from './resources/components/zh';
import * as dataset from './resources/dataset/zh';
import * as datasource from './resources/datasource/zh';
import * as dimension from './resources/dimension/zh';
import * as filter from './resources/filter/zh';
import * as formula from './resources/formula/zh';
import * as home from './resources/home/zh';
import * as index from './resources/index/zh';
import * as layout from './resources/layout/zh';
import * as lineage from './resources/lineage/zh';
import * as login from './resources/login/zh';
import * as manage from './resources/manage/zh';
import * as metric from './resources/metric/zh';
import * as metricCatalog from './resources/metricCatalog/zh';
import * as workbook from './resources/workbook/zh';
import * as property from './resources/property/zh';
import * as periodLimit from './resources/periodLimit/zh';
import * as category from './resources/category/zh';
import * as monitor from './resources/monitor/zh';
import * as approval from './resources/approval/zh';
import * as accelerate from './resources/accelerate/zh';
import * as metricTree from './resources/metricTree/zh';

export default {
  accelerate,
  analysisView,
  authority,
  codeEditor,
  common,
  components,
  dataset,
  datasource,
  dimension,
  filter,
  formula,
  home,
  index,
  layout,
  lineage,
  login,
  manage,
  metric,
  metricCatalog,
  workbook,
  property,
  periodLimit,
  category,
  monitor,
  approval,
  metricTree,
};
