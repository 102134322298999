import { AldSelect } from '@aloudata/aloudata-design';
import { type IProps as IMetricTreePickerProps } from '../MetricTreePicker';
import MetricPickerDropdown from '../Dropdown';
import { useMemo } from 'react';
import ColumnDataTypeIcon from '@/common/ui/ColumnDataTypeIcon';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import { EOriginDataType } from '@/typings';
import { DATATYPE_COLOR } from '../constants';
import { ISelectProps } from '@aloudata/aloudata-design/dist/AldSelect/interface';

/**
 * 指标单选组件
 */
export default function MetricSelect(props: IProps) {
  const { value, selectProps = {}, size, disabled, ...otherProps } = props;

  const selectValue = useMemo(() => {
    if (value) {
      return {
        key: value.code,
        label: value.name,
      };
    }
    return undefined;
  }, [value]);

  const SMALL_ICON_SIZE = 16;
  const MIDDLE_ICON_SIZE = 20;
  const iconSize =
    selectProps.size === 'small' ? SMALL_ICON_SIZE : MIDDLE_ICON_SIZE;

  return (
    <MetricPickerDropdown
      {...otherProps}
      value={value}
      size={size}
      disabled={disabled}
    >
      <AldSelect
        {...selectProps}
        open={false}
        value={selectValue}
        prefix={
          value ? (
            <ColumnDataTypeIcon
              size={iconSize}
              dataType={
                originDataType2DataTypeMap[
                  value?.originDataType || EOriginDataType.VARCHAR
                ]
              }
              color={DATATYPE_COLOR}
            />
          ) : undefined
        }
        labelInValue
        disabled={disabled}
      />
    </MetricPickerDropdown>
  );
}

interface IProps
  extends Omit<IMetricTreePickerProps, 'picked' | 'onPick' | 'renderNode'> {
  selectProps?: ISelectProps;
  disabled?: boolean;
}
