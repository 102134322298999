import { Modal, ModalProps } from '@aloudata/aloudata-design';
import styles from './index.less';
import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { getUniqId } from '@/common/utils';
import t from '@/locales';

export default function LargeModal(props: ModalProps) {
  const { children, open, className, ...rest } = props;

  const uniqClassName = useMemo(() => {
    return getUniqId();
  }, []);
  useEffect(() => {
    const modalBody = document.querySelector(
      `.${uniqClassName}.${styles.modalContent} .ant-modal-body`,
    );
    if (!open) return;
    if (!modalBody) return;
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { height } = entry.contentRect;
        modalBody.setAttribute(
          'style',
          `--modal-body-height-in-large-modal: ${height}px`,
        );
      }
    });

    resizeObserver.observe(modalBody);
    return () => {
      resizeObserver.disconnect();
    };
  }, [open, uniqClassName]);
  return (
    <Modal
      {...rest}
      open={open}
      paddingLess
      // 不能改为true，否则resizeObserver监听不到元素
      destroyOnClose={false}
      width={'calc(100vw - 160px)'}
      style={{
        minWidth: 1280,
        maxWidth: 1680,
        padding: 0,
        height: 'calc(100vh - 48px)',
        maxHeight: 900,
        minHeight: 640,
      }}
      maskClosable={false}
      className={classNames(className, uniqClassName, styles.modalContent)}
      wrapClassName={styles.modalWrapper}
      okText={t.common.modal.save}
    >
      {children}
    </Modal>
  );
}
