import { EResourceType } from '@/typings/authority';
import { ISimpleDataset, TDatasetRelationRequest } from '@/typings/dataset';
import { ISimpleDimension } from '@/typings/dimension';
import { TMetric } from '@/typings/metric';
import { TDefineMetric, TDefineMetricDraft } from '../metric/types';
import { TDefineDimensionDraft } from '../dimension/type';
import { IDatasetRelationResponse, IServerDataset } from '../dataset/types';
import { IUser } from '@/typings';

export enum EResourceImpactType {
  ERROR = 'ERROR',
  WARN = 'WARN',
}

export enum EImpactResourceType {
  METRIC = 'METRIC',
  DIMENSION = 'DIMENSION',
  DATASET = 'DATASET',
  DATASET_RELATION = 'DATASET_RELATION',
}

export interface IBaseResourceImpactReport {
  curResourceId: string;
  curResourceDisplayName: string;
  curResourceType: Omit<EImpactResourceType, 'DATASET_RELATION'>;
  childResources: IResourceImpactResponse[];
}

export interface IMetricResourceImpactReport extends IBaseResourceImpactReport {
  curResourceType: EImpactResourceType.METRIC;
  curResourceDetail: TMetric;
}

export interface IDimensionResourceImpactReport
  extends IBaseResourceImpactReport {
  curResourceType: EImpactResourceType.DIMENSION;
  curResourceDetail: ISimpleDimension;
}

export interface IDataSetResourceImpactReport
  extends IBaseResourceImpactReport {
  curResourceType: EImpactResourceType.DATASET;
  curResourceDetail: ISimpleDataset;
}

export interface IDataSetRelationResourceImpactReport
  extends IBaseResourceImpactReport {
  curResourceType: EImpactResourceType.DATASET_RELATION;
  curResourceDetail: IDatasetRelationResponse;
}

export type TResourceImpactReport =
  | IMetricResourceImpactReport
  | IDimensionResourceImpactReport
  | IDataSetResourceImpactReport
  | IDataSetRelationResourceImpactReport;

export interface IResourceImpactResponse {
  resourceId: string;
  displayName: string;
  name: string;
  owner?: IUser;
  resourceType: EResourceType;
  message: string;
  impactType: EResourceImpactType;
}

// 获取编辑资源影响的返回
export interface IMetricEditImpactRequest {
  resourceType: EImpactResourceType.METRIC;
  resource: TDefineMetric | TDefineMetricDraft;
}

export interface IDimensionEditImpactRequest {
  resourceType: EImpactResourceType.DIMENSION;
  resource: TDefineDimensionDraft;
}

export interface IDataSetEditImpactRequest {
  resourceType: EImpactResourceType.DATASET;
  resource: IServerDataset;
}
export interface IDataSetRelationEditImpactRequest {
  resourceType: EImpactResourceType.DATASET_RELATION;
  resource: TDatasetRelationRequest;
}

export type TEditImpactRequest =
  | IMetricEditImpactRequest
  | IDimensionEditImpactRequest
  | IDataSetEditImpactRequest
  | IDataSetRelationEditImpactRequest;
