import { EDateGranularityType } from '@/typings';
import { ICalendarNode } from '@/typings/customCalendar';
import {
  customCalendarTree,
  customGranularityList,
  customCalendarList,
} from '@/common/globalInfo/appConfig';
import dateTimeMap, {
  granularityAscSort,
  granularityDescSort,
} from '@/constants/dateTime';
import _ from 'lodash';

export function isRealGranularity(val: string): val is EDateGranularityType {
  return Object.values(EDateGranularityType).includes(
    val.toUpperCase() as EDateGranularityType,
  );
}

export enum ECompareGranularityResult {
  CANT_COMPARE = 'CANT_COMPARE',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN = 'GREATER_THAN',
  EQUAL = 'EQUAL',
}

export const looseCompareGranularity = (
  name1: string,
  name2: string,
): ECompareGranularityResult => {
  const granularity1 = getRealGranularity(name1);
  const granularity2 = getRealGranularity(name2);
  const index1 = granularityAscSort.indexOf(granularity1);
  const index2 = granularityAscSort.indexOf(granularity2);
  if (index1 === index2) return ECompareGranularityResult.EQUAL;
  if (index1 < index2) return ECompareGranularityResult.LESS_THAN;
  return ECompareGranularityResult.GREATER_THAN;
};

export const strictCompareGranularity = (
  name1: string,
  name2: string,
): ECompareGranularityResult => {
  // 获取所有包含 name1 的路径
  const paths1 = getNodePaths(customCalendarTree, name1);
  // 获取所有包含 name2 的路径
  const paths2 = getNodePaths(customCalendarTree, name2);

  // 找到同时包含两个节点的路径
  const commonPath = paths1.find((path1) =>
    paths2.some((path2) =>
      _.isEqual(
        path1.slice(
          0,
          Math.max(path1.indexOf(name1), path1.indexOf(name2)) + 1,
        ),
        path2.slice(
          0,
          Math.max(path2.indexOf(name1), path2.indexOf(name2)) + 1,
        ),
      ),
    ),
  );

  // 如果没有共同路径，则无法比较
  if (!commonPath) {
    return ECompareGranularityResult.CANT_COMPARE;
  }

  const index1 = commonPath.indexOf(name1);
  const index2 = commonPath.indexOf(name2);

  if (index1 === index2) return ECompareGranularityResult.EQUAL;
  if (index1 < index2) return ECompareGranularityResult.LESS_THAN;
  return ECompareGranularityResult.GREATER_THAN;
};

export const getAllPaths = (): string[][] => {
  const allPaths: string[][] = [];

  const traverse = (node: ICalendarNode, currentPath: string[]) => {
    const newPath = [...currentPath, node.name];

    // 如果是叶子节点（没有子节点），则保存该路径
    if (!node.children || node.children.length === 0) {
      allPaths.push(newPath);
      return;
    }

    // 继续遍历子节点
    for (const child of node.children) {
      traverse(child, newPath);
    }
  };

  traverse(customCalendarTree, []);
  return allPaths;
};

export const findSmallerOrEqualGranularities = (
  targetName: string,
): string[] => {
  const path: string[] = [];

  const traverse = (node: ICalendarNode): boolean => {
    // 如果找到目标节点
    if (node.name === targetName) {
      path.unshift(node.name);
      return true;
    }

    // 遍历子节点
    for (const child of node.children || []) {
      if (traverse(child)) {
        path.unshift(node.name);
        return true;
      }
    }

    return false;
  };

  traverse(customCalendarTree);
  return path;
};

export const getNodePaths = (
  node: ICalendarNode,
  targetName: string,
): string[][] => {
  const paths: string[][] = [];

  const traverse = (currentNode: ICalendarNode, currentPath: string[]) => {
    // 将当前节点加入路径
    const newPath = [...currentPath, currentNode.name];

    // 如果是叶子节点，并且路径中包含目标节点，则保存该路径
    if (!currentNode.children || currentNode.children.length === 0) {
      if (newPath.includes(targetName)) {
        paths.push(newPath);
      }
      return;
    }

    // 继续遍历子节点
    for (const child of currentNode.children) {
      traverse(child, newPath);
    }
  };

  traverse(node, []);
  return paths;
};

export const findLargerGranularities = (targetName: string): string[] => {
  const allPaths = getAllPaths();

  const targetPaths = allPaths.filter((path) => path.includes(targetName));

  if (targetPaths.length === 0) return [];

  const result = new Set<string>();
  targetPaths.forEach((path) => {
    const targetIndex = path.indexOf(targetName);
    const largerGranularities = path.slice(targetIndex + 1);
    largerGranularities.forEach((name) => {
      result.add(name);
    });
  });
  return Array.from(result);
};

export const getRealGranularity = (name: string): EDateGranularityType => {
  if (isRealGranularity(name)) {
    return name as EDateGranularityType;
  }
  return (
    customGranularityList.find((item) => item.name === name)?.timeGranularity ||
    EDateGranularityType.DAY
  );
};

export const getCustomCalendarByGranularityName = (name: string) => {
  return customCalendarList.find((customCalendarItem) =>
    customCalendarItem.calendarGranularity.some(
      (granularityItem) => granularityItem.name === name,
    ),
  );
};

export const getGranularityDisplayName = (name: string): string => {
  if (isRealGranularity(name)) {
    return dateTimeMap[name].name;
  }
  return (
    customGranularityList.find((item) => item.name === name)?.displayName ||
    name
  );
};

export const getCustomGranularityNameByGranularity = (
  granularities: EDateGranularityType[],
): string[] => {
  return granularities
    .map((item) => {
      return [
        item,
        ...customGranularityList
          .filter(
            (customGranularity) => customGranularity.timeGranularity === item,
          )
          .map((customGranularity) => customGranularity.name),
      ];
    })
    .flat();
};

export const getAllGranularityNames = (): string[] => {
  const result: string[] = [];
  _.forEach(granularityDescSort, (granularity) => {
    result.push(granularity);
    const matchCustomCalendar = _.filter(customGranularityList, (item) => {
      return item.timeGranularity === granularity;
    });
    matchCustomCalendar.forEach((item) => {
      result.push(item.name);
    });
  });
  return result;
};
