import _ from 'lodash';
import { EDateGranularityType } from '../../src/typings';
import dayjs from 'dayjs';

export const mockCustomCalendarList = [
  {
    id: 't',
    displayName: '交易',
    name: 't',
    calendarGranularity: [
      {
        id: 't_week',
        displayName: '交易周',
        name: 't_week',
        timeGranularity: EDateGranularityType.WEEK,
      },
      {
        id: 't_month',
        displayName: '交易月',
        name: 't_month',
        timeGranularity: EDateGranularityType.MONTH,
      },
      {
        id: 't_quarter',
        displayName: '交易季',
        name: 't_quarter',
        timeGranularity: EDateGranularityType.QUARTER,
      },
      {
        id: 't_year',
        displayName: '交易年',
        name: 't_year',
        timeGranularity: EDateGranularityType.YEAR,
      },
    ],
  },
  {
    id: 'fy',
    displayName: '财系列',
    name: 'fy',
    calendarGranularity: [
      {
        id: 'fy_month',
        displayName: '财月',
        name: 'fy_month',
        timeGranularity: EDateGranularityType.MONTH,
      },
      {
        id: 'fy_quarter',
        displayName: '财季',
        name: 'fy_quarter',
        timeGranularity: EDateGranularityType.QUARTER,
      },
      {
        id: 'fy_year',
        displayName: '财年',
        name: 'fy_year',
        timeGranularity: EDateGranularityType.YEAR,
      },
    ],
  },
];

export const mockCustomCalendarTree = {
  name: EDateGranularityType.SECOND,
  children: [
    {
      name: EDateGranularityType.MINUTE,
      children: [
        {
          name: EDateGranularityType.HOUR,
          children: [
            {
              name: EDateGranularityType.DAY,
              children: [
                {
                  name: EDateGranularityType.WEEK,
                  children: [],
                },
                {
                  name: EDateGranularityType.MONTH,
                  children: [
                    {
                      name: EDateGranularityType.QUARTER,
                      children: [
                        {
                          name: EDateGranularityType.YEAR,
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 't_week',
                  children: [
                    {
                      name: 't_month',
                      children: [
                        {
                          name: 't_quarter',
                          children: [
                            {
                              name: 't_year',
                              children: [],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 'fy_month',
                  children: [
                    {
                      name: 'fy_quarter',
                      children: [
                        {
                          name: 'fy_year',
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const getMockCalendarData = () => {
  const now = dayjs(dayjs().format('YYYY-MM-DD')).valueOf();
  const data = [];
  for (let year = 0; year < 10; year++) {
    for (let quarter = 0; quarter < 4; quarter++) {
      for (let month = 0; month < 12; month++) {
        for (let week = 0; week < 4; week++) {
          data.push({
            date:
              now +
              year * 365 * 24 * 60 * 60 * 1000 +
              quarter * 3 * 30 * 24 * 60 * 60 * 1000 +
              month * 30 * 24 * 60 * 60 * 1000 +
              week * 7 * 24 * 60 * 60 * 1000,
            weekOfMonth: week,
            monthOfQuarter: month,
            quarterOfYear: quarter,
            year: year,
          });
        }
      }
    }
  }

  return data;
};
