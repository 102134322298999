import t from '@/locales';
import { getEnableCode } from '@/common/globalInfo/appConfig';
import styles from './index.less';
import { TDimension } from '@/typings/dimension';

export default function DimensionInfo(props: IProps) {
  const { dimension } = props;

  return (
    <>
      {getEnableCode() && (
        <div className={styles.item}>
          <span className={styles.label}>
            {t.components.processedDimensionCard.code}
          </span>
          <span className={styles.value}>{dimension.dimCode || '-'}</span>
        </div>
      )}
      <div className={styles.item}>
        <span className={styles.label}>
          {t.components.processedDimensionCard.dimName}
        </span>
        <span className={styles.value}>{dimension.dimName || '-'}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.label}>
          {t.components.processedDimensionCard.description}
        </span>
        <span className={styles.value}>{dimension.description || '-'}</span>
      </div>
    </>
  );
}

interface IProps {
  dimension: Pick<
    TDimension,
    | 'name'
    | 'displayName'
    | 'dimName'
    | 'dimCode'
    | 'description'
    | 'originDataType'
  >;
}
