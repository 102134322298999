import { EColumnDataType } from '@/typings';
import { ELooseDataType } from './types';

export const looseDataTypeMap = {
  [EColumnDataType.INT]: ELooseDataType.NUMBER,
  [EColumnDataType.DECIMAL]: ELooseDataType.NUMBER,
  [EColumnDataType.DOUBLE]: ELooseDataType.NUMBER,
  [EColumnDataType.TEXT]: ELooseDataType.TEXT,
  [EColumnDataType.DATE]: ELooseDataType.TIME,
  [EColumnDataType.DATE_TIME]: ELooseDataType.TIME,
  [EColumnDataType.BOOLEAN]: ELooseDataType.BOOLEAN,
  [EColumnDataType.JSON]: ELooseDataType.JSON,
};
