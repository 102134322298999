export const define = {
  label: {
    displayName: 'Display Name',
    name: 'Name',
    description: 'Description',
    isRequired: 'Required',
    propertyType: 'Property Type',
  },
  title: {
    basicInfo: 'Basic Info',
    propertyType: 'Type',
  },
  propertyType: {
    text: 'Short Text',
    longText: 'Long Text',
    select: 'Single Select',
    selectUser: 'Select User',
    selectUserGroup: 'Select User Group',
    base: 'Basic Property',
    business: 'Business Property',
    manage: 'Management Property',
    technology: 'Technical Property',
  },
  select: {
    title: {
      candidate: 'Options',
      default: 'Default Value',
      delete: 'Delete',
    },
    placeholder: 'Enter option',
  },
  message: {
    add: {
      success: 'Added Successfully',
    },
    update: {
      success: 'Saved Successfully',
    },
  },
  validate: {
    empty: (filed: string) => `${filed} cannot be empty`,
    duplicate: (filed: string) => `${filed} cannot be duplicate`,
    invalid:
      'Invalid name, only letters, numbers, and underscores are allowed, and length cannot exceed 50 characters',
  },
  modal: {
    title: {
      metric: 'Metric',
      dimension: 'Dimension',
      property: 'Property',
      edit: 'Edit',
      create: 'Create',
    },
  },
};

export const list = {
  column: {
    displayName: 'Display Name',
    name: 'Name',
    description: 'Description',
    type: 'Type',
    isRequired: 'Required',
    required: 'Required',
    notRequired: 'Optional',
    defaultValue: 'Default Value',
    owner: 'Owner',
    system: 'System Attributes',
    enable: 'Status',
    operation: 'Actions',
  },
  tab: {
    base: 'Basic Attributes',
    business: 'Business Attributes',
    manage: 'Management Attributes',
    technology: 'Technical Attributes',
  },
  total: (total: number, item: string) => `${total} ${item}`,
  title: {
    dimension: 'Dimension Attributes',
    metric: 'Metric Attributes',
  },
  createBtn: {
    dimension: 'Create Dimension Attribute',
    metric: 'Create Metric Attribute',
  },
  editMetricLevelModal: {
    title: 'Edit Metric Attribute',
    chName: 'Display Name',
    options: 'Options',
    success: 'Saved Successfully',
    optionsDesc: 'Order from highest to lowest metric level',
    isDefault: 'Default Value',
    level: 'Level',
    name: 'Name',
    delete: 'Delete',
    nameRepeat: 'Name cannot be duplicate',
    notEmpty: 'Name cannot be empty',
    nameInputPlaceholder: 'Enter name',
  },
  operation: {
    edit: 'Edit',
    delete: 'Delete',
  },
};

export const form = {
  validate: {
    empty: (filed: string) => `${filed} cannot be empty`,
    dimNamePattern:
      'Dimension name can only contain letters, numbers, underscores and cannot use double underscores',
    dimCodePattern:
      'Dimension code can only contain letters, numbers, underscores and cannot use double underscores',
    metricNamePattern:
      'Metric name can only contain letters, numbers, underscores and cannot use double underscores',
    metricCodePattern:
      'Metric code can only contain letters, numbers, underscores and cannot use double underscores',
    repeated: (filed: string) => `${filed} is duplicate`,
  },
  placeholder: {
    memberPicker: (filed: string) => `Select ${filed}`,
    input: 'Enter',
  },
  title: {
    base: 'Basic Property',
    business: 'Business Property',
    manage: 'Management Property',
    technology: 'Technical Property',
  },
  system: {
    label: {
      dimName: 'Name',
      dimCode: 'Code',
      dimDisplayName: 'DisplayName',
      dimDescription: 'Description',
      dimCategoryId: 'Category',
      metricName: 'Name',
      metricCode: 'Code',
      metricDisplayName: 'DisplayName',
      unit: 'Unit',
      metricLevel: 'Level',
      businessOwner: 'Business Owner',
      businessCaliber: 'Business Caliber',
      metricCategory: 'Category',
    },
  },
};

export const detail = {
  title: {
    base: 'Basic Property',
    business: 'Business Property',
    manage: 'Management Property',
    technology: 'Technical Property',
  },
};
