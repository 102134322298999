import { reportEvent } from './reportEvent';
import { setupWorker } from 'msw';
import { dataset } from './dataset';
import { metric } from './metric';
import { query } from './query';
import { platformSettings } from './platformSettings';
import { datasource } from './datasource';
import { user } from './user';
import { analysis } from './analysisView';
import { authority } from './authority';
import { workbook } from './workbook';
import { lineage } from './lineage';
import { dimension } from './dimension';
import { category } from './category';
import { property } from './property';
import { queryAttribution } from './queryAttribution';
import { periodLimit } from './periodLimit';
import { monitor } from './monitor';
import { approval } from './approve';
import { softIntegration } from './softIntegration';
import { accelerate } from './accelerate';
import { metricTree } from './metricTree';
import { customCalendar } from './customCalendar';
import { resource } from './resource';

// This configures a Service Worker with the given request handlers.

export const worker = setupWorker(
  ...reportEvent,
  ...dataset,
  ...metric,
  ...user,
  ...query,
  ...platformSettings,
  ...authority,
  ...datasource,
  ...analysis,
  ...workbook,
  ...category,
  ...lineage,
  ...dimension,
  ...category,
  ...property,
  ...queryAttribution,
  ...periodLimit,
  ...monitor,
  ...approval,
  ...accelerate,
  ...softIntegration,
  ...metricTree,
  ...customCalendar,
  ...resource,
);
// This configures a Service Worker with the given request handlers.
