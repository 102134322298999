import t from '@/locales';
import ListTab from '@/pages/Property/List/ListTabTable';
import {
  Button,
  ITableColumn,
  IconButton,
  InputSearch,
  Switch,
} from '@aloudata/aloudata-design';
import {
  EAssetPropertyType,
  EDataType,
  EEnableStatus,
  EInputType,
  EOwnerType,
  EPropertyType,
  IAssetPropertySelect,
  IOwner,
  TAssetProperty,
} from '@/typings/property';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useDeleteAssetProperty,
  useMoveAssetProperty,
  useQueryAssetPropertyList,
  useUpdateAssetPropertyStatus,
} from '@/services/property';
import { filterByKeyword } from '@/common/utils';
import Define from '../Define';
import { ReactComponent as PlusIcon } from '@/assets/icon/Plus-bold-line.svg';
import { ReactComponent as ArrowUpIcon } from '@/assets/icon/Arrow-Up-light-line-1.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/icon/Arrow-Down-light-line-1.svg';
import { ReactComponent as MoreIcon } from '@/assets/icon/Menu-Kebab-Vertical-light-line.svg';
import User from '@/common/domain/user/User';
import {
  isLongText,
  isSelect,
  isText,
  isUser,
  isUserGroup,
  sortProperties,
} from '../function';
import styles from './index.less';
import EditMetricLevelModal from './EditMetricLevelModal';

export const METRIC_LEVEL = 'metricLevel';

const tabItems = [
  {
    key: EPropertyType.BASE,
    label: t.property.list.tab.base,
  },
  {
    key: EPropertyType.BUSINESS,
    label: t.property.list.tab.business,
  },
  {
    key: EPropertyType.MANAGE,
    label: t.property.list.tab.manage,
  },
  {
    key: EPropertyType.TECHNOLOGY,
    label: t.property.list.tab.technology,
  },
];

interface IProps {
  type: EAssetPropertyType;
}

export default function List({ type }: IProps) {
  const [activeKey, setActiveKey] = useState<EPropertyType>(EPropertyType.BASE);
  const [propertyList, setPropertyList] = useState<TAssetProperty[]>([]);
  const { queryAssetPropertyList, loading: queryAssetPropertyListLoading } =
    useQueryAssetPropertyList();
  const { moveAssetProperty, loading: moveAssetPropertyLoading } =
    useMoveAssetProperty();
  const { updatePropertyStatus, loading: updatePropertyStatusLoading } =
    useUpdateAssetPropertyStatus();
  const { deleteAssetProperty, loading: deleteAssetPropertyLoading } =
    useDeleteAssetProperty();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [createPropertyOpen, setCreatePropertyOpen] = useState(false);
  const [editPropertyProps, setEditPropertyProps] = useState({
    open: false,
    name: '',
  });
  const [editMetricLevelModalInfo, setEditMetricLevelModalInfo] =
    useState<IAssetPropertySelect>();

  const queryList = useCallback(() => {
    queryAssetPropertyList({ type }).then((res) => {
      const data = res.queryAllAssetPropertyDefList;
      setPropertyList(data);
    });
  }, [queryAssetPropertyList, type]);

  const moveProperty = useCallback(
    (name: string, frontDef: string | null) => {
      moveAssetProperty({
        name,
        frontDef,
        type,
      }).then((res) => {
        if (res.data) {
          queryList();
        }
      });
    },
    [moveAssetProperty, queryList, type],
  );

  useEffect(() => {
    queryList();
  }, [queryAssetPropertyList, queryList, type]);

  const data = useMemo(() => {
    const currentProperTypeList = propertyList.filter(
      (item) => item.propertyType === activeKey,
    );
    const sortList: TAssetProperty[] = sortProperties(currentProperTypeList);
    if (searchKeyword) {
      return filterByKeyword(
        sortList,
        [(item) => item.displayName, (item) => item.name],
        searchKeyword,
      );
    }
    return sortList;
  }, [activeKey, propertyList, searchKeyword]);

  const onMoveUp = (record: TAssetProperty, index: number) => () => {
    if (index === 0) {
      return;
    }
    if (index === 1) {
      moveProperty(record.name, null);
    }
    const exchangeIndex = index - 1;
    moveProperty(record.name, data[exchangeIndex - 1].name);
  };

  const onMoveDown = (record: TAssetProperty, index: number) => () => {
    if (index === data.length - 1) {
      return;
    }
    moveProperty(record.name, data[index + 1].name);
  };

  const onChangeEnable =
    (record: TAssetProperty, status: EEnableStatus) => () => {
      const { name } = record;
      updatePropertyStatus({
        name,
        status,
        type,
      }).then((res) => {
        if (res.data) {
          queryList();
        }
      });
    };

  const onDeleteProperty = (name: string) => () => {
    deleteAssetProperty({
      name,
      type,
    }).then((res) => {
      if (res.data) {
        queryList();
      }
    });
  };

  const columns: ITableColumn<TAssetProperty>[] = [
    {
      title: t.property.list.column.displayName,
      dataIndex: 'displayName',
      width: '20%',
      render: (displayName: string) => {
        return (
          <div className={styles.ellipsis} title={displayName}>
            {displayName}
          </div>
        );
      },
    },
    {
      title: t.property.list.column.name,
      dataIndex: 'name',
      width: '20%',
      render: (name: string) => {
        return (
          <div className={styles.ellipsis} title={name}>
            {name}
          </div>
        );
      },
    },
    {
      title: t.property.list.column.description,
      dataIndex: 'description',
      width: '20%',
      ellipsis: true,
      render: (description: string) => {
        return (
          <div title={description} className={styles.ellipsis}>
            {description}
          </div>
        );
      },
    },
    {
      title: t.property.list.column.type,
      dataIndex: '',
      width: 126,
      render: (__: string, record: TAssetProperty) => {
        const { dataInput } = record;
        if (isText(dataInput)) {
          return t.property.define.propertyType.text;
        }
        if (isLongText(dataInput)) {
          return t.property.define.propertyType.longText;
        }
        if (isSelect(dataInput)) {
          return t.property.define.propertyType.select;
        }
        if (isUser(dataInput)) {
          return t.property.define.propertyType.selectUser;
        }
        if (isUserGroup(dataInput)) {
          return t.property.define.propertyType.selectUserGroup;
        }
      },
    },
    {
      title: t.property.list.column.isRequired,
      dataIndex: 'isRequired',
      width: 96,
      render: (isRequired: boolean) => {
        return isRequired
          ? t.property.list.column.required
          : t.property.list.column.notRequired;
      },
    },
    {
      title: t.property.list.column.defaultValue,
      dataIndex: '',
      width: '20%',
      render: (__: string, record: TAssetProperty) => {
        const { dataInput } = record;
        const { dataType, inputType } = dataInput;
        if (inputType === EInputType.SELECT && dataType === EDataType.TEXT) {
          const { options } = dataInput;
          return options.find((item) => item.isDefault)?.value;
        } else {
          return '-';
        }
      },
    },
    {
      title: t.property.list.column.owner,
      dataIndex: 'owner',
      width: 200,
      render: (owner: IOwner) => {
        const { type: ownerType } = owner;
        if (ownerType === EOwnerType.SYSTEM) {
          return t.property.list.column.system;
        } else {
          return <User user={owner.owner} />;
        }
      },
    },
    {
      title: t.property.list.column.enable,
      dataIndex: 'status',
      width: 96,
      render: (status: EEnableStatus, record: TAssetProperty) => {
        if (status === EEnableStatus.FORCE_OPEN) {
          return <Switch checked={true} disabled={true} />;
        } else if (status === EEnableStatus.OPEN) {
          return (
            <Switch
              checked={true}
              onClick={onChangeEnable(record, EEnableStatus.CLOSE)}
            />
          );
        } else {
          return (
            <Switch
              checked={false}
              onClick={onChangeEnable(record, EEnableStatus.OPEN)}
            />
          );
        }
      },
    },
    {
      title: t.property.list.column.operation,
      width: 105,
      fixed: 'right',
      render: (__: string, record: TAssetProperty, index: number) => {
        const { owner } = record;
        return (
          <div className={styles.operation}>
            <ArrowUpIcon
              className={styles.icon}
              onClick={onMoveUp(record, index)}
              size={20}
            />
            <ArrowDownIcon
              className={styles.icon}
              onClick={onMoveDown(record, index)}
              size={20}
            />
            {owner.type !== EOwnerType.SYSTEM && (
              <IconButton.dropdown
                icon={<MoreIcon size={20} />}
                showArrow={false}
                menu={{
                  items: [
                    {
                      label: t.property.list.operation.edit,
                      key: 'edit',
                      onClick: () =>
                        setEditPropertyProps({
                          open: true,
                          name: record.name,
                        }),
                    },
                    {
                      type: 'divider',
                    },
                    {
                      label: t.property.list.operation.delete,
                      key: 'delete',
                      onClick: onDeleteProperty(record.name),
                      danger: true,
                    },
                  ],
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            )}
            {record.name === METRIC_LEVEL && (
              <IconButton.dropdown
                icon={<MoreIcon size={20} />}
                showArrow={false}
                menu={{
                  items: [
                    {
                      label: t.property.list.operation.edit,
                      key: 'edit',
                      onClick: () => {
                        setEditMetricLevelModalInfo(
                          record as IAssetPropertySelect,
                        );
                      },
                    },
                  ],
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            )}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <ListTab
        title={
          type === EAssetPropertyType.DIMENSION
            ? t.property.list.title.dimension
            : t.property.list.title.metric
        }
        operatingArea={
          <Button
            icon={<PlusIcon />}
            onClick={() => setCreatePropertyOpen(true)}
            type="primary"
          >
            {type === EAssetPropertyType.DIMENSION
              ? t.property.list.createBtn.dimension
              : t.property.list.createBtn.metric}
          </Button>
        }
        columns={columns}
        data={data}
        loading={
          queryAssetPropertyListLoading ||
          moveAssetPropertyLoading ||
          updatePropertyStatusLoading ||
          deleteAssetPropertyLoading
        }
        total={t.property.list.total(
          data.length,
          tabItems.find((item) => item.key === activeKey)?.label!,
        )}
        tabItems={tabItems}
        onChange={(e) => {
          setActiveKey(e as EPropertyType);
        }}
        columnSizing
        activeKey={activeKey}
        filterArea={
          <InputSearch
            onSearch={(searchString) => {
              setSearchKeyword(searchString);
            }}
            placeholder={t.common.component.searchPlaceholder}
          />
        }
        scroll={{ y: '100%' }}
        rowKey={(record) => record.name}
      />
      <Define
        open={createPropertyOpen}
        onCancel={() => setCreatePropertyOpen(false)}
        type={type}
        onSuccess={queryList}
        defaultPropertyType={activeKey}
      />
      <Define
        {...editPropertyProps}
        onSuccess={queryList}
        type={type}
        isEdit
        onCancel={() => {
          setEditPropertyProps({
            open: false,
            name: '',
          });
        }}
        defaultPropertyType={activeKey}
      />
      {editMetricLevelModalInfo && (
        <EditMetricLevelModal
          open={true}
          onClose={() => setEditMetricLevelModalInfo(undefined)}
          onConfirm={() => setEditMetricLevelModalInfo(undefined)}
          metricLevelInfo={editMetricLevelModalInfo}
        />
      )}
    </>
  );
}
