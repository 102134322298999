export const sidebar = {
  metricDefine: 'Metric Definition',
};

export const header = {
  menu: {
    updatePassword: 'Modify Password',
    logout: 'Logout',
    language: (test: string) => `Language: ${test}`,
    languageType: {
      zh: '简体中文',
      en: 'English',
    },
  },
  helperCenter: 'Help Center',
};

export const changePassword = {
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm Password',
  placeholder: 'Enter',
};

export const personalModal = {
  title: 'Personal Center',
  account: 'Username',
  avatar: 'Avatar',
  copySuccess: 'Copy successful',
  nickname: 'Nickname',
  password: {
    default: 'Password',
    change: 'Modify',
  },
  phone: 'Phone Number',
  email: 'Email',
  error: {
    nickname: 'Nickname length cannot be empty',
  },
};
