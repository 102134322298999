import FilterDependencyValuesSelect from '@/common/domain/filter/FilterDependencyValuesSelect';
import { Condition } from '../../common/Condition';
import { EDependencyType, TFilter } from '../../types';
import { TDimension } from '@/typings/dimension';
import DimensionSelect from '@/common/domain/dimension/Picker/Select';
import { EFilterType as EFilterComponentType } from '@/common/domain/filter/Filter/types';
import { transformValues } from '@/common/domain/filter/Filter/helper';
import { memo, useMemo } from 'react';
import DimensionOperatorInDerivedMetric from '../../common/Operator/DimensionOperatorInDerivedMetric';
import { TMetric } from '@/typings/metric';
import { IDisabledMap } from '@/common/domain/dimension/DimensionPicker/type';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import { EColumnDataType } from '@/typings';
import t from '@/locales';

function DimensionCondition(props: IProps) {
  const {
    value,
    onChange,
    dimensions,
    currentCode,
    supportTimeGranularity = true,
    getDisabledMap,
    hasMatchMetric,
    hasUserAttribute,
  } = props;
  const dimDependency = value.dependency as IDimensionDep;

  const dimension = dimensions.find((dim) => dim.name === dimDependency.name);

  const leftData = useMemo(() => {
    if (!dimension) return undefined;
    return {
      id: dimension.name,
      originDataType: dimension.originDataType,
      displayName: dimension.displayName,
    };
  }, [dimension]);

  return (
    <Condition
      value={value}
      onChange={onChange}
      leftData={leftData}
      currentCode={currentCode}
      renderOperator={(operatorProps) => (
        <DimensionOperatorInDerivedMetric
          {...operatorProps}
          hasMatchMetric={hasMatchMetric}
          hasUserAttribute={hasUserAttribute}
        />
      )}
      renderLeft={({ onChange: onChangeDimDep }) => {
        return (
          <DimensionSelect
            dimensions={dimensions}
            value={
              dimension
                ? {
                    dimensionName: dimension.name,
                    data: dimension,
                    granularity: value.granularity,
                  }
                : undefined
            }
            onChange={(newDim) => {
              const newDep: IDimensionDep = {
                type: EDependencyType.DIMENSION,
                name: newDim.data!.name,
              };
              onChangeDimDep(
                {
                  dependency: newDep,
                  granularity: newDim.granularity,
                },
                newDim.data!.originDataType,
              );
            }}
            getDisabledMap={getDisabledMap}
            showMetricTime={false}
            hideEmptyCategory
            supportTimeGranularity={supportTimeGranularity}
            selectProps={{
              width: 160,
              placeholder: t.common.select.placeholder,
            }}
          />
        );
      }}
      renderValuesPicker={({
        value: dimValues,
        onChange: onChangeDimValues,
      }) => {
        return (
          <FilterDependencyValuesSelect
            dependency={{
              name: dimDependency.name,
              type: EFilterComponentType.DIMENSION_FILTER,
            }}
            value={transformValues(dimValues)}
            onSubmit={(newDimValues) => {
              onChangeDimValues(newDimValues);
            }}
            dataType={
              dimension
                ? originDataType2DataTypeMap[dimension.originDataType]
                : EColumnDataType.TEXT
            }
          />
        );
      }}
    />
  );
}

interface IProps {
  value: TFilter;
  onChange: (value: TFilter) => void;
  dimensions: TDimension[];
  metrics: TMetric[];
  disabledMap: IDisabledMap;
  getDisabledMap?: () => IDisabledMap;
  currentCode?: string;
  supportTimeGranularity?: boolean;
  hasMatchMetric?: boolean;
  hasUserAttribute?: boolean;
}

interface IDimensionDep {
  type: EDependencyType.DIMENSION;
  name: string;
}

export default memo(DimensionCondition);
