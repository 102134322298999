import { Dropdown } from '@aloudata/aloudata-design';
import { IDropdownProps } from '@aloudata/aloudata-design/dist/Dropdown';
import DimensionCard from '.';
import { TDimension } from '@/typings/dimension';
import { isMetricTimeDimension } from '@/common/utils';

export function DimensionCardDropdown(props: IProps) {
  const { children, dimension, dropdown } = props;
  if (!dimension) return children;

  // 指标日期维度和用户自定义的维度不同，不展示小卡片
  if (isMetricTimeDimension(dimension)) return children;

  return (
    <Dropdown
      trigger="hover"
      destroyPopupOnHide
      initialFocus={-1}
      {...dropdown}
      dropdownRender={() => {
        return <DimensionCard dimension={dimension} />;
      }}
    >
      {children}
    </Dropdown>
  );
}

interface IProps {
  children: React.ReactElement;
  dimension?: TDimension;
  dropdown?: Partial<IDropdownProps>;
}
