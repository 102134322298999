import { sample, times } from 'lodash';
import {
  EExecuteStatus,
  EExecuteType,
  ESyncMethodMode,
  ESyncSourceType,
  EUserGroupCreationMode,
  IExecuteRecord,
  ISoftIntegrationGroupSyncConfig,
  ISyncMethod,
  ITableGroupSyncConfig,
  IUserGroup,
  TGroupSyncConfig,
} from '../../src/services/user/types';
import { EDatasourceEntityType } from '../../src/typings/dataSource';
import { ISourceTablePickerValue } from '../../src/common/domain/datasource/SourceTablePicker';
import {
  ESoftIntegrationType,
  ESwitchStatus,
} from '../../src/typings/softIntegration';
export const getUserGroupInfos = (): IUserGroup[] => {
  const manualList = [
    {
      name: '3',
      groupId: 'MANUAL-3',
      parentGroupId: null,
      frontGroupId: '2',
      creationMode: EUserGroupCreationMode.MANUAL,
    },
    {
      name: '3-1',
      groupId: 'MANUAL-3-1',
      parentGroupId: '3',
      frontGroupId: null,
      creationMode: EUserGroupCreationMode.MANUAL,
    },
    {
      name: '3-2',
      groupId: 'MANUAL-3-2',
      parentGroupId: 'MANUAL-3',
      frontGroupId: 'MANUAL-3-1',
      creationMode: EUserGroupCreationMode.MANUAL,
    },
    {
      name: '0',
      groupId: 'MANUAL-0',
      parentGroupId: null,
      frontGroupId: null,
      creationMode: EUserGroupCreationMode.MANUAL,
    },
    {
      name: '0-1',
      groupId: 'MANUAL-0-1',
      parentGroupId: 'MANUAL-0',
      frontGroupId: null,
      creationMode: EUserGroupCreationMode.MANUAL,
    },
    {
      name: '0-2',
      groupId: 'MANUAL-0-2',
      parentGroupId: 'MANUAL-0',
      frontGroupId: '0-1',
      creationMode: EUserGroupCreationMode.MANUAL,
    },
    {
      name: '1',
      groupId: 'MANUAL-1',
      parentGroupId: null,
      frontGroupId: 'MANUAL-0',
      creationMode: EUserGroupCreationMode.MANUAL,
    },
    {
      name: '2',
      groupId: 'MANUAL-2',
      parentGroupId: null,
      frontGroupId: 'MANUAL-1',
      creationMode: EUserGroupCreationMode.MANUAL,
    },
  ];

  const syncList = [
    {
      name: '3',
      groupId: 'SYNC-3',
      parentGroupId: null,
      frontGroupId: 'SYNC-2',
      creationMode: EUserGroupCreationMode.SYNC,
    },
    {
      name: '3-1',
      groupId: 'SYNC-3-1',
      parentGroupId: 'SYNC-3',
      frontGroupId: null,
      creationMode: EUserGroupCreationMode.SYNC,
    },
    {
      name: '3-2',
      groupId: 'SYNC-3-2',
      parentGroupId: 'SYNC-3',
      frontGroupId: 'SYNC-3-1',
      creationMode: EUserGroupCreationMode.SYNC,
    },
    {
      name: '0',
      groupId: 'SYNC-0',
      parentGroupId: null,
      frontGroupId: null,
      creationMode: EUserGroupCreationMode.SYNC,
    },
    {
      name: '0-1',
      groupId: 'SYNC-0-1',
      parentGroupId: 'SYNC-0',
      frontGroupId: null,
      creationMode: EUserGroupCreationMode.SYNC,
    },
    {
      name: '0-2',
      groupId: 'SYNC-0-2',
      parentGroupId: 'SYNC-0',
      frontGroupId: 'SYNC-0-1',
      creationMode: EUserGroupCreationMode.SYNC,
    },
    {
      name: '1',
      groupId: 'SYNC-1',
      parentGroupId: null,
      frontGroupId: 'SYNC-0',
      creationMode: EUserGroupCreationMode.SYNC,
    },
    {
      name: '2',
      groupId: 'SYNC-2',
      parentGroupId: null,
      frontGroupId: 'SYNC-1',
      creationMode: EUserGroupCreationMode.SYNC,
    },
  ];

  return [...manualList, ...syncList];
};

const getMockSourceTablePickerValue = (): ISourceTablePickerValue => ({
  id: 'mock_id',
  name: 'mock_table',
  type: EDatasourceEntityType.TABLE,
});

const getMockSyncMethod = (): ISyncMethod => ({
  syncMode: sample([ESyncMethodMode.MANUAL, ESyncMethodMode.SCHEDULED]),
  cron: '0 0 * * *',
});

const getMockUserSyncConfigProperty = () => ({
  isExtendedAttr: sample([true, false]),
  value: 'mock_value',
});

const getMockITableGroupSyncConfig = (): ITableGroupSyncConfig => ({
  sourceType: ESyncSourceType.TABLE,
  syncConfig: getMockSyncMethod(),
  status: sample([ESwitchStatus.OPEN, ESwitchStatus.CLOSE]),
  sourceConfig: {
    physicalTable: getMockSourceTablePickerValue(),
    propertiesMapping: {
      userGroupId: getMockUserSyncConfigProperty(),
      userGroupName: getMockUserSyncConfigProperty(),
      parentUserGroupId: getMockUserSyncConfigProperty(),
    },
    filter: { formula: 'mock_filter' },
    userBindTable: getMockSourceTablePickerValue(),
    userBindPropertiesMapping: {
      account: getMockUserSyncConfigProperty(),
      bindGroupId: getMockUserSyncConfigProperty(),
    },
    userBindFilter: { formula: 'mock_user_bind_filter' },
  },
  layout: {},
});

const getMockISoftIntegration = (): ISoftIntegrationGroupSyncConfig => ({
  sourceType: ESyncSourceType.SOFT_INTEGRATION,
  syncConfig: getMockSyncMethod(),
  status: sample([ESwitchStatus.OPEN, ESwitchStatus.CLOSE]),
  sourceConfig: {
    softIntegrationDetail: {
      code: 'mock_code',
      type: sample([ESoftIntegrationType.ENTERPRISE_WECHAT]),
    },
  },
  layout: {},
});

export const getMockTGroupSyncConfig = (): TGroupSyncConfig => {
  return sample([getMockITableGroupSyncConfig(), getMockISoftIntegration()])!;
};

const getMockIExecuteRecord = (): IExecuteRecord => {
  const startTime = Date.now();
  const endTime = startTime + sample([1000, 2000, 3000, 4000, 5000]); // 随机生成执行时间
  return {
    type: sample([EExecuteType.USER_SYNC, EExecuteType.USER_GROUP_SYNC]),
    config: getMockTGroupSyncConfig(),
    syncMode: sample([ESyncMethodMode.MANUAL, ESyncMethodMode.SCHEDULED]),
    executeTime: startTime,
    finishTime: endTime,
    status: sample([EExecuteStatus.SUCCESS, EExecuteStatus.FAILURE]),
  };
};

export const generateMockExecuteRecords = (count: number): IExecuteRecord[] => {
  return times(count, getMockIExecuteRecord);
};
