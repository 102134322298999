import {
  EFormulaType,
  EOriginDataType,
  IFunctionOperator,
} from '@/typings/formula';
import { ECheckResultType, TCheckResult } from '../function';
import t from '@/locales';
import {
  getRealGranularity,
  isRealGranularity,
} from '../../../../granularity/helper';
import { customGranularityList } from '../../../../../globalInfo/appConfig';
import { isTimeGranularity } from '@/common/domain/filter/NewFilter/common/CustomTimePicker/GranularityPicker/helper';

export const checkDateTrunc = (params: {
  op: string;
  args: EOriginDataType[];
  ast: IFunctionOperator;
}): TCheckResult => {
  const { op, args, ast } = params;
  const VALID_ARGS_LENGTH = 2;
  const GRANULARITY_ARGS_INDEX = 1;
  if (args.length !== VALID_ARGS_LENGTH) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsLengthError2}`,
    };
  }

  if (
    ![
      EOriginDataType.TIMESTAMP,
      EOriginDataType.DATETIME,
      EOriginDataType.DATE,
      EOriginDataType.CHAR,
      EOriginDataType.TIMESTAMP_NTZ,
      EOriginDataType.TIME,
      EOriginDataType.TIME_WITH_TIME_ZONE,
      EOriginDataType.TIMESTAMP_WITH_TIME_ZONE,
      EOriginDataType.VARCHAR,
      EOriginDataType.STRING,
    ].includes(args[0])
  ) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
    };
  }

  if (ast.args[GRANULARITY_ARGS_INDEX].type !== EFormulaType.CONSTANT) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
    };
  }

  const granularityName = ast.args[GRANULARITY_ARGS_INDEX].val.val.toString();

  if (!isRealGranularity(granularityName)) {
    if (
      !customGranularityList.find(
        (item) => item.name.toUpperCase() === granularityName.toUpperCase(),
      )
    ) {
      return {
        type: ECheckResultType.ERROR,
        errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
      };
    }
  }

  if (args[1] === EOriginDataType.DATE) {
    if (isTimeGranularity(getRealGranularity(granularityName))) {
      return {
        type: ECheckResultType.ERROR,
        errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
      };
    }
  }

  return {
    type: ECheckResultType.ORIGIN_DATA_TYPE,
    originDataType: EOriginDataType.DATETIME,
  };
};
