export const define = {
  label: {
    name: 'Label Name',
    enName: 'Name',
    description: 'Description',
    value: 'Date Value',
    valueDescription:
      'Dates matching this column will be added with date Label',
    table: 'Table',
    column: 'Date Column',
    filterSwitch: 'Filter',
    filterDescription: 'Meet the following conditions',
  },
  title: {
    create: 'Create Date Label',
    edit: 'Edit Date Label',
  },
  validate: {
    empty: (filed: string) => `${filed} cannot be empty`,
    enNamePattern:
      'Can only contain letters, numbers, underscores, length within 50',
  },
  button: {
    create: 'Create',
    edit: 'Save',
  },
  message: {
    createSuccess: 'Created Successfully',
    editSuccess: 'Saved Successfully',
  },
  placeholder: {
    table: 'Select Table',
    column: 'Select Field',
  },
};

export const list = {
  title: 'Date Label',
  total: 'Date Label',
  create: 'Create Date Label',
  column: {
    name: 'Name',
    description: 'Description',
    operation: 'Actions',
    enName: 'Name',
  },
  operation: {
    edit: 'Edit',
    delete: 'Delete',
  },
  message: {
    deleteSuccess: 'Deleted Successfully',
  },
};

export const dateLimitList = {
  title: 'Time Constraint',
  total: 'Time Constraints',
  create: 'Create Time Constraint',
  column: {
    name: 'Name',
    displayName: 'Display Name',
    timeRange: 'Time Range',
  },
  delete: {
    title: 'Delete Time Constraint',
    getContent: (name: string) =>
      `Are you sure to delete time Constraint [${name}]`,
  },
};

export const dateLimitForm = {
  title: {
    edit: 'Edit Time Constraint',
    create: 'Create Time Constraint',
  },
  okText: {
    create: 'Create',
    edit: 'Save',
  },
  message: {
    createSuccess: 'Created Successfully',
    editSuccess: 'Saved Successfully',
  },
  placeholder: {
    input: 'Please input',
  },
  validate: {
    nameMust: 'Enter name',
    nameReg: 'Can only contain letters, numbers, underscores, length within 50',
    displayNameMust: 'Enter display name',
  },
};
