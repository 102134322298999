import t from '@/locales';
import { LogicTree, Radio, Switch } from '@aloudata/aloudata-design';
import styles from './index.less';
import { useEffect, useState } from 'react';
import {
  IFilter,
  IFilterItem as IColumnFilterItem,
} from '@/common/domain/filter/Filter/filter';
import { IFilterItem as IDimensionFilterItem } from '@/pages/Metric/Detail/Content/Authority/RowFilterTable/EditConditionalRuleModal/DimensionFilter/filter';
import { IDataset } from '@/typings/dataset';
import CodeEditorWrapper, {
  ECodeEditorWrapperType,
  EFunctionFilterType,
} from '@/common/domain/codeEditor/CodeEditorWrapper';
import { TFormula } from '@/typings/formula';
import { EMetricType, TMetric } from '@/typings/metric';
import {
  IDimension,
  TDimension,
  TMetricCodeAvailableDimensionsMap,
} from '@/typings/dimension';
import classNames from 'classnames';
import { EParseResultType } from '@/common/domain/Formula/functions/parseError';
import ColumnCondition from '@/common/domain/filter/NewFilter/ColumnFilter/Condition';
import DimensionCondition from '@/common/domain/filter/NewFilter/DimensionFilter/Condition';
import {
  EDependencyType,
  TFilter,
} from '@/common/domain/filter/NewFilter/types';
import { getEmptyFilter } from '@/common/domain/filter/NewFilter/helper';
import { IMetricVertex } from '@/services/lineage/types';
import type { IDisabledMap } from '@/common/domain/metric/MetricPicker/PureMetricList';
import { getAvailableDimensionMetricMapping } from '@/common/domain/dimension/DimensionPicker/helper';
import _ from 'lodash';
import { METRIC_TIME } from '@/constants';
import { TNode } from '@aloudata/aloudata-design/dist/LogicTree';

export enum EFilterMode {
  NORMAL = 'NORMAl',
  CUSTOM_EXPR = 'CUSTOM_EXPR',
}
export type TCustomFilterInForm = {
  type: EFilterMode.CUSTOM_EXPR;
  customFilter?: TFormula;
  dsl?: string;
};
export type TColumnCommonFilterInForm = {
  type: EFilterMode.NORMAL;
  filter?: IFilter<IColumnFilterItem>;
  filterLogicTree?: TNode<TFilter>;
};
export type TDimensionCommonFilterInForm = {
  type: EFilterMode.NORMAL;
  filter?: IFilter<IDimensionFilterItem>;
  filterLogicTree?: TNode<TFilter>;
};
export type TColumnFilterInForm =
  | TColumnCommonFilterInForm
  | TCustomFilterInForm;
export type TDimensionFilterInForm =
  | TDimensionCommonFilterInForm
  | TCustomFilterInForm;
interface IAtomicFilterProps {
  metricType: EMetricType.ATOMIC;
  filterableDatasets: IDataset[];
  value?: TColumnFilterInForm;
  onChange?: TAtomicOnChange;
  filterableDimensions?: undefined;
  hideDimensionEmptyCategory?: boolean;
  className?: string;
  metrics?: TMetric[];
  currentCode?: string;
  availableDimensionsMap?: undefined;
  supportTimeGranularity?: boolean;
}
type TAtomicOnChange = (value: TColumnFilterInForm) => void;
type TDerivedOnChange = (value: TDimensionFilterInForm) => void;
interface IDerivedFilterProps {
  metricType: EMetricType.DERIVED;
  filterableDimensions: IDimension[];
  value?: TDimensionFilterInForm;
  onChange?: TDerivedOnChange;
  filterableDatasets?: undefined;
  hideDimensionEmptyCategory?: boolean;
  className?: string;
  downstreamMetrics?: IMetricVertex[];
  metrics: TMetric[];
  currentCode?: string;
  availableDimensionsMap: TMetricCodeAvailableDimensionsMap;
  supportTimeGranularity?: boolean;
}

export default function Filter({
  value,
  onChange: propsOnChange,
  filterableDatasets,
  metricType,
  filterableDimensions,
  className,
  metrics,
  currentCode,
  availableDimensionsMap,
  supportTimeGranularity,
}: IAtomicFilterProps | IDerivedFilterProps) {
  const [active, setActive] = useState<boolean>(
    value?.type === EFilterMode.NORMAL
      ? !!value.filterLogicTree
      : !!value?.customFilter,
  );
  const onChangeMode = (e: EFilterMode) => {
    if (!propsOnChange) return;
    propsOnChange({
      type: e,
      filterLogicTree: undefined,
    });
  };
  const onChangeColumnFilters = (filterLogicTree: TNode<TFilter>) => {
    if (!propsOnChange) return;
    (propsOnChange as TAtomicOnChange)({
      type: EFilterMode.NORMAL,
      filterLogicTree,
    });
  };
  const onChangeDimensionFilters = (filterLogicTree: TNode<TFilter>) => {
    if (!propsOnChange) return;
    (propsOnChange as TDerivedOnChange)({
      type: EFilterMode.NORMAL,
      filterLogicTree,
    });
  };
  const onChangeCustomExpr = (customFilter: TFormula, dsl: string) => {
    if (!propsOnChange) return;
    propsOnChange({
      type: EFilterMode.CUSTOM_EXPR,
      customFilter,
      dsl,
    });
  };
  useEffect(() => {
    if (!active) {
      setActive(
        value?.type === EFilterMode.NORMAL
          ? !!value.filterLogicTree
          : !!value?.customFilter,
      );
    }
  }, [value, active]);
  return (
    <div className={classNames(styles.filter, className)}>
      <Switch
        className={classNames(styles.switch, {
          [styles.derivedFilterActive]:
            metricType === EMetricType.DERIVED && active,
        })}
        checked={active}
        onChange={(open) => {
          if (!open) {
            propsOnChange?.({
              type: EFilterMode.NORMAL,
            });
          }
          setActive(open);
        }}
      >
        {metricType === EMetricType.ATOMIC
          ? t.metric.metric.define.label.filter
          : null}
      </Switch>
      {active && (
        <div className={styles.filterSection}>
          <div className={styles.addModeChoose}>
            <Radio.Group onChange={onChangeMode} value={value?.type}>
              <Radio value={EFilterMode.NORMAL}>
                <span className={styles.radioText}>
                  {t.metric.metric.define.radio.normal}
                </span>
              </Radio>
              <Radio value={EFilterMode.CUSTOM_EXPR}>
                <span className={styles.radioText}>
                  {t.metric.metric.define.radio.customExpr}
                </span>
              </Radio>
            </Radio.Group>
          </div>
          <div
            className={classNames(styles.filterContent, {
              [styles.normalContent]: value?.type === EFilterMode.NORMAL,
            })}
          >
            {value?.type === EFilterMode.NORMAL && (
              <div className={styles.normal}>
                <div className={styles.des}>
                  {t.metric.metric.define.businessLimit.des}
                </div>
                {metricType === EMetricType.ATOMIC && (
                  <div>
                    <LogicTree
                      newInitializationData={getEmptyFilter(
                        EDependencyType.COLUMN,
                      )}
                      onChange={onChangeColumnFilters}
                      value={value?.filterLogicTree}
                      renderCondition={(data, { changeData }) => (
                        <div>
                          <ColumnCondition
                            value={data}
                            onChange={(config) => changeData(config)}
                            datasets={filterableDatasets || []}
                            supportTimeGranularity={supportTimeGranularity}
                          />
                        </div>
                      )}
                    />
                  </div>
                )}

                {metricType === EMetricType.DERIVED && (
                  <div>
                    <LogicTree
                      newInitializationData={getEmptyFilter(
                        EDependencyType.DIMENSION,
                      )}
                      onChange={onChangeDimensionFilters}
                      value={value?.filterLogicTree}
                      renderCondition={(data, { changeData }) => {
                        const { dependency } = data;
                        if (dependency.type !== EDependencyType.DIMENSION)
                          return null;
                        const { name } = dependency;
                        const selectedDimension = _.find(
                          filterableDimensions,
                          (item) => item.name === name,
                        );
                        const selectedDimensions = selectedDimension
                          ? [selectedDimension]
                          : [];
                        const disabledMap = getDisabledMap(
                          metrics,
                          selectedDimensions,
                          availableDimensionsMap || {},
                        );
                        return (
                          <div>
                            <DimensionCondition
                              value={data}
                              onChange={(config) => changeData(config)}
                              dimensions={
                                filterableDimensions.filter(
                                  (item) => item.name !== METRIC_TIME,
                                ) as TDimension[]
                              }
                              supportTimeGranularity={supportTimeGranularity}
                              metrics={metrics}
                              disabledMap={disabledMap}
                              currentCode={currentCode}
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {value?.type === EFilterMode.CUSTOM_EXPR && (
              <div className={styles.customExprArea}>
                {metricType === EMetricType.ATOMIC && (
                  <CodeEditorWrapper
                    type={ECodeEditorWrapperType.DATASET}
                    ast={value?.customFilter!}
                    functionFilterType={EFunctionFilterType.METRIC_FILTER}
                    currentName={''}
                    borderRadius={'bottom'}
                    className={styles.codeEditorWrapper}
                    placeholder={t.metric.metric.define.expr.filter}
                    dataset={null}
                    one2manyDatasets={filterableDatasets.filter(
                      (item) => !!item.displayName,
                    )}
                    onChange={(result, __, dsl) => {
                      const { ast, type } = result;
                      if (type === EParseResultType.PARSE_RESULT) {
                        onChangeCustomExpr(ast, dsl);
                      }
                    }}
                  />
                )}
                {metricType === EMetricType.DERIVED && (
                  <CodeEditorWrapper
                    type={ECodeEditorWrapperType.DIMENSION}
                    functionFilterType={EFunctionFilterType.METRIC_FILTER}
                    className={styles.codeEditorWrapper}
                    ast={value?.customFilter!}
                    borderRadius={'bottom'}
                    currentName={''}
                    placeholder={t.metric.metric.define.expr.filter}
                    dimensions={filterableDimensions}
                    onChange={(result, __, dsl) => {
                      const { ast, type } = result;
                      if (type === EParseResultType.PARSE_RESULT) {
                        onChangeCustomExpr(ast, dsl);
                      }
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export function getDisabledMap(
  metrics: TMetric[],
  selectedDimensions: IDimension[],
  availableDimensionsMap: TMetricCodeAvailableDimensionsMap,
) {
  const { dimension } = getAvailableDimensionMetricMapping(
    metrics,
    availableDimensionsMap,
  );
  const disabledMap: IDisabledMap = {};
  _.forEach(metrics, (metric) => {
    const unMatchedDimensionDisplayNames: string[] = [];
    _.forEach(selectedDimensions, (dim) => {
      const availableMetricCodes = dimension[dim.name];
      if (availableMetricCodes && !availableMetricCodes.has(metric.code)) {
        // 该指标不可被该维度筛选
        unMatchedDimensionDisplayNames.push(dim.displayName);
      }
    });
    const isDisabled = unMatchedDimensionDisplayNames.length > 0;
    if (isDisabled) {
      disabledMap[metric.code] = t.workbook.workbook.picker.metric.unusable({
        list: unMatchedDimensionDisplayNames
          .map((displayName) => `[${displayName}]`)
          .join(','),
      });
    }
  });
  return disabledMap;
}
