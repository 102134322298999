import { ICategoryTreeNode } from '@/pages/Manage/CategoryManage/categoryHelper';
import { TreeDataNode } from '@aloudata/aloudata-design';
import { TSimpleCategory } from '@/typings/category';
import { EOriginDataType } from '@/typings';
import { TMetric } from '@/typings/metric';

type disabledReason = string;
export interface IDisabledMap {
  [code: string]: disabledReason;
}

export interface ITreeNode<T extends ICategoryLikeItem> extends TreeDataNode {
  key: string;
  title: string;
  isCategory?: boolean;
  checkable?: boolean;
  children?: ITreeNode<T>[];
  isLeaf: boolean;
  data: IDataItem<T> | ICategoryTreeNode | null;
  icon?: JSX.Element;
  disabled?: boolean;
}

export interface IDataItem<T extends ICategoryLikeItem> {
  data: T;
  disabled: boolean;
  disabledReason: string;
}

export enum ESelectMode {
  MULTIPLE_ADD = 'MULTIPLE_ADD', // 批量添加模式，批量选择后点击应用后才生效
  MULTIPLE_CHECK = 'MULTIPLE_CHECK', // 实时选择模式，选择或反选后实时生效
  SINGLE = 'SINGLE', // 单选模式，选择后立即生效
}

export enum EDisplayType {
  TREE = 'TREE', // 树形结构
  LIST = 'LIST', // 列表结构
}

export interface ICategoryLikeItem {
  category: TSimpleCategory[];
}

export interface IMetricMultipleSelectValue<TExtraData = unknown> {
  displayName: string;
  key: string; // 指标唯一标识，可能是指标 code 也可能是加工指标的 id
  originDataType?: EOriginDataType;
  extra?: TExtraData;
  // 完整的指标对象
  metric?: TMetric;
  // 错误信息
  errorMsg?: string;
  // 是否可删除
  removable?: boolean;
}

export interface IRenderItemInfo<TExtraMetricData = unknown> {
  metric: IMetricMultipleSelectValue<TExtraMetricData>;
  closable: boolean;
  onRemove: (metricValue: IMetricMultipleSelectValue<TExtraMetricData>) => void;
  maxWidth?: number;
  displayName: string;
  errIcon?: React.ReactNode;
  node: React.ReactNode;
}

// 触发下拉框关闭回调的原因
export enum EDropdownCloseReason {
  SELECT = 'SELECT', // 完成了指标选择
  CLOSE = 'CLOSE', // 点击关闭按钮
  DROPDOWN_CLOSE = 'DROPDOWN_CLOSE', // 下拉框触发的关闭
}
