import {
  EFormulaType,
  EOriginDataType,
  IFunctionOperator,
} from '@/typings/formula';
import { ECheckResultType, TCheckResult } from '../function';
import t from '@/locales';
import { isRealGranularity } from '../../../../granularity/helper';
import { customGranularityList } from '../../../../../globalInfo/appConfig';

export const checkDateAdd = (params: {
  op: string;
  args: EOriginDataType[];
  ast: IFunctionOperator;
}): TCheckResult => {
  const { op, args, ast } = params;
  const VALID_ARGS_LENGTH = 3;
  const GRANULARITY_ARGS_INDEX = 2;
  if (args.length !== VALID_ARGS_LENGTH) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsLengthError2}`,
    };
  }

  if (
    ![
      EOriginDataType.TIMESTAMP,
      EOriginDataType.DATETIME,
      EOriginDataType.DATE,
      EOriginDataType.CHAR,
      EOriginDataType.TIMESTAMP_NTZ,
      EOriginDataType.TIME,
      EOriginDataType.TIME_WITH_TIME_ZONE,
      EOriginDataType.TIMESTAMP_WITH_TIME_ZONE,
    ].includes(args[0])
  ) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
    };
  }

  if (
    ![
      EOriginDataType.INT,
      EOriginDataType.FLOAT,
      EOriginDataType.BIGINT,
      EOriginDataType.INTEGER,
      EOriginDataType.DECIMAL,
    ].includes(args[1])
  ) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
    };
  }

  if (args[GRANULARITY_ARGS_INDEX] !== EOriginDataType.VARCHAR) {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
    };
  }

  if (ast.args[GRANULARITY_ARGS_INDEX].type === EFormulaType.CONSTANT) {
    const granularity = ast.args[GRANULARITY_ARGS_INDEX].val.val.toString();
    if (!isRealGranularity(granularity.toUpperCase())) {
      if (
        !customGranularityList.find(
          (item) => item.name.toUpperCase() === granularity.toUpperCase(),
        )
      ) {
        return {
          type: ECheckResultType.ERROR,
          errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
        };
      }
    }
  } else {
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
    };
  }
  return {
    type: ECheckResultType.ORIGIN_DATA_TYPE,
    originDataType: EOriginDataType.DATETIME,
  };
};
