import { Tooltip } from '@aloudata/aloudata-design';
import { useEffect, useState } from 'react';
import { useDragLayer } from 'react-dnd';
import { TDimension } from '@/typings/dimension';
import { DimensionCardDropdown } from '../../../DimensionCard/Dropdown';

export function PickerTooltip(props: IProps) {
  const { dimension, children, reason } = props;
  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
  }));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isDragging) {
      setOpen(false);
    }
  }, [isDragging]);

  let childrenNode = children;
  if (reason) {
    // 可分析约束报错
    childrenNode = <Tooltip title={reason}>{children}</Tooltip>;
  }

  return (
    <DimensionCardDropdown
      dimension={dimension}
      dropdown={{
        placement: 'right',
        onOpenChange: (isOpen) => {
          setOpen(isOpen);
        },
        open: !isDragging && open,
        delay: 200,
      }}
    >
      {childrenNode}
    </DimensionCardDropdown>
  );
}
interface IProps {
  children: React.ReactElement;
  dimension: TDimension;
  reason: string;
}
