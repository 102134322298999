export const list = {
  title: 'Monitors',
  filter: {
    all: (number: number) => `All (${number})`,
    error: (number: number) => `Abnormal (${number})`,
    normal: (number: number) => `Normal (${number})`,
    onlyMyOwen: 'My Ownership',
  },
  add: 'Create Monitor',
  card: {
    status: {
      error: 'Abnormal',
      normal: 'Normal',
      disabled: 'Disabled',
    },
    execTime: 'Time',
    owner: 'Owner',
    dataError: 'Data Anomaly Detected',
    emptyDesc: 'No Scheduling Results',
    errorMsg: (msg: string) => `Alert Condition Triggered: ${msg}`,
    operate: {
      view: 'View',
      edit: 'Edit',
      delete: 'Delete',
      addSuccess: 'Created Successfully',
      editSuccess: 'Updated Successfully',
      enable: 'Enable',
      disable: 'Disable',
    },
  },
};

export const detail = {
  history: 'History',
  rules: 'Alert Rules',
  monitorTime: 'Time',
  metricData: 'Metric Results',
  errorResult: 'Error Reason',
  dimensionSplitLimit: 'Only showing first 300 records',
};

export const monitorConfigModal = {
  title: {
    create: 'Create Monitor',
    update: 'Edit Monitor',
  },
  monitorLevel: 'Level',
  displayNamePlaceholder: 'Enter alert name',
  metricSelectNumberType: 'Only numeric metrics are supported',
  metricConfig: 'Monitoring Metric Settings',
  metricPlaceholder: 'Select Metric',
  pushConfig: 'Message Push Settings',
  baseInfo: 'Basic Information',
  name: 'Name',
  metric: 'Metric',
  dimensionSplit: {
    title: 'By Dimension',
    sentence1: '',
    sentence2: '',
    sentence3: '',
    sentence4:
      'group metrics, monitor metric results for the following dimension values:',
    all: 'All',
    custom: 'Custom',
  },
  userPropertyKey: {
    before: 'Use user property',
    after: 'as WeChat userId',
  },
  detectTime: 'Time',
  rule: {
    title: 'Rules',
    tip: 'Meet the following conditions',
    metric: 'Metric',
    metricValue: 'Value',
  },
  operator: {
    equal: 'Equals',
    notEqual: 'Not Equals',
    largeThan: 'Greater Than',
    largeEqual: 'Greater Than or Equal',
    lessThan: 'Less Than',
    lessEqual: 'Less Than or Equal',
    between: 'Between',
    outOfRange: 'Out of Range',
  },
  footer: {
    nextStep: 'Next',
    cancel: 'Cancel',
    prevStep: 'Previous',
    create: 'Create',
    update: 'Confirm',
  },
  pushConfigTitle: 'Push Settings',
  pushConfigTitleTip:
    'When enabled, message notifications will be sent to specified users when metric alert thresholds are reached',
  pushChannel: 'Recipients',
  channelTab: 'Channel',
  channel: 'Channel',
  pushChannelPlaceholder: 'Select Push Channel',
  channelIndex: (index: number) => `Channel ${index} Configuration`,
  receiveUser: 'Recipients',
  userType: {
    user: 'Specified Users',
    custom: 'Custom',
  },
  message: {
    content: 'Message',
    title: 'Title',
    type: 'Message Type',
    markdown: 'Markdown',
    plainText: 'Plain Text',
    titlePlaceholder: 'Enter title',
    desc: 'Message',
    reminderRange: 'Reminder Range',
    reset: 'Reset to Default',
    reminder: {
      user: 'Remind Members (userid)',
      phone: 'Remind Members (phone)',
      allMember: 'Remind All Members (@all)',
    },
    defaultContent: (
      metricTime: string,
      metricName: string,
      metricResult: string,
      rule: string,
      owner: string,
      dimensionSplit?: string,
    ) =>
      `<p><span>As of&nbsp;</span>${metricTime}<span>&nbsp;, the metric you are monitoring&nbsp;</span>${
        dimensionSplit ? `${dimensionSplit}<span>&nbsp;&nbsp;</span>` : ''
      }${metricName}<span>&nbsp;has a result of&nbsp;</span>${metricResult}<span>&nbsp;, triggering alert condition:&nbsp;</span>${rule}<span>&nbsp;</span></p><p><span>Please pay attention, metric owner is&nbsp;</span>${owner}<span>&nbsp;</span></p>`,
  },
  testPush: 'Test',
  commitPush: 'Test',
  commitPushSuccess: 'Message has been sent to specified channel',
  validate: {
    displayName: 'Enter alert name',
    metricCode: 'Select alert metric',
    dimensionSplit: {
      dimension: 'Select dimension',
      values: 'Select dimension values',
      notAvailableDimension: 'This dimension is not available for the metric',
    },
    execCron: 'Time configuration error',
    channel: 'Select channel',
    receivers: 'Select recipients',
    messageTitle: 'Enter title',
    testReceivers: 'Select test users',
  },
};

export const pushConfig = {
  message: {
    desc: {
      toolbar: {
        normal: 'Body',
        h1: 'Large Title',
        h2: 'Small Title',
        insertParams: 'Insert Parameter',
        insertReminder: 'Insert Reminder',
        role: 'Role',
        user: 'User',
      },
      placeholder: 'Enter message',
    },
    params: {
      monitorLevel: 'Level',
      monitorName: 'Name',
      time: 'Time',
      dimensionValue: 'Split Dimension Value',
      metricName: 'Monitoring Metric Name',
      metricResult: 'Metric Result',
      monitorRule: 'Condition Triggered',
      metricOwner: 'Metric Owner',
    },
  },
};
