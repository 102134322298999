import styles from './index.less';
import { EColumnDataType, EDateGranularityType } from '@/typings';
import t from '@/locales';
import { Tooltip } from '@aloudata/aloudata-design';
import ColumnIcon from '../ColumnIcon';
import { EColumnType } from '@/typings/dataset';
const DEFAULT_SIZE = 20;
export const colTypeTip: {
  [key in EColumnDataType]: string;
} = {
  [EColumnDataType.TEXT]: t.filter.sidebar.colTypeIsText,
  [EColumnDataType.INT]: t.filter.sidebar.colTypeIsInt,
  [EColumnDataType.DECIMAL]: t.filter.sidebar.colTypeIsDecimal,
  [EColumnDataType.DOUBLE]: t.filter.sidebar.colTypeIsDouble,
  [EColumnDataType.DATE]: t.filter.sidebar.colTypeIsDate,
  [EColumnDataType.DATE_TIME]: t.filter.sidebar.colTypeIsTimestamp,
  [EColumnDataType.BOOLEAN]: t.filter.sidebar.colTypeIsBoolean,
  [EColumnDataType.JSON]: t.filter.sidebar.colTypeIsJson,
};
const ColumnDataTypeIcon = ({
  dataType,
  size,
  showTip,
  className,
  granularity,
  color,
  disabled,
}: {
  dataType: EColumnDataType;
  size?: number;
  showTip?: boolean;
  className?: string;
  columnType?: EColumnType;
  granularity?: EDateGranularityType;
  color?: string;
  disabled?: boolean;
}) => {
  if (!dataType) {
    return null;
  }
  const DISABLED_OPACITY = 0.25;
  return (
    <Tooltip title={showTip ? colTypeTip[dataType] : ''}>
      <span
        className={styles.wrap}
        style={{ opacity: disabled ? DISABLED_OPACITY : 1 }}
      >
        <ColumnIcon
          color={color || '#374151'}
          type={dataType}
          size={size || DEFAULT_SIZE}
          className={className}
          granularity={granularity}
        />
      </span>
    </Tooltip>
  );
};
export default ColumnDataTypeIcon;
