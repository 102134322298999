import ReactCodeMirror, {
  Compartment,
  EditorView,
  ReactCodeMirrorRef,
  ViewUpdate,
} from '@uiw/react-codemirror';
import styles from './index.less';
import { EOriginDataType, TFormula } from '@/typings/formula';
import { ETokenType, IEditorError, IToken, TExternalInfo } from './type';
import { IFilterFunctionsList } from '@/common/domain/Formula/type';
import { TPropsFunction } from '../CodeEditorWrapper';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  autoCompleteAdapter,
  EAutoCompleteType,
  IPrompt,
  TAutoCompleteItem,
} from '@/common/domain/Formula/functions/autoComplete';
import AutoCompleteList from './AutoCompleteList';
import {
  getCursorPagePosition,
  getCursorPos,
  getDoc,
  getTextBeforeCursor,
  insertText,
  setArrowAndEnterDisabled,
} from './helper/codemirrorHelper';
import { parseFormulaAdapter } from '@/common/domain/Formula/functions/parse';
import {
  getFuncPrompt,
  getNextToken,
  getTokenEnd,
  getTokenListWithIdPath,
  parseDslError,
  replaceNewlines,
  tokenList2Dsl,
  tokenList2RealDsl,
} from './helper/formulaHelper';
import { genFormulaAdapter } from '@/common/domain/Formula/functions/genFormula';
import classNames from 'classnames';
import { linter, Diagnostic } from '@codemirror/lint';
import { createFieldDecorator } from './hightlight';
import _ from 'lodash';
import PromptTrigger from './PromptTrigger';
import { normalTheme } from './styles';
import ErrorMsg from './ErrorMsg';
import {
  getTokensBeforeCursorPos,
  removeWhitespaceAndNewlines,
} from './helper';

const linterCom = new Compartment();
const keyMapCom = new Compartment();

interface ICodeEditorProps {
  defaultAst: TFormula | null;
  currentColumnId?: string;
  readonly?: boolean; // 是否是只读信息
  externalInfo: TExternalInfo;
  filterFunctionList: IFilterFunctionsList;
  onBlur?: TPropsFunction; // 失焦事件
  onChange?: TPropsFunction;
  placeholder?: string;
  className?: string;
  borderRadius?: 'all' | 'top' | 'bottom';
}

export interface ICodeEditorRef {
  focus: () => void;
  complete: (
    completeProps:
      | {
          type: 'column';
          complete: string;
          value: string;
          id: string;
          originDataType: EOriginDataType;
          idPath?: string[];
        }
      | {
          type: 'function';
          functionName: string;
        },
  ) => void;
  getView: () => EditorView;
}

const Y_OFFSET = 20;

const CodeEditor = (
  {
    defaultAst,
    currentColumnId = '',
    readonly,
    externalInfo,
    filterFunctionList,
    onBlur,
    onChange,
    placeholder,
    className,
    borderRadius,
  }: ICodeEditorProps,
  ref: ForwardedRef<ICodeEditorRef>,
) => {
  const editorRef = useRef<ReactCodeMirrorRef>(null);

  const [tokenList, setTokenList] = useState<IToken[]>(
    genFormulaAdapter(defaultAst, externalInfo),
  );

  const [tokenInSelPos, setTokenInSelPos] = useState<IToken | null>(null);

  const [errorList, setErrorList] = useState<IEditorError[]>([]);

  const [selPos, setSelPos] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [promptInfo, setPromptInfo] = useState<IPrompt | null>(null);

  const [autoCompleteOptions, setAutoCompleteOptions] = useState<
    TAutoCompleteItem[]
  >([]);

  const onComplete = (
    complete: string,
    partial: string,
    from: number,
    to: number,
    type?: ETokenType,
  ) => {
    if (complete === partial) return;

    if (editorRef.current?.view)
      insertText(editorRef.current.view, complete, partial, from, to);

    // 如果是一个函数id，则展示函数的信息提示框
    if (type === ETokenType.FUNCTION_ID) {
      setPromptInfo(getFuncPrompt(complete));
    }
  };

  useImperativeHandle(ref, () => ({
    getView: () => editorRef.current?.view!,
    focus: () => editorRef.current?.view?.focus(),
    complete: (completeProps) => {
      const curPos = getCursorPos(editorRef.current?.view?.state!);
      if (completeProps.type === 'column') {
        const { complete } = completeProps;
        if (tokenInSelPos?.type === ETokenType.COLUMN) {
          onComplete(
            complete,
            tokenInSelPos.text,
            tokenInSelPos.offset,
            getTokenEnd(tokenInSelPos),
          );
        } else {
          onComplete(complete, '', curPos, curPos);
        }
      } else {
        const { functionName } = completeProps;

        const nextToken = getNextToken(tokenList, tokenInSelPos);

        // 如果当前token是functionId，下一个是左括号，则将这一部分完全替换
        if (
          nextToken &&
          tokenInSelPos &&
          nextToken.type === ETokenType.LEFT_BRACKETS
        ) {
          onComplete(
            functionName,
            tokenInSelPos.text + nextToken.text,
            tokenInSelPos.offset,
            tokenInSelPos.offset + tokenInSelPos.text.length + 1,
            ETokenType.FUNCTION_ID,
          );
          return;
        }

        if (tokenInSelPos?.type === ETokenType.FUNCTION_ID) {
          onComplete(
            functionName,
            tokenInSelPos.text,
            tokenInSelPos.offset,
            tokenInSelPos.offset + tokenInSelPos.text.length,
            ETokenType.FUNCTION_ID,
          );
          return;
        }

        onComplete(functionName, '', curPos, curPos, ETokenType.FUNCTION_ID);
      }
    },
  }));

  const parseError = useCallback(
    (dsl: string) => {
      if (!editorRef.current?.view) return;

      const editorDsl = getDoc(editorRef.current.view);

      if (editorDsl !== dsl) return;

      const tempErrorList = parseDslError(
        dsl,
        externalInfo,
        filterFunctionList,
      );
      setErrorList(tempErrorList);

      editorRef.current?.view?.dispatch({
        effects: linterCom.reconfigure(
          linter(
            (): Diagnostic[] => {
              return tempErrorList.map((error) => ({
                from: error.from,
                to: error.to,
                severity: 'error',
                message: error.errorMsg,
                markClass: 'errorLine',
              }));
            },
            {
              delay: 0,
            },
          ),
        ),
      });
    },
    [externalInfo, filterFunctionList],
  );

  const autoComplete = useCallback(
    (dsl: string, _tokenInSelPos: IToken | null) => {
      try {
        if (!dsl) {
          setPromptInfo(null);
          setAutoCompleteOptions([]);
        }
        const autoCompleteInfo = autoCompleteAdapter(
          replaceNewlines(dsl),
          externalInfo,
          filterFunctionList,
        );

        if (
          autoCompleteInfo?.type === EAutoCompleteType.COMPLETE &&
          autoCompleteInfo.item[0]?.complete !== _tokenInSelPos?.text
        ) {
          const options = autoCompleteInfo.item;
          setAutoCompleteOptions(options);
          setPromptInfo(null);
          // 禁用上下、回车事件
          setArrowAndEnterDisabled(editorRef.current?.view!, keyMapCom, true);
        } else {
          setPromptInfo(null);
          setAutoCompleteOptions([]);

          setArrowAndEnterDisabled(editorRef.current?.view!, keyMapCom, false);
        }
      } catch (e) {
        console.error('autoComplete', e);
      }
    },
    [externalInfo, filterFunctionList],
  );

  const onCodemirrorUpdate = useCallback(
    (viewUpdate: ViewUpdate) => {
      // 光标变化的时候更新光标处的token
      if (viewUpdate.selectionSet) {
        const tempTokenList = getTokenListWithIdPath(
          getDoc(viewUpdate.view),
          externalInfo,
        );

        const tempTokenInSolPos =
          _.last(
            getTokensBeforeCursorPos(
              tempTokenList,
              getCursorPos(viewUpdate.state),
            ),
          ) || null;

        if (!_.isEqual(tempTokenInSolPos, tokenInSelPos)) {
          setPromptInfo(null);
        }

        // 如果光标变化了但是文本没有变化则关闭提示框
        if (
          _.isEqual(
            replaceNewlines(viewUpdate.startState.doc.toString()),
            replaceNewlines(viewUpdate.state.doc.toString()),
          )
        ) {
          setAutoCompleteOptions([]);
          setPromptInfo(null);
        }

        setTokenInSelPos(tempTokenInSolPos);
      }
    },
    [externalInfo, tokenInSelPos],
  );

  const onCodeMirrorChange = useCallback(
    (value: string, viewUpdate: ViewUpdate) => {
      // 响应onChange事件
      const tempTokenList = getTokenListWithIdPath(value, externalInfo);

      setTokenList(tempTokenList);

      onChange?.(
        parseFormulaAdapter(
          tokenList2RealDsl(tempTokenList),
          externalInfo,
          filterFunctionList,
        ),
        currentColumnId,
        value,
      );
      // 设置光标处的token
      const tempTokenInSolPos =
        _.last(
          getTokensBeforeCursorPos(
            tempTokenList,
            getCursorPos(viewUpdate.state),
          ),
        ) || null;

      setTokenInSelPos(tempTokenInSolPos);

      // 自动提示
      const dslBeforeCursor = getTextBeforeCursor(viewUpdate.state);

      autoComplete(dslBeforeCursor, tempTokenInSolPos);
      setSelPos(getCursorPagePosition(viewUpdate.state, viewUpdate.view));
      // 错误解析
      parseError(value);
    },
    [
      autoComplete,
      currentColumnId,
      externalInfo,
      filterFunctionList,
      onChange,
      parseError,
    ],
  );

  const onCodeMirrorBlur = () => {
    setAutoCompleteOptions([]);
    setPromptInfo(null);
    onBlur?.(
      parseFormulaAdapter(
        tokenList2RealDsl(tokenList),
        externalInfo,
        filterFunctionList,
      ),
      currentColumnId,
      tokenList2Dsl(tokenList),
    );
  };

  useEffect(() => {
    const tempTokenList = genFormulaAdapter(defaultAst, externalInfo);
    const focus = editorRef.current?.view?.hasFocus;

    if (
      removeWhitespaceAndNewlines(tokenList2Dsl(tempTokenList)) !==
        removeWhitespaceAndNewlines(tokenList2Dsl(tokenList)) &&
      !focus
    ) {
      setTokenList(tempTokenList);
      setPromptInfo(null);
      setAutoCompleteOptions([]);
    } else {
      parseError(tokenList2Dsl(tokenList));
    }
  }, [defaultAst, externalInfo, parseError, tokenList]);

  return (
    <>
      <div
        className={classNames(styles.codeEditorWrapper, className, {
          [styles.codeEditorError]: errorList.length > 0,
          [styles.topBorderRadius]: borderRadius === 'top',
          [styles.bottomBorderRadius]: borderRadius === 'bottom',
        })}
      >
        <div
          className={classNames(styles.codeEditorInner, {
            [styles.errorCodeEditor]: errorList.length > 0,
          })}
        >
          <ReactCodeMirror
            basicSetup={{
              closeBrackets: false,
              highlightActiveLine: false,
            }}
            ref={editorRef}
            placeholder={placeholder}
            readOnly={readonly}
            value={tokenList2Dsl(tokenList)}
            onUpdate={onCodemirrorUpdate}
            onChange={onCodeMirrorChange}
            onBlur={onCodeMirrorBlur}
            extensions={[
              linterCom.of(linter(() => [])),
              createFieldDecorator(externalInfo),
              keyMapCom.of([]),
              normalTheme,
            ]}
          />
        </div>
        <ErrorMsg
          isEditorExpand={true}
          error={errorList.length > 0}
          errorMsg={errorList[0]?.errorMsg}
        />
      </div>
      {/* 代码自动提示 */}
      {autoCompleteOptions.length > 0 && selPos && (
        <AutoCompleteList
          show={autoCompleteOptions.length > 0}
          onClose={() => {
            setAutoCompleteOptions([]);
          }}
          options={autoCompleteOptions}
          selPos={{
            x: selPos.x,
            y: selPos.y + Y_OFFSET,
          }}
          complete={onComplete}
          tokenInSolPos={tokenInSelPos}
          tokenList={tokenList}
        />
      )}
      {/* 代码信息提示框 */}
      {!!promptInfo && tokenList.length !== 0 && selPos && (
        <PromptTrigger
          show={!!promptInfo}
          promptInfo={promptInfo}
          selPos={{
            x: selPos.x,
            y: selPos.y + Y_OFFSET,
          }}
          onClose={() => {
            setPromptInfo(null);
          }}
        />
      )}
    </>
  );
};

export default forwardRef(CodeEditor);
