export const list = {
  title: '全部数据集',
  create: '新建数据集',
  dataset: {
    displayName: '展示名',
    name: '名称',
  },
  sidebar: {
    title: '数据集',
  },
  creator: '负责人',
  total: ({ total }: { total: string | number }) => `${total}个数据集`,
  lastModified: '最后编辑',
  menu: {
    transfer: '转交',
    view: '查看',
    share: '分享',
    delete: '删除',
    editBaseInfo: '修改基础信息',
    edit: '编辑',
    deleteConfirm: '确定',
    deleteConfirmOk: '确定',
    deleteConfirmCancel: '取消',
    replaceDatasource: '替换数据源',
  },
  deleteSuccess: '删除成功',
  handle: '操作',
  category: '类目',
  batch: {
    transfer: (value: string) => `数据集 ${value} 无转交权限`,
    adjustCategory: (value: string) => `数据集 ${value} 无调整类目权限`,
  },
};

export const type = {
  sql: '自定义 SQL',
  file: '文件上传',
  table: '数据源表',
};

export const detail = {
  editBaseInfo: '修改基础信息',
  replaceDatasource: '替换数据源',
  editBaseInfoSuccess: '修改成功',
  accelerate: '加速',
  edit: '编辑',
  share: '分享',
  overview: '概览',
  column: {
    default: '字段',
    displayName: '展示名',
    name: '名称',
    desc: '描述',
    dataType: '数据类型',
    columnCount: ({ count }: { count: string | number }) => `${count} 个字段`,
    dimension: '维度',
  },
  relation: '关联',
  lineage: '血缘',
  authority: {
    default: '权限',
    userCount: ({ count }: { count: string | number }) => `${count} 个用户`,
    select: '用户/用户组',
  },
  baseInfo: '基础信息',
  datasetName: '名称',
  datasetDisplayName: '展示名',
  creator: '负责人',
  sourceType: '数据来源',
  createTime: '创建时间',
  updateTime: '最后编辑',
  category: '数据集类目',
};

export const relation = {
  from: {
    default: ({ count }: { count: string | number }) => `被关联(${count})`,
    dataset: '被关联数据集',
  },
  to: {
    default: ({ count }: { count: string | number }) => `关联(${count})`,
    dataset: {
      default: '关联数据集',
      required: '请选择关联数据集',
    },
  },
  radio: {
    lists: '列表',
    graph: '图形',
  },
  field: {
    select: '选择字段',
  },
  create: {
    default: '添加关联',
    success: '新建关联成功',
  },
  current: {
    dataset: {
      default: '当前数据集',
      suffix: '（当前）',
    },
  },
  type: {
    default: '关联方向',
    one2one: '1 : 1',
    many2one: 'N : 1',
  },
  conditions: {
    default: '关联字段',
    add: '添加关联字段',
  },
  creator: '负责人',
  menu: {
    deleteConfirmContent: '是否确认删除关联？',
  },
  modal: {
    title: '关联数据',
    ok: '确定',
    cancel: '取消',
    manual: '帮助手册',
  },
  condition: {
    delete: '删除',
  },
  error: {
    column: {
      required: '请选择字段',
      dataType: '字段数据类型不匹配',
      missing: '字段不存在',
    },
    dataset: {
      missing: '关联数据集不存在',
    },
  },
  update: {
    success: '编辑关联成功',
  },
  delete: {
    success: '删除关联成功',
  },
  er: {
    currentDataset: '当前数据集',
    empty: '当前数据集未与任何数据集进行关联',
  },
};

export const add = {
  title: '选择数据源表',
  stepOne: {
    datasource: {
      confirm: '下一步',
      cancel: '取消',
    },
  },
  datasource: {
    title: '使用数据源表创建',
    desc: '使用数据源中的表创建数据集',
    headerTitle: '选择数据源表',
  },
  SQL: {
    title: '使用 SQL 创建',
    desc: '使用 SQL 查询结果创建数据集',
    headerTitle: 'SQL 创建数据集',
  },
  operate: {
    cancel: '取消',
    create: '创建',
    save: '保存',
  },
  sidebar: {
    column: '字段',
    relation: '关联',
    createColumn: '新建字段',
    editColumn: '编辑字段',
  },
};

export const rightMenu = {
  typeConversion: {
    transformToDate: {
      default: '日期',
      yyyy: 'yyyy年',
      yyyymm: 'yyyy年MM️月',
      yyyymmdd: 'yyyy年MM️月dd日',
      ddmmyyyy: 'dd日MM月yyyy年',
    },
    transformToDateTime: {
      default: '日期时间',
      yyyymmddhhmmss: 'yyyy年MM️月dd日 HH:mm:ss',
    },
    transformToText: '文本',
    transformToBoolean: '布尔',
    transformToInt: '整数',
    transformToDecimal: '高精度小数',
    transformToDouble: '小数',
    transformToJSON: 'JSON',
    default: '字段类型',
  },
  rename: '重命名',
  editDescription: '编辑描述',
  edit: '编辑',
  delete: '删除',
};

export const createColumnModal = {
  name: '名称',
  description: '描述',
  ast: '计算公式',
  cancel: '取消',
  confirm: '确定',
  save: '保存',
  category: '数据集类目',
  displayName: '展示名',
  nameRequired: '该项不能为空',
  nameExist: '该字段已存在',
  nameFormat: '字段名称仅支持英文、数字、下划线，50字符',
  datasetNameFormat: '仅支持英文、数字以及下划线，不超过50个字符',
  datasetDisplayNameFormat:
    '仅支持中文、英文、数字以及标点符号和特殊符号，不超过50个字符',
};

export const createDataset = {
  editSql: {
    modal: {
      title: '编辑SQL',
    },
  },
  selectType: {
    title: '选择新建方式',
  },
  selectDatasource: '选择数据源',
};

export const saveDataset = '保存数据集';

export const editColumn = {
  title: {
    columnName: '字段名称',
    columnDisplayName: '字段展示名',
    sourceName: '来源数据字段',
    dataType: '数据类型',
    desc: '描述',
    operate: {
      default: '操作',
      delete: '删除',
      edit: '编辑',
      view: '查看',
    },
  },
  defaultColumnName: '计算字段',
};

export const addRelation = {
  edit: '编辑关联',
  remove: '移除',
};

export const sidebar = {
  refreshSetting: '更新设置',
  replaceDatasource: '替换数据源',
  synchronization: {
    column: '同步字段',
    sql: '编辑SQL',
    success: '同步成功',
    error: '同步失败',
  },
  relations: {
    empty: '暂无关联数据',
  },
  column: {
    empty: '无数据',
  },

  sql: {
    result: 'SQL 查询结果',
    tableNum: (num: number) => {
      return `${num} 个来源数据`;
    },
  },
  datasetCategoryRouter: {
    more: '更多',
    refresh: '刷新',
    createMore: '创建',
    rename: '重命名',
    delete: '删除',
    moveCategory: '移动类目',
    authManage: '权限管理',
    all: (types: string) => `全部${types}`,
    search: '数据集展示名、名称',
    category: '类目',
    dataset: '数据集',
    createDataset: '新建数据集',
    createSubCategory: '新建子类目',
  },
};

export const deleteColumn = {
  confirm: '确定',
  cancel: '取消',
  confirmContent: '是否确认删除字段？',
};

export const columns = {
  unEdit: '非计算字段不支持编辑公式',
  unDelete: '非计算字段不支持删除公式',
};

export const errorColumn = '以下字段存在错误：';

export const sourceColumnNotExist = '数据源中不存在该列';

export const datasource = {
  empty: {
    default: '暂无数据',
    desc: '从左侧数据源中选择数据',
    sql: '暂无查询结果',
  },
  title: '数据源',
};

export const sqlCreate = {
  query: {
    btn: '查询',
    preview: '预览前',
    rows: '行',
    result: '查询结果',
  },
};

export const overView = {
  title: '数据预览',
};

export const baseInfo = {
  metricNumber: '指标数',
  relationsNumber: '关联数据集',
  descTitle: '描述',
  desc: {
    expand: '展开',
    putAway: '收起',
  },
};

export const column = {
  emptyDesc: '没有符合搜索条件的字段，请重新尝试搜索',
};

export const partitionMappingModal = {
  partition: '分区',
  btn: '字段分区映射',
  noPartitionText: '该数据集依赖的数据源表没有分区字段，不支持设置分区映射。',
  title: '分区映射配置',
  partitionColumnDesc: '当前日期分区字段',
  dateGranularity: '日期粒度',
  noPartitionColumnDesc: '当前分区字段非日期类型，不支持设置日期字段分区映射。',
  partitionMappingDesc:
    '当前表日期类型字段与分区的映射关系，用于查询时进行分区筛选下推。',
  noDateLikeColumn: '当前分区表没有分区外的日期字段，不需要进行字段分区映射。',
  tableColumn: {
    open: '开启映射',
    column: '字段',
    value: '映射内容',
  },
  success: '更新成功',
  sqlForm: {
    title: '日期分区设置',
    required: '该字段不能为空',
    partitionColumn: '分区字段',
    bindPartitionColumn: '绑定分区字段',
    placeholder: {
      dateColumn: '选择日期类型字段',
      granularity: '选择时间粒度',
      sourceTable: '选择数据源表',
      bindPartitionColumn: '选择分区字段',
    },
  },
  partitionMappingTableEmptyDesc: '请先完成分区字段的配置',
};

export const overview = {
  noPermission: '无权查看，请向管理员申请权限',
};

export const dimension = {
  count: ({ count }: { count: number }) => `${count}个维度`,
  batchManage: '批量管理',
  table: {
    add: '新建维度',
    cancel: '取消',
    save: '保存',
    addBtn: '创建',
    batchAdd: '批量新建维度',
    addDesc: '选择维度绑定的字段',
    search: '搜索',
    displayName: '展示名',
    name: '名称',
    dimCode: '维度编码',
    status: '状态',
    description: '描述',
    column: '绑定字段',
    category: '类目',
    operate: '操作',
    approving: '审批中',
    delete: '删除',
    editCategory: '调整类目',
    errorMsg: '内容填写有误，请修改后再次尝试提交。',
    success: '已保存',
  },
  calibrate: {
    notEmpty: '该项不能为空',
    onlyOne: '名称不能重复',
  },
};

export const accelerate = {
  create: '新建物化方案',
  empty: {
    title: '未配置加速方案',
    btn: '配置加速',
  },
  list: '列表',
  dag: '图形',
};
