import { getUniqId } from '../../src/common/utils';
import { getRestfulResponse } from '../utils';
import {
  IQueryVertexLineageRes,
  EVertexType,
  EEdgeType,
  TVertex,
  IEdge,
  IMetricVertex,
  IVertex,
} from '../../src/services/lineage/types';
import { filterMultipleLayers } from '../../src/services/lineage';
import { rest } from 'msw';
import { EMetricType } from '../../src/typings/metric';
import { EOriginDataType } from '../../src/typings';
import { mockCategoryList } from '../category/mockData';
import _ from 'lodash';
import { EQueryEngineType } from '../../src/typings/formula';

const graphData = generateMockData('testId', 3, 6);

export function getDownstreamVertexes() {
  function createMockVertex(id: number, type: EVertexType): TVertex {
    return {
      vertexId: `v${id}`,
      vertexType: type,
      displayName: `${type} DisplayName ${id}`,
      description: `${type} Description ${id}`,
      hasMoreInput: Math.random() > 0.5,
      hasMoreOutput: Math.random() > 0.5,
      custom:
        type === EVertexType.METRIC
          ? {
              originDataType: _.sample(Object.values(EOriginDataType))!,
            }
          : undefined,
    } as TVertex;
  }

  const types = Object.values(EVertexType);
  const resData: IVertex[] = Array.from({ length: 20 }, (_, index) => {
    const typeIndex = index % types.length;
    return createMockVertex(index + 1, types[typeIndex]);
  });
  return resData;
}

export const lineage = [
  rest.get('/lineage/queryVertexLineage', async (req, res, ctx) => {
    const params = req.url.searchParams;
    const vertexId = params.get('vertex_id')!;
    const inputStep = Number(params.get('input_step'));
    const outputStep = Number(params.get('output_step')!);

    const resData = filterMultipleLayers(
      graphData,
      vertexId,
      inputStep,
      outputStep,
    );

    return res(ctx.json(getRestfulResponse(resData)));
  }),
  rest.get('/lineage/queryDownstreamVertexes', (req, res, ctx) => {
    const params = req.url.searchParams;
    const vertexId = params.get('vertex_id')!;
    const step = 0;

    return res(ctx.json(getRestfulResponse(getDownstreamVertexes())));
  }),
];

export function generateMockData(
  startVertexId: string,
  upstreamLayers: number,
  downstreamLayers: number,
): IQueryVertexLineageRes {
  const vertexList: TVertex[] = [];
  const edgeList: IEdge[] = [];

  // 中心节点是派生指标类型的节点
  const centerVertex: IMetricVertex = {
    vertexId: startVertexId,
    vertexType: EVertexType.METRIC,
    displayName: 'Center Metric',
    description: 'Center Description',
    custom: {
      originDataType: _.sample(Object.values(EOriginDataType))!,
      type: EMetricType.DERIVED,
      category: [_.sample(mockCategoryList)!],
    },
  };
  vertexList.push(centerVertex);

  // 生成上游
  let previousLayerVertices = [centerVertex];
  for (let i = 0; i < upstreamLayers; i++) {
    const newLayerVertices: TVertex[] = [];

    for (const vertex of previousLayerVertices) {
      const numNewVertices = Math.floor(Math.random() * 2) + 1; // 每层1到2个节点
      for (let j = 0; j < numNewVertices; j++) {
        const newVertexId = getUniqId();
        let newVertex: TVertex;

        if (
          vertex.vertexType === EVertexType.METRIC &&
          vertex.custom?.type === EMetricType.DERIVED
        ) {
          newVertex = {
            vertexId: newVertexId,
            vertexType: EVertexType.METRIC,
            displayName: 'Atomic Metric',
            description: 'Atomic Metric Description',
            custom: {
              type: EMetricType.ATOMIC,
              originDataType: _.sample(Object.values(EOriginDataType))!,
              category: [_.sample(mockCategoryList)!],
            },
          } as IMetricVertex;
        } else if (vertex.vertexType === EVertexType.METRIC) {
          newVertex = {
            vertexId: newVertexId,
            vertexType: EVertexType.DATASET,
            displayName: 'Dataset',
            description: 'Dataset Description',
          };
        } else {
          newVertex = {
            vertexId: newVertexId,
            vertexType: EVertexType.PHYSICAL_TABLE,
            displayName: 'Physical Table',
            description: 'Physical Table Description',
            custom: {
              datasourceType: EQueryEngineType.AIR,
              datasourceDisplayName: 'AIR Datasource',
            },
          };
        }

        newLayerVertices.push(newVertex);
        vertexList.push(newVertex);

        // 添加边
        edgeList.push({
          srcVertex: newVertex,
          dstVertex: vertex,
          edgeType: EEdgeType.TABLE_IMPACT,
        });
      }
    }

    previousLayerVertices = newLayerVertices as IMetricVertex[];
  }

  // 生成下游
  previousLayerVertices = [centerVertex];
  for (let i = 0; i < downstreamLayers; i++) {
    const newLayerVertices: TVertex[] = [];

    for (const vertex of previousLayerVertices) {
      const numNewVertices = Math.floor(Math.random() * 4) + 1; // 每层1到2个节点
      for (let j = 0; j < numNewVertices; j++) {
        const newVertexId = getUniqId();
        let newVertex: TVertex;

        if (_.random(100) > 50) {
          // 随机生成不同类型的节点
          newVertex = {
            vertexId: newVertexId,
            vertexType: EVertexType.ANALYSIS_VIEW,
            displayName: 'AnalysisView',
            description: 'analysisView desc',
          };
        } else {
          newVertex = {
            vertexId: newVertexId,
            vertexType: EVertexType.METRIC,
            displayName: 'Composite Metric',
            description: 'Composite Metric Description',
            custom: {
              type: EMetricType.COMPOSITE,
              category: [_.sample(mockCategoryList)!],
            },
          };
        }

        newLayerVertices.push(newVertex);
        vertexList.push(newVertex);

        // 添加边
        edgeList.push({
          srcVertex: vertex,
          dstVertex: newVertex,
          edgeType: EEdgeType.TABLE_IMPACT,
        });
      }
    }

    previousLayerVertices = newLayerVertices as IMetricVertex[];
  }

  return {
    vertexList,
    edgeList,
  };
}
