import { EResourceType } from '@/typings/authority';
import { userInfo } from '@/common/globalInfo/appConfig';

export const hasResourceAuthority = (resourceType: EResourceType) => {
  // 每个用户都会有tenant
  if (
    resourceType === EResourceType.TENANT ||
    resourceType === EResourceType.USER_PROPERTY
  )
    return getResourceAuthority(EResourceType.TENANT)?.canEdit;
  return userInfo?.allAuthorityResource?.some(
    (authorityResource) => authorityResource?.resourceType === resourceType,
  );
};

export const getResourceAuthority = (resourceType: EResourceType) => {
  return userInfo?.allAuthorityResource?.find(
    (authorityResource) => authorityResource?.resourceType === resourceType,
  );
};
