export const workbook = {
  copy: {
    success: 'Copy successful',
    error: 'Copy failed',
  },
  list: {
    batch: {
      transfer: (value: string) => {
        return `No transfer permission for dashboard ${value}`;
      },
    },
    menu: {
      transfer: 'Transfer',
      view: 'View',
      share: 'Share',
      editBaseInfo: 'Edit Basic Info',
      edit: 'Edit',
      copy: 'Copy',
      delete: 'Delete',
      deleteConfirm: 'Confirm',
      deleteConfirmContent: ({ name }: { name: string | number }) =>
        `Delete dashboard [${name}]?`,
      deleteConfirmOk: 'Confirm',
      deleteConfirmCancel: 'Cancel',
    },
    deleteSuccess: 'Delete successful',
    name: 'Name',
    unPublished: 'Unpublished',
    published: 'Published',
    creator: 'Owner',
    description: 'Description',
    status: 'Status',
    handle: 'Actions',
    lastModified: 'Last Modified',
    createTime: 'Created At',
    title: 'Dashboard',
    create: 'Create Dashboard',
    total: ({ total }: { total: string | number }) => `${total} Dashboards`,
  },
  detail: {
    title: {
      unnamed: 'Untitled Dashboard',
    },
  },
  result: {
    error: 'Failed to get Dashboard info',
  },
  basicInfo: {
    title: 'Edit Basic Info',
    name: 'Name',
    description: 'Description',
    saveSuccess: 'Save successful',
    confirm: 'Save',
    cancel: 'Cancel',
    error: {
      name: 'Enter name',
    },
  },
  header: {
    btn: {
      quit: 'Exit',
      save: 'Save Draft',
      reset: 'Reset Draft to Online Version',
      edit: 'Edit',
      share: 'Share',
      publish: 'Publish',
      publishSuccess: 'Publish successful',
    },
  },
  shareModal: {
    title: 'Share',
    success: 'Share successful',
  },
  area: {
    header: {
      data: 'Data',
      config: 'Config',
    },
  },
  canvas: {
    addChart: 'Add Chart',
    addFilter: 'Add Filter Component',
  },
  chart: {
    download: {
      noData: 'No data',
      failed: 'Query failed',
    },
    csv: {
      name: 'Untitled File',
    },
    title: {
      unnamed: 'Untitled Chart',
    },
    type: {
      pivotTable: 'Pivot Table',
      metricTree: 'Metric Tree',
      lineChart: 'Line Chart',
      columnChart: 'Grouped Bar Chart',
      barChart: 'Grouped Column Chart',
      pieChart: 'Pie Chart',
      scatter: 'Scatter Plot',
      radar: 'Radar Chart',
      area: 'Area Chart',
      combo: 'Combo Chart',
    },
    config: {
      line: {
        title: 'Row',
      },
      column: {
        title: 'Column',
      },
      value: {
        title: 'Value',
      },
      tab: {
        data: 'Data',
        insight: 'Insight',
      },
    },
    select: {
      placeholder: 'Drag or select dimensions',
    },
    value: {
      placeholder: 'Drag or select metrics',
    },
  },
  element: {
    filter: {
      title: {
        unnamed: 'New Filter',
      },
      dimension: {
        title: 'Filter Dimension',
      },
      type: {
        default: 'Component Style',
        number: 'Number Range',
        date: 'Date Picker',
        list: 'Dropdown List',
      },
      name: 'Component Name',
      noDimension: 'Select filter dimension',
      dimensionException: 'Filter dimension exception',
    },
    defaultTitle: 'Untitled Chart',
    handle: {
      copy: 'Copy',
      delete: 'Delete',
      download: 'Download',
      refresh: 'Refresh',
      downloadInfo: 'Download current chart data',
      editDescription: 'Edit Description',
    },
    description: 'Description',
    empty: {
      title: 'Select metrics and dimensions',
    },
  },
  pivotTable: {
    total: 'Total',
    subTotal: 'Subtotal',
    maxError:
      'Current pivot table data is incomplete, rows or columns exceed limit. Please adjust pivot table configuration',
  },
  viz: {
    queryFailed: 'Query failed',
  },
  config: {
    metricDataRange: 'Metric Time Range',
    metric: {
      noquick: 'None',
      periodicComparison: {
        default: 'Period Comparison',
        value: {
          day: 'PoP Value',
          other: 'PoP Value',
        },
        growthValue: {
          day: 'PoP Value',
          other: 'PoP Growth Value',
        },
        growth: {
          day: 'PoP Growth Rate',
          other: 'PoP Growth Rate',
        },
      },
      ranking: {
        default: 'Ranking',
        globalAsc: 'Global Ascending',
        globalDesc: 'Global Descending',
        rows: {
          asc: 'Row Ascending',
          desc: 'Row Descending',
        },
        columns: {
          asc: 'Column Ascending',
          desc: 'Column Descending',
        },
        groupAsc: 'Group Ascending',
        groupDesc: 'Group Descending',
      },
      proportion: {
        default: 'Proportion',
        global: 'Global Proportion',
        rows: 'Row Proportion',
        columns: 'Column Proportion',
        group: 'Group Proportion',
      },
    },
    column: {
      overflow: ({ maxLength }: { maxLength: string | number }) =>
        `Exceeds maximum limit of ${maxLength}`,
    },
  },
  picker: {
    metric: {
      default: 'Metrics',
      onlyViewPermission: 'Show Accessible Only',
      displayName: 'Metric Name:',
      noPermission: 'No metric access permission',
      desc: 'Business Logic:',
      unusable: ({ list }: { list: string | number }) =>
        `This metric cannot use dimensions ${list} for filtering`,
      fav: 'Favorite Metrics',
      analysis: 'All Metrics',
      search: 'Search',
      collapseAll: 'Collapse All',
    },
    dimension: {
      default: 'Dimensions',
      displayName: 'Dimension Name:',
      desc: 'Description:',
      unusable: ({ list }: { list: string | number }) =>
        `This dimension cannot analyze metrics ${list}`,
      analysis: 'Dimensions',
    },
    search: {
      placeholder: 'Search',
    },
    notFound: 'No Found',
  },
  groupChart: {
    x: {
      title: 'X Axis',
    },
    y: {
      title: 'Y Axis',
    },
    legend: {
      title: 'Legend',
    },
    category: {
      title: 'Category',
    },
  },
  metricTree: {
    mainMetric: 'Main Metric',
    subMetrics: 'Breakdown Metrics',
    editRelation: 'Edit Metric Relations',
    derivateCalc: {
      title: 'Auxiliary Metrics',
      placeholder: 'Select',
    },
    relation: {
      title: 'Edit Metric Relations',
      node: {
        subTitle: 'This metric consists of the following sub-metric relations',
        error: 'Expression contains unrecognizable characters',
        desc: 'Type [ to quickly add selected metrics',
        subDesc1: 'Supports keyboard input of +, -, *, /, ().',
        subDesc2: 'Example: [Average Order Value]*[Number of Buyers]',
      },
      save: 'Save and Update Chart',
    },
    chart: {
      mainMetricTip: 'Select main metric',
      relationTip: 'Click metric to edit breakdown relations',
      contributionRate: (mainRateStr: string) =>
        `Main Contribution Rate: ${mainRateStr} `,
      parentRate: (parentRateStr: string) => `(Parent: ${parentRateStr})`,
    },
    summary: {
      mainReason: '. Main influencing factors:',
      contributionRate: 'Contribution rate is',
      is: 'is',
      detail: 'Detailed Report',
      close: 'Close',
    },
    attributionModal: {
      title: 'Attribution Analysis',
    },
    insight: {
      title: 'Attribution Analysis',
      comparison: 'Comparison Object',
      showSummary: 'Show Attribution Summary',
      preview: 'Preview',
      error: {
        title: 'Metric configuration error:',
        mainMetric: 'Main metric exception',
        subMetric: 'Breakdown metric exception',
        mainMetricExpr:
          'Please add breakdown metrics and edit metric relations',
        metricTimeActive: 'Please enable metric date range',
        treeNode: (displayName: string) =>
          `Metric [${displayName}] formula is not valid arithmetic`,
      },
    },
  },
  menu: {
    title: {
      rename: 'Rename',
      delete: 'Delete',
      granularity: 'Date Granularity',
      sort: {
        default: 'Sort',
        asc: 'Ascending',
        desc: 'Descending',
      },
      axisOrient: 'Axis Position',
      vizType: 'Chart Style',
    },
  },
  filter: {
    empty: 'Drag or select dimensions',
    title: 'Filter',
    filterConfig: {
      title: 'Filter',
    },
    tooltip: {
      displayName: 'Display Name:',
      description: 'Description:',
    },
  },
  sidebar: {
    elementOverview: 'Page Overview',
    create: {
      pivotTableChart: 'Pivot Table',
      viz: 'Visualization',
      GroupedBarHorizontalChartDuotone: 'Bar Chart',
      lineChart: 'Line Chart',
      pieChart: 'Pie Chart',
    },
    replaceDataSource: {
      inUse: {
        emptyTips: 'No data',
      },
    },
  },
  entity: {
    exception: {
      referenceNotFount: ({ name }: { name: string | number }) =>
        `${name} is not available`,
      dataTypeNotMatch: ({ name }: { name: string | number }) =>
        `${name} data type changed`,
      noPermission: 'No metric access permission',
    },
  },
};

export const sidebar = {
  vizStyle: {
    other: 'Others',
  },
  dataConfig: 'Data',
  styleConfig: 'Style',
};

export const chart = {
  error: {
    msg: 'System error occurred',
  },
  styles: {
    categoryAxis: 'Category Axis',
    horizontalAxis: 'Horizontal Axis',
    verticalAxis: 'Vertical Axis',
    verticalAxis2: 'Vertical Axis 2',
    valueAxis: 'Value Axis',
    valueAxis2: 'Value Axis 2',
    showAxisName: ({ title }: { title: string }) => `Show ${title} Name`,
    showAxisLabel: ({ title }: { title: string }) => `Show ${title} Label`,
    axisRange: ({ title }: { title: string }) => `${title} Range`,
    showAxis: 'Show Axis',
    showTick: 'Show Ticks',
    showDataLabel: 'Show Labels',
    showGrid: 'Show Grid Lines',
    restoreDefault: 'Restore Default',
    directions: 'Label Direction',
    position: 'Position',
    axisType: {
      title: 'Scale Type',
      time: 'Time',
      value: 'Linear',
      category: 'Discrete',
    },
    categoryRange: {
      auto: 'Auto',
      custom: 'Custom',
      placeholder: {
        max: 'Maximum',
        min: 'Minimum',
      },
    },
    categoryNum: {
      type: 'Category',
      title: 'Show Category Count',
      all: 'All',
      custom: 'Custom',
      customTip: 'Enter display count, others will be merged as Others',
    },
    legend: {
      title: 'Legend',
      position: {
        title: 'Position',
        left: 'Left',
        right: 'Right',
        bottom: 'Bottom',
        top: 'Top',
      },
      label: 'Label Text',
      text: 'Text',
    },
    value: 'Value',
    radarChartStyle: {
      polygon: 'Polygon',
      circle: 'Circle',
    },
    dataLabel: {
      title: 'Data Label',
      position: 'Position',
      showRange: 'Display Range',
      top: 'Top',
      center: 'Center',
      bottom: 'Bottom',
    },
    direction: {
      title: 'Direction',
      level: 'Horizontal',
      column: 'Vertical',
    },
    range: {
      title: 'Display Range',
      auto: 'Auto',
      all: 'All',
    },
    labelContent: {
      value: 'Value',
      ratio: 'Ratio',
      valueOrRatio: 'Value/Ratio',
    },
    border: {
      title: 'Border',
      auto: 'Auto',
      open: 'On',
      close: 'Off',
    },
    gapWidth: {
      bar: 'Bar',
      title: 'Width',
    },
    chartArea: {
      title: 'Plot Area',
      isGradient: 'Enable Gradient Effect',
    },
    showRange: {
      maxValue: 'Max Value Only',
      minValue: 'Min Value Only',
      maxAndMinValue: 'Max/Min Values',
      endPoint: 'End Points',
      all: 'All',
    },
    lineLabelPosition: {
      auto: 'Auto',
      top: 'Above Line',
      bottom: 'Below Line',
    },
    scatterLabelPosition: {
      auto: 'Auto',
      top: 'Above Bubble',
      bottom: 'Below Bubble',
    },
    fillColor: 'Fill Area Between Points',
    showPoint: 'Show Data Points',
    lineStyle: {
      title: 'Visualization Style',
      lineStyle: 'Line Style',
      mode: 'Setting Mode',
      unify: 'Unified',
      part: 'Independent',
      type: 'Type',
      none: 'Straight',
      step: 'Step',
      stepStart: 'Step (Start)',
      stepEnd: 'Step (End)',
    },
    nullValue: {
      title: 'Null Value Handling',
      none: 'Hide',
      zero: 'Replace with 0',
    },
    showPointStyle: 'Show Data Points',
    pointStyle: 'Data Point Style',
    pointSize: 'Data Point Size',
    pieLabelContent: {
      category: 'Category',
      value: 'Value',
      percentage: 'Percentage',
      categoryValue: 'Category/Value',
      categoryPercentage: 'Category/Percentage',
      valuePercentage: 'Value/Percentage',
      categoryValuePercentage: 'Category/Value/Percentage',
    },
  },
};
