import _ from 'lodash';
import { graphql } from 'msw';
import getUserInfo from '../utils/getUserInfo';
import { getExternalDependencies, getMockDocument } from './mockData';

export const metricTree = [
  graphql.query('queryMetricTree', (req, res, ctx) => {
    const document = getMockDocument();
    return res(
      ctx.data({
        queryMetricTree: {
          id: 'metricTree1',
          name: 'metricTree1',
          description: 'metricTree1_desc',
          document,
          dependencies: getExternalDependencies(document),
          gmtCreate: new Date().getTime(),
          gmtUpdate: new Date().getTime(),
          creator: getUserInfo(),
          authority: {
            canAuth: true,
            canEdit: true,
          },
          everPublished: true,
        },
      }),
      ctx.delay(500),
    );
  }),

  graphql.query('queryMetricTreeList', (req, res, ctx) => {
    const NUM = 50;
    return res(
      ctx.data({
        queryMetricTreeList: {
          data: _.range(NUM).map((i) => ({
            id: i,
            name: `metricTree${i}`,
            description: `metricTree${i}_desc_long_desc_long_desc_long_desc`,
            gmtCreate: new Date().getTime(),
            gmtUpdate: new Date().getTime(),
            creator: getUserInfo(),
            everPublished: i % 3 !== 0,
            authority: {
              canDelete: i % 3 !== 0,
              canEdit: i % 3 !== 0,
              canAuth: i % 3 !== 0,
            },
          })),
          hasNext: true,
          pageNumber: 1,
          pageSize: NUM,
          total: NUM * 5,
        },
      }),
      ctx.delay(500),
    );
  }),

  graphql.mutation('createMetricTree', (req, res, ctx) => {
    const MOCK_NEW_METRIC_TREE_ID = 1000;
    return res(
      ctx.data({
        createMetricTree: MOCK_NEW_METRIC_TREE_ID,
      }),
    );
  }),

  graphql.mutation('updateMetricTree', (req, res, ctx) => {
    return res(
      ctx.data({
        updateMetricTree: true,
      }),
    );
  }),

  graphql.mutation('deleteMetricTree', (req, res, ctx) => {
    return res(
      ctx.data({
        deleteMetricTree: true,
      }),
    );
  }),

  graphql.mutation('updateMetricTreeBasicInfo', (req, res, ctx) => {
    return res(
      ctx.data({
        updateMetricTreeBasicInfo: true,
      }),
      ctx.delay(1000),
    );
  }),

  graphql.mutation('publishMetricTree', (req, res, ctx) => {
    return res(
      ctx.data({
        publishMetricTree: true,
      }),
      ctx.delay(500),
    );
  }),
];
