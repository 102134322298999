import _ from 'lodash';
import { graphql, rest } from 'msw';
import { EPeriodType } from '../../src/typings/metric';
import { getRestfulResponse } from '../utils';

export function getQueryDateTagList() {
  return _.map(_.range(_.random(4)), (i) => {
    return {
      name: `name ${i}`,
      id: `id ${i}`,
      description:
        'descriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescription',
      tableGuid: '产品',
      columnGuid: '日期',
      exprCode: `exprCode${i}`,
    };
  });
}

export function getPeriodList() {
  return _.map(_.range(20), (i) => {
    return {
      id: `id ${i}`,
      name: `name ${i}`,
      displayName: `displayName-${i}`,
      timeRange: '近7天',
      type:
        Math.random() > 0.5 ? EPeriodType.TO_DATE : EPeriodType.GRAIN_TO_DATE,
      typeParams: '-7 DAY of 0 DAY',
    };
  });
}

export const periodLimit = [
  graphql.query('queryDateTagList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryDateTagList: getQueryDateTagList(),
      }),
      ctx.delay(1000),
    );
  }),
  graphql.query('queryDateTagDetail', (req, res, ctx) => {
    return res(
      ctx.data({
        queryDateTagDetail: {
          name: `name`,
          id: `id`,
          description:
            'descriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescription',
          tableGuid: '产品',
          columnName: '仓库',
          path: [
            {
              id: 'id',
              name: 'name',
            },
          ],
        },
      }),
      ctx.delay(1000),
    );
  }),

  graphql.mutation('createDateTag', (req, res, ctx) => {
    return res(
      ctx.data({
        data: true,
      }),
      ctx.delay(1000),
    );
  }),

  graphql.mutation('deleteDateTag', (req, res, ctx) => {
    return res(
      ctx.data({
        data: true,
      }),
      ctx.delay(1000),
    );
  }),
  graphql.query('queryPeriodList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryPeriodList: getPeriodList(),
      }),
      ctx.delay(1000),
    );
  }),
  graphql.query('queryPeriodDetail', (req, res, ctx) => {
    return res(
      ctx.data({
        queryPeriodDetail: {
          name: `name`,
          id: `id`,
          displayName: 'displayName',
          timeRange: '近7天',
          typeParams: '-1 month',
        },
      }),
      ctx.delay(1000),
    );
  }),
  graphql.mutation('createPeriod', (req, res, ctx) => {
    return res(
      ctx.data({
        data: true,
      }),
      ctx.delay(1000),
    );
  }),
  graphql.mutation('updatePeriod', (req, res, ctx) => {
    return res(
      ctx.data({
        data: true,
      }),
      ctx.delay(1000),
    );
  }),
  graphql.mutation('deletePeriod', (req, res, ctx) => {
    return res(
      ctx.data({
        data: true,
      }),
      ctx.delay(1000),
    );
  }),
  graphql.mutation('movePeriodOrder', (req, res, ctx) => {
    return res(
      ctx.data({
        data: true,
      }),
      ctx.delay(1000),
    );
  }),

  rest.post(
    '/query_column_value',
    (
      req: {
        body: {
          columnName: string;
        };
      },
      res,
      ctx,
    ) => {
      const name = req.body.columnName || '';
      return res(
        ctx.json(
          getRestfulResponse({
            table: {
              [name]: _.range(100).map((i) => {
                return [i, 1, 1];
              }),
            },
          }),
        ),
      );
    },
  ),
];
