import { gql } from '@apollo/client';
import { useGqlLazyQuery } from '@/common/utils/gqlRequest';
import { ICalendarData, TCustomCalendarDetail } from '@/typings/customCalendar';
import { TFormula } from '@/typings/formula';

export const useGetCustomCalendarList = () => {
  const schema = gql`
    query queryCustomCalendarList {
      queryCustomCalendarList {
        id
        displayName
        name
        granularity
      }
    }
  `;

  return useGqlLazyQuery<
    { queryCustomCalendarList: TCustomCalendarDetail[] },
    {}
  >(schema);
};

export const useGetCustomCalendarData = () => {
  const schema = gql`
    query queryCalendarData($id: LONG) {
      queryCalendarData(id: $id) {
        date
        weekOfMonth
        monthOfQuarter
        quarterOfYear
        year
      }
    }
  `;

  return useGqlLazyQuery<
    { queryCalendarData: ICalendarData[] },
    { id: string }
  >(schema);
};

export const useGetCustomCalendarDetail = () => {
  const schema = gql`
    query queryCustomCalendarDetail($id: LONG) {
      queryCustomCalendarDetail(id: $id) {
        id
        name
        displayName
        templateType
        templateConfig {
          ... on WmqyCalendarConfigResponse {
            __typename
            startDayOfWeek
            weekMonthCycle
            endDayOfYear {
              month
              lastDay
            }
            extraWeekBelongMonth
            calendarFormat {
              year
              quarter
              month
              week
            }
          }
          ... on MqyCalendarConfigResponse {
            __typename
            startDayOfMonth {
              month
              dayOrder
              dayIndex
            }
            startMonthOfYear
            calendarFormat {
              year
              quarter
              month
              week
            }
          }
        }
      }
    }
  `;

  return useGqlLazyQuery<
    { queryCustomCalendarDetail: TCustomCalendarDetail },
    { id: string }
  >(schema);
};

export const useQueryPreviewDate = () => {
  const schema = gql`
    query expressionEval($expr: JSON) {
      expressionEval(expr: $expr)
    }
  `;

  return useGqlLazyQuery<{ expressionEval: string }, { expr: TFormula }>(
    schema,
  );
};
