import _ from 'lodash';
import { EDataType, EInputType } from '../../src/typings/property';
import {
  EAuthenticationStatus,
  EHttpLoginConfigInputType,
  ELoginAuthenticationType,
  ESoftIntegrationType,
  ESwitchStatus,
} from '../../src/typings/softIntegration';
import { getUniqId } from '../../src/common/utils';

export const getSoftIntegrationMockData = () => {
  return {
    type: ESoftIntegrationType.ENTERPRISE_WECHAT,
    defConfig: [
      {
        dataType: EDataType.TEXT,
        inputType: EInputType.TEXT,
        placeholder: '填入corpId',
        required: true,
        description: '这是corpId',
        name: 'corpId',
        displayName: 'corpId',
        defaultValue: '',
      },
      {
        dataType: EDataType.TEXT,
        inputType: EInputType.TEXT,
        placeholder: '填入AgentId',
        required: true,
        description: '这是AgentId',
        name: 'AgentId',
        displayName: 'AgentId',
        defaultValue: '',
      },
      {
        dataType: EDataType.TEXT,
        inputType: EInputType.TEXT,
        placeholder: '填入Secret',
        required: true,
        description: '这是Secret',
        name: 'Secret',
        displayName: 'Secret',
        defaultValue: '',
      },
      {
        dataType: EDataType.TEXT,
        inputType: EInputType.TEXT,
        placeholder: '填入redirectUri',
        required: true,
        description: '这是redirectUri',
        name: 'redirectUri',
        displayName: '回调地址',
        defaultValue: 'demo.can.aloudata.com',
      },
    ],
    description: '企业微信',
    status: _.sample([ESwitchStatus.CLOSE, ESwitchStatus.OPEN]),
    records: [],
  };
};

export const getLoginAuthentication = (type: ELoginAuthenticationType) => {
  if (type === ELoginAuthenticationType.ACCOUNT_PASSWORD) {
    return {
      type: ELoginAuthenticationType.ACCOUNT_PASSWORD,
      status: EAuthenticationStatus.FORCE_OPEN,
      config: [
        {
          userAuthentication: {
            // 超管
            accountOwner: EAuthenticationStatus.FORCE_OPEN,
            // 手动添加的用户
            manualAccount: ESwitchStatus.OPEN,
            // 同步的用户
            syncAccount: ESwitchStatus.CLOSE,
          },
        },
      ],
    };
  }

  if (type === ELoginAuthenticationType.HTTP) {
    return {
      type: ELoginAuthenticationType.HTTP,
      status: EAuthenticationStatus.OPEN,
      config: [
        {
          code: getUniqId(),
          displayName: 'Http认证1',
          certificationAddress: 'certificationAddress',
          config: {
            params: [
              {
                name: 'account',
                displayName: '用户名',
                userFillIn: true,
                inputType: EHttpLoginConfigInputType.TEXT,
                defaultValue: '',
              },
              {
                name: 'password',
                displayName: '密码',
                userFillIn: true,
                inputType: EHttpLoginConfigInputType.PASSWORD,
                defaultValue: '',
              },
            ],
            result: {
              codePath: 'codePath',
              accountPath: 'accountPath',
            },
          },
          userAuthentication: {
            // 手动添加的用户
            manualAccount: _.sample([ESwitchStatus.CLOSE, ESwitchStatus.OPEN]),
            // 同步的用户
            syncAccount: _.sample([ESwitchStatus.CLOSE, ESwitchStatus.OPEN]),
          },
        },
        {
          code: getUniqId(),
          displayName: 'Http认证2',
          certificationAddress: 'certificationAddress',
          config: {
            params: [
              {
                name: 'account',
                displayName: '用户名',
                userFillIn: true,
                defaultValue: '',
              },
              {
                name: 'password',
                displayName: '密码',
                userFillIn: true,
                defaultValue: '',
              },
              {
                name: 'test',
                displayName: '不用填',
                userFillIn: false,
                defaultValue: 'lalalalal',
              },
            ],
            result: {
              codePath: 'codePath',
              accountPath: 'accountPath',
            },
          },
          userAuthentication: {
            // 手动添加的用户
            manualAccount: _.sample([ESwitchStatus.CLOSE, ESwitchStatus.OPEN]),
            // 同步的用户
            syncAccount: _.sample([ESwitchStatus.CLOSE, ESwitchStatus.OPEN]),
          },
        },
      ],
    };
  }

  if (type === ELoginAuthenticationType.ENTERPRISE_WECHAT) {
    return {
      type: ELoginAuthenticationType.ENTERPRISE_WECHAT,
      config: [
        {
          softIntegrationDetail: {
            code: getUniqId(),
            displayName: '企业微信',
            softIntegration: getSoftIntegrationMockData(),
          },
          userAuthentication: {
            // 手动添加的用户
            manualAccount: _.sample([ESwitchStatus.CLOSE, ESwitchStatus.OPEN]),
            // 同步的用户
            syncAccount: _.sample([ESwitchStatus.CLOSE, ESwitchStatus.OPEN]),
          },
        },
      ],
    };
  }
};
