import { EColumnType } from './dataset';
import { EOriginDataType } from './formula';
import { EColumnDataType, EDateGranularityType, EUnit } from '.';

import { IFilterItem } from '@/pages/Metric/Detail/Content/Authority/RowFilterTable/EditConditionalRuleModal/DimensionFilter/filter';

import { IFilter, TDateRangeType } from '@/common/domain/filter/Filter/filter';
import { TFilter } from '@/common/domain/filter/NewFilter/types';
import { IPreAgg, ISamePeriodOffset, TMetric } from './metric';
import { TCustomTimeValue } from '@/common/domain/filter/NewFilter/common/CustomTimePicker/types';
import { TDimension } from './dimension';
import { TCustomTimeValueWithBaseTime } from '@/common/domain/filter/NewFilter/MetricTimePicker/types';
import { TNode } from '@aloudata/aloudata-design/dist/LogicTree';

export type TMetricTimeConfig = TCustomTimeValue;

export type TMetricTime = TCustomTimeValueWithBaseTime & {
  active: boolean;
};

// 衍生计算类型定义
export enum EQuickCalcType {
  // 环比增长率
  GROWTH = 'SAME_GROWTH',
  // 环比值
  VALUE = 'SAME_VALUE',
  //环比增长值
  GROWTH_VALUE = 'SAME_GROWTH_VALUE',
  // 排名
  RANKING = 'RANKING',
  // 占比
  PROPORTION = 'PROPORTION',
}

export enum EQuickCalcPeriod {
  DAY = 'dod',
  WEEK = 'wow',
  MONTH = 'mom',
  QUARTER = 'qoq',
  YEAR = 'yoy',
}

// 指标周期对应的 id 中的名称
export const quickCalcPeriodMap: {
  [key: string]: string;
} = {
  [EDateGranularityType.DAY]: EQuickCalcPeriod.DAY,
  [EDateGranularityType.WEEK]: EQuickCalcPeriod.WEEK,
  [EDateGranularityType.MONTH]: EQuickCalcPeriod.MONTH,
  [EDateGranularityType.QUARTER]: EQuickCalcPeriod.QUARTER,
  [EDateGranularityType.YEAR]: EQuickCalcPeriod.YEAR,
};

// 指标计算方式对应的 id 中的名称
export const quickCalcMethodMap: {
  [key: string]: string;
} = {
  [EQuickCalcType.GROWTH]: 'growth',
  [EQuickCalcType.VALUE]: 'value',
  [EQuickCalcType.GROWTH_VALUE]: 'growthvalue',
};

export enum EOrderType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type TOrder = {
  id: string;
  column: {
    id: string;
    type: EColumnType;
  };
  type: EOrderType;
};

export type TQuickCalc =
  | {
      granularity: EDateGranularityType;
      calculateType: EQuickCalcType;
    }
  | undefined;

export type TAnalysisMetric = {
  id: string;
  metric: {
    code: string;
    originDataType: EOriginDataType;
    name: string;
    displayName: string; // 分析视图中固化的名称
  };
  // 衍生计算的定义
  quickCalc?: TQuickCalc;
};

// 查询视图供前端消费的配置定义
export interface IAnalysisConfig {
  document: IDocument;
  // 下面的字段均已废弃，是为了兼容历史数据保留的
  // ---------
  // 选择的指标
  metrics?: Array<TAnalysisMetric>;
  // 选择的维度
  dimensions?: Array<{
    id: string;
    column: {
      name: string;
      displayName: string; // 分析视图中固化的名称
      originDataType: EOriginDataType;
    };
    granularity?: EDateGranularityType;
  }>;
  // 指标日期（特殊标志，单独领出来）
  metricTimeActive?: boolean;
  // 指标日期筛选
  metricTimeFilter?: {
    active: boolean;
    value: TDateRangeType;
  };
  // 筛选
  filters?: IFilter<IFilterItem>;
  // 排序
  orders?: Array<TOrder>;
}

export enum EDateFilterUnit {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST30 = 'last30',
  LAST180 = 'last180',
  LAST365 = 'last365',
  LAST3YEARS = 'last3years',
  WEEK = 'week',
  LAST_WEEK = 'last_week',
  LAST7WEEKS = 'last7weeks',
  LAST14WEEKS = 'last14weeks',
  LAST21WEEKS = 'last21weeks',
  MONTH = 'month',
  LAST_MONTH = 'last_month',
  LAST6MONTHS = 'last6months',
  LAST12MONTHS = 'last12months',
  QUARTER = 'quarter',
  LAST_QUARTER = 'last_quarter',
  LAST4QUARTERS = 'last4quarters',
  LAST8QUARTERS = 'last8quarters',
  LAST12QUARTERS = 'last12quarters',
  YEAR = 'year',
  LAST_YEAR = 'last_year',
  CUSTOM = 'custom',
}

export interface IDocument {
  pages: string[];
  pagesMap: Record<string, IPage>;
  elementMap: Record<string, TElement>;
  dependencies: Record<string, IDependencyItem>;
  view: IDocumentView;
}

export interface IDependencies {
  metrics: TMetric[];
  dimensions: TDimension[];
}

export type TFilterInAnalysisView = TFilter & {
  id: string;
};

export interface IFilterRelationShip {
  // 哪些组件可以筛选其他组件
  filter: {
    [key: string]: string[];
  };
  // 哪些组件被其他组件筛选
  filteredBy: {
    [key: string]: string[];
  };
}

export interface IDateLimit {
  disabled: boolean;
  config?: TCustomTimeValue;
  preAggs: IPreAgg[];
  isPreAggCustom: boolean;
}

export interface IDependencyItem {
  id: string;
  metrics: string[];
  dimensions: string[];
}

export type TElement = ITableElement;

// 筛选器组件类型
export enum EFilterComponentType {
  LIST = 'LIST',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
}

export interface IBasicChartElement {
  id: string;
  pageId: string;
  title: string;
  // element 布局信息
  dimensionsMap: Record<string, IDimension>;
  metricsMap: Record<string, IMetric>;
  filters?: TFilterInAnalysisView[];
  filterDependency: Record<string, IFilterDependency>;
  metricTimeFilter: TMetricTime;
  // 排序
  sorts: ISort[];
}

export interface ITableElement extends IBasicChartElement {
  columns: string[];
  values: string[];
}

// 不继承指标
export interface IMetric {
  def: {
    code: string;
  };
  id: string;
  // 指标的名称
  name: string;
  // 展示名
  displayName: string;
  // 分析视图服务端认的列名
  columnName: string;
  // 用户输入的展示名
  title?: string;
  derivativeCalc?: IDerivateCalc;
  description?: string;
  originDataType: EOriginDataType;
  dataType: EColumnDataType;
  exception?: IException;
  unit: EUnit;
  // 时间限定
  dateLimit: IDateLimit;
  // 业务限定依赖
  filterDependency: Record<string, IFilterDependency>;
  // 业务限定
  filters: TNode<TFilterInAnalysisView>;
  // filters: TFilter[];
}

export interface IDerivateCalc {
  // 类型定义 有环比增长率、环比值、环比增长值
  type: EQuickCalcType;
  /**
   * @deprecated 已经移到 offset 中，这里保留是为了兼容老数据
   */
  granularity?: EDateGranularityType;
  // 同环比偏移
  offset?: ISamePeriodOffset;
  // 全局，还是组内
  isGlobal?: boolean;
  // 排名，标记升序或降序
  isAsc?: boolean;
  // 排名和占比，组内情况下选择的维度列表，为空则表示全局
  dimensions?: string[];
}

export interface IDimension {
  def: {
    dimensionName: string;
  };
  id: string;
  // 在分析簿中展示名称
  displayName: string;
  // 维度的展示名
  dimensionDisplayName?: string;
  // 名称
  name: string;
  // 分析视图服务端认的列名
  columnName: string;
  // 用户输入的展示名
  title?: string;
  // 粒度
  granularity?: string;
  dataType: EColumnDataType;
  originDataType: EOriginDataType;
  description: string; // 描述
  exception?: IException;
}

export interface IFilterDependency {
  def: {
    dimensionName: string;
  };
  id: string;
  title?: string;
  granularity?: string;
  dataType: EColumnDataType;
  originDataType: EOriginDataType;
  description: string;
  columnType: EColumnType;
  // 在分析簿中展示名
  displayName: string;
  // 维度的展示名
  dimensionDisplayName?: string;
  // 维度名
  name: string;
  exception?: IException;
  isFillConfig: boolean;
}

export enum EExceptionType {
  // 外部引用不存在
  REFERENCE_NOT_FOUND = 'REFERENCE_NOT_FOUND',
  // 外部引用发生变化
  REFERENCE_CHANGED = 'REFERENCE_CHANGED',
  // 外部引用没有权限
  REFERENCE_NO_PERMISSION = 'REFERENCE_NO_PERMISSION',
}

export interface IException {
  type: EExceptionType;
  message: string;
}

export enum ESortType {
  DIMENSION = 'DIMENSION',
  METRIC = 'METRIC',
}

export interface ISort {
  // 指标或者维度的id
  id: string;
  type: ESortType;
  // 升降序
  isAsc: boolean;
}

export interface IPage {
  // page id
  pageId: string;
  // page title
  title: string;
}

export interface IDocumentView {
  // 当前选择的 page Id
  activePage: string;
  // 当前选中的 element Id
  activeElement?: string;
} // 组件状态
export enum EElementStatus {
  // 初始状态  未触发请求 无配置
  INIT = 'init',
  // 加载中 查询中 显示顶部loading条，内容区域展示上一次显示内容
  LOADING = 'loading',
  // 正常显示
  COMPLETE = 'complete',
}

// 维度和指标的可用关系
export interface IAvailableMap {
  dimension: Record<string, Set<string>>;
  metric: Record<string, Set<string>>;
}
