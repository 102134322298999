import { TCustomTimeValueWithBaseTime } from '@/common/domain/filter/NewFilter/MetricTimePicker/types';
import { TSingleAttributionTableRow } from '@/services/queryAttribution/types';

export enum EComparisonType {
  // 日环比
  DOD = 'DOD',
  // 周同比
  WOW = 'WOW',
  // 月同比
  MOM = 'MOM',
  // 季同比
  QOQ = 'QOQ',
  // 年同比
  YOY = 'YOY',
  // 自定义
  CUSTOM = 'CUSTOM',
}

export interface IAttributionAnalysisRange {
  // 分析日期
  current: TCustomTimeValueWithBaseTime;
  // 分析日期的时间粒度
  granularity: string;
  // 对比方式
  comparisonType: EComparisonType;
  // 自定义对比日期，是具体的时间范围，到秒
  startDateTime?: string;
  endDateTime?: string;
}

// 单个影响因素数据
export interface ISingleInfluenceFactor extends TSingleAttributionTableRow {
  dimension: string;
}

// 维度值表格数据
export interface IDimensionValueAnalysisData
  extends Omit<TSingleAttributionTableRow, 'relativeContributionRate'> {
  parentKey: string;
  depth: number;
  key: string;
  dimension: string;
  children?: IDimensionValueAnalysisData[];
}

export type TComparisonDateValue = Omit<
  IAttributionAnalysisRange,
  'current' | 'granularity'
>;
