export const tab = {
  label: {
    systemIntegration: {
      title: 'Integration',
      softIntegration: 'Office Software',
      messagePush: 'Message Push',
    },
    loginAuthentication: 'Login Authentication',
  },
};

export const user = {
  title: 'Users',
  addUser: 'Create User',
  addSuccess: 'Added Successfully',
  editSuccess: 'Edited Successfully',
  table: {
    account: 'Username',
    nickName: 'Nickname',
    total: ({ total }: { total: string | number }) => `${total} Users`,
    role: 'Role',
    userType: 'User Type',
    status: {
      title: 'Status',
      placeholder: '选择',
      text: (ban: boolean) => (!ban ? 'Enabled' : 'Disabled'),
    },
    createMode: {
      manual: 'Manual Creation',
      sync: 'User Sync',
    },
    operate: {
      title: 'Actions',
      statusBtn: (ban: boolean) => (ban ? 'Enable' : 'Disable'),
      accountBind: 'Account Binding',
      resetPwd: 'Reset Password',
      more: 'More',
    },
  },
  executeModal: {
    title: 'Recent Sync',
    des: 'Last Synced: ',
    cancel: 'Cancel',
    success: 'Sync task initiated, please refresh the page later',
    trigger: 'Sync Now',
  },
  resetPwdModal: {
    title: 'Reset Password',
    desc: (nickname: string) => `Reset password for ${nickname}`,
    getPwd: 'Click to get new password',
    pwdDesc: 'User password has been reset, new password:',
    copy: 'Copy',
    copySuccess: 'Copied to clipboard',
  },
  edit: 'Edit',
  delete: {
    default: 'Delete',
    confirm: 'Remove',
  },
  deleteModal: {
    title: 'Delete User',
    desc: ({ username }: { username: string | number }) =>
      `Delete user ${username} from system. Work content generated by the deleted user will be transferred to platform administrator.`,
  },
  searchEmptyDesc: 'No users match the search criteria, please try again',

  userSync: {
    open: 'User Sync: Enabled',
    syncImmediately: 'Sync Now',
    syncConfig: 'Sync Setting',
  },
  useSyncModal: {
    filter: 'Filter',
    title: 'Sync Users',
    labels: {
      open: 'Enable',
      userSource: {
        title: 'User Source',
        options: {
          dataSource: 'Data Source',
          officeSoftware: 'Office Software',
        },
      },
      extendedAttr: {
        title: 'Extended Attributes',
        placeholder: 'Enter extended attribute name',
      },
      syncUser: {
        title: 'Sync User',
        fields: {
          dataSource: 'Table',
          softIntegration: 'Software',
          userName: 'Username',
          userNickname: ' Nickname',
          password: {
            title: 'Password',
            options: {
              defaultPassword: 'Default',
              bindingField: 'Binding Field',
            },
            hint: (text: string) => 'Default: ' + text,
            forcePasswordUpdate: 'Force password update after first login',
          },
          phoneNumber: 'Phone Number',
          email: 'Email',
        },
        placeholders: {
          dataSource: 'Select data source table',
          softIntegration: 'Select office software',
          userName: 'Select username',
          userNickname: 'Select Nickname',
          phoneNumber: 'Select phone number',
          email: 'Select email',
          pwd: 'Select password',
        },
      },
      syncSettings: {
        title: 'Sync Settings',
        syncMethod: {
          title: 'Sync',
          options: {
            manualSync: 'Manual',
            scheduledSync: 'Scheduled',
          },
        },
        unmatchedUser: {
          title: 'Unmatched',
          options: {
            maintainOriginalState: 'Maintain Original State',
            setToInactive: 'Set to Inactive',
          },
        },
        newUserRole: 'New User Role',
      },
    },
    success: 'Saved Successfully',
  },
  softwareBind: {
    bind: 'Office Software Binding',
    modal: {
      title: 'Office Software Binding',
      emptyDesc: 'No office software integration configured',
      add: 'Configure office software integration',
      saveSuccess: 'Saved Successfully',
      form: {
        selectPlaceholder: 'Select',
        mode: 'Matching',
        auto: 'Auto Match',
        manual: 'Manual Specify',
        manualDesc:
          'Please bind [Software Name] account for each user separately in [Member Management]',
        userProperty: 'User Property',
        marry: 'Match',
        enterpriseWechatProperty: 'Enterprise WeChat Property',
        updateSetting: 'Update Settings',
        cycle: 'Periodic Update',
        manualUpdate: 'Manual Update',
        updateDesc: 'Update once',
        updateNow: 'Update Now',
        updateSuccess: 'Update Successfully',
      },
    },
    userBindModal: {
      title: 'Account Binding',
      save: 'Save',
      search: 'Search',
      selectPlaceholder: 'Select',
      saveSuccess: 'Saved Successfully',
      binUser: 'Bind Enterprise WeChat User',
      notEmpty: 'This property cannot be empty',
      mappingIdExist: 'This account has been bound',
      matchStatus: {
        success: 'Match Successfully',
        fail: 'Match Failed',
        not: 'Not Bound',
      },
    },
  },
};

export const role = {
  owner: 'Super Admin',
  admin: 'Admin',
  analyst: 'Metric Definer',
  usage: 'Metric User',
  viewer: 'Viewer',
};

export const userInfoModal = {
  create: {
    title: 'Create User',
    confirm: 'Create',
  },
  moreInfo: 'More Information',
  edit: {
    title: 'Edit User',
    confirm: 'Save',
  },
  label: {
    account: 'Username',
    nickname: 'Nickname',
    password: 'Password',
    role: 'Role',
    userGroup: 'User Group',
    email: 'Email',
    resetPasswordFirstLogin: 'Force password update after first login',
    phone: 'Phone Number',
  },
  input: {
    placeholder: 'Enter',
  },
  password: {
    placeholder: '6 to 20 characters password',
  },
  error: {
    phone: 'Invalid phone number format',
    email: 'Invalid email format',
  },
};

export const userList = {
  deleteConfirm: 'Confirm delete this user?',
};

export const userInfo = {
  form: {
    required: 'This field is required',
  },
  accountExist: 'Username already exists',
  phoneExist: 'Phone number already exists',
  emailExist: 'Email already exists',
  account: {
    format:
      'Only supports English, numbers, punctuation and special characters, max 50 characters',
  },
};

export const userGroup = {
  title: 'User Groups',
  input: {
    userGroup: {
      placeholder: 'Search',
    },
  },
  addBtn: {
    text: 'Create User Group',
    syncGroup: 'Sync User Group',
    syncGroupOpen: 'User Group Sync: Enabled',
  },
  treeNode: {
    moreAction: {
      addGroup: 'Add Subgroup',
      rename: 'Rename',
      delete: 'Delete',
    },
  },
  duplicateName: 'User group name already exists',
  userList: {
    empty: {
      title: 'No Users',
    },
  },
  userGroup: {
    sync: 'User Group Sync',
    manual: 'Manual Creation',
    search: {
      empty: 'No user groups match the search criteria, please try again',
    },
  },
  user: {
    search: {
      empty: 'No users match the search criteria, please try again',
    },
  },
  emptyText: 'No User Groups',
  deleteGroup: {
    title: 'Delete User Group',
    desc: 'Deleting a user group will also delete all subgroups under it',
    confirm: 'Delete',
    cancel: 'Cancel',
  },
  deleteUser: {
    confirm: 'Delete',
    cancel: 'Cancel',
  },
  emptyGroup: {
    title: 'Create User Group',
  },
  userCount: ({ count }: { count: string | number }) => `${count} Users`,
  userGroupCount: ({ count }: { count: string | number }) =>
    `${count} User Groups`,
  searchPlaceholder: 'Search',
  groupSyncModal: {
    organization: 'User Group Structure Sync',
    groupId: 'Group ID',
    displayName: 'Group Name',
    parentGroupId: 'Parent Group ID',
    userBind: 'User Group Membership Sync',
    account: 'Username',
    inputPlaceHolder: (text: string) => `Select ${text}`,
  },
};

export const useGroup = {
  addUserToGroup: 'Add User',
};

export const userProperty = {
  default: 'User Attributes',
  count: ({ count }: { count: string | number }) => `${count} User Attributes`,
  add: 'Add User Attribute',
  property: {
    name: 'Name',
    displayName: 'Display Name',
    displayNameRequired: 'Display name cannot be empty',
    displayNameRepeat: 'Display name already exists',
    nameRequired: 'Name cannot be empty',
    nameFormat:
      'Only supports letters, numbers and underscores, consecutive underscores not allowed',
    nameRepeat: 'Name already exists',
    edit: {
      default: 'Edit',
      success: 'Edit Successfully',
    },
    modal: {
      edit: 'Edit User Property',
    },
    add: {
      success: 'Add Successfully',
    },
    delete: {
      default: 'Delete',
      title: 'Delete User Attribute',
      desc: ({
        displayName,
        name,
      }: {
        displayName: string | number;
        name: string | number;
      }) => `Confirm delete user attribute [${displayName}/${name}]?`,
      confirm: 'Delete',
      cancel: 'Cancel',
    },
    defManage: 'Attribute Management',
    valueMange: 'Attribute Value Management',
  },
  value: {
    count: ({ count }: { count: string | number }) => `${count} Users`,
    addInBatches: 'Batch Import Attribute Values',
    edit: 'Edit Attribute Values',
    batchModal: {
      title: 'Batch Import Attribute Values',
      step1: '1. Upload Attribute Content',
      step2: '2. Update Attributes',
      downloadFile: 'Current Attribute File',
      fileOverMax: 'File size exceeds limit',
      fileUploadDesc:
        'Recommend downloading current Attribute file for batch import. Only supports xsl format files, max size 20 MB.',
      fileUploadTitle: 'Upload Attribute Content',
      uploadFile: 'Click to Upload',
      uploadAreaDesc: ' or Drag Files Here',
      upLoading: 'Extracting file data...',
      uploading: {
        error: 'File upload failed',
        error2: 'File parsing failed',
        resetBtn: 'Upload Again',
      },
      search: {
        empty: 'No matching content found, please try again.',
      },
      fileNotice:
        'User attribute upload template, please note: 1. Do not modify first three rows 2. Modify attribute values for users',
      selectUpdateList: {
        title: 'Select values to update',
        selected: ({
          count,
          number,
        }: {
          count: string | number;
          number: string | number;
        }) => `Selected ${count}/${number}`,
      },
      preview: {
        title: 'Property Value Preview',
        emptyTitle: 'No preview content',
        emptyDesc1: 'Please upload property file and check values to update.',
        emptyDesc2: 'Please check values to update.',
      },
    },
  },
};

export const category = {
  deleteSuccess: 'Delete Successfully',
  metric: {
    title: 'Metric Category',
  },
  dimension: {
    title: 'Dimension Category',
  },
  dataset: {
    title: 'Dataset Category',
  },
  analysisView: {
    title: 'Metrics View Category',
  },
  search: {
    empty: 'No categories found',
  },
  addNew: 'Create Category',
  newCategory: 'New Category',
  delete: 'Delete',
  deleteConfirm: 'Delete Category',
  deleteConfirmContent:
    'Deleting this category may affect resources under it, confirm delete?',
  rename: 'Rename',
  unCategory: 'Uncategorized',
};

export const authRule = {
  title: 'Permission Rules',
  metric: {
    name: 'Metric',
    subTitle: 'Auto-grant permissions based on metric properties',
    properties: 'Metric Properties',
    authRole: 'Authorize Metric Roles',
  },
  dimension: {
    name: 'Dimension',
    subTitle: 'Auto-grant permissions based on dimension properties',
    properties: 'Dimension Properties',
    authRole: 'Authorize Dimension Roles',
  },
  noDefaultRole: `No default Role`,
  resourceRoleName: (resourceName: string, roleName: string) =>
    `${resourceName}${roleName}`,
  save: 'Save',
  saveSuccess: 'Save Successfully',
};

export const approveSetting = {
  title: 'Approval Settings',
  metricApprove: 'Metric Approval',
  dimensionApprove: 'Dimension Approval',
  metric: 'Metric',
  dimension: 'Dimension',
  table: {
    node: 'Approval Node',
    desc: 'Node Description',
    open: 'Enable Approval',
    operate: {
      title: 'Actions',
      edit: 'Edit',
    },
  },
  nodeType: {
    add: 'Publish',
    edit: 'Edit',
    delete: 'Delete',
    onLine: 'Online',
    offLine: 'Offline',
  },
  editModal: {
    title: (text: string) => `${text} Approval Flow`,
    add: 'Add Approval Flow',
    success: 'Edit Successfully',
    card: {
      user: 'User',
      allUser: 'All Users',
      metricLevel: 'Metric Level',
      node: 'Approval Node',
      nodeTitle: (index: number) => 'Approval Flow ' + index,
      empty: 'Content cannot be empty',
      listEmpty: 'List cannot be empty',
      addNode: 'Add Approval Node',
      title: {
        index: 'Index',
        approveOwner: {
          title: 'Approver',
          user: 'User',
          userRole: 'User Role',
          userGroup: 'User Group',
          userPlaceholder: 'Select user',
          userPlaceholderGroup: 'Select user group',
          rolePlaceholder: 'Select role',
        },
        type: {
          title: 'Method',
          and: 'And Sign',
          or: 'Or Sign',
        },
        delete: 'Delete',
      },
    },
  },
};

export const platformSettings = {
  title: 'Platform Settings',
  code: {
    title: 'Enable Metric/Dimension Code',
    disableWarning: 'Disable Metric/Dimension Code',
    disableDesc:
      'After disabling metric/dimension code, system will delete generated codes. API queries will switch to metric/dimension name mode, please proceed with caution.',
    desc: 'When enabled, unique code values must be filled when defining metrics and dimensions.',
    apiCode: 'API Interface Metric/Dimension Identifier',
    metricTimeCode: 'Metric Time Dimension Code',
    metricTimeLabel: 'Metric Time (metric_time) Dimension Code',
    metricTimeTips:
      'Metric Time (metric_time) is built-in time dimension, use it to view metric results for specific dates.',
    metricTimePlaceholder: 'Enter',
    code: 'Metric/Dimension Code',
    name: 'Metric/Dimension Name',
    settingChangedTitle: 'Change Metric/Dimension Code Settings',
    settingChangedWarning: (contents: string) =>
      `Changing ${contents} may cause integrated API queries to fail, please proceed with caution.`,
    metricTimeError: 'Invalid metric Time dimension code format',
    metricTimeRequired: 'Enter metric Time dimension code',
  },
  save: 'Save',
  saveSuccess: 'Save Successfully',
  continue: 'Continue',
  cancel: 'Cancel',
};

export const softIntegration = {
  title: 'Office Software Integration',
  testModal: 'Test Connectivity',
  testSuccess: 'Test Successfully',
  testError: 'Test Failed',
  desc: 'After integration, can be used for user info sync, login authentication, and message push.',
  statusText: (isOpen: boolean) => (isOpen ? 'Enabled' : 'Disabled'),
  open: 'Enable',
  saveSuccess: 'Save Successfully',
  operate: {
    save: 'Save',
    test: 'Test',
  },
};

export const loginAuthentication = {
  title: 'Login Authentication',
  defaultLoginCode: 'Default Login Method',
  itemTitle: {
    account: 'Account Password',
    http: 'HTTP',
    enterpriseWechat: 'Enterprise WeChat',
    sso: 'SSO',
  },

  formLabel: {
    open: 'Enable',
    userAuthentication: {
      title: 'Auth Users',
      owner: 'Admin Account',
      manual: 'Manually Created Account',
      sync: 'Synced Account',
    },
  },

  http: {
    deleteConfirm: 'Confirm delete?',
    add: 'Add Authentication',
    edit: 'Edit',
    delete: 'Delete',
    argsCount: (count: number) => `${count} Parameters`,

    modal: {
      title: (isEdit: boolean) =>
        isEdit ? 'Edit HTTP Authentication' : 'Add HTTP Authentication',
      displayName: 'Display Name',
      displayNameSuffix: 'Name',
      address: 'Auth URL',
      paramsSetting: {
        title: 'Parameter',
        loginParams: {
          title: 'Login Parameters',
          name: 'Parameter Name',
          displayName: 'Display Name',
          isUserEdit: {
            title: 'Editable',
            yes: 'Yes',
            no: 'No',
          },
          defaultValue: 'Default Value',
          isPassword: 'Encrypted',
          addBtn: 'Custom Parameter',
        },
        result: {
          title: 'Authentication Result',
          codePath: {
            label: 'Result Path',
            placeholder: 'Enter result path',
          },
          successValue: {
            label: 'Success Identifier',
            placeholder: 'Enter success identifier in parse path',
          },
          accountPath: {
            label: 'Account Path',
            placeholder: 'Enter',
          },
        },
      },
      inputPlaceholder: 'Enter',
      notEmpty: 'This field cannot be empty',
    },
  },

  enterpriseWechat: {
    emptyTitle: 'No office software integration configured',
    setSoftIntegration: 'Configure office software integration',
  },
};

export const messagePush = {
  title: 'Message Push',
  addBtnText: 'Add Message Push',
  form: {
    displayname: 'Display Name',
    enName: 'Name',
    pushUrl: 'Push URL',
    remarks: 'Description',
    type: {
      title: 'Type',
      enterpriseWechat: 'Enterprise WeChat',
      custom: 'Custom',
    },
    placeholder: 'Enter',
    notEmpty: 'This field cannot be empty',
  },
  deleteSuccess: 'Delete Successfully',
  deleteDesc: 'Confirm delete?',
  createModal: {
    title: (isEdit: boolean) =>
      isEdit ? 'Edit Message Push' : 'Add Message Push',
    createSuccess: 'Create Successfully',
    updateSuccess: 'Update Successfully',
    operate: {
      cancel: 'Cancel',
      test: 'Test',
      save: 'Save',
    },
    test: {
      successTitle: 'Connection Successfully',
      successDesc: 'Message push connectivity test successful',
      okBtnText: 'Got it',
      errorTitle: 'Connection Failed',
      errorDesc:
        'Message push connectivity test failed, please check configuration',
    },
  },
};
