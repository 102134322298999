import { atom } from 'jotai';
import { EComparisonType, IAttributionAnalysisRange } from '../../types';
import {
  IFilterDependency,
  TFilterInAnalysisView,
  TMetricTime,
} from '@/typings/analysisView';
import {
  EMetricTimeKey,
  metricTimeSelectOptions,
} from '@/common/domain/filter/NewFilter/MetricTimePicker/constants';
import { IDateRangeFilterConfig } from '@/common/domain/filter/NewFilter/types';
import { IDimensionPickerValue } from '@/common/domain/dimension/Picker/types';

/**
 * 已选的维度
 */
export const dimensionsAtom = atom<IDimensionPickerValue[]>([]);

export const defaultCurrentDate = getDefaultMetricTimeFilter();

export const defaultAttributionRange: IAttributionAnalysisRange = {
  current: defaultCurrentDate,
  granularity: defaultCurrentDate.granularity,
  comparisonType: EComparisonType.DOD,
};

/**
 * 分析日期
 */
export const attributionRangeAtom = atom<IAttributionAnalysisRange>(
  defaultAttributionRange,
);

function getDefaultMetricTimeFilter(): TMetricTime {
  const defaultFilter = metricTimeSelectOptions.find(
    (item) => item.key === EMetricTimeKey.YESTERDAY,
  )!.value as IDateRangeFilterConfig;
  return {
    ...defaultFilter,
    active: true,
  };
}

/**
 * 筛选条件
 */
export const filterConfigAtom = atom<{
  filters: TFilterInAnalysisView[];
  filterDependency: Record<string, IFilterDependency>;
}>({
  filters: [],
  filterDependency: {},
});
