import { ReactComponent as ArrowNewWindowSvg } from '@/assets/icon/Arrow-New-window-light-line.svg';
import { TMetric } from '@/typings/metric';
import styles from './index.less';
import MetricInfo from '../ProcessedMetricCard/MetricInfo';
import ColumnDataTypeIcon from '@/common/ui/ColumnDataTypeIcon';
import { originDataType2DataTypeMap } from '../../Formula/constant';
import { windowOpen } from '@/common/utils/windowOpen';
import { memo } from 'react';

function MetricCard(props: IProps) {
  const { metric } = props;

  const { originDataType, name } = metric;

  const dataType = originDataType2DataTypeMap[originDataType];

  return (
    <div className={styles.metricCard}>
      <div className={styles.header}>
        <div className={styles.nameArea}>
          <div className={styles.dataType}>
            <ColumnDataTypeIcon dataType={dataType} color="#10B981" />
          </div>
          <div className={styles.displayName} title={name}>
            {name}
          </div>
        </div>
        <div
          className={styles.icon}
          onClick={() => {
            windowOpen(`/metric/detail/${metric.code}`);
          }}
        >
          <ArrowNewWindowSvg size={20} color="#6B7280" />
        </div>
      </div>
      <div className={styles.body}>
        <MetricInfo metric={metric} />
      </div>
    </div>
  );
}

interface IProps {
  metric: TMetric;
}

export default memo(MetricCard);
