export const list = {
  title: 'Data Source',
  total: {
    datasource: ({ total }: { total: string | number }) =>
      `${total} Data Sources`,
    item: ({ total }: { total: string | number }) => `${total} Items`,
  },
  column: {
    name: 'Name',
    operate: 'Actions',
    createTime: 'Created At',
  },
  content: 'Content',
  authority: 'Permission',
  create: 'Create Data Source',
  menu: {
    edit: 'Edit',
    transfer: 'Transfer',
  },
  delete: {
    title: 'Delete Data Source',
    content: 'Confirm to delete data source',
    confirm: 'Confirm',
    deleteSuccess: 'Delete Successfully',
  },
};

export const tree = {
  fileCatalog: 'File Upload',
  empty: 'No Content Found',
  search: 'Search',
  column: 'Fields',
};

export const edit = {
  title: 'Edit Data Source',
  label: {
    displayName: 'Display Name',
    name: 'Name',
  },
  required: {
    displayName: 'Display name cannot be empty',
  },
};

export const define = {
  create: 'Create Data Source',
  select: 'Select Data Source',
  edit: 'Edit Data Source',
  ok: 'Confirm',
  res: {
    success: (type: string) => `${type} Successfully`,
    fail: (type: string) => `${type} Failed`,
  },
};

export const role = {
  admin: 'Administrator',
  usager: 'User',
  owner: 'Owner',
};

export const external = 'External';

export const fileList = {
  transfer: 'Transfer',
  title: 'File Upload',
  uploadBtn: 'Upload File',
  cnName: 'Display Name',
  enName: 'Name',
  describe: 'Description',
  updateTime: 'Update Time',
  owner: 'Owner',
  handle: 'Actions',
  view: 'View',
  delete: 'Delete',
  editModalTitle: 'Edit Basic Info',
  edit: 'Edit',
  total: (total: number) => {
    return `${total} Files`;
  },
  deleteSuccess: 'Deleted',
  batch: {
    transfer: (value: string) => {
      return `File ${value} has no transfer permission`;
    },
  },
};

export const editFileInfo = {
  cnName: 'Display Name',
  enName: 'Name',
  describe: 'Description',
  enNameRule:
    'Name can only contain letters, numbers, underscores and cannot use double underscores',
  cnNameRule: 'Display name cannot be empty',
  success: 'File information modified successfully',
};

export const fileDetail = {
  preview: {
    baseInfo: {
      title: 'Basic Info',
      displayName: 'Display Name',
      name: 'Name',
      type: 'Type',
      typeValue: 'File Upload',
      owner: 'Owner',
      createTime: 'Created',
      updateTime: 'Last Updated',
      desc: 'Description',
    },
  },
  file: {
    tab: 'File',
    add: 'Append File',
    fileName: 'File',
    createDate: 'Add Time',
    owner: 'Owner',
    handle: 'Actions',
    total: (num: number) => `${num} Records`,
  },
  authority: {
    addAuth: 'Add Authorization',
  },
};

export const fileUpload = {
  upload: 'Upload File',
  appendTitle: 'Append File',
  uploadSuccess: 'Upload Successfully',
  uploadError: 'Upload Failed',
  modalOk: 'Confirm',
  tip: 'Only supports uploading csv, txt format files, and single file size cannot exceed 100 MB.',
  modalCancel: 'Cancel',
  modalTitle: 'File Upload',
  uploadTypeError: 'Only supports uploading csv, txt format',
  uploadNameError: 'Duplicate filename, please rename',
  fileSizeErrorMessage: 'File size exceeds limit',
  fileSizeErrorDes: 'File size cannot exceed 100MB',
  uploadBtn: 'Click to Upload',
  uploadDrag: ' or Drag Files Here',
  uploadReplace: 'Replace File',
  comma: 'Comma (,)',
  verticalBar: 'Vertical Bar (|)',
  tab: 'Tab',
  spaceKey: 'Space',
  semicolon: 'Semicolon (;)',
  separator: 'Separator',
  charset: 'Encoding Format',
  containsHeader: 'Set First Row as Field Display Name',
  appendInfoText:
    'The fields of the appended file will use the field types and data formats set in the first upload for data type conversion.',
  name: 'Name',
  nameError:
    'Name can only contain letters, numbers, underscores, cannot use double underscores, and cannot start with numbers',
  displayName: 'Display Name',
  dataPreview: 'Data Preview',
  errorData: 'Error Data',
  errorDataEmpty: 'No error data found in current file',
  errorLineNumber: 'Line Number',
  errorContent: 'Error Content',
  originData: 'Original Data',
  errorLines: (errorNumber: string | number) => {
    return `${errorNumber} lines of data have errors during data type conversion.`;
  },
  seeErrorPreview: 'View',
  useNullTipLeft: 'Use null value ( ',
  useNullTipRight: ' ) to replace error value',
  skipTip: 'Skip data rows containing error values',
  previewFooterLine: 'Row: ',
  previewFooterAppendLine: 'Append Data Row: ',
  previewFooterColumns: 'Field: ',
  formatBooleanText:
    'Fields with content "true" will be identified as "true"; fields with content "false" will be identified as "false"',
  formatDateText:
    'Fields matching format yyyy/MM/dd (2020/05/20) will be converted to date, converted format will be yyyy-MM-dd (2020-05-20).',
  formatDateTimeText:
    'Fields matching format yyyy/MM/dd hh:mm:ss (2020/05/20 14:21:16) will be converted to datetime, converted format will be yyyy-MM-dd (2020-05-20 14:21:16).',
  formatBooleanTitle: 'Boolean Format',
  formatDateTitle: 'Date Format',
  formatDateTimeTitle: 'DateTime Format',
  columnDataTypeInt: 'Integer',
  columnDataTypeDouble: 'Decimal',
  columnDataTypeDecimal: 'High Precision Decimal',
  columnDataTypeDate: 'Date',
  columnDataTypeDateTime: 'DateTime',
  columnDataTypeText: 'Text',
  columnDataTypeBoolean: 'Boolean',
  columnDataTypeJson: 'JSON',
  dataType: 'Data Type: ',
  dataFormat: 'Data Format: ',
  dateFormat: 'yyyy年MM月dd日',
  dateTimeFormat: 'yyyy年MM月dd日 hh:mm:ss',
  dateTimeNsFormat: 'yyyy年MM月dd日 hh时mm分ss秒',
  realOrFake: 'True,False',
  yesOrNo: 'Yes,No',
  real: 'True',
  fake: 'False',
  yes: 'Yes',
  no: 'No',
  validate: {
    empty: (name: string) => {
      return `${name} cannot be empty`;
    },
    nameIsExists: 'Name already exists',
  },
  describe: 'Description',
  title: 'Data Preview',
  noFile: {
    title: 'No Preview Content',
    description: 'Please upload file to preview data content',
  },
};

export const table = {
  preview: 'Overview',
  column: {
    default: 'Fields',
    name: 'Name',
    dataType: 'Data Type',
    desc: 'Description',
    search: 'Search',
    total: ({ total }: { total: string | number }) => `${total} Fields`,
  },
  refreshSetting: {
    title: 'Update Settings',
  },
  dataPreview: 'Data Preview',
  refresh: 'Refresh',
  basicInfo: {
    default: 'Basic Properties',
    name: 'Name',
    type: 'Type',
    table: 'Table',
    desc: 'Description',
    position: 'Position',
    dataset: 'Related Dataset',
  },
};

export const error = {
  msg: 'System Error',
  tree: 'Failed to get data, please refresh page',
  noPermission:
    'No permission to view, please apply for permission from administrator',
};

export const refreshSetting = {
  edit: 'Edit',
  refreshData: 'Refresh Data',
  partitionAndDateField: {
    title: 'Partition and Date Field Settings',
    formItemNotEmpty: 'This field cannot be empty',
    desc: 'Set the partition field of current table and mapping field and partition for incremental updates and query optimization.',
    partitionFieldSwitch: 'Date Partition',
    partitionField: 'Partition Field',
    select: 'Select Field',
    dateFormat: 'Date Format: ',
    dateFormat1: 'Date Format',
    dateGranularity: 'Granularity: ',
    dateGranularity1: 'Partition Date Granularity',
    dateTypeOptions: {
      nonDate: 'Non-Date Partition Field',
    },
    partitionMapping: 'Partition Mapping',
  },
  dataRefresh: {
    title: 'Data Refresh',
    desc: 'Current table data update cycle and Refresh content, used to Refresh related materialized table data.',
    refreshMethod: 'Trigger',
    manualApiRefresh: 'Manual/API Trigger',
    manualApiTip: 'Data Refresh API ',
    docUrl: 'Instructions',
    scheduledRefresh: 'Scheduled Trigger',
    refreshContent: {
      title: 'Content',
      fullRefresh: 'Full Refresh',
      incrementalRefresh: 'Incremental Refresh',
      incrementalRefreshPartition: (_granularity: string) =>
        `Incremental Partition Refresh (${_granularity})`,
      save: 'Save',
      notEmpty: 'This field cannot be empty',
      cancel: 'Cancel',
      saveSuccess: 'Save Successfully',
    },
  },
  updateModal: {
    title: 'Update Data',
    confirmBtn: 'Update Data',
    desc: 'This update will update all related ACC table data in sequence.',
    refreshRange: 'Update Range',
    refreshLink: 'Update Link',
    desc2:
      'System will update the corresponding data content of following ACC tables in sequence',
    table: {
      table: 'ACC Table',
      updateTime: 'Last Updated',
    },
    success: 'Update Successfully',
  },
};
