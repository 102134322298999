import { IFormatConfig } from '@/services/metric/types';
import { EDateGranularityType, EOriginDataType } from '.';
import {
  IDateLimit,
  IDerivateCalc,
  IFilterDependency,
  TFilterInAnalysisView,
  TMetricTime,
} from './analysisView';
import { TNode } from '@aloudata/aloudata-design/dist/LogicTree/type';
import { EMetricTreeNodeType } from './workbook';
import { TFilterConfig } from '@/common/domain/filter/GlobalFilter/type';

// 指标树 document
export interface IMetricTreeDocument {
  // 节点配置
  // FIXME: 改成 nodeConfigMap
  nodeConfig: Record<string, ITreeNodeConfig>;
  // 节点关系
  relation: ITreeNode[];
  // 归因配置
  attribution: IAttributionConfig;
  // 样式
  style: IMetricTreeStyle;
  filtersConfig: IMetricTreeFiltersConfig;
  comparisonConfig: IMetricTreeComparisonConfig;
}

// 对比配置
export interface IMetricTreeComparisonConfig {
  // 是否开启对比按钮
  isShowBtn: boolean;
  // 默认的对比方式
  default: IMetricTreeComparisonValue;
  // 当前有效的对比方式，配置了开启对比按钮存在
  current?: IMetricTreeComparisonValue;
}

export interface IMetricTreeComparisonValue {
  // 对比方式的时间粒度
  comparisonGranularityList: EDateGranularityType[];
  // 开启了自定义，则存储自定义的配置
  customConfig?: ICustomComparisonConfigValue;
}

// 自定义对比方式的值
export interface ICustomComparisonConfigValue {
  isSingle: boolean; // 是否单日期，单日期时 startDateTime 和 endDateTime 相同
  startDateTime: number;
  endDateTime: number;
}

// 全局筛选条件的配置
export interface IMetricTreeFiltersConfig {
  // 指标日期
  metricTimeFilter: TMetricTime;
  // 筛选条件的值
  filters: TFilterInAnalysisView[];
  // 筛选器配置
  filterConfig: TFilterConfig[];
}

interface IMetricTreeStyle {
  direction: EMetricTreeDirection;
  expandLevel: number; // 默认的展开层级，-1 表示全部
  isRedIncrease: boolean; // 染色方式，是否“红涨绿跌”
}

// 图展示的方向
export enum EMetricTreeDirection {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export interface ITreeNode {
  id: string;
  children: ITreeNode[];
  // 是否折叠
  collapsed?: boolean;
  // 节点配置的 ID
  nodeConfigId: string;
  // 树的父节点，便于遍历，根节点的 parentId 为 null
  parentId: string | null;
}

export interface ITargetManagement {
  active: boolean;
  value?: number;
}

export interface ITreeNodeConfig {
  id: string;
  metric?: IMetric;
  dimensions: string[];
  dimensionMap: Record<string, IDimension>;
  // 目标管理
  targetManagement: ITargetManagement;
}

// 用这个结构是为了防止以后有加工需求，便于扩展
interface IDimension {
  def: {
    dimensionName: string;
  };
  id: string;
  originDataType: EOriginDataType;
}

export interface IMetric {
  id: string;
  def: {
    code: string;
  };
  // 指标的名称
  name: string;
  displayName: string;
  // 自定义名称
  title?: string;
  // 衍生方式
  derivativeCalc?: IDerivateCalc;
  // 数据类型
  originDataType: EOriginDataType;
  // 格式化
  formatConfig?: IFormatConfig;
  // 时间限定
  dateLimit: IDateLimit;
  // 业务限定
  filters: TNode<TFilterInAnalysisView>;
  // 业务限定依赖
  filterDependency: Record<string, IFilterDependency>;
}

export interface IAttributionConfig {
  // 是否开启归因
  active: boolean;
  // key 是节点 ID，记录哪些节点配置了表达式
  exprMap: TAttributionExprConfig;
}

export type TAttributionExprConfig = Record<string, IAttributionExprNode[]>;

export interface IAttributionExprNode {
  // 复用指标看板中的指标树节点类型枚举值
  type: EMetricTreeNodeType;
  // 如果是指标节点，则对应节点 ID，如果是操作符，则对应操作符文本
  content: string;
}
