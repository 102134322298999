import { IProps as IMetricTreePickerProps } from '../MetricTreePicker';
import { EDropdownCloseReason, ESelectMode } from '../types';
import { Dropdown } from '@aloudata/aloudata-design';
import { IDropdownProps } from '@aloudata/aloudata-design/dist/Dropdown';
import { useEffect, useState } from 'react';
import MetricDropdownContent from './DropdownContent';

export default function MetricPickerDropdown(props: IProps) {
  const {
    mode = ESelectMode.SINGLE,
    dropdown,
    children,
    disabled,
    ...otherProps
  } = props;
  const [open, setOpen] = useState(!!dropdown?.open);
  const { onOpenChange: dropdownOnOpenChange, ...otherDropdownProps } =
    dropdown || {};
  const onClose = (reason: EDropdownCloseReason) =>
    dropdownOnOpenChange?.(false, reason);

  useEffect(() => {
    setOpen(!!dropdown?.open);
  }, [dropdown?.open]);

  return (
    <Dropdown
      trigger={'click'}
      open={open}
      onOpenChange={(isOpen) => {
        if (disabled) {
          return;
        }
        setOpen(isOpen);
        dropdownOnOpenChange?.(isOpen, EDropdownCloseReason.DROPDOWN_CLOSE);
      }}
      {...otherDropdownProps}
      disabled={disabled}
      dropdownRender={() => (
        <MetricDropdownContent
          {...otherProps}
          mode={mode}
          onClose={(reason) => {
            setOpen(false);
            onClose?.(reason);
          }}
        />
      )}
    >
      {children}
    </Dropdown>
  );
}

interface IProps extends IMetricTreePickerProps {
  children: React.ReactNode;
  title?: string;
  mode?: ESelectMode; // 为空时，为单选模式
  dropdown?: Omit<IDropdownProps, 'children' | 'onOpenChange'> & {
    onOpenChange?: (isOpen: boolean, reason: EDropdownCloseReason) => void;
  };
  disabled?: boolean;
}
