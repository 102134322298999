import { InputNumber, AldSelect } from '@aloudata/aloudata-design';
import { ERankDirection } from '../../types';
import t from '@/locales';
import styles from './index.less';

interface IProps {
  value?: {
    value: number;
    direction: ERankDirection;
  };

  onChange?: (value: { value: number; direction: ERankDirection }) => void;
}

export default function RankPicker({ value, onChange }: IProps) {
  const onChangeDirection = (direction: ERankDirection) => {
    onChange?.({ value: value?.value || 1, direction });
  };

  const onChangeValue = (val: number | string | null) => {
    onChange?.({
      value: Number(val) || 1,
      direction: value?.direction || ERankDirection.DESC,
    });
  };

  return (
    <div className={styles.wrap}>
      <AldSelect
        className={styles.select}
        value={value?.direction || ERankDirection.DESC}
        onChange={onChangeDirection}
        options={[
          {
            label: t.filter.filter.rank.direction.desc,
            value: ERankDirection.DESC,
          },
          {
            label: t.filter.filter.rank.direction.asc,
            value: ERankDirection.ASC,
          },
        ]}
      />
      <InputNumber
        className={styles.input}
        value={value?.value || 1}
        min={1}
        step={1}
        precision={0}
        onChange={onChangeValue}
      />
    </div>
  );
}
