import { originDataType2DataTypeMap } from '../../src/common/domain/Formula/constant';
import _, { random, sample, times } from 'lodash';
import {
  EAcceleratePlanType,
  EAccelerateResultType,
  EAccelerateTableType,
  EMtColumnType,
  ERefreshType,
  ETaskInstanceStatus,
  IAcceleratePlanBase,
  IAcceleratePlanDetail,
  IAccelerateResultPlan,
  IAccelerateResultPlanListItem,
  IMtColumnMeta,
  IMtNode,
  IPdsNode,
  ITableDag,
  ITaskInstance,
} from '../../src/typings/accelerate';
import { getUniqId, isDateLikeDataType } from '../../src/common/utils';
import { EDateGranularityType, EOriginDataType } from '../../src/typings';
import {
  dimensionList,
  getSimpleMetricList,
} from '../utils/getSimpleMetricList';
import { getMockDatasetInfo } from '../utils';
import { IPreviewAccelerateResponse } from '../../src/services/accelerate/types';
import { generateMockData } from '../lineage';
import { filterMultipleLayers } from '../../src/services/lineage';
import {
  EVertexType,
  IQueryVertexLineageRes,
} from '../../src/services/lineage/types';
import { ESign } from '../../src/typings/dataSource';
import { EDateFilterUnit } from '../../src/typings/analysisView';
import {
  EDateDynamicOperator,
  EDateFilterType,
  EDateFilterValueType,
  EDateRangeType,
  EDateUnitKey,
} from '../../src/common/domain/filter/Filter/filter';
import {
  EDerivedCalculateMode,
  EGranularitySelect,
  EPeriodType,
} from '../../src/typings/metric';

/**
 * 借用了血缘 mock 数据生成方法，这里生成的数据结构是血缘的数据结构
 */
const graphData = getMockGraphData('testId', 3, 3);

function getMockGraphData(id: string, inputStep: number, outputStep: number) {
  const graphMockData = generateMockData(id, inputStep, outputStep);
  return filterMultipleLayers(
    graphMockData,
    id,
    inputStep,
    outputStep,
  ) as IQueryVertexLineageRes;
}

function getMockDatasets() {
  return _.range(4).map((index) => getMockDatasetInfo(`dataset_${index}`));
}

const MOCK_DIM_LENGTH = 5;
const MOCK_METRIC_LENGTH = 5;
const MOCK_COL_LENGTH = 8;

const mockMetricList = getSimpleMetricList().slice(0, MOCK_METRIC_LENGTH);
const mockDimensionList = dimensionList.slice(0, MOCK_DIM_LENGTH);
export const mockDatasets = getMockDatasets();

export function getMockAcceleratePlanBase(
  index: number,
  type: EAcceleratePlanType = EAcceleratePlanType.AGG,
): IAcceleratePlanBase {
  const currDataset = mockDatasets[0];

  const planId = 'planId_' + index;

  return {
    id: planId,
    name: type === EAcceleratePlanType.AGG ? `agg_${planId}` : `raw_${planId}`,
    displayName:
      type === EAcceleratePlanType.AGG ? '指标加速方案' : '明细加速方案',
    datasetName: currDataset.name,
    columns: currDataset.columns.slice(0, MOCK_COL_LENGTH).map((item) => ({
      datasetName: currDataset.name,
      columnName: item.name,
    })),
    metrics: mockMetricList.map((item) => item.code),
    // @ts-ignore-next-line
    dimensions: mockDimensionList.map((item) => {
      const dataType = originDataType2DataTypeMap[item.originDataType];
      let granularity: EDateGranularityType | null = null;
      if (isDateLikeDataType(dataType)) {
        granularity = _.sample([
          EDateGranularityType.DAY,
          EDateGranularityType.MONTH,
          EDateGranularityType.YEAR,
        ]);
      }
      return {
        name: item.name,
        granularity,
      };
    }),
    planType:
      type || _.sample([EAcceleratePlanType.AGG, EAcceleratePlanType.RAW]),
    planGranularity: [],
    authority: {
      canEdit: true,
      canDelete: true,
      canAuth: true,
      canUsage: true,
      canTransfer: true,
    },
  };
}

export function getMtLineage(
  id: string,
  inputStep: number,
  outputStep: number,
) {
  const res = filterMultipleLayers(
    graphData,
    id,
    inputStep,
    outputStep,
  ) as IQueryVertexLineageRes;
  // 将血缘的 mock 数据结构转成加速关系图需要的数据结构
  const newData: ITableDag = {
    nodes: [],
    relations: [],
  };

  const nodes: ITableDag['nodes'] = [];
  const { vertexList, edgeList } = res;
  _.forEach(vertexList, (vertex) => {
    if (
      vertex.vertexType === EVertexType.PHYSICAL_TABLE ||
      vertex.vertexType === EVertexType.DATASET
    ) {
      // 物理表
      nodes.push({
        // @ts-ignore-next-line
        __typename: 'PdsNodeDTO',
        id: vertex.vertexId,
        tableName:
          vertex.vertexId === 'testId'
            ? 'startMtDisplayName'
            : vertex.displayName,
        nodeType: EAccelerateTableType.PDS,
        refreshTime: '0 0 0 * * ?',
        isPartition: _.random() > 0.5,
        isFullUpdate: _.random() > 0.5,
        latestPartition: '2024-07-23',
      });
    } else {
      const mockMtTable = getMaterializedTableMockData();
      // 其他都认为是物化表
      nodes.push({
        // @ts-ignore-next-line
        __typename: 'MtNodeDTO',
        id: vertex.vertexId,
        tableName:
          vertex.vertexId === 'testId'
            ? 'startMtDisplayName'
            : mockMtTable.name,
        nodeType: EAccelerateTableType.MT,
        columns: mockMtTable.columns,
        planType: mockMtTable.type,
        acceleratePlan: mockMtTable.acceleratePlan,
        isPartition: mockMtTable.isPartition,
        isFullUpdate: _.random() > 0.5,
        size: mockMtTable.size,
        owner: mockMtTable.owner,
        latestInstance: mockMtTable.latestInstance,
        latestPartition: '2024-07-23',
      });
    }
  });
  const edges: ITableDag['relations'] = [];
  _.forEach(edgeList, (edge) => {
    edges.push({
      srcId: edge.srcVertex.vertexId,
      dstId: edge.dstVertex.vertexId,
    });
  });

  newData.nodes = nodes;
  newData.relations = edges;
  return newData;
}

export function getPreviewAcceleratePlans(): IPreviewAccelerateResponse {
  const graphData = generateGraph(10);
  graphData.nodes.forEach((node) => {
    if (node.nodeType === EAccelerateTableType.PDS) {
      const currNode = node as IPdsNode;
      currNode.refreshTime = '0 0 0 * * ?';
      currNode.latestInstance = null;
      currNode.isPartition = _.random() > 0.5;
      currNode.isFullUpdate = _.random() > 0.5;
      // @ts-ignore-next-line
      currNode.__typename = 'PdsNodeDTO';
      return;
    }
    const currNode = node as IMtNode;
    const mockMtTable = getMaterializedTableMockData();

    currNode.acceleratePlan = mockMtTable.acceleratePlan;
    currNode.columns = mockMtTable.columns;
    currNode.planType = mockMtTable.type;
    currNode.latestInstance = mockMtTable.latestInstance;
    currNode.size = mockMtTable.size;
    currNode.owner = mockMtTable.owner;
    currNode.isPartition = mockMtTable.isPartition;
    currNode.isFullUpdate = _.random() > 0.5;
    // @ts-ignore-next-line
    currNode.__typename = 'MtNodeDTO';
  });

  const plans = _.range(10).map((i) =>
    getMockAcceleratePlanBase(i, EAcceleratePlanType.AGG),
  );

  const recommend1 = `修改方案 <plan>${plans[0].id}</plan>，增加指标 <metric>${mockMetricList[0].code}</metric>，<metric>${mockMetricList[1].code}</metric>

  优化 <plan>${plans[1].id}</plan> 中的 <metric>${mockMetricList[2].code}</metric>、优化 <plan>${plans[2].id}</plan> 的更新链路。
  `;

  const recommend2 = `新增方案 <plan>${plans[2].id}</plan>，方案内容为：<dimension>metric_time</dimension>，<dimension>${mockDimensionList[0].name}</dimension>，<metric>${mockMetricList[0].code}</metric>

  优化方案 <plan>${plans[2].id}</plan> 中的 <metric>${mockMetricList[2].code}</metric>。
  `;

  return {
    noRecommendDag: graphData,
    recommendDag: graphData,
    recommendInfos: [recommend1, recommend2],
    recommendPlans: plans,
    noRecommendPlans: plans,
  };
}

interface INode {
  id: string;
  tableName: string;
  nodeType: EAccelerateTableType;
}

interface IEdge {
  srcId: string;
  dstId: string;
}

function generateGraph(numNodes: number): {
  nodes: INode[];
  relations: IEdge[];
} {
  if (numNodes < 4) {
    throw new Error('Number of nodes must be at least 4');
  }

  // 创建节点
  const nodes: INode[] = _.times(numNodes, (index) => {
    if (index < 3) {
      // 前三个节点为 PDS 类型
      return {
        tableName: `Table${index + 1}`,
        id: `node${index + 1}`,
        nodeType: EAccelerateTableType.PDS,
      };
    } else {
      // 其他节点为 MT 类型
      return {
        tableName: `Table${index + 1}`,
        id: `node${index + 1}`,
        nodeType: EAccelerateTableType.MT,
      };
    }
  });

  // 创建边
  let relations: IEdge[] = [];

  // 使用 PDS 节点连接到所有 MT 节点
  const pdsNodes = _.filter(nodes, { nodeType: EAccelerateTableType.PDS });
  const mtNodes = _.filter(nodes, { nodeType: EAccelerateTableType.MT });

  pdsNodes.forEach((pdsNode) => {
    mtNodes.forEach((mtNode) => {
      relations.push({
        srcId: pdsNode.id,
        dstId: mtNode.id,
      });
    });
  });

  // 为了增加图的复杂性，可以随机添加更多的边
  _.times(numNodes - 1, () => {
    const srcIndex = _.random(numNodes - 1);
    const dstIndex = _.random(numNodes - 1);
    if (srcIndex !== dstIndex) {
      // 不加入重复的边
      const isExisted = _.some(relations, {
        srcId: nodes[srcIndex].id,
        dstId: nodes[dstIndex].id,
      });
      if (isExisted) {
        return;
      }
      relations.push({
        srcId: nodes[srcIndex].id,
        dstId: nodes[dstIndex].id,
      });
    }
  });

  return {
    nodes,
    relations,
  };
}

export function getMaterializedTableMockData() {
  const mockColumnMeta = () => {
    return {
      name: 'mockColumn',
      originDataType: _.sample([
        EOriginDataType.STRING,
        EOriginDataType.DATE,
        EOriginDataType.INT,
      ]),
      isPartition: true,
      columnType: _.sample([
        EMtColumnType.RAW,
        EMtColumnType.DIMENSION,
        EMtColumnType.METRIC,
      ]),
      timeRange: [
        {
          startTime: Date.now() - -1000 * 60 * 60 * 24 * 9,
          endTime: Date.now() - 1000 * 60 * 60 * 24 * 7, // +1 day
        },
        {
          startTime: Date.now(),
          endTime: Date.now() + 1000 * 60 * 60 * 24, // +1 day
        },
      ],
      aggMapping: getUniqId(),
      rawMapping: {
        columnName: '111',
        datasetName: '2222',
      },
    } as IMtColumnMeta;
  };

  return {
    id: 'mockTableId' + getUniqId(),
    name: 'mockTableName_mockTableName' + getUniqId(),
    dataStartTime: Date.now(),
    dataEndTime: Date.now() + 1000 * 60 * 60 * 24, // +1 day
    columns: [
      mockColumnMeta(),
      mockColumnMeta(),
      mockColumnMeta(),
      mockColumnMeta(),
    ],
    type: EAcceleratePlanType.RAW,
    acceleratePlan: {
      ...getMockAcceleratePlanBase(0, EAcceleratePlanType.AGG),
      owner: {
        account: 'user1',
        userId: 'user1',
        nickname: 'user1',
        photo: '',
      },
      dependencies: {
        metrics: mockMetricList,
        dimensions: mockDimensionList,
        datasets: mockDatasets,
      },
    },
    createTime: Date.now(),

    updateTime: Date.now(),
    size: '1000M',
    lastRefreshTime: Date.now(),
    isPartition: true,
    latestInstance: {
      id: getUniqId(),
      status: _.sample([
        ETaskInstanceStatus.INIT,
        ETaskInstanceStatus.RUNNING,
        ETaskInstanceStatus.SUCCESS,
        ETaskInstanceStatus.FAILED,
        ETaskInstanceStatus.CANCELED,
        ETaskInstanceStatus.WAITING,
      ]),
      startTime: Date.now(),
      endTime: Date.now() + 1000,
      startPartition: 'start partition',
      endPartition: 'end partition',
      sql: 'select * from table',
      log: 'task_instance_log',
      waitTime: 1000,
      type: _.sample([ERefreshType.SCHEDULED, ERefreshType.MANUAL]),
    } as ITaskInstance,
    owner: {
      nickname: 'mockOwner',
      userId: 'mockUserId',
      account: 'mockAccount',
      photo: '',
    },
    authority: {
      canEdit: true,
      canDelete: true,
      canAuth: true,
      canUsage: true,
      canTransfer: true,
    },
  };
}

export function getMockAcceleratePlanDetail(
  index: number,
  type?: EAcceleratePlanType,
): IAcceleratePlanDetail {
  return {
    ...getMockAcceleratePlanBase(index, type),
    mtCount: 10,
    owner: {
      account: 'user1',
      userId: 'user1',
      nickname: 'user1',
      photo: '',
    },
    dependencies: {
      metrics: mockMetricList,
      dimensions: mockDimensionList,
      datasets: mockDatasets,
    },
    planGranularity: [],
  };
}

export function getMockTaskInstance(): ITaskInstance {
  return {
    id: getUniqId(),
    status: _.sample([
      ETaskInstanceStatus.INIT,
      ETaskInstanceStatus.RUNNING,
      ETaskInstanceStatus.SUCCESS,
      ETaskInstanceStatus.FAILED,
      ETaskInstanceStatus.CANCELED,
      ETaskInstanceStatus.WAITING,
    ]),
    startTime: Date.now(),
    endTime: Date.now() + 1000,
    startPartition: Date.now().toString(),
    endPartition: (Date.now() + 1000).toString(),
    plan: getMockAcceleratePlanDetail(0),
    sql: 'select * from table',
    log: 'task_instance_log',
    waitTime: 1000,
    materializedTable: getMaterializedTableMockData(),
    type: _.sample([ERefreshType.SCHEDULED, ERefreshType.MANUAL]),
  };
}

export function generateMockAccelerateResultPlanListItem(): IAccelerateResultPlanListItem {
  return {
    type: EAccelerateResultType.NORMAL,
    id: getUniqId(),
    name: `PlanName${random(1, 100)}`,
    displayName: `DisplayName${random(1, 100)}`,
    metrics: mockMetricList.map((metric, i) => ({
      id: getUniqId(),
      code: metric.code,
      granularity: sample([
        EDateGranularityType.DAY,
        EDateGranularityType.MONTH,
      ]),
      displayName: 'metric' + i,
    })),
    dimensions: mockDimensionList.map((dimension, i) => ({
      id: `dimension-${random(100, 999)}`,
      column: {
        column: dimension.name,
      },
      granularity: sample([
        EDateGranularityType.DAY,
        EDateGranularityType.MONTH,
      ]),
      displayName: 'dimension' + i,
    })),
    mtCount: random(1, 20),
    dependencies: {
      metrics: mockMetricList,
      dimensions: mockDimensionList,
      datasets: mockDatasets,
    },
    refreshConfig: {
      lowerIncrementRefreshScope: {
        interval: random(2, 30),
        sign: sample([ESign.NEGATIVE, ESign.POSITIVE]),
      },
      upperIncrementRefreshScope: {
        interval: _.sample([0, 1]),
        sign: sample([ESign.NEGATIVE, ESign.POSITIVE]),
      },
      granularity: sample([
        EDateGranularityType.MINUTE,
        EDateGranularityType.DAY,
        EDateGranularityType.MONTH,
      ]),
      isScheduleUpdate: true,
      isCustomTime: _.sample([true, false]),
      cron: '0 */30 * * * ?',
      isFullRefresh: _.sample([true, false]),
    },
    owner: {
      userId: `user-${random(1000, 9999)}`,
      account: `user.account${random(1, 100)}`,
      nickname: `Nickname ${random(1, 100)}`,
      photo: '',
      email: sample([`user${random(1, 100)}@example.com`, undefined]),
      phone: sample([`123-456-7890`, undefined]),
    },
    authority: {
      canEdit: true,
      canDelete: true,
      canAuth: true,
      canUsage: true,
      canTransfer: true,
    },
  };
}

export function generateMockAccelerateResultPlanDetail(): IAccelerateResultPlan {
  return {
    id: getUniqId(),
    name: `PlanName${random(1, 100)}`,
    displayName: `DisplayName${random(1, 100)}`,
    metrics: mockMetricList.map((metric, i) => ({
      id: getUniqId(),
      code: metric.code,
      granularity: sample([
        EDateGranularityType.DAY,
        EDateGranularityType.MONTH,
      ]),
      displayName: 'metric' + i,
      calculateParams: {
        period: {
          type:
            Math.random() > 0.5
              ? EPeriodType.TO_DATE
              : EPeriodType.GRAIN_TO_DATE,
          typeParams: '-7 DAY of 0 DAY',
        },
        preAggs: [
          {
            granularity: EDateGranularityType.YEAR,
            calculateType: EDerivedCalculateMode.MAX,
          },
        ],
      },
    })),
    dimensions: mockDimensionList.map((dimension, i) => ({
      id: `dimension-${random(100, 999)}`,
      column: {
        column: dimension.name,
      },
      granularity: sample([
        EDateGranularityType.DAY,
        EDateGranularityType.MONTH,
      ]),
      displayName: 'dimension' + i,
    })),
    filter: [],
    isDisplayCode: sample([true, false]),
    refreshConfig: {
      lowerIncrementRefreshScope: {
        interval: random(1, 30),
        sign: sample([ESign.NEGATIVE, ESign.POSITIVE]),
      },
      upperIncrementRefreshScope: {
        interval: random(1, 30),
        sign: sample([ESign.NEGATIVE, ESign.POSITIVE]),
      },
      granularity: sample([
        EDateGranularityType.DAY,
        EDateGranularityType.MONTH,
      ]),
      isScheduleUpdate: sample([true, false]),
      cron: '0 */15 * * * ?',
      isFullRefresh: true,
    },
    retryStrategy: {
      retryCount: random(1, 5),
      interval: random(1000, 10000),
    },
    updateNotifyConfig: {
      updateSuccessNotifyCodes: times(2, () => `code-${random(100, 999)}`),
      updateFailedNotifyCodes: times(2, () => `code-${random(100, 999)}`),
      updateDelayNotifyConfig: {
        finishTime: `00:${random(10, 59)}:${random(10, 59)}`,
        codes: times(2, () => `code-${random(100, 999)}`),
      },
    },
    layout: {
      filter: {},
      metricTimeFilter: {
        active: false,
        select: EDateFilterUnit.LAST30,
        value: {
          granularity: EDateGranularityType.DAY,
          values: {
            type: EDateRangeType.RANGE,
            includeToday: true,
            values: {
              min: {
                operator: EDateDynamicOperator.DECREASE,
                type: EDateFilterValueType.DYNAMIC,
                granularitySelect: EGranularitySelect.CURRENT_DAY,
                unit: EDateUnitKey.DAY,
                value: 29,
              },
              max: {
                operator: EDateDynamicOperator.DECREASE,
                type: EDateFilterValueType.DYNAMIC,
                granularitySelect: EGranularitySelect.CURRENT_DAY,
                unit: EDateUnitKey.DAY,
                value: 0,
              },
            },
          },
          includeNull: false,
          type: EDateFilterType.RANGE,
        },
      },
    },
    owner: {
      userId: `user-${random(1000, 9999)}`,
      account: `user.account${random(1, 100)}`,
      nickname: `Nickname ${random(1, 100)}`,
      photo: '',
      email: sample([`user${random(1, 100)}@example.com`, undefined]),
      phone: sample([`123-456-7890`, undefined]),
    },
  };
}
