export const column = {
  dataType: {
    boolean: 'Boolean',
    int: 'Integer',
    double: 'Decimal',
    decimal: 'High Precision Decimal',
    dateTime: 'DateTime',
    date: 'Date',
    text: 'Text',
    json: 'JSON',
  },
};

export const tree = {
  name: {
    duplicate: 'Name must be unique',
  },
  node: {
    error: {
      add: 'Failed to add node, please refresh the page',
      update: 'Failed to update node, please refresh the page',
      delete: 'Failed to delete node, please refresh the page',
      move: 'Failed to move node, please refresh the page',
    },
  },
};
