import {
  IDateRangeFilterConfig,
  IDateSpecifyFilterConfig,
  IEqualFilterConfig,
  TDependency,
} from '../NewFilter/types';

export interface IFilterConfigBase {
  dependency: TDependency;
  displayName: string;
  id: string;
}

export enum EFilterConfigType {
  DATE = 'DATE',
  SELECT = 'SELECT',
}

export type TFilterConfig = ISelectFilterConfig | IDateFilterConfig;

export interface ISelectFilterConfig extends IFilterConfigBase {
  type: EFilterConfigType.SELECT;
  value: ISingleSelectFilterValue | IMultipleSelectFilterValue;
}

export interface ISingleSelectFilterValue extends ISelectFilterBase {
  type: ESelectFilterType.SINGLE;
  defaultValue: IEqualFilterConfig;
}

export interface IMultipleSelectFilterValue extends ISelectFilterBase {
  type: ESelectFilterType.MULTIPLE;
  defaultValue: IEqualFilterConfig;
}

export enum ESelectFilterType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export interface ISelectFilterBase {
  type: ESelectFilterType;
  defaultValueEnable: boolean;
}

export interface IDateFilterConfig extends IFilterConfigBase {
  type: EFilterConfigType.DATE;
  value: IDateRangeFilterValue | IDateSpecifyFilterValue;
}

export enum EDateFilterType {
  // 指定时间点
  SPECIFY = 'SPECIFY',
  // 时间范围
  RANGE = 'RANGE',
}

export interface IDateFilterBase {
  granularityName: string;
  type: EDateFilterType;
  defaultValueEnable: boolean;
}

export interface IDateRangeFilterValue extends IDateFilterBase {
  type: EDateFilterType.RANGE;
  defaultValue: IDateRangeFilterConfig;
}

export interface IDateSpecifyFilterValue extends IDateFilterBase {
  type: EDateFilterType.SPECIFY;
  defaultValue: IDateSpecifyFilterConfig;
}
