import _ from 'lodash';
import { EUserCreationMode, EUserSource, IUser } from '../../src/typings';

export default function getUserInfo(): IUser {
  return {
    userId: `userId_${_.random(1, 100000).toString()}`,
    nickname: `用户名_${_.random(1, 100000).toString()}`,
    account: `账号_${_.random(1, 100000).toString()}`,
    userSource: EUserSource.ALOUDATA,
    photo: '',
    ban: false,
    creationMode: EUserCreationMode.MANUAL,
  };
}
