import t from '@/locales';
import { EColumnDataType } from '@/typings';
import { EUserRole } from '@/typings/authority';
export function isNumberLikeDataType(dataType: EColumnDataType) {
  return [
    EColumnDataType.DECIMAL,
    EColumnDataType.DOUBLE,
    EColumnDataType.INT,
  ].includes(dataType);
}
export function isDateLikeDataType(dataType: EColumnDataType) {
  return [EColumnDataType.DATE, EColumnDataType.DATE_TIME].includes(dataType);
}
/**
 * 获取角色名称
 */
export function getRoleName(role: EUserRole) {
  // 资源中的角色文案
  const resourceRoleMap = {
    [EUserRole.ADMIN]: t.authority.role.admin,
    [EUserRole.USAGER]: t.authority.role.usager,
    [EUserRole.VIEWER]: t.authority.role.viewer,
    [EUserRole.ANALYST]: t.authority.role.analyst,
    [EUserRole.OWNER]: t.authority.role.owner,
  };
  return resourceRoleMap[role];
}

export const isMock = !!(
  window.location.port && window.location.hostname === 'localhost'
);
