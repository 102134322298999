export const request = {
  message: 'Request failed',
};

export const component = {
  searchPlaceholder: 'Search',
};

export const document = {
  title: 'Metric Platform',
};

export const dataTypeName = {
  int: 'Integer',
  double: 'Decimal',
  decimal: 'High Precision Decimal',
  dateTime: 'Date Time',
  date: 'Date',
  text: 'Text',
  boolean: 'Boolean',
  json: 'Structured Data',
};

export const page = {
  backHome: 'Back to Home',
  noContent: 'No relevant content found...',
  noContentDesc:
    'Sorry, this file does not exist or you do not have permission to view it. Please check if the link is correct or contact the content owner to update permissions.',
  severError: 'Sorry, something went wrong',
  copySuccess: 'Copied successfully',
  copyError: 'Copy error message',
  severErrorDesc: 'Please try again later.',
};

export const list = {
  searchEmpty: 'No content found',
  searchMetric: 'Search metrics',
  searchDimension: 'Search dimensions',
  searchCategory: 'Search category name',
};

export const granularity = {
  auto: 'Auto',
  year: 'Year',
  quarter: 'Quarter',
  month: 'Month',
  week: 'Week',
  day: 'Day',
  hour: 'Hour',
  minute: 'Minute',
  second: 'Second',
};

export const column = {
  metricTime: 'Metric Time',
};

export const button = {
  save: 'Save',
  close: 'Close',
};

export const input = {
  placeholder: 'Please input',
};

export const select = {
  all: 'All',
  placeholder: 'Select',
};

export const zhUnit = {
  tenThousand: '万',
  hundredMillion: '亿',
  million: '百万',
  tenMillion: '千万',
};

export const metricPicker = {
  search: (enableCode?: boolean) =>
    `Metric ${enableCode ? 'code, ' : ''}display name, name`,
  unAvailableTip: 'Automatically hide unavailable content',
  empty: {
    title: 'No results found',
    desc: 'No matching results',
  },
  apply: 'Apply',
  collapseAll: 'Collapse All',
  close: 'Close',
  pickMetric: 'Select Metric',
};

export const dimensionPicker = {
  apply: 'Apply',
  collapseAll: 'Collapse All',
  close: 'Close',
  pickDimension: 'Select',
};

export const modal = {
  confirm: 'Confirm',
  save: 'Save',
};
