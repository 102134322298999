import t from '@/locales';
import { EDateGranularityType } from '@/typings';

export const ROOT_NODE_ID = 'ROOT_NODE_ID'; // 根节点 ID

// 默认展开所有层级，对应的 expandLevel 值
export const EXPAND_ALL_LEVEL = -1;

// 根节点层级
export const ROOT_NODE_LEVEL = 0;

// 自定义对比值的列 ID
export const CUSTOM_COMPARISON_METRIC_VALUE = 'CUSTOM_COMPARISON_METRIC_VALUE';

// 自定义对比率的列 ID
export const CUSTOM_COMPARISON_METRIC_GROWTH =
  'CUSTOM_COMPARISON_METRIC_GROWTH';

// 自定义增长值的列 ID
export const CUSTOM_COMPARISON_METRIC_GROWTH_VALUE =
  'CUSTOM_COMPARISON_METRIC_GROWTH_VALUE';

export const granularityComparisonTypes = [
  {
    value: EDateGranularityType.DAY,
    name: t.metricTree.comparisonConfigModal.dod,
  },
  {
    value: EDateGranularityType.WEEK,
    name: t.metricTree.comparisonConfigModal.wow,
  },
  {
    value: EDateGranularityType.MONTH,
    name: t.metricTree.comparisonConfigModal.mom,
  },
  {
    value: EDateGranularityType.QUARTER,
    name: t.metricTree.comparisonConfigModal.qoq,
  },
  {
    value: EDateGranularityType.YEAR,
    name: t.metricTree.comparisonConfigModal.yoy,
  },
];
