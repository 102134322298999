import { useContext } from 'react';
import { customCalendarListContext } from './CustomCalendarDataContextProvider';

export const useCustomCalendarDataContext = () => {
  const { customCalendarData, refreshCustomCalendarData } = useContext(
    customCalendarListContext,
  );

  return {
    customCalendarData,
    refreshCustomCalendarData,
  };
};
