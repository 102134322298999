import { useCallback, useMemo, useRef, useState } from 'react';
import {
  ECodeEditorPropsType,
  TExternalInfo,
} from '@/common/domain/codeEditor/CodeEditor/type';
import styles from './index.less';
import { TFormula } from '@/typings/formula';
import { IParseResult } from '@/common/domain/Formula/functions/parse';
import SearchColumnWrapper from '@/common/domain/dataset/SearchColumn/SearchColumnWrapper';
import _ from 'lodash';
import { IColumn, IDataset, TAssociatedDataset } from '@/typings/dataset';
import { PColumn } from '../../dataset/SearchColumn';
import { IMetric, TMetric } from '@/typings/metric';
import { IDimension, TDimension } from '@/typings/dimension';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import { processingEscapeCharacter } from '@/common/domain/Formula/functions/genFormula';
import { IDisabledMap } from '@/common/domain/dimension/DimensionPicker/type';
import t from '@/locales';
import { IconButton } from '@aloudata/aloudata-design';
import { ReactComponent as ArrowLeftIcon } from '@/assets/icon/Arrow-Left-bold-line-1.svg';
import { ReactComponent as ArrowRightIcon } from '@/assets/icon/Arrow-Right-bold-line-1.svg';
import classNames from 'classnames';
import FunctionList from './FunctionList';
import { Resizable } from 'react-resizable';
import { EFunctionType, IFunction } from '@/common/domain/Formula/type';
import { fuzzyMatch } from '@/pages/Dataset/helpers';
import CodeEditor, { ICodeEditorRef } from '../CodeEditor';
import { KEEP_FOCUS_CLASS_COlUMN } from '@/common/domain/codeEditor/CodeEditor/PromptTrigger';
import MetricTreePicker from '../../metric/Picker/MetricTreePicker';
import DimensionTreePicker from '../../dimension/Picker/DimensionTreePicker';
import { ESelectMode } from '../../metric/Picker/types';

export enum ECodeEditorWrapperType {
  DATASET = 'DATASET',
  DIMENSION = 'DIMENSION',
  METRIC = 'METRIC',
  COMPOSE_METRIC = 'COMPOSE_METRIC',
}
export enum EFunctionFilterType {
  METRIC = 'METRIC',
  DIMENSION = 'DIMENSION',
  ATOMIC_METRIC = 'ATOMIC_METRIC',
  DATASET = 'DATASET',
  METRIC_FILTER = 'METRIC_FILTER',
}
type TComplete =
  | {
      type: ECodeEditorWrapperType.DATASET;
      data: {
        column: IColumn;
        dataset: IDataset | null;
      };
    }
  | {
      type: ECodeEditorWrapperType.DIMENSION;
      data: IDimension;
    }
  | {
      type: ECodeEditorWrapperType.METRIC;
      data: IMetric;
    };
export type TPropsFunction = (
  result: IParseResult,
  id: string,
  dsl: string,
) => void;
interface IBaseCodeEditorWrapperProps {
  ast: TFormula | null;
  currentName: string;
  onBlur?: TPropsFunction;
  onChange?: TPropsFunction;
  placeholder?: string;
  focus?: boolean;
  className?: string;
  borderRadius?: 'all' | 'top' | 'bottom';
  functionFilterType?: EFunctionFilterType;
}
interface IDatasetCodeEditorWrapperProps extends IBaseCodeEditorWrapperProps {
  type: ECodeEditorWrapperType.DATASET;
  dataset: IDataset | null;
  one2manyDatasets: TAssociatedDataset; // 代表该数据集的一端表
  many2oneDatasets?: TAssociatedDataset; // 该数据集的n端表
}
interface IMeticCodeEditorWrapperProps extends IBaseCodeEditorWrapperProps {
  type: ECodeEditorWrapperType.METRIC;
  metric: TMetric[];
}
interface IDimensionCodeEditorWrapperProps extends IBaseCodeEditorWrapperProps {
  type: ECodeEditorWrapperType.DIMENSION;
  dimensions: IDimension[] | null;
  getDisabledMap?: () => IDisabledMap;
}

interface IComposeMetricCodeEditorWrapperProps
  extends IBaseCodeEditorWrapperProps {
  type: ECodeEditorWrapperType.COMPOSE_METRIC;
  metric: TMetric[];
  dimensions: IDimension[] | null;
}

type TProps =
  | IDatasetCodeEditorWrapperProps
  | IMeticCodeEditorWrapperProps
  | IDimensionCodeEditorWrapperProps
  | IComposeMetricCodeEditorWrapperProps;

const columnTitleMap = {
  [ECodeEditorWrapperType.DATASET]: t.components.codeEditorWrapper.column,
  [ECodeEditorWrapperType.METRIC]: t.components.codeEditorWrapper.metric,
  [ECodeEditorWrapperType.DIMENSION]: t.components.codeEditorWrapper.dimension,
  [ECodeEditorWrapperType.COMPOSE_METRIC]:
    t.components.codeEditorWrapper.metric,
};
const DEFAULT_BOX_WIDTH = 160;
const CLOSE_BOX_WIDTH = 36;
const getWidth = (width: number) => {
  if (width <= DEFAULT_BOX_WIDTH) return DEFAULT_BOX_WIDTH;
  return width;
};
export const getFilterFunctionFn = (type?: EFunctionFilterType) => {
  const filterMetricFunction = (functionItem: IFunction<{}>) => {
    return !(
      functionItem.descZh?.type === EFunctionType.WINDOW ||
      functionItem.descZh?.type === EFunctionType.ANALYSES ||
      functionItem.descZh?.type === EFunctionType.AGG
    );
  };

  const filterMetricFilterFunction = (functionItem: IFunction<{}>) => {
    return !(
      functionItem.descZh?.type === EFunctionType.WINDOW ||
      functionItem.descZh?.type === EFunctionType.ANALYSES ||
      functionItem.descZh?.type === EFunctionType.AGG ||
      fuzzyMatch(functionItem.name, 'isfiltered')
    );
  };

  const filterAtomicMetricFunction = (functionItem: IFunction<{}>) => {
    return !(
      (
        functionItem.descZh?.type === EFunctionType.WINDOW ||
        functionItem.descZh?.type === EFunctionType.ANALYSES ||
        fuzzyMatch(functionItem.name, 'isfiltered')
      )
      // functionItem.descZh?.type === EFunctionType.AGG
    );
  };
  const filterDimensionFunction = (functionItem: IFunction<{}>) => {
    return (
      !(functionItem.descZh?.type === EFunctionType.ANALYSES) ||
      (functionItem.descZh?.type === EFunctionType.ANALYSES &&
        [
          'poweradd',
          'powersub',
          'powerfix',
          'earlier',
          'removefilter',
        ].includes(functionItem.name.toLowerCase())) ||
      fuzzyMatch(functionItem.name, 'isfiltered')
    );
  };
  const filterDatasetFunction = (functionItem: IFunction<{}>) => {
    return !fuzzyMatch(functionItem.name, 'isfiltered');
  };
  if (type === EFunctionFilterType.ATOMIC_METRIC) {
    return filterAtomicMetricFunction;
  }
  if (type === EFunctionFilterType.METRIC) return filterMetricFunction;
  if (type === EFunctionFilterType.DIMENSION) return filterDimensionFunction;
  if (type === EFunctionFilterType.DATASET) return filterDatasetFunction;
  if (type === EFunctionFilterType.METRIC_FILTER)
    return filterMetricFilterFunction;

  return () => true;
};
const ICON_SIZE = 16;

const CodeEditorWrapper = (props: TProps) => {
  const {
    type,
    onBlur,
    onChange,
    placeholder,
    borderRadius = 'all',
    functionFilterType,
  } = props;

  const [isFunctionBoxOpen, setIsFunctionBoxOpen] = useState<boolean>(true);
  const [isColumnBoxOpen, setIsColumnBoxOpen] = useState<boolean>(true);
  const [columnBoxWidth, setColumnBoxWidth] = useState(DEFAULT_BOX_WIDTH);
  const [functionBoxWidth, setFunctionBoxWidth] = useState(DEFAULT_BOX_WIDTH);
  const codemirrorRef = useRef<ICodeEditorRef>(null);

  const [composeMetricSidebarSelectKey, setComposeMetricSidebarSelectKey] =
    useState<ECodeEditorWrapperType.METRIC | ECodeEditorWrapperType.DIMENSION>(
      ECodeEditorWrapperType.METRIC,
    );

  const onDatasetColumnClick = useCallback(
    (selectedColumnInfo: PColumn, datasetName?: string) => {
      if (type === ECodeEditorWrapperType.DATASET) {
        const tempDataset = [
          props.dataset,
          ...props.one2manyDatasets,
          ...(props.many2oneDatasets || []),
        ].find((item) => item?.name === datasetName);
        if (tempDataset) {
          const columnData = tempDataset.defColumns.find(
            (item) => item.name === selectedColumnInfo.id,
          )!;
          const isCurrentDataset = tempDataset.name === props.dataset?.name;
          completeColumn({
            type: ECodeEditorWrapperType.DATASET,
            data: {
              dataset: isCurrentDataset ? null : tempDataset,
              column: columnData,
            },
          });
        }
      }
    },
    [props, type],
  );
  const onMetricItemClick = useCallback((itemInfo: TMetric) => {
    completeColumn({
      type: ECodeEditorWrapperType.METRIC,
      data: itemInfo,
    });
  }, []);
  const onDimensionItemClick = useCallback((itemInfo: TDimension) => {
    completeColumn({
      type: ECodeEditorWrapperType.DIMENSION,
      data: itemInfo,
    });
  }, []);
  const onFunctionNameClick = useCallback((name: string) => {
    return codemirrorRef.current?.complete({
      type: 'function',
      functionName: name + '(',
    });
  }, []);
  function completeColumn({ type: thisType, data }: TComplete) {
    if (thisType === ECodeEditorWrapperType.DATASET) {
      const { dataset, column } = data;
      codemirrorRef.current?.complete({
        type: 'column',
        complete: !dataset
          ? `[${processingEscapeCharacter(column.displayName)}]`
          : `[${processingEscapeCharacter(
              dataset.displayName,
            )}/${processingEscapeCharacter(column.displayName)}]`,
        value: column.displayName,
        id: column.name,
        originDataType: column.originDataType,
        idPath: !dataset ? [column.name] : [dataset.name, column.name],
      });
    }
    if (thisType === ECodeEditorWrapperType.DIMENSION) {
      const item = transform2ListItem({
        type: thisType,
        data,
      });

      codemirrorRef.current?.complete({
        type: 'column',
        complete: `[${item.displayName}]`,
        value: item.displayName,
        id: item.id,
        originDataType: item.originDataType,
        idPath: [item.id],
      });
    }
    if (thisType === ECodeEditorWrapperType.METRIC) {
      const item = transform2ListItem({
        type: thisType,
        data,
      });
      codemirrorRef.current?.complete({
        type: 'column',
        complete: `[${item.displayName}]`,
        value: item.displayName,
        id: item.id,
        originDataType: item.originDataType,
        idPath: [item.id],
      });
    }
  }

  const externalInfo = useMemo(() => {
    return getExternalInfo(props);
  }, [props]);

  return (
    <div
      className={classNames(styles.wrapper, props.className, {
        [styles.topBorderRadius]: borderRadius === 'top',
        [styles.bottomBorderRadius]: borderRadius === 'bottom',
      })}
    >
      <Resizable
        handle={<div className={styles.resizerHandle} />}
        width={functionBoxWidth}
        className={styles.resizable}
        onResize={(e, data) => {
          if (isFunctionBoxOpen) {
            const widthRes = _.get(data, 'size.width', DEFAULT_BOX_WIDTH);
            setFunctionBoxWidth(getWidth(widthRes));
          }
        }}
        axis="x"
      >
        <div
          className={classNames(styles.functionBox, {
            [styles.closeBox]: !isFunctionBoxOpen,
            [styles.topBorderRadius]: borderRadius === 'top',
            [styles.bottomBorderRadius]: borderRadius === 'bottom',
          })}
          style={{ width: functionBoxWidth }}
        >
          <div className={styles.header}>
            <div className={styles.title}>
              {t.components.codeEditorWrapper.function}
            </div>
            <IconButton
              className={styles.operateIcon}
              onClick={() => {
                if (isFunctionBoxOpen) {
                  setFunctionBoxWidth(CLOSE_BOX_WIDTH);
                  setIsFunctionBoxOpen(false);
                } else {
                  setFunctionBoxWidth(DEFAULT_BOX_WIDTH);
                  setIsFunctionBoxOpen(true);
                }
              }}
              icon={
                isFunctionBoxOpen ? (
                  <ArrowLeftIcon size={ICON_SIZE} />
                ) : (
                  <ArrowRightIcon size={ICON_SIZE} />
                )
              }
            ></IconButton>
          </div>
          {isFunctionBoxOpen && (
            <div className={styles.content}>
              <FunctionList
                filterFunctionList={getFilterFunctionFn(functionFilterType)}
                onClick={onFunctionNameClick}
              />
            </div>
          )}
        </div>
      </Resizable>

      <Resizable
        handle={<div className={styles.resizerHandle} />}
        width={columnBoxWidth}
        className={styles.resizable}
        onResize={(e, data) => {
          if (isColumnBoxOpen) {
            const widthRes = _.get(data, 'size.width', DEFAULT_BOX_WIDTH);
            setColumnBoxWidth(getWidth(widthRes));
          }
        }}
        axis="x"
      >
        <div
          className={classNames(styles.columnBox, {
            [styles.closeBox]: !isColumnBoxOpen,
          })}
          style={{ width: columnBoxWidth }}
        >
          {isColumnBoxOpen ? (
            <>
              <div className={styles.header}>
                <div className={styles.titleWrapper}>
                  {type === ECodeEditorWrapperType.COMPOSE_METRIC ? (
                    <>
                      <div
                        className={classNames(
                          styles.title,
                          KEEP_FOCUS_CLASS_COlUMN,
                          {
                            [styles.notActive]:
                              composeMetricSidebarSelectKey !==
                              ECodeEditorWrapperType.METRIC,
                          },
                        )}
                        onClick={() => {
                          setComposeMetricSidebarSelectKey(
                            ECodeEditorWrapperType.METRIC,
                          );
                        }}
                      >
                        {columnTitleMap[ECodeEditorWrapperType.METRIC]}
                      </div>
                      <div
                        className={classNames(
                          styles.title,
                          KEEP_FOCUS_CLASS_COlUMN,
                          {
                            [styles.notActive]:
                              composeMetricSidebarSelectKey !==
                              ECodeEditorWrapperType.DIMENSION,
                          },
                        )}
                        onClick={() => {
                          setComposeMetricSidebarSelectKey(
                            ECodeEditorWrapperType.DIMENSION,
                          );
                        }}
                      >
                        {columnTitleMap[ECodeEditorWrapperType.DIMENSION]}
                      </div>
                    </>
                  ) : (
                    <div className={styles.title}>{columnTitleMap[type]}</div>
                  )}
                </div>
                <IconButton
                  className={styles.operateIcon}
                  onClick={() => {
                    if (isColumnBoxOpen) {
                      setColumnBoxWidth(CLOSE_BOX_WIDTH);
                      setIsColumnBoxOpen(false);
                    } else {
                      setColumnBoxWidth(DEFAULT_BOX_WIDTH);
                      setIsColumnBoxOpen(true);
                    }
                  }}
                  icon={
                    isColumnBoxOpen ? (
                      <ArrowLeftIcon size={ICON_SIZE} />
                    ) : (
                      <ArrowRightIcon size={ICON_SIZE} />
                    )
                  }
                ></IconButton>
              </div>
              <div className={styles.content}>
                {type === ECodeEditorWrapperType.DATASET && (
                  <SearchColumnWrapper
                    items={_.concat(
                      props?.dataset || [],
                      props.one2manyDatasets,
                      props?.many2oneDatasets || [],
                    )}
                    hasBorder={false}
                    onChange={onDatasetColumnClick}
                    hasPadding={false}
                  />
                )}
                {type === ECodeEditorWrapperType.DIMENSION && (
                  <div className={styles.padding}>
                    <DimensionTreePicker
                      dimensions={props.dimensions as TDimension[]}
                      showMetricTime={false}
                      getDisabledMap={props.getDisabledMap}
                      hideEmptyCategory
                      onChange={onDimensionItemClick}
                      size="small"
                      mode={ESelectMode.SINGLE}
                      noPadding
                    />
                  </div>
                )}
                {type === ECodeEditorWrapperType.METRIC && (
                  <div className={styles.padding}>
                    <MetricTreePicker
                      metrics={props.metric}
                      onChange={onMetricItemClick}
                      size="small"
                      noPadding
                    />
                  </div>
                )}
                {type === ECodeEditorWrapperType.COMPOSE_METRIC && (
                  <div className={styles.padding}>
                    {composeMetricSidebarSelectKey ===
                    ECodeEditorWrapperType.METRIC ? (
                      <MetricTreePicker
                        metrics={props.metric}
                        onChange={onMetricItemClick}
                        size="small"
                        noPadding
                      />
                    ) : (
                      <DimensionTreePicker
                        dimensions={props.dimensions as TDimension[]}
                        showMetricTime={false}
                        hideEmptyCategory
                        onChange={onDimensionItemClick}
                        size="small"
                        mode={ESelectMode.SINGLE}
                        noPadding
                      />
                    )}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className={styles.header}>
              {type === ECodeEditorWrapperType.COMPOSE_METRIC ? (
                <>
                  {composeMetricSidebarSelectKey ===
                  ECodeEditorWrapperType.METRIC ? (
                    <div className={styles.title}>
                      {columnTitleMap[ECodeEditorWrapperType.METRIC]}
                    </div>
                  ) : (
                    <div className={styles.title}>
                      {columnTitleMap[ECodeEditorWrapperType.DIMENSION]}
                    </div>
                  )}
                </>
              ) : (
                <div className={styles.title}>{columnTitleMap[type]}</div>
              )}
              <IconButton
                className={styles.operateIcon}
                onClick={() => {
                  if (isColumnBoxOpen) {
                    setColumnBoxWidth(CLOSE_BOX_WIDTH);
                    setIsColumnBoxOpen(false);
                  } else {
                    setColumnBoxWidth(DEFAULT_BOX_WIDTH);
                    setIsColumnBoxOpen(true);
                  }
                }}
                icon={
                  isColumnBoxOpen ? (
                    <ArrowLeftIcon size={ICON_SIZE} />
                  ) : (
                    <ArrowRightIcon size={ICON_SIZE} />
                  )
                }
              ></IconButton>
            </div>
          )}
        </div>
      </Resizable>
      <div
        className={styles.editorBox}
        style={{
          minWidth: '30%',
        }}
      >
        <CodeEditor
          ref={codemirrorRef}
          defaultAst={props.ast}
          externalInfo={externalInfo}
          filterFunctionList={getFilterFunctionFn(functionFilterType)}
          currentColumnId={props.currentName}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          readonly={false}
          borderRadius={borderRadius}
        />
      </div>
    </div>
  );
};
function getExternalInfo(props: TProps): TExternalInfo {
  if (props.type === ECodeEditorWrapperType.DATASET) {
    return {
      type: ECodeEditorPropsType.DATASET,
      dataset: props.dataset,
      many2oneDatasets: props.many2oneDatasets,
      one2manyDatasets: props.one2manyDatasets,
    };
  }
  if (props.type === ECodeEditorWrapperType.METRIC) {
    return {
      type: ECodeEditorPropsType.LIST,
      list: props.metric.map((metric) =>
        transform2ListItem({
          type: props.type,
          data: metric,
        }),
      ),
    };
  }
  if (props.type === ECodeEditorWrapperType.COMPOSE_METRIC) {
    return {
      type: ECodeEditorPropsType.COMPOSE_METRIC,
      list: props.metric.map((metric) =>
        transform2ListItem({
          type: ECodeEditorWrapperType.METRIC,
          data: metric,
        }),
      ),
      dimensionList:
        props.dimensions?.map((dimensions) =>
          transform2ListItem({
            type: ECodeEditorWrapperType.DIMENSION,
            data: dimensions,
          }),
        ) || [],
    };
  }
  return {
    type: ECodeEditorPropsType.LIST,
    list:
      props.dimensions?.map((dimensions) =>
        transform2ListItem({
          type: props.type,
          data: dimensions,
        }),
      ) || [],
  };
}
function transform2ListItem({
  type,
  data,
}:
  | {
      type: ECodeEditorWrapperType.DIMENSION;
      data: IDimension;
    }
  | {
      type: ECodeEditorWrapperType.METRIC;
      data: IMetric;
    }) {
  if (type === ECodeEditorWrapperType.DIMENSION)
    return {
      id: data.name,
      displayName: data.displayName,
      dataType: originDataType2DataTypeMap[data.originDataType],
      originDataType: data.originDataType,
    };
  return {
    id: data.code,
    displayName: data.name,
    dataType: data.dataType,
    originDataType: data.originDataType,
  };
}
export default CodeEditorWrapper;
