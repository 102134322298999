import { engineInfo } from '@/common/globalInfo/appConfig';
import { generateParser } from './parser/autocompleteParser';
import { ISuggestResult } from './parser/types';
import { EQueryEngineType } from '@/typings/formula';

export const getEngineType = () => {
  return engineInfo.queryEngineType || EQueryEngineType.AIR;
};

export function replaceStartDollarEscapeCharacter(str: string) {
  return str.replace(/^\\\$/, '$');
}

export function removeSourceDollar(str: string) {
  return str.replace(/^\$/, '');
}

// 获取解析的列表
export function getSuggests(formula: string) {
  try {
    const parser = generateParser();
    parser.feed(`${formula}|`);
    return parser.results[0] as ISuggestResult;
    // .error的时候返回一个空对象
  } catch {
    return {};
  }
}

// 是否有列建议
export function hasColumnSuggests(formula: string) {
  return !!getSuggests(formula).suggestColumn;
}
