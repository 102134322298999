import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import _ from 'lodash';
import {
  EColumnDataType,
  EDateGranularityType,
  EOriginDataType,
  IDatasourceItem,
  IRestfulResponse,
} from '../../src/typings';
import {
  EColumnType,
  ESourceType,
  IColumn,
  IDataset,
  IDatasetRelation,
  IDatasetSourceInfo,
  ITableColumn,
  ERelationType,
  IDefColumn,
} from '../../src/typings/dataset';
import {
  EFormulaType,
  EQueryEngineType,
  TFormula,
} from '../../src/typings/formula';
import {
  EAuthoritySource,
  EResourceType,
  EUserRole,
  IAuthorityResource,
} from '../../src/typings/authority';
import { getUniqId } from '../../src/common/utils';
import { mockCategoryList } from '../category/mockData';
import { TSimpleDatasetInfo } from '../../src/pages/Dataset/components/RelationModal/Form/helper';
import { EDatasourceEntityType, ESign } from '../../src/typings/dataSource';
import getUserInfo from './getUserInfo';
/**
 * 生成 restful response 的标准结构
 */
export function getRestfulResponse<T = unknown>(
  data: T,
  opt?: Partial<IRestfulResponse<T>>,
): IRestfulResponse<T> {
  return {
    success: true,
    data,
    errorMsg: '',
    errorCode: '',
    detailErrorMsg: '',
    ...opt,
  };
}

export function getMockTableSourceInfo(
  type: ESourceType,
  content: string,
): IDatasetSourceInfo {
  return {
    name: 'table_name',
    displayName: '库存分析',
    description: '',
    type,
    content,
    datasourceItems: getDatasourceItems(),
    columns: getTableColumns(),
    path: [
      {
        guid: 'datasource1',
        name: 'datasource1',
        entityType: EDatasourceEntityType.CATALOG,
      },
      {
        guid: 'database1',
        name: 'database1',
        entityType: EDatasourceEntityType.SCHEMA,
      },
    ],
    datasource: {
      displayName: '业务数据库',
      name: 'biz_datasource',
      datasourceType: null,
    },
  };
}

export function getDatasourceItems(): IDatasourceItem[] {
  return [
    {
      id: 'datasourceItem_id',
      name: 'datasourceItem_name',
      queryEngineType: EQueryEngineType.AIR,
    },
  ];
}

export function getTableColumns(): ITableColumn[] {
  const otherColumns = _.map(_.range(10), (index) => ({
    name: `col_${index}`,
    displayName: `字段_${index}`,
    originDataType: EOriginDataType.BOOLEAN,
    description: `字段_${index}`,
    isPartition: false,
    isFirstPartition: false,
  }));
  return [
    {
      name: 'year_month',
      displayName: '年月',
      originDataType: EOriginDataType.DATETIME,
      description: '年月' + getUniqId(),
      isPartition: false,
      isFirstPartition: false,
    },
    {
      name: 'warehouse',
      displayName: '仓库',
      originDataType: EOriginDataType.VARCHAR,
      description: '仓库' + getUniqId(),
      isPartition: false,
      isFirstPartition: false,
    },
    {
      name: 'product_code',
      displayName: '产品编码',
      originDataType: EOriginDataType.VARCHAR,
      description: '产品编码',
      isPartition: false,
      isFirstPartition: false,
    },
    {
      name: 'product_name',
      displayName: '产品名称',
      originDataType: EOriginDataType.VARCHAR,
      description: '产品名称',
      isPartition: false,
      isFirstPartition: false,
    },
    {
      name: 'product_type',
      displayName: '产品类别',
      originDataType: EOriginDataType.VARCHAR,
      description: '产品类别',
      isPartition: false,
      isFirstPartition: false,
    },
    {
      name: 'stock',
      displayName: '库存余量',
      originDataType: EOriginDataType.INTEGER,
      description: '库存余量',
      isPartition: false,
      isFirstPartition: false,
    },
    {
      name: 'start_amount',
      displayName: '期初存货金额',
      originDataType: EOriginDataType.REAL,
      description: '期初存货金额',
      isPartition: false,
      isFirstPartition: false,
    },
    {
      name: 'end_amount',
      displayName: '期末存货金额',
      originDataType: EOriginDataType.REAL,
      description: '期末存货金额',
      isPartition: false,
      isFirstPartition: false,
    },

    {
      name: 'sales',
      displayName: '销售件数',
      originDataType: EOriginDataType.INTEGER,
      description: '销售件数',
      isPartition: false,
      isFirstPartition: false,
    },
    {
      name: 'cost',
      displayName: '单件销货成本',
      originDataType: EOriginDataType.REAL,
      description: '单件销货成本',
      isPartition: false,
      isFirstPartition: false,
    },
    {
      name: 'date',
      displayName: '分区日期',
      originDataType: EOriginDataType.DATE,
      description: '分区日期',
      isPartition: true,
      isFirstPartition: false,
    },
    {
      name: 'date_time',
      displayName: '日期时间',
      originDataType: EOriginDataType.DATETIME,
      description: '日期时间',
      isPartition: true,
      isFirstPartition: false,
    },
    {
      name: 'json',
      displayName: 'json',
      originDataType: EOriginDataType.JSON,
      description: 'json',
      isPartition: false,
      isFirstPartition: false,
    },
    ...otherColumns,
  ];
}

export function getAuthorityResource(
  canUsage: boolean = true,
): IAuthorityResource {
  return {
    resourceId: 'test_id',
    resourceType: EResourceType.DATASET,
    authorityRole: EUserRole.OWNER,
    authoritySource: EAuthoritySource.DIRECT,
    authority: {
      canEdit: true,
      canDelete: true,
      canUsage,
      canAuth: true,
      authRoleList: [EUserRole.ADMIN, EUserRole.USAGER, EUserRole.VIEWER],
      canTransfer: false,
    },
  };
}
export function getMockDatasetInfo(datasetName: string): IDataset {
  const mockSourceInfo = _.sample([
    // { type: ESourceType.TABLE, content: 'table_name' },
    { type: ESourceType.SQL, content: "SELECT * FROM 'testTable'" },
    // { type: ESourceType.FILE, content: 'file_id' },
  ]);

  const sourceInfo = getMockTableSourceInfo(
    mockSourceInfo.type,
    mockSourceInfo.content,
  );

  const partitionColumn: IDefColumn = {
    dataType: EColumnDataType.DATE,
    type: EColumnType.DIMENSION,
    description: '',
    originDataType: EOriginDataType.DATE,
    displayName: '分区字段',
    name: 'partitionColumn',
    isPartition: true,
    isFirstPartition: true,
    sourceColumn: 'partitionColumn',
    ast: { type: EFormulaType.COLUMN, path: ['source', 'partitionColumn'] },
  };

  const dateColumn = Array.from({ length: 10 })
    .fill(0)
    .map(
      (v, i): IDefColumn => ({
        dataType: EColumnDataType.DATE,
        type: EColumnType.DIMENSION,
        description: '',
        isFirstPartition: false,
        originDataType: EOriginDataType.DATE,
        displayName: '分区字段',
        name: 'dateColumn' + i,
        sourceColumn: 'partitionColumn',
        isPartition: false,
        ast: { type: EFormulaType.COLUMN, path: ['source', 'partitionColumn'] },
      }),
    );

  const columns = _.map(sourceInfo.columns, (col) => {
    const dataType = originDataType2DataTypeMap[col.originDataType];
    const type = [
      EColumnDataType.DECIMAL,
      // EColumnDataType.INT,
      EColumnDataType.DOUBLE,
    ].includes(dataType)
      ? EColumnType.MEASURE
      : EColumnType.DIMENSION;
    return {
      ...col,
      dataType,
      type,
      dimension: {
        name: getUniqId(),
        displayName: col.displayName,
        originDataType: col.originDataType,
        owner: {
          userId: getUniqId(),
        },
      },
    };
  });

  const defColumns: IDefColumn[] = _.map(
    columns,
    (col): IDefColumn => ({
      ...col,
      displayName: col.name,
      sourceColumn: col.name,
      ast: {
        type: EFormulaType.COLUMN,
        path: [sourceInfo.name, col.name],
      } as TFormula,
      dimension: {
        name: getUniqId(),
        displayName: col.displayName,
        originDataType: col.originDataType,
        owner: {
          userId: getUniqId(),
        },
      },
    }),
  ).concat([partitionColumn, ...dateColumn]);

  return {
    name: datasetName,
    metricCount: 10,
    displayName: `DS_${datasetName}`,
    description:
      _.random(100) > 30
        ? _.range(100)
            .map(() => `数据集描述_${datasetName}`)
            .join(';')
        : '',
    relations: getDatasetRelations(columns, false),
    relationFroms: getDatasetRelations(columns, true),
    partitionMapping: {
      dateGranularity: EDateGranularityType.DAY,
      columnPartitionMappings: [],
    },
    sourceInfo,
    columns,
    defColumns,
    owner: getUserInfo(),
    updateTime: new Date('2023-07-06 19:39:00').getTime(),
    createTime: new Date('2023-07-06 09:30:00').getTime(),
    authorityResource: getAuthorityResource(true),
    category: [_.sample(mockCategoryList)!],
  };
}

export function getDatasetRelations(
  columns: IColumn[],
  isRelated: boolean, // 是否被关联
): IDatasetRelation[] {
  const RELATION_NUM = 5;

  return _.map<number, IDatasetRelation>(_.range(RELATION_NUM), (index) => {
    const type = index % 2 ? ERelationType.HAS_ONE : ERelationType.HAS_MANY;

    const srcDatasetName = isRelated ? `srcDatasetName_${index}` : '';
    const dstDatasetName = !isRelated ? `dstDatasetName_${index}` : '';

    return {
      id: `relation_id_${index}`,
      type,
      srcDatasetName,
      dstDatasetName,
      srcDatasetDisplayName: isRelated ? `srcDatasetDisplayName_${index}` : '',
      dstDatasetDisplayName: !isRelated ? `dstDatasetDisplayName_${index}` : '',
      srcDataset: {
        name: srcDatasetName,
        displayName: isRelated ? `srcDatasetDisplayName_${index}` : '',
        columns: isRelated
          ? _.map(_.range(10), (i) => {
              return {
                name: `srcName_${i}`,
                displayName: `srcDisplayName_${i}`,
              };
            })
          : columns.map((column) => ({
              name: column.name,
              displayName: column.displayName,
            })),
      } as TSimpleDatasetInfo,
      dstDataset: {
        name: dstDatasetName,
        displayName: !isRelated ? `dstDatasetDisplayName_${index}` : '',
        columns: !isRelated
          ? _.map(_.range(10), (i) => {
              return {
                name: `dstName_${i}`,
                displayName: `dstDisplayName_${i}`,
              };
            })
          : columns.map((column) => ({
              name: column.name,
              displayName: column.displayName,
            })),
      } as TSimpleDatasetInfo,
      relationConditions: _.map(_.range(10), (i) => {
        return {
          srcAst: {
            type: EFormulaType.COLUMN,
            path: isRelated
              ? [srcDatasetName, `srcName_${i}`]
              : [columns[i].name],
          },
          dstAst: {
            type: EFormulaType.COLUMN,
            path: !isRelated
              ? [dstDatasetName, `dstName_${i}`]
              : [columns[i].name],
          },
        };
      }),
      filters: [],
      owner: getUserInfo(),
    };
  });
}
