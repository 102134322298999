import { ETokenType, IRender, IToken } from '../type';
import _ from 'lodash';
import { colorArray } from '@/constants/columnHighlightColor';

export function calculatePopupPosition(
  x: number,
  y: number,
  popupWidth: number,
): {
  x: number;
  y: number;
} {
  const pageWidth =
    document.documentElement.clientWidth || document.body.clientWidth;
  let popupX: number;
  let popupY: number;

  // Check if popup overflows from the right side of the page
  if (x + popupWidth > pageWidth) {
    popupX = pageWidth - popupWidth; // Position it at the right edge
  } else {
    popupX = x; // Position it at the specified x coordinate
  }

  // Check if popup overflows from the top of the page
  if (y < 0) {
    popupY = 0; // Position it at the top edge
  } else {
    popupY = y; // Position it at the specified y coordinate
  }

  return { x: popupX, y: popupY };
}

/**
 * 获取token的规则：
 * - 如果当前光标处在token的中间，则返回当前token及之前所有的token，
 * - 如果当前光标处于两个token之间，
 *    - 则先判断右侧的token是否是实体，如果是实体则返回右侧token及之前所有token，否则返回左侧token及之前所有token
 * @param token
 * @param col
 */
export const getTokensBeforeCursorPos = (tokens: IToken[], col: number) => {
  const result: IToken[] = [];
  _.forEach(tokens, (token, index) => {
    result.push(token);
    if (isCursorInToken(col, token)) {
      return false;
    }
    if (isBorder(col, token) && index !== tokens.length - 1) {
      if (isEntity(tokens[index + 1])) {
        result.push(tokens[index + 1]);
        return false;
      }
      return false;
    }

    if (
      isLeftBrackets(token) &&
      token.offset + token.text.length >= col &&
      isFunctionName(result[index - 1] || {})
    ) {
      result.pop();
    }
  });

  return result;
};

// 判断是否在token的边界上
const isBorder = (col: number, token: IToken) =>
  col === token.offset + token.text.length;
// 是否在token中
const isCursorInToken = (col: number, token: IToken) =>
  col >= token.offset && col < token.offset + token.text.length;
// 判断一个token是否是实体
const isEntity = (token: IToken) => {
  const entityTypeArr = ['table', 'column', 'functionId', 'string'];
  return entityTypeArr.includes(token.type);
};
const isLeftBrackets = (token: IToken) => {
  return token.text === '(';
};

const isFunctionName = (token: IToken) => {
  return token.type === 'functionId';
};

// 获取token中的列并将列转换成编辑器可以渲染的结构
export const getColumnHighlight = (
  tokenList: IToken[],
): {
  codemirrorRender: IRender[];
} => {
  const filterTokenList = tokenList.filter(
    (token) => [ETokenType.COLUMN].includes(token.type) && token.idPath,
  );
  // 中间转换函数
  const columnTextColorMap = filterTokenList.reduce((result, token) => {
    return {
      ...result,
      [token.text]:
        result[token.text] ||
        colorArray[Object.keys(result).length % colorArray.length],
    };
  }, {} as { [columnText: string]: string });

  const codemirrorRender = filterTokenList.map((token) => ({
    offset: token.offset,
    end: token.end,
    color: columnTextColorMap[token.text],
  }));

  return {
    codemirrorRender,
  };
};

export function removeWhitespaceAndNewlines(input: string): string {
  return input.replace(/\s+/g, '');
}
