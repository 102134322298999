export const datePart = {
  year: 'Year',
  quarter: 'Quarter',
  month: 'Month',
  day: 'Day of Month',
  hour: 'Hour',
  minute: 'Minute',
  second: 'Second',
  weeknum: 'Week Number',
  weekday: 'Day of Week',
};

export const placeholder = 'Enter calculation formula';

export const dataModel = {
  DSL: {
    source: 'Source',
  },
};

export const dashboard = {
  DSL: {
    metric: 'Metric',
  },
};

export const callOp = {
  datatrunc: {
    year: 'Year',
    quarter: 'Quarter',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute',
    second: 'Second',
  },
};

export const derivate = {
  error: {
    referenceError: 'Reference field error',
    constantError: 'Constant error',
    binaryOperatorError: 'Binary operator error',
    unknownError: 'Formula type error',
    parserError: 'Formula parsing error',
  },
};

export const parse = {
  error: {
    columnNotFound: 'Not exist',
    columnNeedAggFn:
      'Field is from n-end table, external needs nested aggregation function',
    isFilteredFnOnlyCanUseDimension:
      'Only dimension fields can be used inside isFiltered function',
    dimensionOnlyCanUsedInIsFilteredFn:
      'Dimension fields can only be used inside isFiltered function',
    notFunction: 'Not a function',
    functionNotExist: 'Function does not exist',
    functionNotNeedArgs: 'Function does not need parameters',
    functionArgsLengthError2: 'Wrong number of function parameters',
    functionArgsTypeError: 'Function parameter type error',
  },
};

export const findEntity = {
  error: {
    noAuth: 'No permission to use this field',
  },
};
