import { Input, Progress, AldSelect } from '@aloudata/aloudata-design';
import styles from './index.less';
import { useMemo, useState } from 'react';
import { useQueryAllUserPropertyDef } from '@/services/user';
import { IUserPropertyDef } from '@/services/user/types';
import _ from 'lodash';
import t from '@/locales';
import {
  ECommonFilterValueType,
  TFilterSelectValues,
} from '../../../Filter/filter';
import { transformValues } from '@/common/domain/filter/Filter/helper';
import { useMount } from 'ahooks';
interface IProps {
  onChange: (data: TFilterSelectValues) => void;
  value: TFilterSelectValues;
}
export default function UserAttributePicker({ onChange, value }: IProps) {
  const [userPropertyDefs, setUserPropertyDefs] =
    useState<IUserPropertyDef[]>();
  const { queryUserPropertyDefs, loading: queryAllUserPropertyDefLoading } =
    useQueryAllUserPropertyDef();
  const [keyword, setKeyword] = useState<string>('');
  const displayOptions = useMemo(() => {
    if (!userPropertyDefs) {
      return [];
    }
    return userPropertyDefs
      .map((item) => ({
        label: item.displayName,
        value: item.name,
      }))
      .filter((item) =>
        item.label.toLocaleLowerCase().includes(keyword.toLowerCase()),
      );
  }, [keyword, userPropertyDefs]);
  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(_.trim(e.target.value));
  };
  const dropdownRender = (menu: React.ReactElement) => {
    return (
      <div>
        <div className={styles.loadingBar}>
          <Progress loading={queryAllUserPropertyDefLoading} />
        </div>
        <div className={styles.inputWrapper}>
          <Input
            className={styles.inputSearch}
            onChange={onChangeSearch}
            placeholder={t.filter.filter.search}
            value={keyword}
          />
        </div>
        <div>{menu}</div>
      </div>
    );
  };
  const onChangeValue = (val: string) => {
    onChange([
      {
        type: ECommonFilterValueType.STRING,
        value: val,
      },
    ]);
  };
  useMount(() => {
    queryUserPropertyDefs({}).then((res) => {
      setUserPropertyDefs(res.queryAllUserPropertyDef);
    });
  });
  return (
    <div className={styles.wrap}>
      <AldSelect
        showArrow={true}
        onChange={onChangeValue}
        value={transformValues(value)[0]}
        listHeight={150}
        placeholder={t.filter.filter.attribute.placeholder}
        popupClassName={styles.dropdown}
        onOpenChange={async (open) => {
          if (open && !userPropertyDefs) {
            queryUserPropertyDefs({}).then((res) => {
              setUserPropertyDefs(res.queryAllUserPropertyDef);
            });
          }
          setKeyword('');
        }}
        dropdownRender={dropdownRender}
        onInputKeyDown={(e) => {
          // 禁止键盘事件
          e.stopPropagation();
          e.preventDefault();
        }}
        options={displayOptions}
      />
    </div>
  );
}
