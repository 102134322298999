import {
  TQuickCalc,
  quickCalcMethodMap,
  quickCalcPeriodMap,
} from '@/typings/analysisView';

/**
 * 生成指标 id
 * 1，如果是查原始指标，则为：指标code，如TotalPrice
 2，如果是查指标的月同比，则为：[指标code]_[周期]_[计算的方式]
 */
export function getMetricId(metric: { code: string }, quickCalc?: TQuickCalc) {
  let id = '';
  if (quickCalc) {
    const period = quickCalcPeriodMap[quickCalc.granularity];
    const method = quickCalcMethodMap[quickCalc.calculateType];
    id = `${metric.code}_${period}_${method}`;
  } else {
    id = metric.code;
  }
  return `METRIC_${id}`;
}

/**
 * 生成维度 id
 *
 通用规则：[列名称]_[日期粒度]

 如果没有日期粒度，则表示为[列名称]
 */
export function getDimensionId(dimensionName: string, granularity?: string) {
  let id = '';
  if (!granularity) {
    id = `${dimensionName}`;
  } else {
    id = `${dimensionName}_${granularity!.toLocaleLowerCase()}`;
  }
  // replace 是因为存在"."的 ID 不能命中服务端加速
  return `DIMENSION_${id}`.replaceAll('.', '_');
}
