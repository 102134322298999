import { dateTimeGranularityOptions } from '@/constants';
import { EDateGranularityType } from '@/typings';
import { IDateTag } from '@/typings/periodLimit';
import _ from 'lodash';
import { IValue } from './types';

/**
 * 获取动态时间的单位显示名称
 */
export function getUnitDisplayName(value: IValue, dateTags?: IDateTag[]) {
  const { granularity, dateTag } = value;
  if (granularity === EDateGranularityType.DAY && dateTag) {
    return _.find(dateTags, { exprCode: dateTag })?.name || dateTag;
  }
  return _.find(dateTimeGranularityOptions, { value: granularity })?.label;
}

/**
 * 是否是比日粒度小的时间粒度
 */

export function isTimeGranularity(granularity: EDateGranularityType) {
  return [
    EDateGranularityType.HOUR,
    EDateGranularityType.MINUTE,
    EDateGranularityType.SECOND,
  ].includes(granularity);
}
