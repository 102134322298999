import { IAuthority } from './authority';

export enum ECategoryType {
  'CATEGORY_ANALYSIS_VIEW' = 'CATEGORY_ANALYSIS_VIEW',
  'CATEGORY_METRIC' = 'CATEGORY_METRIC',
  'CATEGORY_DIMENSION' = 'CATEGORY_DIMENSION',
  'CATEGORY_DATASET' = 'CATEGORY_DATASET',
}
export enum ECategoryProperty {
  'CATEGORY_SYSTEM' = 'SYSTEM',
  'CATEGORY_CUSTOM' = 'CUSTOM',
}
export interface ICategory {
  id: string;
  name: string;
  resourceCount: number;
  parentId: string | null;
  frontId: string | null;
  type: ECategoryProperty;
  authority?: IAuthority;
}

export type TSimpleCategory = Pick<ICategory, 'id' | 'name'>;
