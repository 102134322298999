import {
  EMetricTimeKey,
  getGetMetricTimeConfig,
} from '@/common/domain/filter/NewFilter/MetricTimePicker/constants';
import { TMetricTime, TMetricTimeConfig } from '@/typings/analysisView';

// react-dnd 拖放类型
export const DND_TYPE_ADD_METRIC = 'DND_TYPE_ADD_METRIC';
export const DND_TYPE_ADD_DIMENSION = 'DND_TYPE_ADD_DIMENSION';
export const DND_TYPE_PLACE_DIMENSION = 'DND_TYPE_PLACE_DIMENSION';
export const DND_TYPE_PLACE_METRIC = 'DND_TYPE_PLACE_METRIC';

export const QUERY_LIMIT = 1000;

// 默认的指标日期
export const getDefaultMetricTime = (): TMetricTime => {
  const last30Config = getGetMetricTimeConfig(EMetricTimeKey.LAST_30_DAYS);
  return {
    active: true,
    ...(last30Config.value as TMetricTimeConfig),
  };
};
