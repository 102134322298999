import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import functionConfig from '@/common/domain/Formula/functionConfig/functionConfig';
import { looseDataTypeMap as dateType2LooseDataTypeMap } from '@/common/domain/Formula/helper';
import t from '@/locales';
import {
  EFormulaType,
  EOriginDataType,
  EQueryEngineType,
  IFunctionOperator,
  TFormula,
} from '@/typings/formula';
import { ECheckResultType, TCheckResult } from '../function';
export const isArgsPositiveIntegerFn = (args: TFormula) => {
  if (
    args.type === EFormulaType.CONSTANT &&
    [EOriginDataType.BIGINT, EOriginDataType.DOUBLE].includes(args.val.type) &&
    Number(args.val.val) > 0
  )
    return true;
  return false;
};
const checkLag = (params: {
  op: string;
  args: EOriginDataType[];
  ast: IFunctionOperator;
  queryEngineType: EQueryEngineType;
}): TCheckResult => {
  const ARGS_COLUMN_INDEX = 0;
  const ARGS_OFFSET_INDEX = 1;
  const ARGS_SPACE_INDEX = 2;
  const ARGS_LENGTH_TWO = 2;
  const { op, args, ast, queryEngineType } = params;
  const { signatures } =
    functionConfig[queryEngineType].functions[op.toUpperCase()];
  const columnArgs = ast.args[ARGS_COLUMN_INDEX];
  const offsetArgs = ast.args[ARGS_OFFSET_INDEX];
  // 只有一个参数的时候检查第一个参数是否是列
  const checkColumnArgsError = (): TCheckResult | null => {
    if (ast.args.length === 1 && columnArgs.type !== EFormulaType.COLUMN) {
      return {
        type: ECheckResultType.ERROR,
        errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
      };
    }
    return null;
  };
  // 检查第二个参数是否是bigInt
  const checkOffsetArgsError = (): TCheckResult | null => {
    if (
      offsetArgs.type === EFormulaType.CONSTANT &&
      args[ARGS_OFFSET_INDEX] === EOriginDataType.BIGINT &&
      isArgsPositiveIntegerFn(offsetArgs)
    )
      return null;
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
    };
  };
  // 检查第一个参数和第三个参数是否是同一大类
  const checkSpaceArgsError = (): TCheckResult | null => {
    const columnLooseDateType =
      dateType2LooseDataTypeMap[
        originDataType2DataTypeMap[args[ARGS_COLUMN_INDEX]]
      ];
    const spaceLooseDateType =
      dateType2LooseDataTypeMap[
        originDataType2DataTypeMap[args[ARGS_SPACE_INDEX]]
      ];
    if (columnLooseDateType !== spaceLooseDateType) {
      return {
        type: ECheckResultType.ERROR,
        errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
      };
    }
    return null;
  };
  const getSuccessReturn = (argsLength: number): TCheckResult => {
    if (argsLength <= ARGS_LENGTH_TWO)
      return {
        type: ECheckResultType.ORIGIN_DATA_TYPE,
        originDataType: args[ARGS_COLUMN_INDEX],
      };
    const result = signatures.find((item) => {
      return item.args.every((type, index) => args[index] === type);
    });
    if (result) {
      return {
        type: ECheckResultType.ORIGIN_DATA_TYPE,
        originDataType: result.returnType,
      };
    }
    return {
      type: ECheckResultType.ERROR,
      errorMsg: `${op}${t.formula.parse.error.functionArgsTypeError}`,
    };
  };
  const checkoutArgs = (argLength: number): TCheckResult => {
    const funcArr = [
      checkColumnArgsError,
      checkOffsetArgsError,
      checkSpaceArgsError,
    ];
    const result = funcArr
      .slice(0, argLength)
      .reduce<TCheckResult | null>((tempResult, fun) => {
        return tempResult || fun();
      }, null);
    return result ? result : getSuccessReturn(argLength);
  };
  return checkoutArgs(args.length);
};
export default checkLag;
