import { get, post } from '@/common/utils/request';
import {
  IUser,
  IUserDetail,
  IUserWithAuthority,
  IListItems,
  IPagerInput,
  ELanguage,
} from '@/typings';
import {
  EExecuteType,
  IAkInfo,
  IBindUserDetail,
  IExecuteRecord,
  ILoginDefaultCode,
  IQueryUserAndGroupReq,
  IQueryUserAndGroupRes,
  IQueryUserListRequest,
  ISoftwareProperty,
  IUserGroup,
  IUserGroupResponse,
  IUserProperty,
  IUserPropertyDef,
  TGroupSyncConfig,
  TUserSoftBindConfig,
  TUserSyncConfig,
} from './types';
import { gql } from '@apollo/client';
import {
  useGqlLazyQuery,
  useGqlMutation,
  useGqlQuery,
} from '@/common/utils/gqlRequest';
import { EUserRole } from '@/typings/authority';
import { ISoftIntegrationBaseInfo } from '@/typings/softIntegration';
import { useRequest } from 'ahooks';
import { userBaseInfo, userDetail } from './gql';

/**
 * 获取当前用户的 AK 信息
 */
export function getAkInfo(): Promise<IAkInfo> {
  return post('/user/getAk');
}

/**
 * 校验用户名是否唯一
 */
export function checkNameExists(name: String): Promise<boolean> {
  return post('/user/checkNameExists', {
    account: name,
  });
}

// 检查手机和邮箱是否为宜
export function checkPhoneOrEmailExists(
  text: String,
  type: 'email' | 'phone',
  userId?: string,
): Promise<boolean> {
  return post('/user/checkPhoneOrEmailExists', {
    param: text,
    type,
    userId,
  });
}

export function useQueryUserBaseListPage() {
  const schema = gql`
    query queryUserBaseListPage($queryUserListRequest: QueryUserListRequest) {
      queryUserBaseListPage(queryUserListRequest: $queryUserListRequest) {
        data {
          ${userBaseInfo}
        }
                 total
        pageNumber
        pageSize
      }
    }
  `;

  const { run, loading } = useGqlLazyQuery<
    { queryUserBaseListPage: IListItems<IUser> },
    { queryUserListRequest: IQueryUserListRequest }
  >(schema);

  return {
    queryUserBaseListPage: run,
    loading,
  };
}

/**
 * 获取当前租户下的用户列表（包含用户所属组的信息）
 */
export function useQueryUserList() {
  const schema = gql`
    query queryUserListPage($queryUserListRequest: QueryUserListRequest) {
      queryUserListPage(queryUserListRequest: $queryUserListRequest) {
        data {
          ${userDetail} 
        }
        total
        pageNumber
        pageSize
      }
    }
  `;

  const { run, loading } = useGqlLazyQuery<
    { queryUserListPage: IListItems<IUserWithAuthority> },
    { queryUserListRequest: IQueryUserListRequest }
  >(schema);

  return {
    queryUserList: run,
    loading,
  };
}

// 获取用户详情接口
export function useQueryUserDetail() {
  const schema = gql`
    query queryUserDetail($userId: String) {
      queryUserDetail(userId: $userId) {
        userId
        nickname
        account
        userSource
        phone
        email
        disableProperties
        resetPasswordFirstLogin
        authorityResource {
          authorityRole
          authoritySource
          authority {
            canEdit
            canDelete
            canAuth
            authRoleList
          }
        }
        groups
      }
    }
  `;

  const { run, loading } = useGqlLazyQuery<
    { queryUserDetail: IUserDetail },
    { userId: string }
  >(schema);

  return {
    queryUserDetail: run,
    loading,
  };
}

/**
 * 新增用户
 */
export interface IUserRequest
  extends Omit<IUser, 'photo' | 'ban' | 'creationMode'> {
  email?: string;
  phone?: string;
  password?: string;
  authorityRole: EUserRole;
  groupList: string[];
  resetPasswordFirstLogin: boolean; // 登录后是否重置密码
}

export function useCreateUser() {
  const schema = gql`
    mutation createUser($userRequest: UserRequest) {
      createUser(userRequest: $userRequest)
    }
  `;

  const { run, loading } = useGqlMutation<
    { createUser: boolean },
    {
      userRequest: IUserRequest;
    }
  >(schema);

  return {
    createUser: run,
    loading,
  };
}

/**
 * 编辑用户
 */
export function useUpdateUser() {
  const schema = gql`
    mutation updateUser($userRequest: UserRequest) {
      updateUser(userRequest: $userRequest)
    }
  `;

  const { run, loading } = useGqlMutation<
    { updateUser: boolean },
    { userRequest: IUserRequest }
  >(schema);

  return {
    updateUser: run,
    loading,
  };
}

/**
 * 删除用户
 */
export function useDeleteUser() {
  const schema = gql`
    mutation deleteUser($userId: String) {
      deleteUser(userId: $userId)
    }
  `;

  const { run, loading } = useGqlMutation<
    { deleteUser: boolean },
    { userId: string }
  >(schema);

  return {
    deleteUser: run,
    loading,
  };
}

/**
 * 获取用户组列表
 */
export const useQueryAllGroup = (opt?: { skip?: boolean }) => {
  const schema = gql`
    query queryAllGroup {
      queryAllGroup {
        groupId
        name
        parentGroupId
        frontGroupId
        creationMode
      }
    }
  `;

  const { data, refetch, loading } = useGqlQuery<
    { queryAllGroup: IUserGroup[] },
    {}
  >(schema, {}, opt);

  return {
    queryAllGroup: refetch,
    loading,
    data,
  };
};

/**
 * 新建用户组
 */
export const useCreateGroup = () => {
  const schema = gql`
    mutation createGroup($groupRequest: GroupRequest) {
      createGroup(groupRequest: $groupRequest)
    }
  `;

  const { run, loading } = useGqlMutation<
    { createGroup: string },
    { groupRequest: IUserGroup }
  >(schema);

  return {
    createGroup: (groupRequest: IUserGroup) => {
      return run({
        groupRequest,
      })
        .then((data) => {
          return data.data?.createGroup || '';
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
    loading,
  };
};

/**
 * 修改用户组
 */
export const useUpdateGroup = () => {
  const schema = gql`
    mutation updateGroup($groupRequest: GroupRequest) {
      updateGroup(groupRequest: $groupRequest)
    }
  `;

  const { run, loading } = useGqlMutation<
    { updateGroup: boolean },
    { groupRequest: IUserGroup }
  >(schema);

  return {
    updateGroup: (groupRequest: IUserGroup) => {
      return run({
        groupRequest,
      })
        .then((data) => {
          return !!data.data?.updateGroup;
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
    loading,
  };
};

/**
 * 移动用户组
 */
export const useMoveGroup = () => {
  const schema = gql`
    mutation moveGroup($groupRequest: GroupRequest) {
      moveGroup(groupRequest: $groupRequest)
    }
  `;

  const { run, loading } = useGqlMutation<
    { moveGroup: boolean },
    { groupRequest: IUserGroup }
  >(schema);

  return {
    moveGroup: (groupRequest: IUserGroup) => {
      return run({
        groupRequest,
      })
        .then((data) => {
          return !!data.data?.moveGroup;
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
    loading,
  };
};

/**
 * 删除用户组
 */
export const useDeleteGroup = () => {
  const schema = gql`
    mutation deleteGroup($groupId: String) {
      deleteGroup(groupId: $groupId)
    }
  `;

  const { run, loading } = useGqlMutation<
    { deleteGroup: boolean },
    { groupId: string }
  >(schema);

  return {
    deleteGroup: (groupId: string) => {
      return run({
        groupId,
      })
        .then((data) => {
          return !!data.data?.deleteGroup;
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    },
    loading,
  };
};

/**
 * 添加用户到分组
 */
export const useAddUserToGroup = () => {
  const schema = gql`
    mutation addUserGroup($groupId: String, $userIds: [String]) {
      addUserGroup(groupId: $groupId, userIds: $userIds)
    }
  `;

  const { run, loading } = useGqlMutation<
    { addUserGroup: boolean },
    { groupId: string; userIds: string[] }
  >(schema);

  return {
    addUserToGroup: run,
    loading,
  };
};

/**
 * 从分组中移除用户
 */
export const useDeleteUserFromGroup = () => {
  const schema = gql`
    mutation deleteUserGroup($groupId: String, $userIds: [String]) {
      deleteUserGroup(groupId: $groupId, userIds: $userIds)
    }
  `;

  const { run, loading } = useGqlMutation<
    { deleteUserGroup: boolean },
    { groupId: string; userIds: string[] }
  >(schema);

  return {
    deleteUserGroup: run,
    loading,
  };
};

/**
 * 查询分组下的所有资源
 */
export const useQueryUserOrGroupInGroup = () => {
  const schema = gql`
    query queryUserGroup($groupId: String) {
      queryUserGroup(groupId: $groupId) {
        groupId
        name
        groups {
          groupId
          name
          parentGroupId
          frontGroupId
        }
        users {
          userId
          nickname
          account
          photo
        }
      }
    }
  `;

  const { run, loading } = useGqlLazyQuery<
    { queryUserGroup: IUserGroupResponse },
    { groupId: string }
  >(schema);

  return {
    queryUserOrGroupInGroup: run,
    loading,
  };
};

/**
 * 创建用户属性定义
 */
export const useAddUserPropertyDef = () => {
  const schema = gql`
    mutation addUserPropertyDef(
      $userPropertyDefRequest: UserPropertyDefRequest
    ) {
      addUserPropertyDef(userPropertyDefRequest: $userPropertyDefRequest)
    }
  `;

  const { run, loading } = useGqlMutation<
    { addUserPropertyDef: boolean },
    { userPropertyDefRequest: { name: string; displayName: string } }
  >(schema);

  return {
    addUserPropertyDef: run,
    loading,
  };
};

/**
 * 修改用户属性定义
 */
export const useUpdateUserPropertyDef = () => {
  const schema = gql`
    mutation updateUserPropertyDef(
      $userPropertyDefRequest: UserPropertyDefRequest
    ) {
      updateUserPropertyDef(userPropertyDefRequest: $userPropertyDefRequest)
    }
  `;

  const { run, loading } = useGqlMutation<
    { updateUserPropertyDef: boolean },
    { userPropertyDefRequest: { name: string; displayName: string } }
  >(schema);

  return {
    updateUserPropertyDef: run,
    loading,
  };
};

/**
 * 删除用户属性定义
 */
export const useDeleteUserPropertyDef = () => {
  const schema = gql`
    mutation deleteUserPropertyDef($name: String) {
      deleteUserPropertyDef(name: $name)
    }
  `;

  const { run, loading } = useGqlMutation<
    { deleteUserPropertyDef: boolean },
    { name: string }
  >(schema);

  return {
    deleteUserPropertyDef: run,
    loading,
  };
};

/**
 * 批量保存用户属性
 */
export const useBatchSaveUserProperty = () => {
  const schema = gql`
    mutation batchSaveUserProperty(
      $userPropertyRequests: [UserPropertyRequest]
    ) {
      batchSaveUserProperty(userPropertyRequests: $userPropertyRequests)
    }
  `;

  const { run, loading } = useGqlMutation<
    { batchSaveUserProperty: boolean },
    {
      userPropertyRequests: Omit<IUserProperty, 'disableProperties'>[];
    }
  >(schema);

  return {
    batchSaveUserProperty: run,
    loading,
  };
};

/**
 * 查询所有的用户属性定义
 */
export const useQueryAllUserPropertyDef = () => {
  const schema = gql`
    query queryAllUserPropertyDef {
      queryAllUserPropertyDef {
        name
        displayName
        type
      }
    }
  `;

  const { run, loading } = useGqlLazyQuery<
    {
      queryAllUserPropertyDef: IUserPropertyDef[];
    },
    {}
  >(schema);

  return {
    queryUserPropertyDefs: run,
    loading,
  };
};

/**
 * 查询全量用户属性
 */
export const useQueryAllUserProperty = () => {
  const schema = gql`
    query queryAllUserProperty {
      queryAllUserProperty {
        account
        disableProperties
        properties {
          name
          value
          userPropertyDef {
            name
            displayName
            type
          }
        }
      }
    }
  `;

  const { run, loading } = useGqlLazyQuery<
    {
      queryAllUserProperty: IUserProperty[];
    },
    {}
  >(schema);

  return {
    queryAllUserProperties: run,
    loading,
  };
};

/**
 * 根据 ID 查询用户和用户组
 */
export function queryUserAndGroupByIds(
  req: IQueryUserAndGroupReq,
): Promise<IQueryUserAndGroupRes> {
  return post('/user/queryUserAndGroup', req);
}

// 查询用户同步配置
export function useQueryUserSyncConfig() {
  const schema = gql`
    query queryUserSyncConfig {
      queryUserSyncConfig {
        sourceType
        sourceConfig
        syncConfig {
          syncMode
          cron
        }
        unMatchUserProcessing
        authorityRole
        layout
        status
      }
    }
  `;

  const { run, loading } = useGqlLazyQuery<
    {
      queryUserSyncConfig: TUserSyncConfig;
    },
    {}
  >(schema);

  return {
    queryUserSyncConfig: run,
    loading,
  };
}

// 查询办公软件绑定同步配置
export function useQueryUserSoftBindConfig() {
  const schema = gql`
    query queryUserSoftBindConfig {
      queryUserSoftBindConfig {
        type
        softIntegrationDetail {
          code
          type
        }
        sourceKey
        targetKey
        syncConfig {
          syncMode
          cron
        }
        status
      }
    }
  `;

  const { run, loading } = useGqlLazyQuery<
    {
      queryUserSoftBindConfig: TUserSoftBindConfig[];
    },
    {}
  >(schema);

  return {
    queryUserSoftBindConfig: run,
    loading,
  };
}

// 更改用户状态（冻结、启用）
export const useUpdateUserStatus = () => {
  const schema = gql`
    mutation updateUserBanStatus($userId: String, $ban: Boolean) {
      updateUserBanStatus(userId: $userId, ban: $ban)
    }
  `;

  const { run, loading } = useGqlMutation<
    { updateUserStatus: boolean },
    {
      userId: string;
      ban: boolean;
    }
  >(schema);

  return {
    updateUserStatus: run,
    loading,
  };
};
/**
 * 获取用户导入的默认密码
 */
export function getUserImportDefaultPwd(): Promise<string> {
  return get('/user/tip');
}

// 保存同步配置
export const useSaveUserSyncConfig = () => {
  const schema = gql`
    mutation saveUserSyncConfig($userSyncConfigRequest: UserSyncConfigRequest) {
      saveUserSyncConfig(userSyncConfigRequest: $userSyncConfigRequest)
    }
  `;

  const { run, loading } = useGqlMutation<
    { saveUserSyncConfig: boolean },
    { userSyncConfigRequest: TUserSyncConfig }
  >(schema);

  return {
    saveUserSyncConfig: run,
    loading,
  };
};

// 获取用户/用户组最后一次同步记录
export const useQueryLastSyncRecord = () => {
  const schema = gql`
    query queryLastExecuteRecord($type: String) {
      queryLastExecuteRecord(type: $type) {
        type
        config
        syncMode
        executeTime
        finishTime
        status
      }
    }
  `;

  const { run, loading } = useGqlLazyQuery<
    {
      queryLastExecuteRecord: IExecuteRecord;
    },
    { type: EExecuteType }
  >(schema);

  return {
    queryLastExecuteRecord: run,
    loading,
  };
};

// 触发同步
export const useTriggerSync = () => {
  const schema = gql`
    mutation triggerSync($type: String) {
      triggerSync(type: $type)
    }
  `;

  const { run, loading } = useGqlMutation<
    { triggerSync: boolean },
    {
      type: EExecuteType;
    }
  >(schema);

  return {
    triggerSync: run,
    loading,
  };
};

// 查询用户组同步配置
export const useQueryGroupSyncConfig = () => {
  const schema = gql`
    query queryGroupSyncConfig {
      queryGroupSyncConfig {
        sourceType
        sourceConfig
        syncConfig {
          syncMode
          cron
        }
        status
        layout
      }
    }
  `;

  const { run, loading } = useGqlLazyQuery<
    {
      queryGroupSyncConfig: TGroupSyncConfig;
    },
    {}
  >(schema);

  return {
    queryGroupSyncConfig: run,
    loading,
  };
};

// 更新用户组同步配置
export const useSaveGroupSyncConfig = () => {
  const schema = gql`
    mutation saveGroupSyncConfig(
      $groupSyncConfigRequest: GroupSyncConfigRequest
    ) {
      saveGroupSyncConfig(groupSyncConfigRequest: $groupSyncConfigRequest)
    }
  `;

  const { run, loading } = useGqlMutation<
    { saveGroupSyncConfig: boolean },
    {
      groupSyncConfigRequest: TGroupSyncConfig;
    }
  >(schema);

  return {
    saveGroupSyncConfig: run,
    loading,
  };
};

// updateUserBaseInfo(userRequest:UserRequest):Boolean
export const useUpdateUserBaseInfo = () => {
  const schema = gql`
    mutation updateUserBaseInfo($userRequest: UserRequest) {
      updateUserBaseInfo(userRequest: $userRequest)
    }
  `;

  const { run, loading } = useGqlMutation<
    { updateUserBaseInfo: boolean },
    {
      userRequest: Partial<IUserRequest>;
    }
  >(schema);

  return {
    updateUserBaseInfo: run,
    loading,
  };
};

export const useQuerySoftPropertyKeyList = () => {
  const schema = gql`
    query querySoftPropertyKeyList($type: String) {
      querySoftPropertyKeyList(type: $type) {
        name
        displayName
      }
    }
  `;
  return useGqlLazyQuery<
    {
      querySoftPropertyKeyList: ISoftwareProperty[];
    },
    {
      type: string;
    }
  >(schema);
};

// 获取办公软件中的userId
export const useQuerySoftUserIdsPage = () => {
  const schema = gql`
    query queryMappingIdPage(
      $mappingIdPageRequest: MappingIdPageRequest
      $softIntegrationRequest: SoftIntegrationDetailRequest
    ) {
      queryMappingIdPage(
        mappingIdPageRequest: $mappingIdPageRequest
        softIntegrationRequest: $softIntegrationRequest
      ) {
        total
        data
        pageNumber
        pageSize
        hasNext
      }
    }
  `;

  return useGqlLazyQuery<
    {
      queryMappingIdPage: IListItems<string>;
    },
    {
      mappingIdPageRequest: {
        keyword?: string;
        pager: IPagerInput;
      };
      softIntegrationRequest: ISoftIntegrationBaseInfo;
    }
  >(schema);
};

// 立即执行配置
export const useTriggerUserSoftBindConfig = () => {
  const schema = gql`
    mutation triggerUserSoftBindConfig(
      $userSoftBindConfigRequest: UserSoftBindConfigRequest
    ) {
      triggerUserSoftBindConfig(
        userSoftBindConfigRequest: $userSoftBindConfigRequest
      )
    }
  `;

  return useGqlMutation<
    { triggerUserSoftBindConfig: boolean },
    {
      userSoftBindConfigRequest: TUserSoftBindConfig;
    }
  >(schema);
};

// 保存办公软件配置
export const useSaveUserSoftBindConfig = () => {
  const schema = gql`
    mutation saveUserSoftBindConfig(
      $userSoftBindConfigRequest: [UserSoftBindConfigRequest]
    ) {
      saveUserSoftBindConfig(
        userSoftBindConfigRequest: $userSoftBindConfigRequest
      )
    }
  `;

  return useGqlMutation<
    { saveUserSyncConfig: boolean },
    {
      userSoftBindConfigRequest: TUserSoftBindConfig[];
    }
  >(schema);
};

// 查询用户绑定配置详情
export const useQueryBindUserDetailList = (userId: string) => {
  const schema = gql`
    query queryBindUserDetail($userId: String) {
      queryBindUserDetail(userId: $userId) {
        userId
        softIntegrationDetail {
          code
          type
        }
        type
        sourceKey
        targetKey
        mappingId
        mappingStatus
      }
    }
  `;

  return useGqlQuery<
    {
      queryBindUserDetail: IBindUserDetail[];
    },
    {
      userId: string;
    }
  >(schema, {
    userId,
  });
};

// 为用户绑定第三方账户
export const useBinsUserMappingId = () => {
  const schema = gql`
    mutation bindUserMappingId(
      $bindUserMappingRequest: BindUserMappingRequest
    ) {
      bindUserMappingId(bindUserMappingRequest: $bindUserMappingRequest)
    }
  `;

  return useGqlMutation<
    {
      bindUserMappingId: boolean;
    },
    {
      bindUserMappingRequest: {
        userId: string;
        mappingId: string;
        softIntegration: ISoftIntegrationBaseInfo;
      };
    }
  >(schema);
};

// 检测第三方的id是否已经被绑定
// 为用户绑定第三方账户
export const useCheckMappingIdExists = () => {
  const schema = gql`
    mutation checkMappingIdExists(
      $bindUserMappingRequest: BindUserMappingRequest
    ) {
      bindUserMappingId(bindUserMappingRequest: $bindUserMappingRequest)
    }
  `;

  return useGqlMutation<
    {
      bindUserMappingId: boolean;
    },
    {
      bindUserMappingRequest: {
        userId: string;
        mappingId: string;
        softIntegration: ISoftIntegrationBaseInfo;
      };
    }
  >(schema);
};

// 获取当前租户的默认登录方式
export const useQueryDefaultLoginCode = (manual = true) => {
  const { loading, data, runAsync } = useRequest<ILoginDefaultCode, []>(
    () => get<ILoginDefaultCode>('/queryDefaultLoginCode'),
    {
      manual,
    },
  );

  return {
    data,
    loading,
    queryDefaultLoginCode: runAsync,
  };
};

// 保存默认登录方式
export const useUpdateDefaultLoginCode = () => {
  const schema = gql`
    mutation updateDefaultLoginCode($type: String, $code: String) {
      updateDefaultLoginCode(type: $type, code: $code)
    }
  `;

  return useGqlMutation<
    {
      updateDefaultLoginCode: boolean;
    },
    {
      type: string;
      code: string;
    }
  >(schema);
};

// 设置当前用户的语言
export const useSetLanguage = () => {
  const schema = gql`
    mutation setLanguage($language: Language) {
      setLanguage(language: $language)
    }
  `;

  return useGqlMutation<
    {
      setLanguage: boolean;
    },
    { language: ELanguage }
  >(schema);
};
