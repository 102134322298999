import t from '@/locales';
import { EOriginDataType } from '@/typings/formula';
import { EColumnDataType } from '@/typings';
export const originDataType2DataTypeMap: Record<
  EOriginDataType,
  EColumnDataType
> = {
  [EOriginDataType.BIGINT]: EColumnDataType.INT,
  [EOriginDataType.INT]: EColumnDataType.INT,
  [EOriginDataType.INTEGER]: EColumnDataType.INT,
  [EOriginDataType.SMALLINT]: EColumnDataType.INT,
  [EOriginDataType.TINYINT]: EColumnDataType.INT,
  [EOriginDataType.BOOLEAN]: EColumnDataType.BOOLEAN,
  [EOriginDataType.DOUBLE]: EColumnDataType.DOUBLE,
  [EOriginDataType.FLOAT]: EColumnDataType.DOUBLE,
  [EOriginDataType.REAL]: EColumnDataType.DOUBLE,
  [EOriginDataType.DECIMAL]: EColumnDataType.DECIMAL,
  [EOriginDataType.VARCHAR]: EColumnDataType.TEXT,
  [EOriginDataType.VARBINARY]: EColumnDataType.TEXT,
  [EOriginDataType.CHAR]: EColumnDataType.TEXT,
  [EOriginDataType.TABLE]: EColumnDataType.TEXT,
  [EOriginDataType.LIST]: EColumnDataType.TEXT,
  [EOriginDataType.STRING]: EColumnDataType.TEXT,
  [EOriginDataType.JSON]: EColumnDataType.JSON,
  [EOriginDataType.STRUCT]: EColumnDataType.JSON,
  [EOriginDataType.MAP]: EColumnDataType.JSON,
  [EOriginDataType.ARRAY]: EColumnDataType.JSON,
  [EOriginDataType.DATETIME]: EColumnDataType.DATE_TIME,
  [EOriginDataType.TIME]: EColumnDataType.DATE_TIME,
  [EOriginDataType.TIMESTAMP]: EColumnDataType.DATE_TIME,
  [EOriginDataType.TIMESTAMP_NTZ]: EColumnDataType.DATE_TIME,
  [EOriginDataType.TIME_WITH_TIME_ZONE]: EColumnDataType.DATE_TIME,
  [EOriginDataType.TIMESTAMP_WITH_TIME_ZONE]: EColumnDataType.DATE_TIME,
  [EOriginDataType.INTERVAL_DAY_TO_SECOND]: EColumnDataType.DATE_TIME,
  [EOriginDataType.INTERVAL_YEAR_TO_MONTH]: EColumnDataType.DATE_TIME,
  [EOriginDataType.DATE]: EColumnDataType.DATE,
  [EOriginDataType.VARIANT]: EColumnDataType.JSON,
};
export const dataType2OriginDataTypeMap: Record<
  EColumnDataType,
  EOriginDataType
> = {
  [EColumnDataType.INT]: EOriginDataType.BIGINT,
  [EColumnDataType.TEXT]: EOriginDataType.VARCHAR,
  [EColumnDataType.BOOLEAN]: EOriginDataType.BOOLEAN,
  [EColumnDataType.DATE]: EOriginDataType.DATE,
  [EColumnDataType.JSON]: EOriginDataType.JSON,
  [EColumnDataType.DECIMAL]: EOriginDataType.DECIMAL,
  [EColumnDataType.DOUBLE]: EOriginDataType.DOUBLE,
  [EColumnDataType.DATE_TIME]: EOriginDataType.TIMESTAMP,
};
export const DSL_METRIC = t.formula.dashboard.DSL.metric;
export const DSL_SOURCE = t.formula.dataModel.DSL.source;
