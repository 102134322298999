import styles from './index.less';
import {
  ESelectMode,
  IDataItem,
  IDisabledMap,
} from '../../../metric/Picker/types';
import { EResourceType } from '@/typings/authority';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import _ from 'lodash';
import { ALL_DIMENSION_LENGTH, metricTimeColumn } from '@/constants';
import ColumnDataTypeIcon from '@/common/ui/ColumnDataTypeIcon';
import { ReactComponent as DimensionSvg } from '@/assets/icon/Dimension-light-line.svg';
import { PickerTooltip } from './Tooltip';
import classNames from 'classnames';
import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import { filterByKeyword, isMetricTimeDimension } from '@/common/utils';
import { getEnableCode } from '@/common/globalInfo/appConfig';
import TreePicker, {
  IReqParams,
  ITreePickerRef,
} from '../../../metric/Picker/TreePicker';
import { ECategoryType } from '@/typings/category';
import { useGetCategoryList } from '@/services/category';
import { DATATYPE_COLOR } from '../constants';
import { TDimension } from '@/typings/dimension';
import { useQueryDimensionList } from '@/services/dimension';
import { EOriginDataType } from '@/typings';

function DimensionTreePicker(props: IProps, ref: React.Ref<ITreePickerRef>) {
  const {
    dimensions: propsDimensions,
    size,
    filterCandidate,
    showDataType = true,
    showMetricTime = true,
    supportTimeGranularity,
    loading: propsLoading,
    renderNode,
    ...otherProps
  } = props;

  const { run: queryDimensionList } = useQueryDimensionList();

  const { data: categoryData, loading } = useGetCategoryList({
    categoryType: ECategoryType.CATEGORY_DIMENSION,
  });

  const queryDataList = useCallback(
    (params: IReqParams) => {
      const { keyword, categoryId } = params;
      return queryDimensionList({
        request: {
          keyword,
          categoryId,
          pager: {
            pageNumber: 1,
            pageSize: ALL_DIMENSION_LENGTH,
          },
        },
      }).then((res) => res.queryDimensionList.data || []);
    },
    [queryDimensionList],
  );

  const ICON_SIZE_SMALL = 16;
  const ICON_SIZE_MIDDLE = 20;
  const iconSize = size === 'small' ? ICON_SIZE_SMALL : ICON_SIZE_MIDDLE;

  const renderItem = useCallback(
    (item: IDataItem<TDimension>) => {
      const { data: dimension, disabled, disabledReason } = item;
      const itemNode = (
        <PickerTooltip dimension={dimension} reason={disabledReason}>
          <div
            className={classNames(
              styles.dimensionItem,
              {
                [styles.disabled]: disabled,
                [styles.small]: size === 'small',
              },
              classNames || '',
            )}
            key={dimension.name}
          >
            <div className={styles.dataType}>
              {showDataType ? (
                <ColumnDataTypeIcon
                  dataType={
                    originDataType2DataTypeMap[dimension.originDataType]
                  }
                  size={iconSize}
                  color={DATATYPE_COLOR}
                />
              ) : (
                <DimensionSvg size={iconSize} />
              )}
            </div>
            <div className={styles.nameWrapper}>
              <div className={styles.name}>
                {dimension.displayName || dimension.dimName}
              </div>
            </div>
          </div>
        </PickerTooltip>
      );
      return renderNode ? renderNode(itemNode, dimension, disabled) : itemNode;
    },
    [iconSize, showDataType, size, renderNode],
  );

  const enableCode = getEnableCode();

  const filterItem = useCallback(
    (dataItem: TDimension, keyword: string) => {
      // FIXME: 后续删除下面2行判断
      if (dataItem.authority?.canUsage === false) {
        return false;
      }
      if (dataItem.isPublished === false) {
        return false;
      }

      if (typeof filterCandidate === 'function') {
        const res = filterCandidate(dataItem);
        if (res === false) {
          return res;
        }
      }
      const trimKeyword = _.trim(keyword);
      if (trimKeyword) {
        const filteredList = filterByKeyword(
          [dataItem],
          [
            (item) => item.displayName,
            (item) => item.dimName,
            (item) => (enableCode ? item.dimCode || '' : ''),
          ],
          keyword,
        );
        if (filteredList.length === 0) {
          return false;
        }
      }
      return true;
    },
    [enableCode, filterCandidate],
  );

  const getKey = useCallback((dataItem: TDimension) => {
    return dataItem.name;
  }, []);

  useImperativeHandle(ref, () => ({
    collapseAll: () => {
      treeRef.current?.collapseAll();
    },
  }));

  const treeRef = useRef<ITreePickerRef>(null);

  // 是否展示"指标日期"维度，只根据 showMetricTime 判断，因此排除掉传入的维度列表中的"指标日期"维度
  const propsDimensionsWithoutMetricTime = useMemo(() => {
    return propsDimensions
      ? _.filter(
          propsDimensions,
          (dimension) => !isMetricTimeDimension(dimension),
        )
      : undefined;
  }, [propsDimensions]);

  const metricTimeDimension = useMemo(() => {
    const metricTimeDim = _.cloneDeep(metricTimeColumn) as TDimension;
    if (supportTimeGranularity) {
      metricTimeDim.originDataType = EOriginDataType.TIMESTAMP;
    }
    return metricTimeDim;
  }, [supportTimeGranularity]);

  return (
    <TreePicker<TDimension>
      {...otherProps}
      ref={treeRef}
      dataItems={propsDimensionsWithoutMetricTime}
      renderItem={renderItem}
      filterCandidate={filterItem}
      getKey={getKey}
      queryDataList={queryDataList}
      categories={categoryData || []}
      loading={propsLoading || loading}
      iconSize={iconSize}
      hideLocalStorageKey="IS_HIDE_UNAVAILABLE_DIMENSIONS"
      staticDataItems={showMetricTime ? [metricTimeDimension] : []}
      size={size}
    />
  );
}

export interface IProps {
  dimensions?: TDimension[];
  value?: TDimension; // 单选选中的指标
  onChange?: (dimension: TDimension) => void; // 单选选中的回调
  picked?: TDimension[]; // 选中的指标列表
  onPick?: (dimensions: TDimension[]) => void; // 批量勾选
  size?: 'small';
  showDataType?: boolean; // 是否显示数据类型，否则显示指标类型，在集中授权需要渲染成指标类型
  getDisabledMap?: () => IDisabledMap; // 禁用的 map
  renderNode?: (
    node: React.ReactNode,
    dimension: TDimension,
    disabled: boolean,
  ) => React.ReactNode; // 用于自定义支持拖拽的节点
  showSearch?: boolean; // 显示搜索框
  // 查询候选项时的代持资源信息，存在代持的情况下，不要根据当前用户的 canUsage 权限过滤候选列表
  queryListMeta?: {
    resourceType: EResourceType;
    resourceId: string;
  };
  mode?: ESelectMode; // 选择模式
  filterCandidate?: (dimension: TDimension) => boolean; // 过滤候选列表，如：指标详情页排除当前指标自身
  showUnAvailableSwitch?: boolean; // 显示不可用开关
  showMetricTime?: boolean; // 显示"指标日期"维度
  supportTimeGranularity?: boolean; // 是否支持比日更小的时间粒度
  hideEmptyCategory?: boolean; // 是否隐藏空类目
  noPadding?: boolean; // 是否不显示左右内边距
  loading?: boolean; // 是否显示加载中
}

export default forwardRef(DimensionTreePicker);
