import {
  EFormulaType,
  EOriginDataType,
  IFunctionOperator,
  TCorrectFormula,
  TFormula,
} from '@/typings/formula';
import { getWrapCallOp } from './index';

/**
 * 移除日期抽取
 */
export const recoverDataTrunc = (ast: IFunctionOperator): TCorrectFormula => {
  return ast.args[0];
};

/**
 * 获取 dateTrunc 的日期粒度
 * TODO: 修改函数名为第二个参数
 */
export const getDateTruncGranularity = (ast: IFunctionOperator) => {
  const granularityDef = ast.args[1];

  if (
    granularityDef.type === EFormulaType.CONSTANT &&
    granularityDef.val.type === EOriginDataType.VARCHAR
  )
    return granularityDef.val.val;
};

/**
 * 获取 trunc 的日期粒度
 */
export const getTruncGranularity = (ast: IFunctionOperator) => {
  const granularityDef = ast.args[1];

  if (
    granularityDef.type === EFormulaType.CONSTANT &&
    granularityDef.val.type === EOriginDataType.BIGINT
  ) {
    return granularityDef.val.val;
  }
};
/**
 * 获取日期抽取的日期粒度
 */
export const getDatePartGranularity = (ast: IFunctionOperator) => {
  return getWrapCallOp(ast);
};

/**
 * 判断是否有效的公式内容
 */
export function isCorrectFormula(ast: TFormula): ast is TCorrectFormula {
  const invalidTypes = [EFormulaType.EMPTY, EFormulaType.PARTIAL];
  return !invalidTypes.includes(ast.type);
}
