import { graphql } from 'msw';
import { getMockCalendarData, mockCustomCalendarList } from './mockData';
export const customCalendar = [
  // 获取权限列表
  graphql.query('queryCustomCalendarList', (req, res, ctx) => {
    return res(
      ctx.data({
        queryCustomCalendarList: mockCustomCalendarList.sort(function () {
          return Math.random() - 0.5;
        }),
      }),
      ctx.delay(1000),
    );
  }),

  // 获取日历数据
  graphql.query('queryCalendarData', (req, res, ctx) => {
    return res(ctx.data({ queryCalendarData: getMockCalendarData() }));
  }),
];
