import { originDataType2DataTypeMap } from '@/common/domain/Formula/constant';
import {
  EColumnDataType,
  EDateGranularityType,
  EOriginDataType,
} from '@/typings';
import { TMetric } from '@/typings/metric';
import {
  ESortType,
  IDependencyItem,
  IDimension as IWorkbookDimension,
  IDocument,
  IMetric as IWorkbookMetric,
  IPivotTableElement,
  IVizElement,
  TElement,
  EWorkBookElementType,
  IFilterElement,
  EFilterComponentType,
  IMetricTreeElement,
  IMetric,
  TMetricTreeNode,
  EMetricTreeNodeType,
} from '@/typings/workbook';
import { IDimension } from '@/typings/dimension';
import _ from 'lodash';
import {
  dimensionList,
  metricList,
} from '../utils/getSimpleMetricList';
import { EFilterOperator } from '@/common/domain/filter/Filter/filter';
import { EAxisOrient } from '@/common/domain/viz/Echarts/common/vizStyles/common';
import { TDimension } from '@/typings/dimension';
import { EUnit } from '../../src/typings';
import { EVizType } from '@/common/domain/viz/Echarts/typing';
import { getUniqId } from '@/common/utils';
import { EColumnType } from '@/typings/dataset';
import { getDefaultFilter } from '@/pages/Metric/Detail/Content/Authority/RowFilterTable/EditConditionalRuleModal/DimensionFilter/helper';
import {
  EDateDynamicOperator,
  EDateValueType,
  EFilterType,
} from '@/common/domain/filter/NewFilter/types';
import { TMetricTime } from '@/typings/analysisView';
import { getInitialLogicNode } from '@aloudata/aloudata-design/dist/LogicTree/helper';

const pageId = 'page1';
const pages = [pageId];

export const pivotTableElementId = 'pivotTableElementId';
export const metricTreeElementId = 'metricTreeElementId';
export const lineChartElementId = 'lineChartElementId';

export const metricTimeFilter: TMetricTime = {
  active: false,
  granularity: EDateGranularityType.DAY,
  type: EFilterType.DATE_RANGE,
  values: {
    max: {
      operator: EDateDynamicOperator.DECREASE,
      type: EDateValueType.DYNAMIC,
      value: 179,
      unit: EDateGranularityType.DAY,
    },
    min: {
      operator: EDateDynamicOperator.DECREASE,
      type: EDateValueType.DYNAMIC,
      value: 0,
      unit: EDateGranularityType.DAY,
    },
  },
};

export default function getMockDocument(
  elements: TElement[] = [
    generateMetricTreeElement({
      elementId: metricTreeElementId,
      mainMetric: metricList[0],
      subMetrics: metricList.slice(1, 8),
    }),
    generatePivotTableElement({
      elementId: pivotTableElementId,
      rowDims: dimensionList.slice(0, 2),
      columnDims: dimensionList.slice(2, 4),
      valueMetrics: metricList.slice(0, 4),
    }),
    generateVizElement({
      elementId: lineChartElementId,
      type: EVizType.LINE_CHART,
      xAxisDim: dimensionList[0],
      legendDim: dimensionList[1],
      yAxisMetrics: metricList.slice(0, 4),
    }),
  ],
) {
  const elementMap = _.reduce<TElement, Record<string, TElement>>(
    elements,
    (acc, element) => {
      acc[element.id] = element;
      return acc;
    },
    {},
  );

  const dependencies = _.reduce<TElement, Record<string, IDependencyItem>>(
    _.values(elementMap),
    (acc, element) => {
      if (element.type === EWorkBookElementType.FILTER) {
        return acc;
      }
      acc[element.id] = {
        id: element.id,
        metrics: _(element.metricsMap).values().map('id').value(),
        dimensions: _(_.get(element, 'dimensionMap') || {})
          .values()
          .map('name')
          .value(),
      };
      return acc;
    },
    {},
  );
  const document: IDocument = {
    pages,
    pagesMap: {
      [pageId]: {
        pageId,
        title: 'page1',
      },
    },
    elementMap,
    dependencies,
    view: {
      activePage: pageId,
      activeElement: pivotTableElementId,
      activeColumns: [],
    },
  };
  return document;
}

export const generateDimension = (
  dimensionName: string,
  id: string,
): IWorkbookDimension => {
  const dim = _.find(dimensionList, { name: dimensionName })!;
  const dataType = originDataType2DataTypeMap[dim.originDataType];
  return {
    def: {
      dimensionName,
    },
    id,
    sort: {
      id,
      type: ESortType.DIMENSION,
      isAsc: true,
    },
    displayName: dim.displayName,
    dimensionDisplayName: dim.displayName,
    name: dim.name,
    granularity:
      dataType === EColumnDataType.DATE ||
      dataType === EColumnDataType.DATE_TIME
        ? _(EDateGranularityType).values().sample()!
        : undefined,
    dataType: dataType,
    originDataType: dim.originDataType,
    description: dim.description,
  };
};

export const generateMetric = (code: string, id: string): IWorkbookMetric => {
  const originDataType = _(EOriginDataType).values().sample()!;
  const dataType = originDataType2DataTypeMap[originDataType];
  return {
    def: {
      code: `${code}`,
    },
    id,
    name: `Metric ${code}`,
    displayName: `Metric ${code}`,
    originDataType: originDataType,
    dataType: dataType,
    description: 'Metric description',
    unit: EUnit.OTHER,
    filterDependency: {},
    filters: getInitialLogicNode([]),
    dateLimit: {
      disabled: true,
      preAggs: [],
      isPreAggCustom: false,
    },
  };
};

const generateMetricTreeElement = ({
  elementId,
  mainMetric,
  subMetrics,
}: {
  elementId: string;
  mainMetric: TMetric;
  subMetrics: TMetric[];
}): IMetricTreeElement => {
  const metricsMap = _.reduce<TMetric, Record<string, IWorkbookMetric>>(
    [mainMetric].concat(subMetrics),
    (acc, metric) => {
      const metricId = metric.code;
      acc[metricId] = generateMetric(metric.code, metricId);
      return acc;
    },
    {},
  );
  const subMetricIds = subMetrics.map((metric) => {
    const { code } = metric;
    const chartMetric = _.find(metricsMap, { def: { code } })!;
    return chartMetric.id;
  });
  return {
    id: elementId,
    type: EWorkBookElementType.METRIC_TREE,
    title: 'metricTree',
    pageId,
    layout: {
      width: 1280,
      height: 350,
      top: 0,
      left: 0,
    },
    filterDependency: {},
    metricTimeFilter: metricTimeFilter,
    metricsMap,
    mainMetric: mainMetric.code,
    subMetrics: subMetricIds,
    treeNodes: [
      {
        id: getUniqId(),
        type: EMetricTreeNodeType.METRIC,
        metricId: mainMetric.code,
        children: getMockTreeNodes(
          true,
          subMetricIds.map((metricId) => metricsMap[metricId]),
          1,
        ),
      },
    ],
    derivativeCalcList: [],
  };
};

function getMockTreeNodes(
  valid: boolean,
  metrics: IMetric[],
  maxLevel: number,
  level: number = 0,
): TMetricTreeNode[] {
  const MIN_NUM = 1;
  if (valid) {
    // 生成合法的四则运算表达式
    const exprItems = ['1', '+', '2', '*', '3'];
    return exprItems.map((item, i) => {
      if (isNaN(Number(item))) {
        return {
          id: getUniqId(),
          type: EMetricTreeNodeType.OPERATOR,
          content: item,
        };
      }
      return {
        id: getUniqId(),
        metricId: metrics[i]?.id,
        type: EMetricTreeNodeType.METRIC,
        children:
          level < maxLevel
            ? getMockTreeNodes(valid, metrics, maxLevel, level + 1)
            : [],
      };
    });
  }
  const arr = _.range(1, _.random(1 + MIN_NUM, metrics.length + 1));
  return _.map(arr, (i) => {
    const type = _.sample([
      EMetricTreeNodeType.METRIC,
      EMetricTreeNodeType.OPERATOR,
    ]);
    if (type === EMetricTreeNodeType.METRIC) {
      return {
        id: getUniqId(),
        metricId: metrics[i - 1].id,
        type,
        children:
          level < maxLevel
            ? getMockTreeNodes(false, metrics, maxLevel, level + 1)
            : [],
      };
    }
    return {
      id: `${level}-${i}`,
      type,
      content: _.sample(['+', '-', '*', '/']),
    };
  });
}

export const generatePivotTableElement = ({
  elementId,
  rowDims,
  columnDims,
  valueMetrics,
}: {
  elementId: string;
  rowDims: IDimension[];
  columnDims: IDimension[];
  valueMetrics: TMetric[];
}): IPivotTableElement => {
  return {
    id: elementId,
    pageId,
    title: 'pivotTable',
    layout: {
      width: 1280,
      height: 350,
      top: 200,
      left: 0,
    },
    filterDependency: {},
    metricTimeFilter: metricTimeFilter,
    dimensionsMap: _.reduce<IDimension, Record<string, IWorkbookDimension>>(
      rowDims.concat(columnDims),
      (acc, dim, index) => {
        const dimensionId = dim.name;
        acc[dimensionId] = generateDimension(dim.name, dimensionId);
        return acc;
      },
      {},
    ),
    metricsMap: _.reduce<TMetric, Record<string, IWorkbookMetric>>(
      valueMetrics,
      (acc, metric) => {
        const metricId = metric.code;
        acc[metricId] = generateMetric(metric.code, metricId);
        return acc;
      },
      {},
    ),
    type: EWorkBookElementType.PIVOT_TABLE,
    rows: rowDims.map((dim) => dim.name),
    columns: columnDims.map((dim) => dim.name),
    values: valueMetrics.map((metric) => metric.code),
  };
};

export const generateVizElement = ({
  elementId,
  type,
  xAxisDim,
  legendDim,
  yAxisMetrics,
}: {
  elementId: string;
  type: EVizType;
  xAxisDim: IDimension;
  legendDim: IDimension;
  yAxisMetrics: TMetric[];
}): IVizElement => {
  return {
    id: elementId,
    xAxis: xAxisDim.name,
    yAxis: yAxisMetrics.map((metric) => metric.code),
    pageId,
    title: type as string,
    layout: {
      width: 1280,
      height: 350,
      top: 400,
      left: 0,
    },
    filterDependency: {},
    metricTimeFilter: metricTimeFilter,
    dimensionsMap: _.reduce<IDimension, Record<string, IWorkbookDimension>>(
      [xAxisDim, legendDim],
      (acc, dim) => {
        const dimensionId = dim.name;
        acc[dimensionId] = generateDimension(dim.name, dimensionId);
        return acc;
      },
      {},
    ),
    metricsMap: _.reduce<TMetric, Record<string, IWorkbookMetric>>(
      yAxisMetrics,
      (acc, metric, index) => {
        const metricId = metric.code;
        acc[metricId] = generateMetric(metric.code, metricId);
        return acc;
      },
      {},
    ),
    type,
    axisOrient: yAxisMetrics.reduce(
      (result, metric) => ({
        ...result,
        [metric.code]: EAxisOrient.LEFT,
      }),
      {},
    ),
  };
};

export function generateFilterElement({
  elementId,
  dimension,
}: {
  elementId: string;
  dimension: IDimension;
}): IFilterElement {
  const filterDepId = dimension.name;
  const dimDataType = originDataType2DataTypeMap[dimension.originDataType];

  return {
    type: EWorkBookElementType.FILTER,
    id: elementId,
    pageId: 'page1',
    title: 'filter element',
    layout: {
      width: 400,
      height: 100,
      top: 0,
      left: 0,
    },
    showTitle: true, // 是否显示标题
    filterDependency: {
      [filterDepId]: {
        id: filterDepId,
        def: {
          dimensionName: dimension.name,
        },
        displayName: dimension.displayName,
        name: dimension.name,
        dimensionDisplayName: dimension.displayName,
        dataType: dimDataType,
        originDataType: dimension.originDataType,
        description: dimension.description,
        columnType: EColumnType.DIMENSION,
        isFillConfig: false,
      },
    },
    filters: {
      id: getUniqId(),
      logicalOperator: EFilterOperator.AND,
      children: [
        {
          id: getUniqId(),
          dependentId: filterDepId,
          filter: getDefaultFilter(dimDataType),
        },
      ],
    },
    componentType: EFilterComponentType.LIST,
  };
}

export function getExternalDependencies(document: IDocument): {
  dimensions: TDimension[];
  metrics: TMetric[];
} {
  const result = {
    dimensions: [],
    metrics: [],
  } as {
    dimensions: TDimension[];
    metrics: TMetric[];
  };

  _.forEach(document.dependencies, (dependency) => {
    const { id: elementId } = dependency;
    const element = document.elementMap[elementId];
    if (element.type === EWorkBookElementType.FILTER) {
      return;
    }
    const { metricsMap } = element;
    _.forEach(metricsMap, (metric) => {
      const {
        def: { code },
      } = metric;
      if (!_.find(result.metrics, { code })) {
        const metricInfo = _.find(metricList, { code })!;
        result.metrics.push(metricInfo);
      }
    });

    _.forEach(_.get(element, 'dimensionsMap', {}), (dimension) => {
      const {
        def: { dimensionName },
      } = dimension;

      if (!_.find(result.dimensions, { name: dimensionName })) {
        const dim = _.find(dimensionList, { name: dimensionName })!;
        result.dimensions.push(dim);
      }
    });
  });

  return result;
}
