import { EResourceType } from '@/typings/authority';
import { ECategoryProperty, ICategory } from '../../src/typings/category';
export const mockCategoryList: ICategory[] = [
  {
    id: 'category-1',
    name: '类目1',
    resourceCount: 4,
    parentId: null,
    frontId: null,
    type: ECategoryProperty.CATEGORY_CUSTOM,
    authority: {
      canEdit: true,
      canDelete: true,
      canUsage: true,
      canAuth: true,
      canTransfer: true,
    },
  },
  {
    id: 'category-2',
    name: '类目2',
    resourceCount: 0,
    parentId: null,
    frontId: 'category-1',
    type: ECategoryProperty.CATEGORY_CUSTOM,
    authority: {
      canEdit: true,
      canDelete: true,
      canUsage: true,
      canAuth: true,
      canTransfer: true,
    },
  },
  {
    id: 'category-2-1',
    name: '类目2-1',
    resourceCount: 0,
    parentId: 'category-2',
    frontId: null,
    type: ECategoryProperty.CATEGORY_CUSTOM,
    authority: {
      canEdit: true,
      canDelete: true,
      canUsage: true,
      canAuth: true,
      canTransfer: true,
    },
  },
  {
    id: 'category-2-2',
    name: '类目2-2',
    resourceCount: 0,
    parentId: 'category-2',
    frontId: 'category-2-1',
    type: ECategoryProperty.CATEGORY_CUSTOM,
    authority: {
      canEdit: true,
      canDelete: true,
      canUsage: true,
      canAuth: true,
      canTransfer: true,
    },
  },
  {
    id: 'category-2-2-1',
    name: '类目2-2-1',
    resourceCount: 0,
    parentId: 'category-2-2',
    frontId: null,
    type: ECategoryProperty.CATEGORY_CUSTOM,
    authority: {
      canEdit: true,
      canDelete: true,
      canUsage: true,
      canAuth: true,
      canTransfer: true,
      canCreateResources: [EResourceType.METRIC],
    },
  },
  {
    id: 'category-3',
    name: '类目3',
    resourceCount: 0,
    parentId: null,
    frontId: 'category-2',
    type: ECategoryProperty.CATEGORY_CUSTOM,
    authority: {
      canEdit: true,
      canDelete: true,
      canUsage: true,
      canAuth: true,
      canTransfer: true,
    },
  },
  {
    id: 'category-3-1',
    name: '类目3-1',
    resourceCount: 0,
    parentId: 'category-3',
    frontId: null,
    type: ECategoryProperty.CATEGORY_CUSTOM,
    authority: {
      canEdit: true,
      canDelete: true,
      canUsage: true,
      canAuth: true,
      canTransfer: true,
    },
  },
  {
    id: '-1',
    name: '未分类',
    resourceCount: 0,
    parentId: null,
    frontId: 'category-3',
    type: ECategoryProperty.CATEGORY_SYSTEM,
    authority: {
      canEdit: true,
      canDelete: true,
      canUsage: true,
      canAuth: true,
      canTransfer: true,
    },
  },
];
