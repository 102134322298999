import { isMock } from './common/utils/helper';
import {
  anymetricsHosts,
  ignoreHosts,
  loggerPath,
  sentryDSN,
  userInfo,
} from '@/common/globalInfo/appConfig';

// localhost打开网页的时候会启动 msw mock 服务
if (isMock) {
  const { worker } = require('../mocks/browser');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}
const APP_NAME = 'CAN-fe';
// 本地开发环境不上传
if (process.env.UMI_ENV !== 'develop') {
  reportError({
    sentryDSN,
    sentryTargetHosts: anymetricsHosts,
    ignoreHosts,
    customTargetHosts: [loggerPath],
    userInfo,
    appName: APP_NAME,
  });
}

import { IUser } from '@/typings';
import { init } from 'aloudata-tracking';
interface IReportErrorProps {
  sentryDSN: string;
  sentryTargetHosts: string[];
  ignoreHosts?: string[];
  customTargetHosts: string[];
  userInfo?: IUser;
  appName: string;
}

function reportError({ sentryTargetHosts, appName }: IReportErrorProps) {
  const host = window.location.hostname;

  if (ignoreHosts?.includes(host)) {
    return null;
  }

  // 如果是demo或者release环境就将错序信息发送到sentry
  if (sentryTargetHosts.includes(host)) {
    return init({
      sentryDSN,
      release: 'can_release',
      appName,
      user: {
        id: userInfo?.userId,
        username: userInfo?.nickname,
      },
    });
  }
  // else {
  //   init({
  //     sentryDSN: '',
  //     release: 'can_release',
  //     appName,
  //     user: {
  //       id: userInfo?.userId,
  //       username: userInfo?.nickname,
  //     },
  //     customTargetHosts,
  //   });
  // }
}
